import {ADDTOBUCKET, CLEAR_BUCKET, FETCH_USER_BUCKET, FETCH_USER_FAVS, GET_BUCKET, REMOVEFROMBUCKET } from '../constants/actionTypes';

export default (bucket = [], action) => {
  switch (action.type) {
    case GET_BUCKET:
      return action.payload;
    case ADDTOBUCKET:
      return [...bucket, action.payload];
    case REMOVEFROMBUCKET:
      return bucket.filter((bucketItem) => bucketItem !== action.payload);
    case FETCH_USER_BUCKET:
      if(!action.payload.length) {
        return bucket = [0];
      } else {
      return action.payload;
      }
    case CLEAR_BUCKET: 
      return bucket = [];
    default:
      return bucket;
  }
};