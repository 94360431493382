import React , { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Container, Grid, Typography, Avatar, IconButton } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import useStyles from './styles';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { getUserBucket} from '../../actions/user';
import { addToUserFavorites, removeFromUserFavorites } from '../../actions/favorites';
import { addToUserVisited, removeFromUserVisited } from '../../actions/favorites';
import { addToUserBucket, removeFromUserBucket } from '../../actions/favorites';
import { selectLocation } from '../../actions/locations';
import { useHistory } from 'react-router-dom';

const BucketCard = ({bucket, userId, canReact, setCanReact}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [favs, setFavs] = useState(bucket.favs);
    const [visits, setVisits] = useState(bucket.visits);
    const [buckets, setBuckets] = useState(bucket.buckets);
    const [placeDetails, setPlaceDetails] = useState({latitude: bucket.latitude, longitude: bucket.longitude, coordinates: bucket.coordinates, location: bucket.location, name:bucket.name, placeName:bucket.placeName, country: bucket.country});
    const user = JSON.parse(localStorage.getItem('profile'));
    const currentUserId = user?.result?.googleId || user?.result?.id;
    const [selectedLocation, setSelectedLocation] = useState({latitude: '', longitude: '', coordinates: '', location: '', name:'', placeName:'', country: ''});

    const handleClick = () => {
        setSelectedLocation({...selectedLocation, latitude: `${bucket.latitude}`, longitude: `${bucket.longitude}`, coordinates:`${bucket.coordinates}`, location:`${bucket.location}`, name:`${bucket.name}`, placeName:`${bucket.placeName}`, country:`${bucket.country}` });  
    } 
      useEffect(() => {
        if(selectedLocation.coordinates !== '') {
          dispatch(selectLocation(selectedLocation))
        }
          // console.log(selectedLocation);
          if(selectedLocation.coordinates){
              history.push('/search');
          }
        }, [handleClick]);

        useEffect(() => {
            setFavs(bucket.favs);
            setVisits(bucket.visits);
            setBuckets(bucket.buckets);
            setPlaceDetails({latitude: bucket.latitude, longitude: bucket.longitude, coordinates: bucket.coordinates, location: bucket.location, name:bucket.name, placeName:bucket.placeName, country: bucket.country})
          }, [bucket]);

          const addToFavs = () => {
            setCanReact(false);
            setFavs([...favs, currentUserId ])
            dispatch(addToUserFavorites(placeDetails)).then(() =>
            dispatch(getUserBucket(userId)).then(() => (
              setCanReact(true)
            ))
            )
          }
          const removeFromFavs = () => {
            setCanReact(false);
            setFavs(favs.filter((fav) => fav !== currentUserId));
            dispatch(removeFromUserFavorites(placeDetails.coordinates)).then(() =>
            dispatch(getUserBucket(userId)).then(() => (
              setCanReact(true)
            ))
            )
          }
      
          const addToVisits = () => {
            setCanReact(false);
            setVisits([...visits, currentUserId ])
            dispatch(addToUserVisited(placeDetails)).then(() =>
            dispatch(getUserBucket(userId)).then(() => (
              setCanReact(true)
            ))
            )
          }
          const removeFromVisits = () => {
            setCanReact(false);
            setVisits(visits.filter((visit) => visit !== currentUserId));
            dispatch(removeFromUserVisited(placeDetails.coordinates)).then(() =>
            dispatch(getUserBucket(userId)).then(() => (
              setCanReact(true)
            ))
            )
          }
        
          const addToBuckets = () => {
            setCanReact(false);
            setBuckets([...buckets, currentUserId]);
            dispatch(addToUserBucket(placeDetails)).then(() =>
            dispatch(getUserBucket(userId)).then(() => (
              setCanReact(true)
            ))
            )
          }
          const removeFromBuckets = () => {
            setCanReact(false);
            setBuckets(buckets.filter((bucket) => bucket !== currentUserId));
            dispatch(removeFromUserBucket(placeDetails.coordinates)).then(() =>
            dispatch(getUserBucket(userId)).then(() => (
              setCanReact(true)
            ))
            )
          }
          
          const Fav = () => {
            if(favs) {
            if(favs.length > 0) {
              return favs.find((fav) => fav === (currentUserId))
              ? (
                <>
                <IconButton size="small" style={{background:'#f7f7f7', marginRight:'3px', border:'1px solid #ededed'}} onClick={removeFromFavs} disabled = {canReact === false}>
                  <FavoriteIcon style={{color:'#fd297b'}} />
                </IconButton>
                </>
              ) : (
                <>
                <IconButton size="small" style={{background:'#f7f7f7', marginRight:'3px', border:'1px solid #ededed'}} onClick={addToFavs} disabled = {canReact === false}>
                  <FavoriteBorder style={{color:canReact && '#333'}} />
                  </IconButton>
                </>
              )
            } }
            return  <IconButton size="small" style={{background:'#f7f7f7', marginRight:'3px', border:'1px solid #ededed'}} onClick={addToFavs} disabled = {canReact === false}>
            <FavoriteBorder style={{color:canReact && '#333'}} />
            </IconButton>
          }
      
          const Visited = () => {
            if(visits) {
            if(visits?.length > 0) {
              return visits.find((visit) => visit === (currentUserId))
              ? (
                <>
                <IconButton size="small" style={{marginLeft:'10px', marginRight:'3px', background:'#f7f7f7', border:'1px solid #ededed'}} onClick={removeFromVisits} disabled = {canReact === false}>
                  <CheckCircleIcon style={{color:'#22c960'}} />
                </IconButton>
                </>
              ) : (
                <>
                <IconButton size="small" style={{marginLeft:'10px', marginRight:'3px', background:'#f7f7f7', border:'1px solid #ededed'}} onClick={addToVisits} disabled = {canReact === false}>
                  <CheckCircleOutline style={{color:canReact && '#333'}} />
                </IconButton>
                </>
              )
            } }
            return <IconButton size="small" style={{marginLeft:'10px', marginRight:'3px', background:'#f7f7f7', border:'1px solid #ededed'}} onClick={addToVisits} disabled = {canReact === false}>
            <CheckCircleOutline style={{color:canReact && '#333'}} />
            </IconButton> 
          }
      
          const Bucket = () => {
            if(buckets) {
            if(buckets?.length > 0) {
              return buckets.find((bucket) => bucket === (user?.result?.googleId || user?.result?.id))
              ? (
                <>
                <IconButton size="small" style={{marginLeft:'10px', marginRight:'3px', background:'#f7f7f7', border:'1px solid #ededed'}} onClick={removeFromBuckets} disabled = {canReact === false}>
                  <img src="BucketIcon.png" style={{maxWidth:'25px'}} />
                  </IconButton>
                  </>
              ) : (
                <><IconButton size="small" style={{marginLeft:'10px', marginRight:'3px', background:'#f7f7f7', border:'1px solid #ededed'}} onClick={addToBuckets} disabled = {canReact === false}>
                {canReact ? <img src="BucketBorderIcon.png" style={{maxWidth:'25px'}} /> : <img src="BucketBorderIconDisabled.png" style={{maxWidth:'25px'}} /> }
                </IconButton>
                </>
              )
            } }
            return <IconButton size="small" style={{marginLeft:'10px', marginRight:'3px', background:'#f7f7f7', border:'1px solid #ededed'}} onClick={addToBuckets} disabled = {canReact === false}>
            {canReact ? <img src="BucketBorderIcon.png" style={{maxWidth:'25px'}} /> : <img src="BucketBorderIconDisabled.png" style={{maxWidth:'25px'}} /> }
            </IconButton>
          
          }

    return (
        <div className={classes.button} variant="outlined" style={{justifyContent:'flex-start'}}>
            <Avatar className={classes.locPic} src={bucket?.locationImage || bucket?.locPic || 'Urban.jpeg'}></Avatar>
            <div className={classes.content}>
            <div className={classes.locationText}>
            <Typography variant='subtitle1' color="primary" style={{overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap', textDecoration:'none', cursor:'pointer'}} onClick={handleClick}>{bucket?.name}</Typography>
            </div>
            {bucket.name !== bucket.country && (
            <Typography variant="subtitle1" style={{color:'silver', marginLeft:'10px', marginTop:'-5px'}}>{bucket.country}</Typography>       
            )}
            <Typography  variant="subtitle2" style={{color:'silver', marginLeft:'10px', marginTop:'-5px'}}>{bucket.locPostCount} {bucket?.locPostCount === 1 ? 'Post' : 'Posts'}</Typography>
            <div className={classes.reacts}>
            <Fav />
            <Typography>{favs?.length ? favs.length : 0}</Typography>
            <Visited />
            <Typography>{visits?.length ? visits.length : 0}</Typography>
            <Bucket />
            <Typography>{buckets?.length ? buckets.length : 0}</Typography>
            
            </div>
            </div>
            </div>

    )
}

export default BucketCard;
