import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import CommentCard from './CommentCard';


const CommentCards = ({commentCount, comments, postId}) => {
    // const comments = useSelector(state => state.comments);
    const commentRef = useRef();
    const prevCountRef = useRef();

    // useEffect(() => {
    //   if (!commentRef.current) return;
    //   if(prevCountRef.current === undefined || prevCountRef.current < commentCount) {
    //   commentRef.current.scrollIntoView({behavior:'smooth', block:'nearest'});
    //   }
    //   prevCountRef.current = commentCount;
    // }, [commentCount])

    useEffect(() => {
      if (!commentRef.current) return;
      if(!comments.length) return;
      if(prevCountRef.current === undefined || prevCountRef.current < comments.length) {
      commentRef.current.scrollIntoView({behavior:'smooth', block:'nearest'});
      }
      prevCountRef.current = comments.length;
    }, [comments])

    
   
    
    // console.log(commentCount);
    if(commentCount === 0) {
      return <Typography style={{color:'darkGray', textAlign:'center', marginTop:'10px', marginBottom:'10px'}}>&nbsp;&nbsp;&nbsp;No comments to show</Typography>
    }
    
    
    return(
      <>
        {!comments.length ? <CircularProgress style={{margin:'10px'}}/> : (
            <div container alignItems="stretch" spacing={3} style={{maxHeight:'300px', overflowY:'auto', overscrollBehavior:'auto'}}>
            {comments.map((comment) => {
              if (comment.postId !== postId) return null;
              return (
              <div key={comment.id}>
                <CommentCard comment = {comment} commentCount={commentCount} />
              </div>   
              )    
              })}
            <div ref={commentRef} />
          </div>
        )}
        </>
    )}

export default CommentCards
