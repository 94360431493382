import { FETCH_USER_POSTS, UPDATE, LIKE, DISLIKE, DELETE, ADD_COMMENT_REFRESH, CLEAR_USER_POSTS } from '../constants/actionTypes';

export default (userPosts = [], action) => {
  switch (action.type) {
    case FETCH_USER_POSTS:
      if(!action.payload.length) {
        return userPosts  = [0];
      } else {
      return action.payload;
      }
    case CLEAR_USER_POSTS:
      return userPosts = [];
    case LIKE:
      return userPosts.map((post) => (post.id === action.payload.id ? action.payload : post));
    case DISLIKE:
      return userPosts.map((post) => (post.id === action.payload.id ? action.payload : post));
    case UPDATE:
      return userPosts.map((post) => (post.id === action.payload.id ? action.payload : post));
    case DELETE:
      return userPosts.filter((post) => post.id !== action.payload);
    case ADD_COMMENT_REFRESH: 
      return userPosts.map((post) => (post.id === action.payload.id ? action.payload : post));
    default:
      return userPosts;
  }
};