import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
//   pos: {
//     marginBottom: 12,
//   },
}));

const SimpleCard = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const bull = <span className={classes.bullet}>•</span>;


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root} style={{backgroundColor:'#fafafa', boxShadow:'0 0 2px black', padding:'5px'}}>
      <CardContent>
        {/* <Typography className={classes.title} color="textSecondary" gutterBottom>
          Word of the Day
        </Typography> */}
        
        <Typography variant="h6" component="h2" color="primary" gutterBottom style={{textAlign:'center', display:'flex', alignItems:'center'}}>
        <InfoIcon />&nbsp; Welcome to TravelAIO!
        </Typography>
        <Typography variant="subtitle2" color="primary" >
          TravelAIO is a social media platform for travel. 
          Add photos, videos and articles and share your travel content with the world.
          Explore travel destinations around the world and see what others have shared. 
          Whether you want to share your travel experiences, post an awesome photo, share a cinematic travel video or 
          let tourists know about the best local travel spots, this is the place for you! 
        </Typography>
      </CardContent>
      

      <CardActions style = {{borderTop:'1px solid #e8e8e8', padding:'5px'}}>
      <Typography variant="subtitle2" color="primary" onClick={handleExpandClick} style={{cursor:'pointer', marginLeft:'auto'}}>Need Help Signing Up?</Typography>
      <IconButton size="small"
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        > 
          {/* <Fab size="small" style={{backgroundColor:'#EDEDED'}}> */}
          <ExpandMoreIcon style={{backgroundColor:'#3f51b5', color:'white', borderRadius:'50%' }} />
          
          {/* </Fab> */}
        </IconButton>
        </CardActions>
      
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
        <Typography variant="subtitle2" color="primary" >
        If you are not sure how to sign up, follow these steps to create a new account.
          {/* <ul> */}

          {/* <li> */}
              <br></br>{bull} Click the <strong>'SIGN IN'</strong> button in the top right corner.<br></br>
              {/* </li> */}
           {/* <li> */}
               {bull} Click on <strong style={{}}>'Don't have and account? Sign up'</strong> and you will see the sign up form. <br></br>
               {/* </li> */}
           {/* <li> */}
               {bull} Simply click <strong style={{}}>'Google Sign Up'</strong> and sign up with one click via Google or...<br></br>
               {bull} Fill out the form and click <strong>'Sign Up'</strong>.<br></br>
               {/* </li> */}
          {/* </ul> */}
        </Typography>
        </CardContent>
      </Collapse>


    </Card>
  );
}

export default SimpleCard;
