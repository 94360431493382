import { GET_COMMENTS, ADD_COMMENT, DELETE_COMMENT, LIKE_COMMENT, DISLIKE_COMMENT, CHANGE_COMMENTS } from '../constants/actionTypes';

export default (comments = [], action) => {
  switch (action.type) {
    case GET_COMMENTS:
      return action.payload;
    case ADD_COMMENT:
      if(comments.length === 1 && comments[0].postId === action.payload.postId) {
        return [action.payload];
      } else {
        return [...comments, action.payload];
      }      
    case LIKE_COMMENT: 
      return comments.map((comment) => (comment.id === action.payload.id ? action.payload : comment));
    case DISLIKE_COMMENT:
      return comments.map((comment) => (comment.id === action.payload.id ? action.payload : comment));
    case DELETE_COMMENT:
      return comments.filter((comment) => comment.id !== action.payload);
    case CHANGE_COMMENTS:
      return comments = [];
    default:
      return comments;
  }
};