import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import FileBase from 'react-file-base64';
// import { IconButton } from '@material-ui/core';
// import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import { updateUser } from '../../../actions/user';
import { getSignature } from '../../../api';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import useStyles from './styles';

const UserInfo = ({handleClose, widgetOpen, setWidgetOpen, enablePhoto, setEnablePhoto}) => {
    const classes = useStyles();
    const [userData, setUserData] = useState({firstName:'', lastName:'', name:'', profilePic:'', country:'', bio:'', facebook:'', instagram:'', youtube:'', website:''});
    // const [country, setCountry] = useState({ country:''});
    const user = useSelector(state => state.user);
    const [countryName, setCountryName] = useState(user?.result?.country || '');
    const [profilePicUrl, setProfilePicUrl] = useState(user?.result?.profilePic || '');
    
    // console.log(user);
    const history = useHistory();
    const [image, setImage] = useState(null);
    const id = user?.result?.id;
    // console.log(user);

    const dispatch = useDispatch();

    useEffect(() => {
      if (user) setUserData(user?.result);
      }, [user]);

    // useEffect(() => {
    //   if (!enablePhoto) {
    //     setEnablePhoto(true)
    //   }
    // })

    const [place, setPlace] = 
    useState({name:'',
              // id:'',
              // address:'',
              photos:[]
  });
  const [query, setQuery] = useState(user?.result?.country || '');
  // console.log(user);
  // console.log(user.firstName);
  // const [city, setCity] = useState("")
  const autoCompleteRef = useRef(null);
  // console.log(place);

  // let autoComplete;

  const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const handleScriptLoad = () => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    // { 
    //   // types: ["(cities)"],
    //  componentRestrictions: { country: "lk" } }
  );
  // autoCompleteRef.current.setFields(["address_components", "formatted_address", 'place_id', 'geometry', 'name', 'photos']);
  autoCompleteRef.current.addListener("place_changed", handlePlaceSelect);
}

const handlePlaceSelect = () => {
  const addressObject = autoCompleteRef.current.getPlace();
  const name = addressObject.name;
  const countryString = addressObject.adr_address.substring(addressObject.adr_address.lastIndexOf("<span class=\"country-name\">"), addressObject.adr_address.lastIndexOf("<"));
  const country = countryString.split(">")[1];
  const placeName = name + "," + " " + country;
  const photos = addressObject.photos;
  const long_name = addressObject.address_components.map(addressComponent => addressComponent.long_name);
  const long_name_str = long_name.toString();
  // updateQuery(query);
  // setPlace({...place, name:name, photos: photos});
  // setPostData({...postData, location:long_name_str})
  // setUserData({...userData, country:country})
  setCountryName(country);
  setQuery(country);
  // console.log(addressObject);
  // console.log(placeName);
  // setPlace({...place, });
}

useEffect(() => {
  loadScript(
    `https://maps.googleapis.com/maps/api/js?key=AIzaSyDXGCxkxHNNK_ZO18wSSJlcwCA242XZuqk&callback=initMap&libraries=places`,
    () => handleScriptLoad(setQuery, autoCompleteRef)
  );
}, []);

/////////////////////////////////////

const MyWidget = () => {
  var myWidget = window.cloudinary.createUploadWidget(
    {
      cloud_name: 'travelaio',
      api_key: '222487881423418',
      // public_id:'ohHiMark',
      // cropping:true,
      // croppingShowDimensions:true,
      // croppingAspectRatio:1,
      // uploadPreset: "profilePic",
      upload_signature: generateSignature,
    },
    (error, result) => {
      setEnablePhoto(true);
      if (!error && result && result.event === "success") {
        // console.log("Done! Here is the image info: ", result.info);
        setProfilePicUrl(result.info.secure_url);
        setImage(result.info.original_filename + '.' + result.info.format)
        // console.log(userData);
      } else if(error) {
        console.log(error);
        // console.log(result);
      }
    }
  );
  
  const handleWidget = (e) => {
    // e.preventDefault();
    myWidget.open()
    setEnablePhoto(false)
  }

  useEffect(() => {
    if (widgetOpen) {
      // myWidget.close();
      handleWidget()
      setWidgetOpen(false)
    } else {
      console.log();
    }
  }, [widgetOpen]);

  return (
    <>
    <Button id="upload_widget" className="cloudinary-button" variant="contained" color="primary" size="large" onClick={handleWidget} disabled={!enablePhoto} style={{marginLeft:'7px', marginBottom:'15px', textTransform:'capitalize'}}>
      Add Photo
    </Button>
    {/* <p style={{marginLeft:'20px'}}>{image}</p> */}

    {(image) && 
    <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginBottom:'15px', marginLeft:'5px'}}>
      <p style={{marginLeft:"5px", marginRight:'5px'}}>Photo Added</p>
      <CheckCircleIcon style={{color:'green'}} />
    </div>
    }
    </>
  )
}

const generateSignature = async (callback, params_to_sign) => {
  // console.log('boom boom ciao');
  try {
    const signature = await getSignature(params_to_sign);
    callback(signature.data);
    // console.log(signature.data);
    // console.log(params_to_sign);
    // console.log(callback);
  } catch (error) {
    console.log(error);
  }
}; 


const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(updateUser(id, {...userData, country:countryName, profilePic:profilePicUrl}, history));
  };

    return (
      <Grid container alignItems="stretch" spacing={3} style={{background:'white', marginLeft:'auto', marginRight:'auto', marginTop:'0px', width:'100%', border:'2px solid #ededed', borderRadius:'15px' }}>
        <form style={{padding:'12px'}} autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
        <div style={{width:'100%'}}>
              <Typography variant="subtitle2" style={{marginLeft:'10px', color:'darkgray', marginBottom:'10px'}}>Profile Picture</Typography>
            </div>
            <MyWidget />
            <div style={{width:'100%'}}>
              <Typography variant="subtitle2" style={{marginLeft:'10px', color:'darkgray'}}>First Name</Typography>
            </div>
            <TextField style={{marginBottom:'20px'}} name="firstName" variant="outlined" value={userData?.firstName} fullWidth onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}/>
            <div style={{width:'100%'}}>
              <Typography variant="subtitle2" style={{marginLeft:'10px', color:'darkgray'}}>Last Name</Typography>
            </div>
            <TextField style={{marginBottom:'20px'}} name="lastName" variant="outlined" value={userData?.lastName} fullWidth onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}/>
            <div style={{width:'100%'}}>
              <Typography variant="subtitle2" style={{marginLeft:'10px', color:'darkgray'}}>Add Country</Typography>
            </div>
            <div id="tinyInput5" className="search-location-input" style={{display:"flex", justifyContent:'center',  alignItems:'center', height:'55px', width:'100%', margin:'0px', marginTop:'10px', marginBottom:'20px'}}>
            <SearchIcon style={{backgroundColor:'white', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', border:'1px solid silver', borderRight:'0', padding:'12px', fontSize:'3.45rem'}}/>
                <input
                    ref={autoCompleteRef}
                    onChange={event => setQuery(event.target.value)}
                    placeholder="Select Your Country"
                    value={query}
                />      
            </div>
            <div style={{width:'100%'}}>
              <Typography variant="subtitle2" style={{marginLeft:'10px', color:'darkgray'}}>Bio</Typography>
            </div>
            <TextField style={{marginBottom:'20px'}} name="bio" value={userData?.bio} variant="outlined" fullWidth onChange={(e) => setUserData({ ...userData, bio: e.target.value })} />
            <div style={{width:'100%'}}>
              <Typography variant="subtitle2" style={{marginLeft:'10px', color:'darkgray'}}>Facebook</Typography>
            </div>
            <TextField style={{marginBottom:'20px'}} name="facebook" value={userData?.facebook} variant="outlined" fullWidth onChange={(e) => setUserData({ ...userData, facebook: e.target.value })}/>
            <div style={{width:'100%'}}>
              <Typography variant="subtitle2" style={{marginLeft:'10px', color:'darkgray'}}>Instagram</Typography>
            </div>
            <TextField style={{marginBottom:'20px'}} name="instagram" value={userData?.instagram} variant="outlined" fullWidth onChange={(e) => setUserData({ ...userData, instagram: e.target.value })}/>
            <div style={{width:'100%'}}>
              <Typography variant="subtitle2" style={{marginLeft:'10px', color:'darkgray'}}>YouTube</Typography>
            </div>
            <TextField style={{marginBottom:'20px'}} name="youtube" value={userData?.youtube} variant="outlined" fullWidth onChange={(e) => setUserData({ ...userData, youtube: e.target.value })}/>
            <div style={{width:'100%'}}>
              <Typography variant="subtitle2" style={{marginLeft:'10px', color:'darkgray'}}>Personal Website or Blog</Typography>
            </div>
            <TextField style={{marginBottom:'20px'}} name="website" value={userData?.website} variant="outlined" fullWidth onChange={(e) => setUserData({ ...userData, website: e.target.value })}/>
            
            <Button variant="contained" style={{ marginLeft:'auto'}} color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button style={{ marginLeft:'10px'}} variant="contained" type="submit" color="primary">
            Save
          </Button>
        </form>
        </Grid>
    )
}

export default UserInfo
