import {ADDTOFAVS, ADD_TO_FOLLOWING, FETCH_USER_FAVS, GET_FAVS, GET_FOLLOWERS, REMOVEFROMFAVS, REMOVE_FROM_FOLLOWING } from '../constants/actionTypes';

export default (followers = [], action) => {
  switch (action.type) {
    case GET_FOLLOWERS:
      return action.payload;
    case ADD_TO_FOLLOWING:
      return [...followers, action.payload];
    case REMOVE_FROM_FOLLOWING:
      return followers.filter((followers) => followers !== action.payload);
    // case FETCH_USER_FAVS:
    //   return action.payload;
    // case DELETE:
    //   return posts.filter((favorite) => post._id !== action.payload);
    default:
      return followers;
  }
};