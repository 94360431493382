import React, { useEffect, useRef} from 'react';
import { Grid, CircularProgress, Grow, Container } from '@material-ui/core';
import { useSelector } from 'react-redux';

import UserCard from './UserCard';
// const currentUser = JSON.parse(localStorage.getItem('profile'));
// const currentUserId = currentUser?.result?.googleId || currentUser?.result?.id;
// import useStyles from './styles';

const UserResults = ({currentUserId}) => {
  const users = useSelector((state) => state.users);
  const searchRef = useRef();

  useEffect(() => {
    if (!searchRef.current) return;
    searchRef.current.scrollIntoView({behavior:'smooth'})
  }, [users])
  

  return (
    !users.length ? <CircularProgress /> : (
    <Grow in>
    <Container>
    
      <Grid container alignItems="stretch" spacing={3} md={8} sm={10} xs={12} style={{margin:'auto', display:'flex', justifyContent:'center', alignItems:'center', borderTop:'2px solid #EDEDED', marginTop:'25px', paddingTop:'25px'}}>
      <div ref = {searchRef}></div>
        {users.map((user) => (
          <Grid item xs={12} sm={12} md={12}>
            <UserCard user={user}/>
          </Grid>
        ))}
      </Grid>
      </Container>
      </Grow>
    )
  );
};

export default UserResults;
