import { GET_LOCATION } from '../constants/actionTypes';

export default (placeSelect = [], action) => {
  switch (action.type) {
    case GET_LOCATION:
      if(action.payload == null) {
          return placeSelect;
      } else {
      return action.payload;
      }
    default:
      return placeSelect;
  }
};