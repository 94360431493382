import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Container, Grow, Grid, Typography, Button, Avatar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import ScrollableTabsButtonForce from './CategoryMenus';

import Alert from '@material-ui/lab/Alert';

import { getPosts } from '../../actions/posts';
import { getSuggestedUsers } from '../../actions/user';
// import Posts from '../Posts/Posts';
import Form from '../Form/Form';
import { getSignature } from '../../api';
import DialogTitle from './Instructions';
import { CHANGE_NEWSFEED } from '../../constants/actionTypes';
import SimpleCard from './SiteDets';
import SuggestedTravelers from './SuggestedTravelers';
import SuggestedTravelersPC from './SuggestedTravelersPC';
import { setControl } from '../../actions/locations';

const Posts = lazy(() => import('../Posts/Posts'));

const useStyles = makeStyles((theme) => ({
  button: {
    margin:'5px',
    width:'245px', 
    backgroundColor:'white', 
    border:'1px solid #ededed', 
    textTransform:'Capitalize', 
    justifyContent:'flex-start', 
    borderRadius:'25px', 
    paddingLeft:'6px', 
    color:'#333'
  }, 
  categoryDiv : {
    backgroundColor:'white',
    display:'flex',
    flexDirection:'column',
    marginRight:'10px',
    padding:'5px',
    borderRadius:'15px',
    border:'2px solid #ededed',
    paddingTop:'10px',
    paddingBottom:'5px'
  }
}))

const Home = () => {
  const classes = useStyles();
  const [user, setuser] = useState(JSON.parse(localStorage.getItem('profile')));
  const userId = user?.result?.googleId || user?.result?.id
  const authStatus = localStorage.getItem('authStatus');
  const [postState, setPostState] = useState(user ? false : true);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState('');
  // const [followed, setFollowed] = useState([]);
  const travelers = useSelector((state) => state.suggestedUsers);
  const [travelerIds, setTravelerIds] = useState([]);

  const posts = useSelector((state) => state.posts);
  let lastPost;
  lastPost = posts[posts.length - 1];
  const [page, setPage] = useState((lastPost?.pageNumber + 1) || 1);
  const lastPostPage = lastPost?.pageNumber;
  // const authObjectStatus = authObject?.data?.authStatus;
  // const [authStatus, setAuthStatus] = useState(authObjectStatus);
  // const [currentId, setCurrentId] = useState(0);
  
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getPosts());
  // }, [dispatch]);

  const getPostFunc = () => {
  //   dispatch(getPosts(page));
  //   setpage(page + 1)
  }

  useEffect(() => {
    if (!user) return;
    setPage(page);
  }, [page]);

  const handleCategory = (data) => {
    setCategory(data);
    setLoadingPosts(true);
    setPostState(null);
    dispatch({ type: CHANGE_NEWSFEED})
    setPage(1);
  }

  const handlePostButton1 = () => {
    setCategory('');
    setLoadingPosts(true);
    setPostState(false);
    dispatch({ type: CHANGE_NEWSFEED})
    setPage(1);
  }
  const handlePostButton2 = () => {
    setCategory('');
    setLoadingPosts(true);
    setPostState(true);
    dispatch({ type: CHANGE_NEWSFEED})
    setPage(1);   
  }

  const handleClick = () => {
    // dispatch(getSuggestedUsers(userId))
  }
  

   useEffect(() => {
     if (!user) return;
     if (posts.length === 15 && page === 1) {
    dispatch(getSuggestedUsers(userId))
    }
    else {
      return;
    }
    }, [posts]);
    

    useEffect(() => {
      setuser(JSON.parse(localStorage.getItem('profile')));
      console.log(user);
    }, [] )

    const handleAlertClick = () => {
      dispatch(setControl('Settings'))
    }

  // const MyWidget = () => {
  //   var myWidget = window.cloudinary.createUploadWidget(
  //     {
  //       cloud_name: 'travelaio',
  //       api_key: '222487881423418',
  //       public_id:'sample',
  //       // uploadPreset: "<upload_preset>",
  //       upload_signature: generateSignature,
  //     },
  //     (error, result) => {
  //       if (!error && result && result.event === "success") {
  //         console.log("Done! Here is the image info: ", result.info);
  //       } else if(error) {
  //         console.log(error);
  //         console.log(result);
  //       }
  //     }
  //   );

  //   const handleClick = () => {
  //     myWidget.open();
  //   }

  //   return (
  //     <button id="upload_widget" className="cloudinary-button" onClick={handleClick}>
  //       Upload
  //     </button>
  //   )
  // }

  // const generateSignature = async (callback, params_to_sign) => {
  //   try {
  //     const signature = await getSignature(params_to_sign);
  //     callback(signature.data);
  //     console.log(signature.data);
  //     console.log(params_to_sign);
  //     console.log(callback);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <Grow in>
      <Container id="homeContainer">
        {/* <MyWidget /> */}
        {/* <DialogTitle /> */}
        <Grid container justify="space-between" alignItems="stretch" style={{display:'flex', justifyContent:'center'}} >
          <Grid id="categoryColumn" item md={3} style={{display:'flex', alignItems:'flex-end', flexDirection:'column'}}>
          <div className={classes.categoryDiv}>
          {user ? (
            <Grid style={{display:'flex', justifyContent:'center', margin:'10px', marginTop:'0px'}}>
              <DialogTitle authStatus={authStatus} />
            </Grid>
            ) : 
            <Grid style={{display:'flex', justifyContent:'center'}}>
              <SimpleCard />
            </Grid>
            }
            {/* <Typography>{category}</Typography>
            <Typography>{page}</Typography> */}
            <Button variant="outlined" className={classes.button} onClick={handlePostButton1} disabled={loadingPosts} style={{backgroundColor:postState === false && '#ededed', fontWeight:postState === false && 'bold'}}>
              <Avatar style={{marginRight:'10px', backgroundColor:'#edded'}} src="BucketBorderIcon (8).png"></Avatar>
            Recent
            </Button>
            <Button variant="outlined" className={classes.button} onClick={handlePostButton2} disabled={loadingPosts} style={{backgroundColor:postState === true && '#ededed', fontWeight:postState === true && 'bold'}}>
              <Avatar style={{marginRight:'10px', backgroundColor:postState === true ? '#ededed' : 'white'}}><WhatshotIcon color="secondary"/></Avatar>
            Top
            </Button>
            <Button variant="outlined" className={classes.button} onClick={() => handleCategory('Nature')} style={{backgroundColor:category === 'Nature' && '#ededed', fontWeight:category === 'Nature' && 'bold'}} disabled={loadingPosts}>
              <Avatar style={{marginRight:'10px'}} src="NatureIcon.jpeg"></Avatar>
            Nature
            </Button>
            <Button variant="outlined" className={classes.button} onClick={() => handleCategory(encodeURIComponent('Scenery & Landscapes'))} style={{backgroundColor:category === encodeURIComponent('Scenery & Landscapes') && '#ededed', fontWeight:category === encodeURIComponent('Scenery & Landscapes') && 'bold'}} disabled={loadingPosts}>
              <Avatar style={{marginRight:'10px'}} src="LandscapeIcon.jpeg"></Avatar>
            Scenery & Landscapes
            </Button>
            <Button variant="outlined" className={classes.button} onClick={() => handleCategory('Animals')} style={{backgroundColor:category === 'Animals' && '#ededed', fontWeight:category === 'Animals' && 'bold'}} disabled={loadingPosts}>
              <Avatar style={{marginRight:'10px'}} src="Animals.jpeg"></Avatar>
            Animals
            </Button>
            <Button variant="outlined" className={classes.button} onClick={() => handleCategory('Urban')} style={{backgroundColor:category === 'Urban' && '#ededed', fontWeight:category === 'Urban' && 'bold'}} disabled={loadingPosts}>
              <Avatar style={{marginRight:'10px'}} src="Urban.jpeg"></Avatar>
            Urban
            </Button>
            <Button variant="outlined" className={classes.button} onClick={() => handleCategory('Architecture')} style={{backgroundColor:category === 'Architecture' && '#ededed', fontWeight:category === 'Architecture' && 'bold'}} disabled={loadingPosts}>
              <Avatar style={{marginRight:'10px'}} src="Architecture.jpeg"></Avatar>
            Architecture
            </Button>
            <Button variant="outlined" className={classes.button} onClick={() => handleCategory('Culture')} style={{backgroundColor:category === 'Culture' && '#ededed', fontWeight:category === 'Culture' && 'bold'}} disabled={loadingPosts}>
              <Avatar style={{marginRight:'10px'}} src="Culture.jpeg"></Avatar>
            Culture
            </Button>
            <Button variant="outlined" className={classes.button} onClick={() => handleCategory('History')} style={{backgroundColor:category === 'History' && '#ededed', fontWeight:category === 'History' && 'bold'}} disabled={loadingPosts}>
              <Avatar style={{marginRight:'10px'}} src="History.jpeg"></Avatar>
            History
            </Button>
            <Button variant="outlined" className={classes.button} onClick={() => handleCategory('Religion')} style={{backgroundColor:category === 'Religion' && '#ededed', fontWeight:category === 'Religion' && 'bold'}} disabled={loadingPosts}>
              <Avatar style={{marginRight:'10px'}} src="Religion.webp"></Avatar>
            Religion
            </Button>
            <Button variant="outlined" className={classes.button} onClick={() => handleCategory('Experiences')} style={{backgroundColor:category === 'Experiences' && '#ededed', fontWeight:category === 'Experiences' && 'bold'}} disabled={loadingPosts}>
              <Avatar style={{marginRight:'10px'}} src="Experiences.jpeg"></Avatar>
            Experiences
            </Button>
            <Button variant="outlined" className={classes.button} onClick={() => handleCategory('Events')} style={{backgroundColor:category === 'Events' && '#ededed', fontWeight:category === 'Events' && 'bold'}} disabled={loadingPosts}>
              <Avatar style={{marginRight:'10px'}} src="Events.jpeg"></Avatar>
            Events
            </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={7} md={6} style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
            {/* {user ? (
            <Grid style={{display:'flex', justifyContent:'center', margin:'10px', marginTop:'20px'}}>
              <DialogTitle authStatus={authStatus} />
            </Grid>
            ) : 
            <Grid style={{display:'flex', justifyContent:'center'}}>
              <SimpleCard />
            </Grid>
            } */}
            {/* {user ? (
            <Grid style={{display:'flex', justifyContent:'flex-start', margin:'10px', marginLeft:'0px', marginRight:'0px', boxShadow:'0px 0px 2px silver', padding:'5px', borderRadius:'5px'}}>
              <Button variant={postState == false ? "contained" : "outlined"} color="primary" size="small" style={{textTransform:'inherit', margin:'5px', marginLeft:'5px', color:postState == false && "white"}} onClick={handlePostButton1} disabled={loadingPosts}>Most Recent Posts</Button>
              <Button variant={postState == true ? "contained" : "outlined"} color="primary" size="small" style={{textTransform:'inherit', margin:'5px', color:postState == true && "white"}} onClick={handlePostButton2}  disabled={loadingPosts}>Top Posts</Button>
            </Grid>
            ) : (
            <Grid style={{display:'flex', justifyContent:'flex-start', margin:'10px', marginLeft:'0px', marginRight:'0px', boxShadow:'0px 0px 2px silver', padding:'5px', borderRadius:'5px'}}>
              <Button variant={postState == true ? "contained" : "outlined"} color="primary" size="small" style={{textTransform:'inherit', margin:'5px', color:postState == true && "white" }} onClick={handlePostButton2}  disabled={loadingPosts}>Top Posts</Button>
              <Button variant={postState == false ? "contained" : "outlined"} color="primary" size="small" style={{textTransform:'inherit', margin:'5px', color:postState == false && "white", marginLeft:'5px'}} onClick={handlePostButton1}  disabled={loadingPosts}>Most Recent Posts</Button>
            </Grid>            
            )} */}
            <div id="mobileCategories">
              <ScrollableTabsButtonForce loadingPosts={loadingPosts} setLoadingPosts={setLoadingPosts} postState={postState} category={category} handleCategory={handleCategory} handlePostButton1={handlePostButton1} handlePostButton2={handlePostButton2}/>
            </div>
            {/* {(travelers?.length > 0) && (
              <div id="suggestedMobile">
              <SuggestedTravelers loadingPosts={loadingPosts} travelers={travelers} travelerIds={travelerIds} setTravelerIds={setTravelerIds} loading={loading} setLoading={setLoading} />
              </div>
            )} */}
            {/* <Button onClick={handleClick}>Get Users</Button> */}
            {/* {(page > 3 && travelers?.length > 0) && (
              <div id="suggestedMobile">
              <SuggestedTravelers loadingPosts={loadingPosts} travelers={travelers} travelerIds={travelerIds} setTravelerIds={setTravelerIds} loading={loading} setLoading={setLoading} />
              </div>
            )} */}
            {user && (user?.result?.profilePic === '') && (
                <Alert severity="error" onClose={()=>{}} style={{display:'flex', alignItems:'center', marginBottom:'10px'}}
                    action={
                       <Button color="inherit" size="small" style={{textTransform:'capitalize'}} onClick={handleAlertClick} component={Link} to={`/${userId}`} >
                         Add Now
                      </Button>
                     }
                  >Add a profile pic
                </Alert>
            )}
            
            <Suspense fallback = {<Typography>Loading...</Typography>}>
              <Posts postState={postState} user={user} loadingPosts={loadingPosts} setLoadingPosts={setLoadingPosts} posts={posts} page={page} setPage={setPage} category={category} setCategory={setCategory} lastPostPage={lastPostPage} travelers={travelers} travelerIds={travelerIds} setTravelerIds={setTravelerIds} />
            </Suspense>
            {/* {(page === 3 && travelers?.length > 0) && (
              <div id="suggestedMobile">
              <SuggestedTravelers loadingPosts={loadingPosts} travelers={travelers} travelerIds={travelerIds} setTravelerIds={setTravelerIds} loading={loading} setLoading={setLoading} />
              </div>
            )} */}
          </Grid>
          <Grid item md={3} style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
            {/* <Typography>{travelerIds}</Typography> */}
            {(travelers?.length > 0) && (
            <div id="suggestedPC" style={{width:'100%'}}>
              <SuggestedTravelersPC loadingPosts={loadingPosts} travelers={travelers} travelerIds={travelerIds} setTravelerIds={setTravelerIds} loading={loading} setLoading={setLoading} />
            </div>
            )}
          </Grid>
          {/* <Grid item xs={12} sm={7} md={6}>

          </Grid> */}
          {/* <Grid item xs={12} sm={4}>
            <Stream />
          </Grid> */}
        </Grid>
      </Container>
    </Grow>
  );
};

export default Home;
