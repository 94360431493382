import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography, Divider, Grid, Avatar, IconButton } from '@material-ui/core';
import { formatDistanceStrict, set } from "date-fns";
import useStyles from './styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MoreVert from '@material-ui/icons/MoreVert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { likeComment, deleteComment, dislikeComment } from '../../actions/comments';
import post from '../../reducers/post';

const CommentCard = ({comment, commentCount}) => {
    const currentTime = new Date();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [commentLikes, setCommentLikes] = useState(comment?.likes);
    const [showActions, setShowActions] = useState(false);
    const [acting, setActing] = useState(false);
    const user = JSON.parse(localStorage.getItem('profile'));
    const userId = user?.result?.googleId || user?.result?.id;
    const creator = comment?.userId;
    if(comment?.createdAt){
        var createdAtTime = formatDistanceStrict(new Date(comment?.createdAt), new Date(currentTime), {addSuffix:false})
        var createdAtDigit = createdAtTime.split(' ')[0];
        var createdAtWord = createdAtTime.split(' ')[1];
        var createdAtLetter = createdAtWord.charAt(0);
        var createdAt = createdAtDigit + createdAtLetter;
        }

    const handleLike = () => {
        if(comment?.likes.find((like) => like === (user?.result?.googleId || user?.result?.id))) {
            setCommentLikes(comment?.likes.filter((id) => id !== userId));
            dispatch(dislikeComment(comment.postId, comment.id));
        } else {
            setCommentLikes([...comment?.likes, userId]);
            dispatch(likeComment(comment.postId, comment.id));
        }
    }

    const handleDelete = () => {
        setActing(true);
        dispatch(deleteComment(comment.id, comment.postId))
        // console.log('red');
        setActing(false)
    }

    const showingActions = () => {
        setActing(true);
    }

    const hidingActions = () => {
        setActing(false)
    }

    useEffect(() => {
      setShowActions(!showActions);
    }, [acting])
    

    const Likes = () => {
        if(commentLikes) {
            if(commentLikes.length > 0) {
                return commentLikes.find((like) => like === (user?.result?.googleId || user?.result?.id))
                ? (
                    <><IconButton style={{marginBottom:'auto'}} size="small" onClick={handleLike} disabled={!user?.result || (user.result?.status == 'Pending')}>
                    <FavoriteIcon fontSize="small" style={{color:'#fd297b'}} />
                    </IconButton>
                     {(commentLikes?.length > 0) && (
                        <Typography variant="subtitle2" style={{color:'#fd297b'}}>{commentLikes?.length}</Typography>
                     )}
                    </>
                  ) : (
                    <><IconButton style={{marginBottom:'auto'}} size="small" onClick={handleLike} disabled={!user?.result || (user.result?.status == 'Pending')}>
                      <FavoriteBorderIcon fontSize="small" style={{color:'black'}} />
                      </IconButton>
                      {(commentLikes?.length > 0) && (
                        <Typography variant="subtitle2" style={{color:'black'}}>{commentLikes?.length}</Typography>
                     )}
                      </>
                  );
            }}
            return <><IconButton style={{marginBottom:'auto'}} size="small" onClick={handleLike} disabled={!user?.result || (user?.result?.status == 'Pending')}>
             <FavoriteBorderIcon fontSize="small" style={{color:'black'}}/> 
             {/* &nbsp;Like; */}
             </IconButton>
             {(commentLikes?.length > 0) && (
                        <Typography variant="subtitle2" style={{color:'black'}}>{commentLikes?.length}</Typography>
                     )}
             </>
    };

    return(
        // <div style={{}}>
        <div className={classes.root} style={{marginLeft:acting ? '-50px' : '0', transition:'ease-in-out 0.5s'}}>
        {/* {!travelers?.length ? null :
        <Typography style={{color:'darkgrey', textAlign:'left', marginLeft:'5px', marginBottom:'5px'}}>Suggested Travelers</Typography>} */}
        {/* {
        travelers.map(traveler =>  */}
        <div className={classes.button} variant="outlined">
          {/* <div className={classes.avatarContainer} > */}
            <Avatar className={classes.avatar} src={comment?.profilePic ? comment?.profilePic : null} style={{border:'', boxShadow:'0 0 2px darkGray', background:!post?.profilePic && 'linear-gradient(to left, #9121c2, #FF5E5E )' }}>{comment?.userName?.charAt(0)}</Avatar>
            {/* <FavoriteBorderIcon /> */}
          {/* </div> */}
        <div className={classes.text}>
          <div className={classes.creatorDets}>
          <Typography variant='subtitle2' color="primary" component={Link} to={`/${comment.userId}`} style={{overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap', textDecoration:'none'}}>{comment?.userName}&nbsp;</Typography>
          <Typography variant='subtitle2' style={{color:'darkgrey'}}> &#183;&nbsp;{createdAt}</Typography>
          {/* <FavoriteBorderIcon fontSize="small" style={{marginLeft:'auto'}}/> */}
          {/* {(comment.userId === '106540195790408777533') && ( */}
          {creator === userId && (
          <IconButton
          onClick={showingActions} 
          onBlur={hidingActions}
          style={{marginLeft:'auto'}}
          size="small"
          >
          <MoreVert fontSize="small" style={{color:'gray'}} />
          </IconButton>
          )}
          {/* )} */}
          </div>
          <Typography variant='subtitle2' style={{color:'black', textTransform:'none'}}>{comment?.commentBody}</Typography>
        </div>
        <div className={classes.actions}>
            <Likes />
        {/* <IconButton
          onClick={() => dispatch(likeComment(comment.postId, comment.id))}
          style={{marginBottom:'auto'}}
          size="small"
          >
        <FavoriteBorderIcon fontSize="small"
         style={{color:'black'}}
        />
        </IconButton> */}
        {/* {(commentLikes?.length > 0) && (
        <Typography variant="subtitle2">{commentLikes?.length}</Typography>
    )} */}
        {/* <Typography variant="subtitle2">{commentCount}</Typography> */}
        </div>
        </div>
        {/* )} */}
  {/* </div> */}
  {(acting) && (
  <IconButton size="small" style={{margin:'auto', marginRight:'-40px'}} onClick={handleDelete} onMouseDown={(e)=> (e.preventDefault(), e.stopPropagation())}>
  {/* <SendIcon className={showMore ? classes.showingMore : classes.notShowingMore} style={{color:'#526bf4'}} /> */}
  <DeleteForeverIcon fontSize="small"/>
</IconButton>
  )}
</div>
  
    // <Grid container xs={12} sm={12} md={12} style={{
    //     // backgroundColor:'yellow',
    //     boxShadow:'0 0 3px silver',
    //     paddingTop:'7px',
    //     paddingBottom:'7px',
    //     // paddingRight:'15px',
    //     // paddingLeft:'7px',
    //     // borderBottom:'1px solid silver',
    //     borderRadius:'10px',
    //      color:'black', 
    //     //  marginBottom:'2px'
    //      }}>
    //     <Grid container xs={12} sm={12} md={12} style={{
    //         // backgroundColor:'purple',
    //         marginTop:'5px',
    //         marginBottom:'5px',
    //         // borderBottom:'2px solid #EDEDED',
    //          display:'flex', alignItems:'center'}}>
    //         <Grid item xs={3} sm={2} md={2} style={{
    //             // backgroundColor:'black',
    //                 display:'flex',
    //                 justifyContent:'center',
    //                 alignItems:'center',
    //                 marginLeft:'-10px'
    //                 }}>
    //             <Avatar className={classes.large} src = {comment.profilePic}  
    //             component={Link} to={`/${comment.userId}`} 
    //             style={{boxShadow: '0 0 4px grey'}} />
    //         </Grid>
    //         <Grid item xs={9} sm={10} md={10} style={{
    //             // backgroundColor:'white',
    //              display:'flex', flexDirection:'row'}}>
    //             <Grid item xs={12} sm={12} md={12} style={{
    //                 // backgroundColor:'blue',
    //                 marginLeft:'-10px',
    //                 display:'flex', flexDirection:'row', alignItems:'center',
    //                 }}>
    //                 <Typography variant="subtitle1" component={Link} to={`/${comment.userId}`} color="primary" style={{
    //                     // backgroundColor:'red',
    //                     // marginLeft:'-12px',
    //                     textDecoration:'none',
    //                      }}>{comment?.userName}&nbsp;</Typography>
    //                <Typography variant="subtitle2" style={{color:'grey', textAlign:'right'}}> &#183;&nbsp;{createdAt}</Typography>
    //             </Grid>
    //             {/* <Grid item xs={3} sm={2} md={3} style={{
    //                 // backgroundColor:'green',
    //                  display:'flex', justifyContent:'center', alignItems:'center'}}>
    //                 <Typography variant="subtitle2" style={{
    //                     backgroundColor:'orange', 
    //                     color:'black'}}>2m ago</Typography>
    //             </Grid> */}
    //         </Grid>
    //     </Grid>
    //     <Divider />
    //     <Grid container xs={12} sm={12} md={12} style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', 
    //     // backgroundColor:'lightblue',
    //     }}>
    //         {/* <Grid item xs={3} sm={2} md={2} style={{
    //                 backgroundColor:'green',
    //                  display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
    //                 <Typography variant="subtitle2" style={{
    //                     // backgroundColor:'orange', 
    //                     color:'silver'}}>{createdAt}</Typography>
    //             </Grid> */}
    //         <Grid item xs={9} sm={10} md={10} style={{display:'flex', 
    //         // backgroundColor:'lightgreen',
    //         paddingRight:'10px',
            
    //         wordBreak: 'break-word'
    //         }}>
    //             <Typography variant="subtitle2" style={{marginLeft:'-20px',}}>{comment?.commentBody}</Typography>
    //         </Grid>
    //     </Grid>
    // </Grid> 
    ) 
  }

export default CommentCard;
