import { FETCH_EXPLORE_BUCKETS, ADD_TO_EXPLORE, REMOVE_FROM_EXPLORE } from '../constants/actionTypes';

export default (exploreBuckets = [], action) => {
  switch (action.type) {
    case FETCH_EXPLORE_BUCKETS:
      return action.payload;
    case ADD_TO_EXPLORE:
      return exploreBuckets.map((fav) => (fav.coordinates === action.payload.coordinates ? {...fav, favs:action.payload.favs, visits:action.payload.visits, buckets:action.payload.buckets} : fav));
    case REMOVE_FROM_EXPLORE:
      return exploreBuckets.map((fav) => (fav.coordinates === action.payload.coordinates ? {...fav, favs:action.payload.favs, visits:action.payload.visits, buckets:action.payload.buckets} : fav));
    default:
      return exploreBuckets;
  }
};