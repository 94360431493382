import {ADDTOFAVS, CLEAR_FAVORITES, FETCH_USER_FAVS, GET_FAVS, REMOVEFROMFAVS } from '../constants/actionTypes';

export default (favorites = [], action) => {
  switch (action.type) {
    case GET_FAVS:
      return action.payload;
    case ADDTOFAVS:
      return [...favorites, action.payload];
    case REMOVEFROMFAVS:
      return favorites.filter((favorite) => favorite !== action.payload);
    case CLEAR_FAVORITES: {
      return favorites = [];
    }
    default:
      return favorites;
  }
};
