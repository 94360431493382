import { makeStyles } from '@material-ui/core/styles';

export const backgroundColor = 'linear-gradient(45deg, #ff2857 30%, #FF8E53 90%)';

export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#fafafa',
    color:'#f50057'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    display:'flex',
    flexDirection:'column'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform:'capitalize',
    width:'75%',
    height:'45px',    
    marginBottom:'5px',
    marginTop:'24px',
    margin:'auto',
    background:'linear-gradient(45deg, #526bf4 25%, #3ec7e0 100%)',
    color:'white',
    boxShadow:'none'
  },
  googleButton: {
    marginBottom: theme.spacing(2),
    color:'white !important',
    textTransform:'capitalize',
    width:'75%',
    background: 'linear-gradient(45deg, #ff2857 30%, #FF8E53 90%)',
    height:'45px',
    boxShadow:'none'
  },
}));

// background: 'linear-gradient(45deg, #ff2857 30%, #FF8E53 90%)',
