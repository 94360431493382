import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Button, Box, Tabs, Tab, TextField, Paper, CardActions, CardContent, Collapse } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';
import { Typography } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { updatePost } from '../../../actions/posts';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';


// import VerticalTabs from './TagLibrary';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'flex-start',
    padding:'5px',
    cursor:'text',
    width:'97%',
    minHeight:'50px',
    border:'1px solid silver',
    borderRadius:'5px',
    margin: 'auto',
    marginTop:'7px',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    }
  },
  
  tagTitles : {
    color:'#000',
    borderTop : '1px solid #ededed',
    paddingTop : '15px',
    display:'flex',
    alignItems:'center'
  }, 
  tagSubTitles : {
    color:'grey',
    display:'flex',
    alignItems:'flex-start',
    marginTop:'-5px',
    marginBottom:'5px'
  }, 
  chip : {
    margin:'3px',
    marginLeft:'0px'
  },
  section : {
    marginTop:'20px',
    marginBottom:'10px'
  }, 
  tabRoot: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tabs : {
    minWidth:'50px'
  }, 
  vibesSelected: {
    margin:'3px',
    marginLeft:'0px',
    backgroundColor:'#9b59b6 !important',
    color:'white'
  }, 
  vibesOutlined: {
    margin:'3px',
    marginLeft:'0px',
    backgroundColor:'white !important',
    color:'#9b59b6',
    border: '1px solid #9b59b6'
  }, 
  typeSelected: {
    margin:'3px',
    marginLeft:'0px',
    background:'linear-gradient(to right, #FD297B, #FF655B)',
    color:'white'
  }, 
  typeOutlined: {
    margin:'3px',
    marginLeft:'0px',
    backgroundColor:'white !important',
    color:'#FD297B',
    border: '1px solid #FF655B'
  }, 
  ax: {
    margin:'3px',
    marginLeft:'0px',
    background:'linear-gradient(to right, #11998e, #38ef7d)',
    color:'white'
  }, 
  axo: {
    margin:'3px',
    marginLeft:'0px',
    backgroundColor:'white !important',
    color:'#11998e',
    border: '1px solid #11998e'
  }, 
}));

const MaxWidthDialog = ({post, postId, postType}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
//   const [selected, setSelected] = useState({broadCategories:[], generalTags:[] });
  const [postData, setPostData] = useState({title: '', caption:'', summary:'', photoURL:'', videoURL:'', message: '', content:'', tags: '', generalTags:'', activityTags:'', vibeTags:'', selectedFile: '', placeName:'', location:'' });
  // const post = useSelector((state) => (postId ? state.posts.find((message) => message.id === postId) : null));
  const [location, setLocation] = useState({ placeName:post?.placeName, locName:post?.locName, country:post?.country, location:post?.location, latitude : post?.latitude, longitude: post?.longitude, coordinates: post?.coordinates});
  const [selectedTags, setselectedTags] = useState([]);
  const [vidtagSelected, setVidtagSelected] = useState(post?.AVTags?.length > 0 ? true : false);
  const [pseudoCount, setPseudoCount] = useState(0);
  const [query, setQuery] = useState("");
  const parentRef = useRef();
  const childRef = useRef();
  const [value, setValue] = React.useState(0);
  const [chipCount, setChipCount] = useState(0);
  const dispatch = useDispatch();
  const [chipData, setChipData] = useState([
    {label:'Nature', type:'Categories',tagType:'General'},
    {label:'Animals', type:'Categories',tagType:'General'},
    {label:'Urban', type:'Categories',tagType:'General'},
    {label:'Architecture', type:'Categories',tagType:'General'},
    {label:'Culture', type:'Categories',tagType:'General'},
    {label:'History', type:'Categories',tagType:'General'},
    {label:'Religion', type:'Categories',tagType:'General'},
    {label:'Scenery & Landscapes', type:'Categories',tagType:'General'},
    {label:'Experiences', type:'Categories',tagType:'General'},
    {label:'Events', type:'Categories',tagType:'General'},

    {label:'Nature Photography', type:'Nature', tagType:'General'},
    {label:'Enjoying Nature', type:'Nature', tagType:'General'},
    {label:'Amidst the Wilderness', type:'Nature', tagType:'General'},
    {label:'Majestic Mountains', type:'Nature', tagType:'General'},
    {label:'Sea & Beaches', type:'Nature', tagType:'General'},
    {label:'Enchanting Forests', type:'Nature', tagType:'General'},
    {label:'Tropical Paradise', type:'Nature', tagType:'General'},
    {label:'Winter Destination', type:'Nature', tagType:'General'},
    {label:'Polar Regions', type:'Nature', tagType:'General'},
    {label:'Desert Dunes', type:'Nature', tagType:'General'},
    {label:'Countryside', type:'Nature', tagType:'General'},
    {label:'Remote', type:'Nature', tagType:'General'},
    {label:'Natural Attractions', type:'Nature', tagType:'General'},
    {label:'Natural Wonders', type:'Nature', tagType:'General'},

    {label:'Wildlife', type:'Animals', tagType:'General'},
    {label:'Birds', type:'Animals', tagType:'General'},
    {label:'Aquatic Life', type:'Animals', tagType:'General'},
    {label:'Marine Wildlife', type:'Animals', tagType:'General'},
    {label:'Exotic Animals', type:'Animals', tagType:'General'},
    {label:'Domestic Animals', type:'Animals', tagType:'General'},
    {label:'Endangered Species', type:'Animals', tagType:'General'},
    {label:'Animals & Humans', type:'Animals', tagType:'General'},
    {label:'Awesome Animal Moments', type:'Animals', tagType:'General'},


    {label:'Cityscape', type:'Urban', tagType:'General'},//////////////////////////x1-
    {label:'Townscape', type:'Urban', tagType:'General'},//////////////////////////x1-
    {label:'Small Town Charm', type:'Urban', tagType:'General'},
    {label:'Life in the City', type:'Urban', tagType:'General'},
    {label:'City Lights', type:'Urban', tagType:'General'},
    {label:'City at Night', type:'Urban', tagType:'General'},
    {label:'Exploring the City', type:'Urban', tagType:'General'},
    {label:'Skyline', type:'Urban', tagType:'General'},
    {label:'Street Photography', type:'Urban', tagType:'General'},

    {label:'Cultural Landmarks', type:'Culture', tagType:'General'},
    {label:'Life of Locals', type:'Culture', tagType:'General'},
    {label:'Everyday Life', type:'Culture', tagType:'General'},
    {label:'Heritage', type:'Culture', tagType:'General'},
    {label:'Traditions & Customs', type:'Culture', tagType:'General'},
    {label:'Cultural Events', type:'Culture', tagType:'General'},////////////////////x1-
    {label:'Cultural Hotspots', type:'Culture', tagType:'General'},
    {label:'Multicultural', type:'Culture', tagType:'General'},
    {label:'Street Photography', type:'Culture', tagType:'General'},//////////////////x1-
    {label:'Indigenous Peoples', type:'Culture', tagType:'General'},

    {label:'Ancient Ruins', type:'History', tagType:'General'},
    {label:'Museums', type:'History', tagType:'General'},
    {label:'Historical Landmarks', type:'History', tagType:'General'},
    {label:'Historical Site', type:'History', tagType:'General'},
    {label:'Historic Architecture', type:'History', tagType:'General'},
    {label:'Historical Art', type:'History', tagType:'General'},
    {label:'Historical Monuments', type:'History', tagType:'General'},
    {label:'Natural History', type:'History', tagType:'General'},
    {label:'Prehistoric', type:'History', tagType:'General'},

    {label:'Religious Landmarks', type:'Religion', tagType:'General'},
    {label:'Religious Sites', type:'Religion', tagType:'General'},
    {label:'Religious Architecture', type:'Religion', tagType:'General'},////////////////////x1-
    {label:'Religious Art', type:'Religion', tagType:'General'},
    {label:'Religious Monuments', type:'Religion', tagType:'General'},
    {label:'Religious Events', type:'Religion', tagType:'General'},//////////////////////////x1-
    {label:'Holy', type:'Religion', tagType:'General'},
    {label:'Sacred', type:'Religion', tagType:'General'},

    {label:'Iconic Landmarks', type:'Architecture', tagType:'General'},
    {label:'Modern Architecture', type:'Architecture', tagType:'General'},
    {label:'Historic Architecture', type:'Architecture', tagType:'General'},
    {label:'Religious Architecture', type:'Architecture', tagType:'General'},/////////////////////x1-
    {label:'Landscape Architecture', type:'Architecture', tagType:'General'},
    {label:'Indoor Scenery', type:'Architecture', tagType:'General'},//////////////////////////////x1-
    {label:'Amazing Structures', type:'Architecture', tagType:'General'},
    {label:'Engineering Marvels', type:'Architecture', tagType:'General'},

    {label:'Beautiful Scenery', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Amazing View', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Indoor Scenery', type:'Scenery & Landscapes', tagType:'General'},/////////////////////////x1-
    {label:'Random Travel Clicks', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Forest Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Cityscape', type:'Scenery & Landscapes', tagType:'General'},//////////////////////////////x1-
    {label:'Townscape', type:'Scenery & Landscapes', tagType:'General'},/////////////////////////////x1-
    {label:'Urban Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Rural Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Desert Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Seascape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Cloudscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Mountain Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Fall Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Winter Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Summer Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Spring Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Sunrise', type:'Scenery & Landscapes', tagType:'General' },
    {label:'Sunset', type:'Scenery & Landscapes', tagType:'General' },
    {label:'Northern Lights', type:'Scenery & Landscapes', tagType:'General' },
    {label:'Starry Skies', type:'Scenery & Landscapes', tagType:'General' },

    {label:'Just My Travels', type:'Experiences', tagType:'General'},
    {label:'Adventure', type:'Experiences', tagType:'General'},
    {label:'Entertainment', type:'Experiences', tagType:'General'},
    {label:'Leisure & Relaxation', type:'Experiences', tagType:'General'},
    {label:'Luxury Experience', type:'Experiences', tagType:'General'},
    {label:'Cultural Experience', type:'Experiences', tagType:'General'},
    {label:'Spiritual Experience', type:'Experiences', tagType:'General'},
    {label:'Romantic Experience', type:'Experiences', tagType:'General'},
    {label:'Food & Dining', type:'Experiences', tagType:'General'},
    {label:'Nightlife', type:'Experiences', tagType:'General'},

    {label:'Festivals', type:'Events', tagType:'General'},
    {label:'Carnivals', type:'Events', tagType:'General'},
    {label:'Fairs', type:'Events', tagType:'General'},
    {label:'Concerts', type:'Events', tagType:'General'},
    {label:'Cultural Events', type:'Events', tagType:'General'},/////////////////////////////////////x1-
    {label:'Religious Events', type:'Events', tagType:'General'},/////////////////////////////////////x1-
    {label:'Sports Events', type:'Events', tagType:'General'},



    ... post?.type === 'Video' ? [

    {label:'Short Clip', type:'AVTags', tagType:'AVTags'},
    {label:'Cinematic Video', type:'AVTags', tagType:'AVTags'},
    {label:'Vlog', type:'AVTags', tagType:'AVTags'},
    {label:'Travel Guide', type:'AVTags', tagType:'AVTags'},
    {label:'Best Of', type:'AVTags', tagType:'AVTags'},
    {label:'Tour', type:'AVTags', tagType:'AVTags'},
    {label:'Review', type:'AVTags', tagType:'AVTags'}, ] : 
    
     post?.type === 'Article' ? [

    {label:'Personal Blog Post', type:'AVTags', tagType:'AVTags'},
    {label:'Travel Guide', type:'AVTags', tagType:'AVTags'},
    {label:'Destination Spotlight', type:'AVTags', tagType:'AVTags'},
    {label:'Review Article', type:'AVTags', tagType:'AVTags'},
    {label:'Best Of', type:'AVTags', tagType:'AVTags'},
    {label:'How-To Guide', type:'AVTags', tagType:'AVTags'},
    {label:'Advice & Tips', type:'AVTags', tagType:'AVTags'},
    {label:'FAQs', type:'AVTags', tagType:'AVTags'},
    
    ] : [],

    {label:'Peaceful', type:'Vibes', tagType:'Vibes'},
    {label:'Relaxing', type:'Vibes', tagType:'Vibes'},
    {label:'Luxurious', type:'Vibes', tagType:'Vibes'},
    {label:'Exciting', type:'Vibes', tagType:'Vibes'},
    {label:'Adventurous', type:'Vibes', tagType:'Vibes'},
    {label:'Romantic', type:'Vibes', tagType:'Vibes'},
    {label:'Exotic', type:'Vibes', tagType:'Vibes'},
    {label:'Spiritual', type:'Vibes', tagType:'Vibes'},
    {label:'Scenic', type:'Vibes', tagType:'Vibes'},
    {label:'Awe-Inspiring', type:'Vibes', tagType:'Vibes'},
    {label:'Bustling', type:'Vibes', tagType:'Vibes'},
    {label:'Charming', type:'Vibes', tagType:'Vibes'},
    {label:'Vibrant', type:'Vibes', tagType:'Vibes'},
    {label:'Cozy', type:'Vibes', tagType:'Vibes'},

    {label:'Abseiling', type:'Activities', tagType:'Activities'},
    {label:'Air Rifle Shooting', type:'Activities', tagType:'Activities'},
    {label:'Archery', type:'Activities', tagType:'Activities'},
    {label:'ATV Ride', type:'Activities', tagType:'Activities'},

    {label:'Backpacking', type:'Activities', tagType:'Activities'},
    {label:'Badminton', type:'Activities', tagType:'Activities'},
    {label:'Banana Boat Ride', type:'Activities', tagType:'Activities'},
    {label:'BASE Jumping', type:'Activities', tagType:'Activities'},
    {label:'Beach Volleyball', type:'Activities', tagType:'Activities'},
    {label:'Bird Watching', type:'Activities', tagType:'Activities'},
    {label:'Boat Ride', type:'Activities', tagType:'Activities'},
    {label:'Bodyboarding', type:'Activities', tagType:'Activities'},
    {label:'Bowling', type:'Activities', tagType:'Activities'},
    {label:'Bungee Jumping', type:'Activities', tagType:'Activities'},

    {label:'Cage Diving', type:'Activities', tagType:'Activities'},
    {label:'Camping', type:'Activities', tagType:'Activities'},
    {label:'Canoeing', type:'Activities', tagType:'Activities'},
    {label:'Canyoning', type:'Activities', tagType:'Activities'},
    {label:'Cave Diving', type:'Activities', tagType:'Activities'},
    {label:'Chess', type:'Activities', tagType:'Activities'},
    {label:'Cliff Diving', type:'Activities', tagType:'Activities'},
    {label:'Clubbing', type:'Activities', tagType:'Activities'},
    {label:'Cycling', type:'Activities', tagType:'Activities'},

    {label:'Dancing', type:'Activities', tagType:'Activities'},
    {label:'Deep Diving', type:'Activities', tagType:'Activities'},
    {label:'Diving', type:'Activities', tagType:'Activities'},
    {label:'Dolphin Watching', type:'Activities', tagType:'Activities'},

    {label:'Equestrian', type:'Activities', tagType:'Activities'},
    {label:'Fly Fish Ride', type:'Activities', tagType:'Activities'},
    {label:'Flyboard Flying', type:'Activities', tagType:'Activities'},
    {label:'Freediving', type:'Activities', tagType:'Activities'},
    {label:'Go-Karting', type:'Activities', tagType:'Activities'},
    {label:'Golf', type:'Activities', tagType:'Activities'},
    {label:'Hang Gliding', type:'Activities', tagType:'Activities'},
    {label:'Helicopter Ride', type:'Activities', tagType:'Activities'},
    {label:'Hiking', type:'Activities', tagType:'Activities'},
    {label:'Hockey', type:'Activities', tagType:'Activities'},
    {label:'Horseback Ride', type:'Activities', tagType:'Activities'},

    {label:'Ice Climbing', type:'Activities', tagType:'Activities'},
    {label:'Ice Diving', type:'Activities', tagType:'Activities'},
    {label:'Ice Hockey', type:'Activities', tagType:'Activities'},
    {label:'Ice Skating', type:'Activities', tagType:'Activities'},
    {label:'Jet Skiing', type:'Activities', tagType:'Activities'},
    {label:'Jogging', type:'Activities', tagType:'Activities'},

    {label:'Kayaking', type:'Activities', tagType:'Activities'},
    {label:'Kite Flying', type:'Activities', tagType:'Activities'},
    {label:'Kite Surfing', type:'Activities', tagType:'Activities'},
    {label:'Kneeboarding', type:'Activities', tagType:'Activities'},
    {label:'Laser Tag', type:'Activities', tagType:'Activities'},
    {label:'Longboarding', type:'Activities', tagType:'Activities'},

    {label:'Mini Golf', type:'Activities', tagType:'Activities'},
    {label:'Motorcycle Ride', type:'Activities', tagType:'Activities'},
    {label:'Mountain Biking', type:'Activities', tagType:'Activities'},
    {label:'Mountaineering', type:'Activities', tagType:'Activities'},

    {label:'Paddleboarding', type:'Activities', tagType:'Activities'},
    {label:'Paddlesurfing', type:'Activities', tagType:'Activities'},
    {label:'Paintball', type:'Activities', tagType:'Activities'},
    {label:'Parachuting', type:'Activities', tagType:'Activities'},
    {label:'Paragliding', type:'Activities', tagType:'Activities'},
    {label:'Parasailing', type:'Activities', tagType:'Activities'},
    {label:'Pool & Billiards', type:'Activities', tagType:'Activities'},

    {label:'Quad Bike Ride', type:'Activities', tagType:'Activities'},
    {label:'Racquetball', type:'Activities', tagType:'Activities'},
    {label:'Rafting', type:'Activities', tagType:'Activities'},
    {label:'Rock Climbing', type:'Activities', tagType:'Activities'},
    {label:'Roller Coaster Ride', type:'Activities', tagType:'Activities'},
    {label:'Rowing', type:'Activities', tagType:'Activities'},
    {label:'Ocean Walker', type:'Activities', tagType:'Activities'},

    {label:'Safari', type:'Activities', tagType:'Activities'},
    {label:'Sailing', type:'Activities', tagType:'Activities'},
    {label:'Scuba Diving', type:'Activities', tagType:'Activities'},
    {label:'Seaplane Ride', type:'Activities', tagType:'Activities'},
    {label:'Shark Cage Diving', type:'Activities', tagType:'Activities'},
    {label:'Skateboarding', type:'Activities', tagType:'Activities'},
    {label:'Skiing', type:'Activities', tagType:'Activities'},
    {label:'Skimboarding', type:'Activities', tagType:'Activities'},
    {label:'Sky Diving', type:'Activities', tagType:'Activities'},
    {label:'Sledding', type:'Activities', tagType:'Activities'},
    {label:'Snorkelling', type:'Activities', tagType:'Activities'},
    {label:'Snowboarding', type:'Activities', tagType:'Activities'},
    {label:'Snowmobiling', type:'Activities', tagType:'Activities'},
    {label:'Snowshoeing', type:'Activities', tagType:'Activities'},
    {label:'Speed Boat Ride', type:'Activities', tagType:'Activities'},
    {label:'Spelunking', type:'Activities', tagType:'Activities'},
    {label:'Spinnaker Flying', type:'Activities', tagType:'Activities'},
    {label:'Stargazing', type:'Activities', tagType:'Activities'},
    {label:'Surfing', type:'Activities', tagType:'Activities'},
    {label:'Swimming', type:'Activities', tagType:'Activities'},

    {label:'Table Tennis', type:'Activities', tagType:'Activities'},
    {label:'Tennis', type:'Activities', tagType:'Activities'},
    {label:'Train Ride', type:'Activities', tagType:'Activities'},
    {label:'Trekking', type:'Activities', tagType:'Activities'},

    {label:'Underwater Scooter Riding', type:'Activities', tagType:'Activities'},
    {label:'Video Games', type:'Activities', tagType:'Activities'},
    {label:'VR/AR Games', type:'Activities', tagType:'Activities'},

    {label:'Wakeboarding', type:'Activities', tagType:'Activities'},
    {label:'Water Skiing', type:'Activities', tagType:'Activities'},
    {label:'Water Sports', type:'Activities', tagType:'Activities'},
    {label:'Whale Watching', type:'Activities', tagType:'Activities'},
    {label:'Whitewater Rafting', type:'Activities', tagType:'Activities'},
    {label:'Windsurfing', type:'Activities', tagType:'Activities'},
    {label:'Yoga', type:'Activities', tagType:'Activities'},
    {label:'Ziplining', type:'Activities', tagType:'Activities'},
    {label:'Zorbing', type:'Activities', tagType:'Activities'},

    
    

  ]);

  const categoryArray = ["Categories", "Nature", "Animals", "Urban", "Architecture", "Culture", "History", "Religion", "Scenery & Landscapes", "Experiences", "Events", "Vibes", "Activities"];
  const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","Y","Z"];

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }

  // const handleSubmit = (e) => {
  //     e.preventDefault();
  //     setOpen(false);
  // }

  // const clear = () => {
  //   // setCurrentId(0);
  //   handleClose();
  //   setPostData({title: '', caption:'', summary:'', photoURL:'', videoURL:'', message: '', content:'', tags: '', selectedFile: '', placeName:'', location:'' });
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setselectedTags([]);
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  useEffect(() => {
    if (post) {

    const uniqueTags = chipData.filter((chip) => post.tags.includes(chip.label)).reduce((unique, chip) => {
      if (!unique.some((tag) => tag.label === chip.label)) {
        unique.push(chip);
      }
      return unique;
    }, []);
    setselectedTags(uniqueTags);
  }
    // setselectedTags(chipData.filter(chip => post.tags.includes(chip.label)));
  }, [post]);

  const handleClick = (selected) => () => {

    if(selectedTags.find((tag) => tag.label === selected.label)) {
      setselectedTags(selectedTags.filter(tag => tag.label !== selected.label));
      // setVidtagSelected(false);
    } else {
      if(selectedTags?.length < 10) 
    setselectedTags([...selectedTags, selected]);
    if(selected.type === "Categories") {
    setPseudoCount(pseudoCount + 1)
    };
    // setVidtagSelected(true);
    }
  }

  const handleClearTags = () => {
    setselectedTags([]);
    setPseudoCount(0);
  }

  const handleDelete = (chipToDelete) => {
    setselectedTags((chips) => chips.filter((chip) => chip.label !== chipToDelete));
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if(selectedTags?.length > 1) {
    parentRef.current.scrollIntoView();
    }
  }, [pseudoCount]);

  ////////////////////Form Functions////////////////////

  const user = JSON.parse(localStorage.getItem('profile'));

  ////Location States/////////////////
 
  const [place, setPlace] = 
    useState({name:'',
              // id:'',
              // address:'',
              photos:[]
  });
  const [locQuery, setLocQuery] = useState(post?.placeName);
  // const [city, setCity] = useState("")
  const autoCompleteRef = useRef(null);
  // console.log(place);

  // let autoComplete;

  const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const handleScriptLoad = () => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    // { 
    //   // types: ["(cities)"],
    //  componentRestrictions: { country: "lk" } }
  );
  // autoCompleteRef.current.setFields(["address_components", "formatted_address", 'place_id', 'geometry', 'name', 'photos']);
  autoCompleteRef.current.addListener("place_changed", handlePlaceSelect);
}

const handlePlaceSelect = () => {
  const addressObject = autoCompleteRef.current.getPlace();
  const name = addressObject.name;
  const countryString = addressObject.adr_address.substring(addressObject.adr_address.lastIndexOf("<span class=\"country-name\">"), addressObject.adr_address.lastIndexOf("<"));
  const country = countryString.split(">")[1];
  let placeName;
  if(name === country) {
    placeName = name;
  } else {
    placeName = name + "," + " " + country;
  }
  // const placeName = name + "," + " " + country;
  const photos = addressObject.photos;
  const long_name = addressObject.address_components.map(addressComponent => addressComponent.long_name);
  const long_name_str = long_name.toString();
  const latitude = (addressObject.geometry.location.lat());
  const longitude = (addressObject.geometry.location.lng());
  const coordinates = addressObject.geometry.location.lat() + ', ' + addressObject.geometry.location.lng();
  // updateLocQuery(locQuery);
  // setPlace({...place, name:name, photos: photos});
  // setPostData({...postData, location:long_name_str})
  setLocation({...location, location:long_name_str,  latitude:latitude, longitude:longitude, coordinates:coordinates, placeName:placeName, locName:name, country:country })
  setLocQuery(placeName);
  // console.log(addressObject);
  // console.log(placeName);
  // setPlace({...place, });
}

useEffect(() => {
  loadScript(
    `https://maps.googleapis.com/maps/api/js?key=AIzaSyDXGCxkxHNNK_ZO18wSSJlcwCA242XZuqk&callback=initMap&libraries=places`,
    () => handleScriptLoad(setLocQuery, autoCompleteRef)
  );
}, [open]);
  /////////////////////////////////////

  useEffect(() => {
    if (post) setPostData(post);
  }, [post]);
  // console.log(post);

  const clear = () => {
    // setCurrentId(0);
    handleClose();
    setPostData({title: '', caption:'', summary:'', photoURL:'', videoURL:'', message: '', content:'', tags: '', selectedFile: '', placeName:'', location:'', tags:'', generalTags:'', activityTags:'', vibeTags:'' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(postData.tags);
    dispatch(updatePost(postId, { ...postData, name: user?.result?.name, placeName:location.placeName, locName:location.locName, country:location.country, location:location.location,latitude:location.latitude, longitude:location.longitude, coordinates:location.coordinates, tags:selectedTags, AVTags:selectedTags.filter(tag => tag.type === 'AVTags'), generalTags:selectedTags.filter(tag => (tag.tagType === 'General')), activityTags:selectedTags.filter(tag => (tag.type === 'Activities')), vibeTags:selectedTags.filter(tag => (tag.type === 'Vibes') && tag.label) }));
    clear();
  };

  if (!user?.result?.name) {
    return (
      <Paper className={classes.paper}>
        <Typography variant="h6" align="center">
          Please Sign In to create your own memories and like other's memories.
        </Typography>
      </Paper>
    );
  }

  ////////////////////Form Functions////////////////////


  

  return (
    <React.Fragment>
      {postId && (
      <Button size="small" color="primary" onClick = {handleClickOpen} fullWidth style={{height:'2.5rem', paddingLeft:'15px', justifyContent:'left', textTransform:'capitalize', fontSize:'0.95rem'}}>
        <EditIcon fontSize="small" />&nbsp;&nbsp;Edit
      </Button>
      )
     
      // <MenuItem onClick={handleClickOpen}>
      // <IconButton color="inherit">
      //   <AddBoxOutlinedIcon />
      //     </IconButton>
      //     <p>Create</p>
      // </MenuItem>
      }
      <Dialog
        id="formTagDialog"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title" style={{boxShadow:'0 0 10px -6.5px black', marginBottom:'13px'}}>Edit Post</DialogTitle>
        <DialogContent>
        <form autoComplete="off" noValidate onSubmit={handleSubmit} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}>
{post.type == 'Video' && (
<>
<div style={{width:'100%'}}>
  <Typography style={{color:'#333'}}>YouTube Video Link</Typography>
</div>
<TextField name="YouTube Video Link" variant="outlined" fullWidth multiline value={postData.videoURL} onChange={(e) => setPostData({ ...postData, videoURL: e.target.value })} />
</>
)}
{(post.type == 'Video' || post.type == 'Article') && (
<>
<div style={{width:'100%', marginTop: post.type === 'Video' && '20px'}}>
  <Typography style={{color:'#333'}}>Edit Title</Typography>
</div>
<TextField name="title" variant="outlined" fullWidth value={postData.title} onChange={(e) => setPostData({ ...postData, title: e.target.value })} />
</>
)}
{(post.type == 'Video' || post.type == 'Photo') && (
<>
  <div style={{width:'100%', marginTop:'20px'}}>
    <Typography style={{color:'#333'}}>Edit Caption</Typography>
  </div>
<TextField name="caption" variant="outlined" fullWidth value={postData.caption} onChange={(e) => setPostData({ ...postData, caption: e.target.value })} />
</>
)}
{(post.type == 'Article') && (
<>
<div style={{width:'100%', marginTop:'20px'}}>
    <Typography style={{color:'#333'}}>Edit Summary</Typography>
</div>
<TextField name="summary" variant="outlined" fullWidth multiline rows={3} value={postData.summary} onChange={(e) => setPostData({ ...postData, summary: e.target.value })} />
</>
)}
{(post.type == 'Article') && (
<>
<div style={{width:'100%', marginTop:'20px'}}>
  <Typography style={{color:'#333'}}>Edit Content</Typography>
</div>
<TextField name="content" variant="outlined" fullWidth multiline rows={8} value={postData.content} onChange={(e) => setPostData({ ...postData, content: e.target.value })} />
</>
)}
{/* <TextField name="tags" variant="outlined" label="Tags (coma separated)" fullWidth value={postData.tags} onChange={(e) => setPostData({ ...postData, tags: e.target.value.split(',') })} /> */}
  <div style={{width:'100%', marginTop:'20px'}}>
    <Typography style={{color:'#333'}}>Edit Location</Typography>
  </div>
<div id="tinyInput4" className="search-location-input" style={{display:"flex", justifyContent:'center',  alignItems:'center', height:'55px', width:'100%', margin:'0px', marginBottom:'20px'}}>
<SearchIcon style={{backgroundColor:'white', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', border:'1px solid silver', borderRight:'0', padding:'12px', fontSize:'3.45rem'}}/>
<input
ref={autoCompleteRef}
onChange={event => setLocQuery(event.target.value)}
placeholder="Enter a location"
class="pac-target-input"
value={locQuery}
/>
</div>
<div style={{width:'100%', marginTop:'20px', marginBottom:'10px', textAlign:'center', borderTop:'5px solid #ededed', paddingTop:'10px'}}>
    <Typography style={{color:'#333'}}>Edit Tags</Typography>
  </div>
      <div id="tagSearch">
          <SearchIcon style={{backgroundColor:'#ededed', borderTopLeftRadius: '25px', borderBottomLeftRadius: '25px', padding:'12px', fontSize:'2.8rem'}}/>
        <input
        autoComplete='off'
        id="tagSearchInput"
        class="pac-target-input"
        onChange={event => setQuery(event.target.value)}
        placeholder="Search For Tags"
        value={query}
      />
      </div>

        <div className={classes.section}>
        {/* {selectedTags.length ? ( */}
          <div style={{display:'flex', alignItems:'baseline', borderTop:'1px solid #ededed', paddingTop:'10px'}}>
        <Typography variant="subtitle1">Selected Tags <span variant="subtitle2" style={{color:'grey', fontSize:'0.8rem'}}>({selectedTags?.length}/10)</span></Typography>
        {(selectedTags?.length > 0) && (
        <Button onClick={handleClearTags} color="secondary" style={{marginLeft:'auto', textTransform:'capitalize'}}>
            Clear All
          </Button>
        )}
          </div>
        <div className={classes.selected}>
        {selectedTags.map((data) => {
          return (
              
              <Chip
              label={data.label}
              className={classes.chip}
              variant="default"
              onDelete = {() => handleDelete(data.label)}
              // color="primary"
              />
          )
        })}
        </div>
        </div>
        {(query === "") && (
        <div className={classes.tabRoot} style={{borderTop : '1px solid #ededed',}}>
      <div className={classes.section}>
      <AppBar id="tagBar" position="static" color="default" style={{width:'100%'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          {post.type === 'Video' && (
          <Tab label="Video Tags" {...a11yProps(0)} className={classes.tabs} /> )}
          {post.type === 'Article' && (
          <Tab label="Article Tags" {...a11yProps(0)} className={classes.tabs} /> )}
          <Tab label="Descriptive" {...a11yProps(1)} className={classes.tabs} />
          <Tab label="Vibes" {...a11yProps(2)} className={classes.tabs} />
          {/* <Tab label="Place Types" {...a11yProps(2)} className={classes.tabs} /> */}
          <Tab label="Activities" {...a11yProps(3)} className={classes.tabs} />
          {/* <Tab label="Miscellaneous" {...a11yProps(4)} className={classes.tabs} /> */}
        </Tabs>
      </AppBar>
      </div>
      {post?.type === 'Video' && (
        <TabPanel class="tabPanel" value={value} index={0}>
        <div class="tabPanel" className={classes.section}>
          {/* <CardActions style={{borderBottom : !expanded && '1px solid #ededed'}}>
            <Typography variant="subtitle1" onClick={handleExpandClick} style={{color:'#FF655B', cursor:'pointer'}}>Not sure which tag to use?</Typography>
           
            <IconButton size="small"
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            style={{padding:'initial'}}
          > 
            <ExpandMoreIcon style={{background:'linear-gradient(to right, #FD297B, #FF655B)', color:'white', borderRadius:'50%' }} />
            
          </IconButton>
          </CardActions> */}
          {/* <Collapse in={expanded} timeout="auto" unmountOnExit> */}
          {/* <CardContent>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Typography variant="subtitle2" style={{paddingBottom:'15px', color:'#000', borderBottom:'1px solid #ededed', marginBottom:'15px'}}>
                      Video tags let travelers know the type of video. You can only select one video tag. <br></br>
                      <span style={{color:'#FF655B', fontWeight:'bold'}}>Short Clip - </span>Add this tag for videos less than 30 seconds. <br></br>
                      <span style={{color:'#FF655B', fontWeight:'bold'}}>Cinematic - </span>Add this tag for cinematic travel videos and travel films. <br></br>
                      <span style={{color:'#FF655B', fontWeight:'bold'}}>Vlog - </span>Add this tag for your personal travel vlogs<br></br>
                      <span style={{color:'#FF655B', fontWeight:'bold'}}>Travel Guide - </span>Add this tag for your travel guides about a destination.<br></br>
                      <span style={{color:'#FF655B', fontWeight:'bold'}}>Best Of - </span>Add this tag for your travel guides about a destination.<br></br>
                      <span style={{color:'#FF655B', fontWeight:'bold'}}>Roundup - </span>Add this tag for your travel guides about a destination.<br></br>
                      <span style={{color:'#FF655B', fontWeight:'bold'}}>Tour - </span>Add this tag for a tour of a destination. (e.g. - New York City Tour)<br></br>
                      <span style={{color:'#FF655B', fontWeight:'bold'}}>Review - </span>Add this tag for your video review of a destination or experience. (e.g. - Hotel review, Food review)<br></br>
                      </Typography>
          </Collapse>
          </CardContent> */}
  
          
          {/* <Typography className={classes.tagTitles} variant="subtitle2" style={{paddingBottom:'15px', color:'#000', borderBottom:'1px solid #ededed', marginBottom:'15px'}}>Video tags let travelers know the type of video. You can only select one video tag. <br></br>Add the 'Short Clip' tag for videos less than 30 seconds. <br></br>Add the 'Best Of ' tag for videos that show the top places to go or top things to do <br></br> (e.g. - Top 10 places to visit in Rio).</Typography> */}
          { 
          chipData.map((data) => {
            if(data.type === "AVTags") {
            return (
                
                <Chip
                label={data.label}
                className = {selectedTags.find(tag => tag.label === data.label) ? classes.typeSelected : classes.typeOutlined}
                onClick={handleClick(data)}
                variant={selectedTags.find((tag) => tag.label === data.label) ? "default" : "outlined"}
                // color="primary"
                disabled={(vidtagSelected && selectedTags.find((tag) => (tag.type === 'AVTags' && tag.label !== data.label))) ? true : false }
                />
            )
          } else { 
            return null;
          }})}
          {/* {(vidtagSelected && selectedTags.length > 0) && 
          <CardActions style={{justifyContent:'flex-end', borderTop:'1px solid #ededed', marginTop:'10px'}}>
          <CardContent style={{display:'flex', alignItems:'center', paddingRight:'0 !important', padding:'10px'}}>
            <Typography color="primary" onClick={() => handleChange(null, 1)} style={{cursor:'pointer'}}>Add more tags</Typography>
            <IconButton  size="small" onClick={() => handleChange(null, 1)} style={{background:'linear-gradient(to right, #2196F3 30%, #21CBF3 90%)', marginLeft:'8px'}}>
              <NavigateNextIcon style={{color:'white', padding:'initial'}} />
            </IconButton>
          </CardContent>
          </CardActions>
  } */}
          
          {/* {
          selectedTags.map(tag => 
            (tag.type === "Categories") &&
            <div className={classes.section}>
            <Typography className={classes.tagTitles} variant="subtitle1">{tag.label}</Typography>
            {chipData.map((data) => {
              if(tag.label === data.type) {
              return (
                  
                  <Chip
                  label={data.label}
                  className={classes.chip}
                  onClick={handleClick(data)}
                  variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                  color="primary"
                  />
              )
              } else {
                return null;
              }
            })}
            </div>
          )} */}
          </div>
        </TabPanel>
      )}
      {post?.type === 'Article' && (
        <TabPanel class="tabPanel" value={value} index={0}>
        <div class="tabPanel" className={classes.section}>
        {        
        chipData.map((data) => {
          if(data.type === "AVTags") {
          return (
              
              <Chip
              label={data.label}
              className = {selectedTags.find(tag => tag.label === data.label) ? classes.ax : classes.axo}
              onClick={handleClick(data)}
              variant={selectedTags.find((tag) => tag.label === data.label) ? "default" : "outlined"}
              color="primary"
              disabled={(selectedTags.find((tag) => (tag.type === 'AVTags' && tag.label !== data.label))) ? true : false }
              />
          )
        } else { 
          return null;
        }})}
        </div>
        </TabPanel>
      )}
     
      <TabPanel class="tabPanel" value={value} index={post?.type === 'Photo' ? 0 : 1}>
        <div class="tabPanel" className={classes.section}>
        <Typography className={classes.tagTitles} variant="subtitle1">Select a category to see it's tags</Typography>

        {
        
        chipData.map((data) => {
          if(data.type === "Categories") {
          return (
              
              <Chip
              label={data.label}
              className={classes.chip}
              onClick={handleClick(data)}
              variant={selectedTags.find((tag) => tag.label === data.label) ? "default" : "outlined"}
              color="primary"
              />
          )
        } else {
          return null;
        }})}
        {
        selectedTags.map(tag => 
          (tag.type === "Categories") &&
          <div className={classes.section}>
          <Typography className={classes.tagTitles} variant="subtitle1">{tag.label}</Typography>
          {chipData.map((data) => {
            if(tag.label === data.type) {
            return (
                
                <Chip
                label={data.label}
                className={classes.chip}
                onClick={handleClick(data)}
                variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                color="primary"
                />
            )
            } else {
              return null;
            }
          })}
          </div>
        )}
        </div>

      </TabPanel>
      <TabPanel class="tabPanel" value={value} index={post?.type === 'Photo' ? 1 : 2}>
      <div class="tabPanel" className={classes.section}>
        {/* <Typography className={classes.tagTitles} variant="subtitle1">Vibe Tags</Typography> */}
        {        
        chipData.map((data) => {
          if(data.type === "Vibes") {
          return (
              
              <Chip
              label={data.label}
              className = {selectedTags.find(tag => tag.label === data.label) ? classes.vibesSelected : classes.vibesOutlined}
              onClick={handleClick(data)}
              variant={selectedTags.find((tag) => tag.label === data.label) ? "default" : "outlined"}
              color="primary"
              />
          )
        } else {
          return null;
        }})}
        </div>
      </TabPanel>
      <TabPanel class="tabPanel" value={value} index={post?.type === 'Photo' ? 2 : 3}>
        
        {alphabet.map(cat => 
        <div className={classes.section}>
          <Typography className={classes.tagTitles} variant="subtitle2" style={{marginBottom:'10px'}}>{cat}</Typography>
         {chipData.map(data => 
          {
            if(data.type === "Activities" && data.label.charAt(0) === cat) {
              return (
                <Chip
                label={data.label}
                className={classes.chip}
                onClick={handleClick(data)}
                variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                color="secondary"
                />
            )
            } else {
              return null; 
            }
          })
        }</div>)}
        
      </TabPanel>
    </div>
   )}
        {
        (query !== "") && (
        categoryArray.map(cat => 
          <div className={classes.section}>
          {chipData.find((dat) => 
          ((dat.label.toLowerCase().includes(query.toLowerCase()) && dat.type === cat))) && 
          <Typography className={classes.tagTitles} variant="subtitle1">{cat}</Typography>
          }
          <div>
          {chipData.map((data) => {
          if(data.label.toLowerCase().includes(query.toLowerCase()) && data.type === cat) {
            switch (data.type) {
              case "Activities":
                return <Chip
                    label={data.label}
                    className={classes.chip}
                    onClick={handleClick(data)}
                    variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                    color="secondary"
                    />
                break;
              
              case "Vibes":
                return <Chip
                    label={data.label}
                    className = {selectedTags.find(tag => tag.label === data.label) ? classes.vibesSelected : classes.vibesOutlined}
                    onClick={handleClick(data)}
                    variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                    
                    />
                break;
            
              default:
                return <Chip
                    label={data.label}
                    className={classes.chip}
                    onClick={handleClick(data)}
                    variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                    color="primary"
                    />
                break;
            }
          } else {
            return null; 
          }
        })}
        </div>
          </div>
            ))
      }
        
        <div ref={parentRef} style={{backgroundColor:'red', width:'100%'}}>

        </div>
        </form>
        </DialogContent>
        <DialogActions style={{boxShadow:'0 0 10px -6.5px black'}}>
        <Button onClick={handleClose} color="secondary">
              Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
              Done
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default MaxWidthDialog;
