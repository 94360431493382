import React, { useEffect, useState } from 'react';
import { Card, Container, Grid, Typography, Avatar, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import BookmarkIcon from '@material-ui/icons/Bookmark';
import useStyles from './styles';
import { addToFollowing, removeFromFollowing } from '../../actions/user';

const UserCard = ({user}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const currentUser = JSON.parse(localStorage.getItem('profile'));
    const currentUserId = currentUser?.result?.googleId || currentUser?.result?.id;
    const [followerState, setFollowerState] = useState('');

    useEffect(() => {
        setFollowerState(user?.followers);
    }, [user])

    // const handleFollow = () => {
    //     setFollowerState([...user?.followers, currentUserId]);
    //     dispatch(addToFollowing({userId : user.id }));
    // }

    // const handleUnFollow = () => {
    //     setFollowerState(user?.followers.filter((id) => id !== currentUserId));
    //     dispatch(removeFromFollowing(user.id));
    // }

    const handleFollow = () => {
            setFollowerState([...user?.followers, currentUserId]);
            dispatch(addToFollowing({userId : user.id}));
    }

    const handleUnfollow = () => {
        setFollowerState(user?.followers.filter((id) => id !== currentUserId));
        dispatch(removeFromFollowing(user.id));
    }

    // const Following = () => {
    //     // const reactorName = user?.result?.name;
    //     console.log(currentUserId);
    //     // if(postLikes) {
    //     if (user.id === currentUserId) {
    //         return null;
    //     } 
    //     else if (followerState?.length > 0 && user.id !== currentUserId) { 
    //         return followerState.find((follower) => follower === (currentUserId))
    //         ? (
    //           <Button variant="outlined" color="primary" style={{textTransform:"inherit"}} onClick={handleUnFollow}>Following</Button>
    //         ) : (
    //           <Button variant="contained" color="primary" style={{textTransform:"inherit"}} onClick={handleFollow}>Follow</Button>
    //         );
            
    //   } else {
    //     return <Button variant="contained" color="primary" style={{textTransform:"inherit", width:'50%'}} onClick={handleFollow}>FollowMe</Button> 
    //   }
    // }

    const Following = () => {
        // const reactorName = user?.result?.name;
        // console.log(reactorName);
        if(user.id === currentUserId){
            return null;
        }
        if(followerState) {
        if (followerState?.length > 0) { 
          return followerState.find((follower) => follower === (currentUserId))
            ? (
                <Button variant="outlined" color="primary" style={{textTransform:"inherit"}} onClick={handleUnfollow}>Following</Button>
            ) : (
                <Button variant="contained" color="primary" style={{textTransform:"inherit"}} onClick={handleFollow}>Follow</Button>
            );
        } }
        return <Button variant="contained" color="primary" style={{textTransform:"inherit"}} onClick={handleFollow}>Follow</Button>
                 
      };

    return (
        <Container>
            
            <Grid container style={{display:'flex', justifyContent:'center'}}>
                <Grid item xs={12} sm={12} md={10}>
                    <Card class="profileCard" style={{display:'flex', flexDirection:'row', border:'2px solid #ededed', paddingTop:'15px', paddingBottom:'15px'}}>
                        <Grid item xs={3} sm={3} md={2} style={{
                            // backgroundColor:'red',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                            // paddingTop:'5px',
                            // paddingBottom:'5px',
                            }}>
                            <Avatar src = {user?.profilePic ? user?.profilePic : null} style={{border:'', boxShadow:'0 0 2px darkGray', background:!user?.profilePic && 'linear-gradient(to left, #9121c2, #FF5E5E )' }}>{user?.name?.charAt(0)}</Avatar>
                        </Grid>
                        <Grid item xs={6} sm={6} md={8} style={{
                            // backgroundColor:'green', 
                            display:'flex', alignItems:'center', marginLeft:'-15px'}}>
                            <Typography variant="subtitle1" color="primary" component={Link} to={`/${user.id}`} style={{textDecoration:'none'}}>{user?.name}</Typography>
                        </Grid>
                        <Grid item xs={3} sm={3} md={2} style={{
                            // backgroundColor:'red',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                            // paddingTop:'5px',
                            // paddingBottom:'5px',
                            }}>
                            {/* <Avatar className = {classes.large} src = {user.profilePic || "https://res.cloudinary.com/travelaio/image/upload/v1630150067/Norway_llldp2.jpg"} /> */}
                            <Following />
                        </Grid>
                        {/* <Grid item xs={1} sm={1} md={1} style={{
                            // backgroundColor:'red', 
                            display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
                            <FavoriteIcon fontSize="medium" color="secondary"
                            // style={{color:'red'}}
                             />
                            <Typography color="secondary" variant="subtitle1">{favorite.favCount ? favorite.favCount : 0}</Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} style={{
                            // backgroundColor:'red', 
                            display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
                            <CheckCircleIcon fontSize="medium" style={{color:'#5cb85c'}} />
                            <Typography variant="subtitle1" style={{color:'#5cb85c'}}>{favorite.visitCount ? favorite.visitCount : 0}</Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} style={{
                            // backgroundColor:'red', 
                            display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
                            <BookmarkIcon fontSize="medium" style={{color:'#f0ad4e'}} />
                            <Typography variant="subtitle1" style={{color:'#f0ad4e'}}>{favorite.bucketCount ? favorite.bucketCount : 0}</Typography>
                        </Grid> */}
                       
                    </Card>
                </Grid>
            </Grid>
            
        </Container>
    )
}

export default UserCard
