import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Button, Box, Tabs, Tab } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';
import { Typography } from '@material-ui/core';
// import VerticalTabs from './TagLibrary';
import SearchIcon from '@material-ui/icons/Search';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'flex-start',
    padding:'5px',
    cursor:'text',
    width:'97%',
    minHeight:'50px',
    border:'1px solid silver',
    borderRadius:'5px',
    margin: 'auto',
    marginTop:'7px',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    }
  },
  
  tagTitles : {
    color:'#000',
    borderTop : '1px solid #ededed',
    paddingTop : '15px',
    display:'flex',
    alignItems:'center'
  }, 
  tagSubTitles : {
    color:'grey',
    display:'flex',
    alignItems:'flex-start',
    marginTop:'-5px',
    marginBottom:'5px'
  }, 
  chip : {
    margin:'3px',
    marginLeft:'0px'
  },
  section : {
    marginTop:'20px',
    marginBottom:'10px'
  }, 
  tabRoot: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tabs : {
    minWidth:'50px'
  }, 
  vibesSelected: {
    margin:'3px',
    marginLeft:'0px',
    backgroundColor:'#9b59b6 !important',
    color:'white'
  }, 
  vibesOutlined: {
    margin:'3px',
    marginLeft:'0px',
    backgroundColor:'white !important',
    color:'#9b59b6',
    border: '1px solid #9b59b6'
  }, 
  miscSelected: {
    margin:'3px',
    marginLeft:'0px',
    backgroundColor:'green !important',
    color:'white'
  }, 
  miscOutlined: {
    margin:'3px',
    marginLeft:'0px',
    backgroundColor:'white !important',
    color:'green',
    border: '1px solid green'
  }
}));

const MaxWidthDialog = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
//   const [selected, setSelected] = useState({broadCategories:[], generalTags:[] });
  const [selectedTags, setselectedTags] = useState([]);
  const [pseudoCount, setPseudoCount] = useState(0);
  const [query, setQuery] = useState("");
  const parentRef = useRef();
  const childRef = useRef();
  const [value, setValue] = React.useState(0);
  const [chipCount, setChipCount] = useState(
  //   [
  //   "Nature" = 0,
  //   "Animals" = 0,
  //   "Urban" = 0,
  //   "Architecture" = 0
  // ]
  0
  )
  const [chipData, setChipData] = useState([
    {label:'Nature', type:'Categories',tagType:'General'},
    {label:'Animals', type:'Categories',tagType:'General'},
    {label:'Urban', type:'Categories',tagType:'General'},
    {label:'Architecture', type:'Categories',tagType:'General'},
    {label:'Culture', type:'Categories',tagType:'General'},
    {label:'History', type:'Categories',tagType:'General'},
    {label:'Religion', type:'Categories',tagType:'General'},
    {label:'Scenery & Landscapes', type:'Categories',tagType:'General'},
    {label:'Experiences', type:'Categories',tagType:'General'},
    {label:'Events', type:'Categories',tagType:'General'},

    {label:'Nature Photography', type:'Nature', tagType:'General'},
    {label:'Enjoying Nature', type:'Nature', tagType:'General'},
    {label:'Amidst the Wilderness', type:'Nature', tagType:'General'},
    {label:'Majestic Mountains', type:'Nature', tagType:'General'},
    {label:'Sea & Beaches', type:'Nature', tagType:'General'},
    {label:'Tropical Paradise', type:'Nature', tagType:'General'},
    {label:'Winter Destination', type:'Nature', tagType:'General'},
    {label:'Polar Regions', type:'Nature', tagType:'General'},
    {label:'Desert Dunes', type:'Nature', tagType:'General'},
    {label:'Countryside', type:'Nature', tagType:'General'},
    {label:'Remote', type:'Nature', tagType:'General'},
    {label:'Natural Attractions', type:'Nature', tagType:'General'},
    {label:'Natural Wonders', type:'Nature', tagType:'General'},

    {label:'Wildlife', type:'Animals', tagType:'General'},
    {label:'Birds', type:'Animals', tagType:'General'},
    {label:'Aquatic Life', type:'Animals', tagType:'General'},
    {label:'Marine Wildlife', type:'Animals', tagType:'General'},
    {label:'Endemic Species', type:'Animals', tagType:'General'},
    {label:'Animals & Humans', type:'Animals', tagType:'General'},
    {label:'Exotic Animals', type:'Animals', tagType:'General'},
    {label:'Awesome Animal Moments', type:'Animals', tagType:'General'},


    {label:'Cityscape', type:'Urban', tagType:'General'},
    {label:'Townscape', type:'Urban', tagType:'General'},
    {label:'Small Town Beauty', type:'Urban', tagType:'General'},
    {label:'Life in the City', type:'Urban', tagType:'General'},
    {label:'City Lights', type:'Urban', tagType:'General'},
    {label:'City at Night', type:'Urban', tagType:'General'},
    {label:'Exploring the City', type:'Urban', tagType:'General'},
    {label:'Skyline', type:'Urban', tagType:'General'},
    {label:'Street Photography', type:'Urban', tagType:'General'},

    {label:'Cultural Landmark', type:'Culture', tagType:'General'},
    {label:'Life of Locals', type:'Culture', tagType:'General'},
    {label:'Everyday Life', type:'Culture', tagType:'General'},
    {label:'Heritage', type:'Culture', tagType:'General'},
    {label:'Traditions & Customs', type:'Culture', tagType:'General'},
    {label:'Cultural Festival', type:'Culture', tagType:'General'},
    {label:'Cultural Hotspot', type:'Culture', tagType:'General'},
    {label:'Multicultural', type:'Culture', tagType:'General'},
    {label:'Street Photography', type:'Culture', tagType:'General'},
    {label:'Indegenous Peoples', type:'Culture', tagType:'General'},

    {label:'Ancient Ruins', type:'History', tagType:'General'},
    {label:'Museum', type:'History', tagType:'General'},
    {label:'Historical Landmark', type:'History', tagType:'General'},
    {label:'Historical Site', type:'History', tagType:'General'},
    {label:'Historical Art', type:'History', tagType:'General'},
    {label:'Historical Monument', type:'History', tagType:'General'},
    {label:'Natural History', type:'History', tagType:'General'},
    {label:'Prehistoric', type:'History', tagType:'General'},

    {label:'Religious Landmark', type:'Religion', tagType:'General'},
    {label:'Religious Site', type:'Religion', tagType:'General'},
    {label:'Religious Architecture', type:'Religion', tagType:'General'},
    {label:'Religious Art', type:'Religion', tagType:'General'},
    {label:'Religious Monument', type:'Religion', tagType:'General'},
    {label:'Religious Event', type:'Religion', tagType:'General'},
    {label:'Holy', type:'Religion', tagType:'General'},
    {label:'Sacred', type:'Religion', tagType:'General'},

    {label:'Iconic Landmark', type:'Architecture', tagType:'General'},
    {label:'Modern Architecture', type:'Architecture', tagType:'General'},
    {label:'Historic Architecture', type:'Architecture', tagType:'General'},
    {label:'Religious Architecture', type:'Architecture', tagType:'General'},
    {label:'Landscape Architecture', type:'Architecture', tagType:'General'},
    {label:'Indoor Scenery', type:'Architecture', tagType:'General'},
    {label:'Amazing Structures', type:'Architecture', tagType:'General'},
    {label:'Engineering Marvels', type:'Architecture', tagType:'General'},

    {label:'Beautiful Scenery', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Amazing View', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Indoor Scenery', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Random Travel Clicks', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Forest Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Cityscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Townscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Urban Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Rural Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Desert Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Seascape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Cloudscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Mountain Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Fall Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Winter Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Summer Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Spring Landscape', type:'Scenery & Landscapes', tagType:'General'},

    {label:'Just My Travels', type:'Experiences', tagType:'General'},
    {label:'Adventure', type:'Experiences', tagType:'General'},
    {label:'Entertainment', type:'Experiences', tagType:'General'},
    {label:'Leisure & Relaxation', type:'Experiences', tagType:'General'},
    {label:'Luxury Experience', type:'Experiences', tagType:'General'},
    {label:'Cultural Experience', type:'Experiences', tagType:'General'},
    {label:'Spiritual Experience', type:'Experiences', tagType:'General'},
    {label:'Romantic Experience', type:'Experiences', tagType:'General'},
    {label:'Food & Dining', type:'Experiences', tagType:'General'},
    {label:'Nightlife', type:'Experiences', tagType:'General'},

    {label:'Festival', type:'Events', tagType:'General'},
    {label:'Carnival', type:'Events', tagType:'General'},
    {label:'Fair', type:'Events', tagType:'General'},
    {label:'Concert', type:'Events', tagType:'General'},
    {label:'Cultural Event', type:'Events', tagType:'General'},
    {label:'Religious Event', type:'Events', tagType:'General'},
    {label:'Sports Event', type:'Events', tagType:'General'},

    



    {label:'Village', type:'Architecture'},
    {label:'Marine Wonderland', type:'Architecture'},

    {label:'Peaceful', type:'Vibes' },
    {label:'Serene', type:'Vibes' },
    {label:'Relaxing', type:'Vibes' },
    {label:'Luxurious', type:'Vibes' },
    {label:'Exciting', type:'Vibes' },
    {label:'Adventurous', type:'Vibes' },
    {label:'Romantic', type:'Vibes' },
    {label:'Cozy', type:'Vibes' },
    {label:'Thrilling', type:'Vibes' },

    {label:'Abseiling', type:'Activities', tagType:'Activities'},
    {label:'Air Rifle Shooting', type:'Activities', tagType:'Activities'},
    {label:'Archery', type:'Activities', tagType:'Activities'},
    {label:'ATV Ride', type:'Activities', tagType:'Activities'},

    {label:'Backpacking', type:'Activities', tagType:'Activities'},
    {label:'Badminton', type:'Activities', tagType:'Activities'},
    {label:'Banana Boat Ride', type:'Activities', tagType:'Activities'},
    {label:'BASE Jumping', type:'Activities', tagType:'Activities'},
    {label:'Beach Volleyball', type:'Activities', tagType:'Activities'},
    {label:'Bird Watching', type:'Activities', tagType:'Activities'},
    {label:'Boat Ride', type:'Activities', tagType:'Activities'},
    {label:'Bodyboarding', type:'Activities', tagType:'Activities'},
    {label:'Bowling', type:'Activities', tagType:'Activities'},
    {label:'Bungee Jumping', type:'Activities', tagType:'Activities'},

    {label:'Cage Diving', type:'Activities', tagType:'Activities'},
    {label:'Camping', type:'Activities', tagType:'Activities'},
    {label:'Canoeing', type:'Activities', tagType:'Activities'},
    {label:'Canyoning', type:'Activities', tagType:'Activities'},
    {label:'Cave Diving', type:'Activities', tagType:'Activities'},
    {label:'Chess', type:'Activities', tagType:'Activities'},
    {label:'Cliff Diving', type:'Activities', tagType:'Activities'},
    {label:'Clubbing', type:'Activities', tagType:'Activities'},
    {label:'Cycling', type:'Activities', tagType:'Activities'},

    {label:'Dancing', type:'Activities', tagType:'Activities'},
    {label:'Deep Diving', type:'Activities', tagType:'Activities'},
    {label:'Diving', type:'Activities', tagType:'Activities'},
    {label:'Dolphin Watching', type:'Activities', tagType:'Activities'},

    {label:'Equestrian', type:'Activities', tagType:'Activities'},
    {label:'Fly Fish Ride', type:'Activities', tagType:'Activities'},
    {label:'Flyboard Flying', type:'Activities', tagType:'Activities'},
    {label:'Freediving', type:'Activities', tagType:'Activities'},
    {label:'Go-Karting', type:'Activities', tagType:'Activities'},
    {label:'Golf', type:'Activities', tagType:'Activities'},
    {label:'Hang Gliding', type:'Activities', tagType:'Activities'},
    {label:'Helicopter Ride', type:'Activities', tagType:'Activities'},
    {label:'Hiking', type:'Activities', tagType:'Activities'},
    {label:'Hockey', type:'Activities', tagType:'Activities'},
    {label:'Horseback Ride', type:'Activities', tagType:'Activities'},

    {label:'Ice Climbing', type:'Activities', tagType:'Activities'},
    {label:'Ice Diving', type:'Activities', tagType:'Activities'},
    {label:'Ice Hockey', type:'Activities', tagType:'Activities'},
    {label:'Ice Skating', type:'Activities', tagType:'Activities'},
    {label:'Jet Skiing', type:'Activities', tagType:'Activities'},
    {label:'Jogging', type:'Activities', tagType:'Activities'},

    {label:'Kayaking', type:'Activities', tagType:'Activities'},
    {label:'Kite Flying', type:'Activities', tagType:'Activities'},
    {label:'Kite Surfing', type:'Activities', tagType:'Activities'},
    {label:'Kneeboarding', type:'Activities', tagType:'Activities'},
    {label:'Laser Tag', type:'Activities', tagType:'Activities'},
    {label:'Longboarding', type:'Activities', tagType:'Activities'},

    {label:'Mini Golf', type:'Activities', tagType:'Activities'},
    {label:'Motorcycle Ride', type:'Activities', tagType:'Activities'},
    {label:'Mountain Biking', type:'Activities', tagType:'Activities'},
    {label:'Mountaineering', type:'Activities', tagType:'Activities'},

    {label:'Paddleboarding', type:'Activities', tagType:'Activities'},
    {label:'Paddlesurfing', type:'Activities', tagType:'Activities'},
    {label:'Paintball', type:'Activities', tagType:'Activities'},
    {label:'Parachuting', type:'Activities', tagType:'Activities'},
    {label:'Paragliding', type:'Activities', tagType:'Activities'},
    {label:'Parasailing', type:'Activities', tagType:'Activities'},
    {label:'Pool & Billiards', type:'Activities', tagType:'Activities'},

    {label:'Quad Bike Ride', type:'Activities', tagType:'Activities'},
    {label:'Racquetball', type:'Activities', tagType:'Activities'},
    {label:'Rafting', type:'Activities', tagType:'Activities'},
    {label:'Rock Climbing', type:'Activities', tagType:'Activities'},
    {label:'Roller Coaster Ride', type:'Activities', tagType:'Activities'},
    {label:'Rowing', type:'Activities', tagType:'Activities'},
    {label:'Ocean Walker', type:'Activities', tagType:'Activities'},

    {label:'Safari', type:'Activities', tagType:'Activities'},
    {label:'Sailing', type:'Activities', tagType:'Activities'},
    {label:'Scuba Diving', type:'Activities', tagType:'Activities'},
    {label:'Seaplane Ride', type:'Activities', tagType:'Activities'},
    {label:'Shark Cage Diving', type:'Activities', tagType:'Activities'},
    {label:'Skateboarding', type:'Activities', tagType:'Activities'},
    {label:'Skiing', type:'Activities', tagType:'Activities'},
    {label:'Skimboarding', type:'Activities', tagType:'Activities'},
    {label:'Sky Diving', type:'Activities', tagType:'Activities'},
    {label:'Sledding', type:'Activities', tagType:'Activities'},
    {label:'Snorkelling', type:'Activities', tagType:'Activities'},
    {label:'Snowboarding', type:'Activities', tagType:'Activities'},
    {label:'Snowmobiling', type:'Activities', tagType:'Activities'},
    {label:'Snowshoeing', type:'Activities', tagType:'Activities'},
    {label:'Speed Boat Ride', type:'Activities', tagType:'Activities'},
    {label:'Spelunking', type:'Activities', tagType:'Activities'},
    {label:'Spinnaker Flying', type:'Activities', tagType:'Activities'},
    {label:'Stargazing', type:'Activities', tagType:'Activities'},
    {label:'Surfing', type:'Activities', tagType:'Activities'},
    {label:'Swimming', type:'Activities', tagType:'Activities'},

    {label:'Table Tennis', type:'Activities', tagType:'Activities'},
    {label:'Tennis', type:'Activities', tagType:'Activities'},
    {label:'Train Ride', type:'Activities', tagType:'Activities'},
    {label:'Trekking', type:'Activities', tagType:'Activities'},

    {label:'Underwater Scooter Riding', type:'Activities', tagType:'Activities'},
    {label:'Video Games', type:'Activities', tagType:'Activities'},
    {label:'VR/AR Games', type:'Activities', tagType:'Activities'},

    {label:'Wakeboarding', type:'Activities', tagType:'Activities'},
    {label:'Water Skiing', type:'Activities', tagType:'Activities'},
    {label:'Water Sports', type:'Activities', tagType:'Activities'},
    {label:'Whale Watching', type:'Activities', tagType:'Activities'},
    {label:'Whitewater Rafting', type:'Activities', tagType:'Activities'},
    {label:'Windsurfing', type:'Activities', tagType:'Activities'},
    {label:'Yoga', type:'Activities', tagType:'Activities'},
    {label:'Ziplining', type:'Activities', tagType:'Activities'},
    {label:'Zorbing', type:'Activities', tagType:'Activities'},

    
    

  ]);

  const categoryArray = ["Categories", "Nature", "Animals", "Urban", "Architecture", "Culture", "History", "Religion", "Scenery & Landscapes", "Experiences", "Events", "Vibes", "Activities"];
  const miscArray = ["Weather", "Sky", "Seasons"];
  const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","Y","Z"];

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setselectedTags([]);
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const handleClick = (selected) => () => {
    if(selectedTags.find((tag) => tag.label === selected.label)) {
      setselectedTags(selectedTags.filter(tag => tag.label !== selected.label));
    } else {
      if(selectedTags.length < 10) 
    setselectedTags([...selectedTags, selected]);
    if(selected.type === "Categories") {
    setPseudoCount(pseudoCount + 1)
    };
    }
  }

  const handleClearTags = () => {
    setselectedTags([]);
    setPseudoCount(0);
  }

  const handleDelete = (chipToDelete) => {
    setselectedTags((chips) => chips.filter((chip) => chip.label !== chipToDelete));
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if(selectedTags.length > 1) {
    parentRef.current.scrollIntoView();
    }
  }, [pseudoCount]);
  

  return (
    <React.Fragment>
    <div ref={parentRef} style={{width:'100%'}}>
     <Typography style={{marginLeft:'10px', color:'#333'}}>Add Tags</Typography>
     </div>
      <div style={{marginBottom:'15px'}} className={classes.root} label="Add Tags" onClick={handleClickOpen}>
      {selectedTags.map((data) => {
          return (
              
              <Chip
              label={data.label}
              className={classes.chip}
              variant="default"
              // onDelete = {() => handleDelete(data.label)}
              // color="primary"
              />
          )
        })}
      </div>
      <Dialog
        id="formTagDialog"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title" style={{boxShadow:'0 0 10px -6.5px black', marginBottom:'13px'}}>Add Tags</DialogTitle>
        <DialogContent>
      <div id="tagSearch">
          <SearchIcon style={{backgroundColor:'#ededed', borderTopLeftRadius: '25px', borderBottomLeftRadius: '25px', padding:'12px', fontSize:'2.8rem'}}/>
        <input
        autoComplete='off'
        id="tagSearchInput"
        class="pac-target-input"
        onChange={event => setQuery(event.target.value)}
        placeholder="Search For Tags"
        value={query}
      />
      </div>

        <div className={classes.section}>
        {/* {selectedTags.length ? ( */}
          <div style={{display:'flex', alignItems:'baseline', borderTop:'1px solid #ededed', paddingTop:'10px'}}>
        <Typography variant="subtitle1">Selected Tags <span variant="subtitle2" style={{color:'grey', fontSize:'0.8rem'}}>({selectedTags.length}/10)</span></Typography>
        {(selectedTags.length > 0) && (
        <Button onClick={handleClearTags} color="secondary" style={{marginLeft:'auto', textTransform:'capitalize'}}>
            Clear All
          </Button>
        )}
          </div>
        <div className={classes.selected}>
        {selectedTags.map((data) => {
          return (
              
              <Chip
              label={data.label}
              className={classes.chip}
              variant="default"
              onDelete = {() => handleDelete(data.label)}
              // color="primary"
              />
          )
        })}
        </div>
        </div>
        {(query === "") && (
        <div className={classes.tabRoot} style={{borderTop : '1px solid #ededed',}}>
      <div className={classes.section}>
      <AppBar id="tagBar" position="static" color="default" style={{width:'100%'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab label="Descriptive" {...a11yProps(0)} className={classes.tabs} />
          <Tab label="Vibes" {...a11yProps(1)} className={classes.tabs} />
          {/* <Tab label="Place Types" {...a11yProps(2)} className={classes.tabs} /> */}
          <Tab label="Activities" {...a11yProps(2)} className={classes.tabs} />
          <Tab label="Miscellaneous" {...a11yProps(3)} className={classes.tabs} />
        </Tabs>
      </AppBar>
      </div>
      <TabPanel class="tabPanel" value={value} index={0}>
        <div class="tabPanel" className={classes.section}>
        <Typography className={classes.tagTitles} variant="subtitle1">Select a category to see it's tags</Typography>

        {
        
        chipData.map((data) => {
          if(data.type === "Categories") {
          return (
              
              <Chip
              label={data.label}
              className={classes.chip}
              onClick={handleClick(data)}
              variant={selectedTags.find((tag) => tag.label === data.label) ? "default" : "outlined"}
              color="primary"
              />
          )
        } else {
          return null;
        }})}
        {
        selectedTags.map(tag => 
          (tag.type === "Categories") &&
          <div className={classes.section}>
          <Typography className={classes.tagTitles} variant="subtitle1">{tag.label}</Typography>
          {chipData.map((data) => {
            if(tag.label === data.type) {
            return (
                
                <Chip
                label={data.label}
                className={classes.chip}
                onClick={handleClick(data)}
                variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                color="primary"
                />
            )
            } else {
              return null;
            }
          })}
          </div>
        )}
        </div>

      </TabPanel>
      <TabPanel class="tabPanel" value={value} index={1}>
      <div class="tabPanel" className={classes.section}>
        {/* <Typography className={classes.tagTitles} variant="subtitle1">Vibe Tags</Typography> */}
        {        
        chipData.map((data) => {
          if(data.type === "Vibes") {
          return (
              
              <Chip
              label={data.label}
              className = {selectedTags.find(tag => tag.label === data.label) ? classes.vibesSelected : classes.vibesOutlined}
              onClick={handleClick(data)}
              variant={selectedTags.find((tag) => tag.label === data.label) ? "default" : "outlined"}
              color="primary"
              />
          )
        } else {
          return null;
        }})}
        </div>
      </TabPanel>
      <TabPanel class="tabPanel" value={value} index={2}>
        
        {alphabet.map(cat => 
        <div className={classes.section}>
          <Typography className={classes.tagTitles} variant="subtitle2" style={{marginBottom:'10px'}}>{cat}</Typography>
         {chipData.map(data => 
          {
            if(data.type === "Activities" && data.label.charAt(0) === cat) {
              return (
                <Chip
                label={data.label}
                className={classes.chip}
                onClick={handleClick(data)}
                variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                color="secondary"
                />
            )
            } else {
              return null; 
            }
          })
        }</div>)}
        
      </TabPanel>
      <TabPanel value={value} index={3}>
      {miscArray.map(cat => 
        <div className={classes.section}>
          <Typography className={classes.tagTitles} variant="subtitle2" style={{marginBottom:'10px'}}>{cat}</Typography>
         {chipData.map(data => 
          {
            if(data.type === cat) {
              return (
                <Chip
                label={data.label}
                className = {selectedTags.find(tag => tag.label === data.label) ? classes.miscSelected : classes.miscOutlined}
                onClick={handleClick(data)}
                variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                />
            )
            } else {
              return null; 
            }
          })
        }</div>)}
      </TabPanel>
    </div>
   )}
        {
        (query !== "") && (
        categoryArray.map(cat => 
          <div className={classes.section}>
          {chipData.find((dat) => 
          ((dat.label.toLowerCase().includes(query.toLowerCase()) && dat.type === cat))) && 
          <Typography className={classes.tagTitles} variant="subtitle1">{cat}</Typography>
          }
          <div>
          {chipData.map((data) => {
          if(data.label.toLowerCase().includes(query.toLowerCase()) && data.type === cat) {
            switch (data.type) {
              case "Activities":
                return <Chip
                    label={data.label}
                    className={classes.chip}
                    onClick={handleClick(data)}
                    variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                    color="secondary"
                    />
                break;
              
              case "Vibes":
                return <Chip
                    label={data.label}
                    className = {selectedTags.find(tag => tag.label === data.label) ? classes.vibesSelected : classes.vibesOutlined}
                    onClick={handleClick(data)}
                    variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                    
                    />
                break;

              case "Sky" || "Seasons" || "Weather":
                return <Chip
                    label={data.label}
                    className = {selectedTags.find(tag => tag.label === data.label) ? classes.miscSelected : classes.miscOutlined}
                    onClick={handleClick(data)}
                    variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                    
                    />
                break;
            
              default:
                return <Chip
                    label={data.label}
                    className={classes.chip}
                    onClick={handleClick(data)}
                    variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                    color="primary"
                    />
                break;
            }
          } else {
            return null; 
          }
        })}
        </div>
          </div>
            ))
      }
        
        <div ref={parentRef} style={{backgroundColor:'red', width:'100%'}}>

        </div>
        </DialogContent>
        <DialogActions style={{boxShadow:'0 0 10px -6.5px black'}}>
        <Button onClick={handleClose} color="secondary">
              Cancel
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
              Done
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default MaxWidthDialog;
