import { combineReducers } from 'redux';

import posts from './posts';
import landingPosts from './landing';
import userPosts from './userPosts';
import auth from './auth';
import places from './place';
import tagPosts from './tagPosts';
import tag from './tag';
import favorites from './favorites';
import visited from './visited';
import bucket from './bucket';
import likes from './likes';
import locations from './locations';
import exploreFavs from './exploreFavs';
import exploreVisits from './exploreVisits';
import exploreBuckets from './exploreBuckets';
import user from './user';
import users from './users';
import followers from './followers';
import following from './following';
import comments from './comments';
import post from './post';
import profileFollowers from './profileFollowers';
import placeSelect from './placeSelect';
import suggestedUsers from './suggestedUsers';
import userFavs from './userFavs';
import userVisits from './userVisits';
import userBuckets from './userBuckets';
import control from './control';

export const reducers = combineReducers({ posts, landingPosts, userPosts, post, auth, places, tagPosts, tag, favorites, visited, bucket, likes, locations, exploreFavs, exploreVisits, exploreBuckets, userFavs, userVisits, userBuckets, user, users, suggestedUsers, followers, profileFollowers, following, comments, placeSelect, control });
