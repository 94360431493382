
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    // justifyContent: 'center',
  },
  fileInput: {
    width: '97%',
    margin: '10px 0',
  },
  buttonSubmit: {
    margin: 10,
  },
  title: {
    justifyContent: 'center',
    alignItems:'center',
    margin:'auto',
    marginLeft: 0
  },
  pacContainer : {
    backgroundColor:'black'
  }
}));
