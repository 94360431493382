import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { Button, Avatar } from '@material-ui/core';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: '#fafafa',
  },
  button: {
    margin:'5px',
    marginLeft:'2px',
    marginRight:'2px',
    width:'auto', 
    backgroundColor:'white', 
    border:'2px solid #ededed', 
    textTransform:'Capitalize', 
    justifyContent:'flex-start', 
    borderRadius:'25px', 
    paddingLeft:'6px',
    // paddingTop:'1px',
    // paddingBottom:'1px'
  }
}));

export default function ScrollableTabsButtonForce({loadingPosts, setLoadingPosts, postState, category, handleCategory, handlePostButton1, handlePostButton2}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  console.log(value);
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" class="categoryBar" style={{paddingLeft:'5px', paddingRight:'5px'}}>
        <Tabs
          id="categoryMenus"
          value={value}
          // onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          textColor="primary"
          aria-label="scrollable force tabs example"
          TabIndicatorProps={{
            style: {display: 'none'}
          }}
          style={{paddingTop:'5px', paddingBottom:'5px', backgroundColor:'white'}}
        >
          <Button className={classes.button} variant="outlined" {...a11yProps(0)} disabled={loadingPosts} onClick={handlePostButton1} style={{border:postState === false && '2px solid #333', fontWeight:postState === false && 'bold'}}>
              <Avatar style={{backgroundColor:'white'}} src="BucketBorderIcon (8).png"></Avatar>
            Recent
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(1)} disabled={loadingPosts} onClick={handlePostButton2} style={{border:postState === true && '2px solid #333', fontWeight:postState === true && 'bold'}}>
              <Avatar style={{backgroundColor:'white'}}><WhatshotIcon color="secondary"/></Avatar>
            Top
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(2)} onClick={() => handleCategory('Nature')} style={{border:category === 'Nature' && '2px solid #333', fontWeight:category === 'Nature' && 'bold'}} disabled={loadingPosts} >
          <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="NatureIcon.jpeg"></Avatar>
            Nature
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(3)} onClick={() => handleCategory('Scenery And Landscapes')} style={{border:category === 'Scenery And Landscapes' && '2px solid #333', fontWeight:category === 'Scenery And Landscapes' && 'bold'}} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="LandscapeIcon.jpeg"></Avatar>
            Scenery And Landscapes
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(4)} onClick={() => handleCategory('Animals')} style={{border:category === 'Animals' && '2px solid #333', fontWeight:category === 'Animals' && 'bold' }} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="Animals.jpeg"></Avatar>
            Animals
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(5)} onClick={() => handleCategory('Urban')} style={{border:category === 'Urban' && '2px solid #333', fontWeight:category === 'Urban' && 'bold' }} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="Urban.jpeg"></Avatar>
            Urban
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(6)} onClick={() => handleCategory('Architecture')} style={{border:category === 'Architecture' && '2px solid #333', fontWeight:category === 'Architecture' && 'bold' }} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="Architecture.jpeg"></Avatar>
            Architecture
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(7)} onClick={() => handleCategory('Culture')} style={{border:category === 'Culture' && '2px solid #333', fontWeight:category === 'Culture' && 'bold' }} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="Culture.jpeg"></Avatar>
            Culture
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(8)} onClick={() => handleCategory('History')} style={{border:category === 'History' && '2px solid #333', fontWeight:category === 'History' && 'bold' }} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="History.jpeg"></Avatar>
            History
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(9)} onClick={() => handleCategory('Religion')} style={{border:category === 'Religion' && '2px solid #333', fontWeight:category === 'Religion' && 'bold' }} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="Religion.webp"></Avatar>
            Religion
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(10)} onClick={() => handleCategory('Experiences')} style={{border:category === 'Experiences' && '2px solid #333', fontWeight:category === 'Experiences' && 'bold' }} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="Experiences.jpeg"></Avatar>
            Experiences
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(11)} onClick={() => handleCategory('Events')} style={{border:category === 'Events' && '2px solid #333', fontWeight:category === 'Events' && 'bold' }} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="Events.jpeg"></Avatar>
            Events
          </Button>
          {/* <Tab label="Item Two" {...a11yProps(1)} />
          <Tab label="Item Three" {...a11yProps(2)} />
          <Tab label="Item Four" {...a11yProps(3)} />
          <Tab label="Item Five" {...a11yProps(4)} />
          <Tab label="Item Six" {...a11yProps(5)} />
          <Tab label="Item Seven" {...a11yProps(6)} /> */}
        </Tabs>
      </AppBar>
      {/* <TabPanel value={value} index={0}>
        Item One
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel> */}
    </div>
  );
}
