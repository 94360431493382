import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// import { getUserPosts } from '../../actions/user';
import { getUserBucket, getUserFavorites, getUserVisited } from '../../actions/user';

import { Grid, IconButton, Menu, MenuItem } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
// import PersonPinIcon from '@material-ui/icons/PersonPin';
// import HelpIcon from '@material-ui/icons/Help';
// import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
// import ThumbDown from '@material-ui/icons/ThumbDown';
// import ThumbUp from '@material-ui/icons/ThumbUp';
// import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import DescriptionIcon from '@material-ui/icons/Description';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// import Posts from '../Posts/Posts';
import UserPosts from '../Posts/UserPosts';
import FavoriteCards from './FavoriteCards';
import VisitedCards from './VisitedCards';
import BucketCards from './BucketCards';
import { getFollowing, getProfileFollowers } from '../../actions/user';
import FollowingCards from './FollowingCards';
import FollowerCards from './FollowerCards';
import { AppBar, Button, Avatar } from '@material-ui/core';



// const AntTabs = withStyles({
//     root: {
//       borderBottom: '1px solid #e8e8e8',
//     },
//     indicator: {
//       backgroundColor: '#1890ff',
//     },
//   })(Tabs);

//   const AntTab = withStyles((theme) => ({
//     root: {
//       textTransform: 'none',
//       minWidth: 72,
//       fontWeight: theme.typography.fontWeightRegular,
//       marginRight: theme.spacing(4),
//       fontFamily: [
//         '-apple-system',
//         'BlinkMacSystemFont',
//         '"Segoe UI"',
//         'Roboto',
//         '"Helvetica Neue"',
//         'Arial',
//         'sans-serif',
//         '"Apple Color Emoji"',
//         '"Segoe UI Emoji"',
//         '"Segoe UI Symbol"',
//       ].join(','),
//       '&:hover': {
//         color: '#40a9ff',
//         opacity: 1,
//       },
//       '&$selected': {
//         color: '#1890ff',
//         fontWeight: theme.typography.fontWeightMedium,
//       },
//       '&:focus': {
//         color: '#40a9ff',
//       },
//     },
//     selected: {},
//   }))((props) => <Tab disableRipple {...props} />);
 

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`scrollable-force-tabpanel-${index}`}
//       aria-labelledby={`scrollable-force-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `scrollable-force-tab-${index}`,
//     'aria-controls': `scrollable-force-tabpanel-${index}`,
//   };
// }

// // const handleFavorites = () => {
// //   disp
// // }

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     width: '100%',
//     backgroundColor: 'white',
//   },
//   padding: {
//     padding: theme.spacing(3),
//     paddingLeft: '0px !important',
//     padddingRight: '0px !important'
//   },
//   appBar : {
//       backgroundColor:'rgba(255,255,255,0.2)'
//   }
// }));

// export default function ScrollableTabsButtonForce({ userId }) {

  // const dispatch = useDispatch();
//   const [hovered, setHovered] = useState(false);
//   // const user = JSON.parse(localStorage.getItem('profile'));
//   // const user = user?.result?.googleId || user?.result?.id;
//   // const bla = JSON.parse(localStorage.getItem('profile'))
//   // console.log(userId);
//   // const user = '102905570188181136049';
//   // const user = 'Mountain'

//   // useEffect(() => {
//   //   dispatch(getUserPosts(user?.result?.googleId || user?.result?.id));
//   // }, [dispatch]);

//   const classes = useStyles();
//   const [value, setValue] = React.useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//     (newValue !== 3) && setHovered(false);
//   };

//   const handleBucketClick = () => {
//     setHovered(true);
//     dispatch(getUserBucket(userId))
//   };

//   return (
//     <div id="profileTabs" className={classes.root}>
//       {/* <AppBar className={classes.appBar} position="static"> */}
//         <AntTabs
//           value={value}
//           onChange={handleChange}
//           variant="scrollable"
//           scrollButtons="on"
//           indicatorColor="primary"
//           textColor="primary"
//           aria-label="scrollable force tabs example"
//         >
//           <AntTab label="Posts" icon={<DescriptionIcon />} {...a11yProps(0)} />
//           <AntTab onClick={() => dispatch(getUserFavorites(userId))} label="Favorites" icon={<FavoriteIcon />} {...a11yProps(1)} />
//           <AntTab onClick={() => dispatch(getUserVisited(userId))} label="Visited" icon={<CheckCircleIcon />} {...a11yProps(2)} />
//           <AntTab onClick={() => handleBucketClick()} label= 'Bucket' onMouseEnter = {() => setHovered(true)} onMouseLeave = {(value !== 3) && (() => setHovered(false))} icon={hovered == false ? <img src="BucketBorderIconGrey.png" style={{maxWidth:'25px', }} /> : <img src="BucketBorderIconBlue.png" style={{maxWidth:'25px', }} />} {...a11yProps(3)} />
//           <AntTab onClick={() => dispatch(getProfileFollowers(userId))} label="Followers" icon={<AccountBoxIcon/>} {...a11yProps(4)} />
//           <AntTab onClick={() => dispatch(getFollowing(userId))} label="Following" icon={<RecentActorsIcon />} {...a11yProps(5)} />
//           {/* <AntTab label="Settings" icon={<SettingsIcon />} {...a11yProps(6)} /> */}
//           {/* <AntTab label="Item Seven" icon={<ThumbUp />} {...a11yProps(6)} /> */}
//         </AntTabs>
//       {/* </AppBar> */}
//       <TabPanel id="profileTab1" value={value} index={0}>
//         {/* <Grow in> */}
//           {/* <Container style={{backgroundColor:'red'}}> */}
//             <Grid container justify="space-between" alignItems="stretch"  style={{display:'flex', justifyContent:'center'}}>
//               <Grid item xs={12} sm={6} md={7}  style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
//                 <UserPosts />
//               </Grid>
//             </Grid>
//           {/* </Container> */}
//         {/* </Grow> */}
//       </TabPanel>

//       <TabPanel value={value} index={1}>
//       <Grid container justify="space-between" alignItems="stretch"  style={{display:'flex', justifyContent:'center'}}>
//           <Grid item xs={12} sm={6} md={7}  style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
//             <FavoriteCards />
//           </Grid>
//         </Grid>
//       </TabPanel>

//       <TabPanel value={value} index={2}>
//       <Grid container justify="space-between" alignItems="stretch"  style={{display:'flex', justifyContent:'center'}}>
//         <Grid item xs={12} sm={6} md={7}  style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
//           <VisitedCards />
//         </Grid>
//       </Grid>
//       </TabPanel>

//       <TabPanel value={value} index={3}>
//       <Grid container justify="space-between" alignItems="stretch"  style={{display:'flex', justifyContent:'center'}}>
//         <Grid item xs={12} sm={6} md={7}  style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
//           <BucketCards />
//         </Grid>
//       </Grid>
//       </TabPanel>

//       <TabPanel value={value} index={4}>
//       <Grid container justify="space-between" alignItems="stretch"  style={{display:'flex', justifyContent:'center'}}>
//         <Grid item xs={12} sm={6} md={7}  style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
//           <FollowerCards />
//         </Grid>
//       </Grid>
//       </TabPanel>
      
//       <TabPanel value={value} index={5}>
//       <Grid container justify="space-between" alignItems="stretch"  style={{display:'flex', justifyContent:'center'}}>
//         <Grid item xs={12} sm={6} md={7}  style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
//           <FollowingCards />
//         </Grid>
//       </Grid>
//       </TabPanel>

//       {/* <TabPanel value={value} index={6}>
//         Item Seven
//       </TabPanel> */}
//     </div>
//   );
// }


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    width: '100%',
    backgroundColor: 'white',
    border:'2px solid #ededed',
    // borderRadius:'15px',
    marginBottom:'10px',
    
  },
  button: {
    margin:'5px',
    marginLeft:'2px',
    marginRight:'2px',
    width:'auto', 
    backgroundColor:'white', 
    border:'2px solid #ededed', 
    textTransform:'Capitalize', 
    justifyContent:'flex-start', 
    borderRadius:'25px', 
    paddingLeft:'6px',
    // paddingTop:'1px',
    // paddingBottom:'1px'
  },
  selected : {
    color:'#526bf4'
  },
  unselected : {
    color:'grey'
  }
}));

export default function ScrollableTabsButtonForce({loadingPosts, setLoadingPosts, postState, category, handleCategory, userId, tabState, setTabState, show}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" class="profileBar">
        <Tabs
          id="profileBar"
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          textColor="primary"
          aria-label="scrollable force tabs example"
          TabIndicatorProps={{
            style: {display: 'none'}
          }}
          style={{paddingTop:'5px', paddingBottom:'5px', backgroundColor:'white', borderRadius:'15px'}}
        >
          {/* <Button className={classes.button} variant="outlined" {...a11yProps(0)} onClick={() => setTabState("Posts")} style={{border:category === 'Nature' && '2px solid #333', fontWeight:category === 'Nature' && 'bold'}} disabled={loadingPosts} >
          <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="NatureIcon.jpeg"></Avatar>
            Posts
          </Button> */}
          <MenuItem onClick={()=> setTabState('Posts')} className={tabState === 'Posts' ? classes.selected : classes.unselected}>
            <IconButton className={tabState === 'Posts' ? classes.selected : classes.unselected}>
              <DescriptionIcon />
            </IconButton>
            <Typography className={tabState === 'Posts' ? classes.selected : classes.unselected}>Posts</Typography>
          </MenuItem>
          <MenuItem onClick={() => (dispatch(getUserFavorites(userId)), setTabState("Favorites"))} className={tabState === 'Favorites' ? classes.selected : classes.unselected}>
            <IconButton className={tabState === 'Favorites' ? classes.selected : classes.unselected} >
              <FavoriteIcon />
            </IconButton>
            <Typography className={tabState === 'Favorites' ? classes.selected : classes.unselected}>Favorites</Typography>
          </MenuItem>
          <MenuItem onClick={() => (dispatch(getUserVisited(userId)), setTabState("Visited"))} className={tabState === 'Visited' ? classes.selected : classes.unselected}>
            <IconButton className={tabState === 'Visited' ? classes.selected : classes.unselected}>
              <CheckCircleIcon  />
            </IconButton>
            <Typography className={tabState === 'Visited' ? classes.selected : classes.unselected}>Visited</Typography>
          </MenuItem>
          <MenuItem onClick={() => (dispatch(getUserBucket(userId)), setTabState("Bucket"))} className={tabState === 'Visited' ? classes.selected : classes.unselected}>
            <IconButton className={tabState === 'Bucket' ? classes.selected : classes.unselected}>
              <img src={tabState==='Bucket' ? "BucketBorderIconBlue.png" : "BucketBorderIconGrey.png"} style={{maxWidth:'25px'}}/>
            </IconButton>
            <Typography className={tabState === 'Bucket' ? classes.selected : classes.unselected}>Bucket</Typography>
          </MenuItem>
          <MenuItem onClick={() => (dispatch(getProfileFollowers(userId)), setTabState("Followers"))} className={tabState === 'Followers' ? classes.selected : classes.unselected}>
            <IconButton className={tabState === 'Followers' ? classes.selected : classes.unselected}>
              <AccountBoxIcon />
            </IconButton>
            <Typography className={tabState === 'Followers' ? classes.selected : classes.unselected}>Followers</Typography>
          </MenuItem>
          <MenuItem onClick={() => (dispatch(getFollowing(userId)), setTabState("Following"))} className={tabState === 'Following' ? classes.selected : classes.unselected}>
          <IconButton className={tabState === 'Following' ? classes.selected : classes.unselected} style={{padding:'8.75px'}}>
            <RecentActorsIcon style={{fontSize:'1.9rem'}} />
          </IconButton>
          <Typography className={tabState === 'Following' ? classes.selected : classes.unselected}>Following</Typography>
          </MenuItem>

          {/* <Button className={classes.button} variant="outlined" {...a11yProps(1)} onClick={() => (dispatch(getUserFavorites(userId)), setTabState("Favorites"))} style={{border:category === 'Nature' && '2px solid #333', fontWeight:category === 'Nature' && 'bold'}} disabled={loadingPosts} {...a11yProps(1)} >
          <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="NatureIcon.jpeg"></Avatar>
            Favorites
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(2)} onClick={() => (dispatch(getUserVisited(userId)), setTabState("Visited"))} style={{border:category === 'Nature' && '2px solid #333', fontWeight:category === 'Nature' && 'bold'}} disabled={loadingPosts} >
          <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="NatureIcon.jpeg"></Avatar>
            Visited
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(3)} onClick={() => (dispatch(getUserBucket(userId)), setTabState("Bucket"))} style={{backgroundColor:category === 'Scenery And Landscapes' && '#ededed'}} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="LandscapeIcon.jpeg"></Avatar>
            Bucket
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(4)} onClick={() => (dispatch(getProfileFollowers(userId)), setTabState("Followers"))} style={{backgroundColor:category === 'Animals' && '#ededed', }} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="Animals.jpeg"></Avatar>
            Followers
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(5)} onClick={() => (dispatch(getFollowing(userId)), setTabState("Following"))} style={{backgroundColor:category === 'Urban' && '#ededed', }} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="Urban.jpeg"></Avatar>
            Following
          </Button> */}

          {/* <Button className={classes.button} variant="outlined" {...a11yProps(6)} onClick={() => handleCategory('Architecture')} style={{backgroundColor:category === 'Architecture' && '#ededed', }} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="Architecture.jpeg"></Avatar>
            Architecture
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(6)} onClick={() => handleCategory('Culture')} style={{backgroundColor:category === 'Culture' && '#ededed', }} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="Culture.jpeg"></Avatar>
            Culture
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(6)} onClick={() => handleCategory('History')} style={{backgroundColor:category === 'History' && '#ededed', }} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="History.jpeg"></Avatar>
            History
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(6)} onClick={() => handleCategory('Religion')} style={{backgroundColor:category === 'Religion' && '#ededed', }} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="Religion.webp"></Avatar>
            Religion
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(6)} onClick={() => handleCategory('Experiences')} style={{backgroundColor:category === 'Experiences' && '#ededed', }} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="Experiences.jpeg"></Avatar>
            Experiences
          </Button>
          <Button className={classes.button} variant="outlined" {...a11yProps(6)} onClick={() => handleCategory('Events')} style={{backgroundColor:category === 'Events' && '#ededed', }} disabled={loadingPosts}>
              <Avatar style={{marginRight: '5px', backgroundColor:'white'}} src="Events.jpeg"></Avatar>
            Events
          </Button> */}
        </Tabs>
      </AppBar>
    </div>
  );
}