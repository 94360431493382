import { FETCH_ALL, CREATE, UPDATE, DELETE, LIKE, DISLIKE, FETCH_USER_POSTS, ADD_COMMENT_REFRESH, CHANGE_NEWSFEED } from '../constants/actionTypes';

export default (posts = [], action) => {
  switch (action.type) {
    case FETCH_ALL:
      // if(action.payload === null) {
      //   return action.payload;
      // } else {
      if(posts.includes(action.payload)) {
        return posts;
      } else {
        // console.log(posts);
        // console.log(action.payload);
        return posts.concat(action.payload);
      }
      // }
    // case FETCH_USER_POSTS:
    //   return action.payload;
    case LIKE:
      return posts.map((post) => (post.id === action.payload.id ? action.payload : post));
    case DISLIKE:
      return posts.map((post) => (post.id === action.payload.id ? action.payload : post));
    case CREATE:
      return [...posts, action.payload];
    case UPDATE:
      return posts.map((post) => (post.id === action.payload.id ? action.payload : post));
    case DELETE:
      return posts.filter((post) => post.id !== action.payload);
    case ADD_COMMENT_REFRESH: 
      return posts.map((post) => (post.id === action.payload.id ? action.payload : post));
    case CHANGE_NEWSFEED:
      return posts = [];
    default:
      return posts;
  }
};

