import { ADD_COMMENT, ADD_COMMENT_REFRESH, GET_COMMENTS, DELETE_COMMENT, LIKE_COMMENT, DISLIKE_COMMENT } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const getComments = (coordinates) => async(dispatch) => {
    try {
      const { data } = await api.getComments(coordinates);
      
      console.log(data);
      // console.log({coordinates});
      
      dispatch({ type: GET_COMMENTS, payload: data});
    } catch (error) {
      console.log(error);
      // console.log(coordinates);
    }
  }

export const addComment = (commentData, postId) => async (dispatch) => {
    
    // console.log(postId);
    try {
      const { data } = await api.addComment(commentData, postId);
      // console.log(data);
      dispatch({ type: ADD_COMMENT, payload: data.comments });
      dispatch({ type: ADD_COMMENT_REFRESH, payload: data.pst});
    } catch (error) {
      console.log(error);
    }
  };

export const deleteComment = (commentId, postId) => async (dispatch) => {
  console.log(commentId, postId);
  try {
   const {data} = await api.deleteComment(commentId, postId);
  //  console.log(data);

    dispatch({ type: DELETE_COMMENT, payload: commentId });
    dispatch({ type: ADD_COMMENT_REFRESH, payload: data.pst});
  } catch (error) {
    console.log(error);
  }
};

export const likeComment = (postId, commentId) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem('profile'));

  try {
    const { data } = await api.likeComment(postId, commentId, user?.token);
    // console.log(data);
    dispatch({ type: LIKE_COMMENT, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const dislikeComment = (postId, commentId) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem('profile'));

  try {
    const { data } = await api.dislikeComment(postId, commentId, user?.token);
    console.log(data);

    dispatch({ type: DISLIKE_COMMENT, payload: data});
  } catch (error) {
    console.log(error);
  }
};