import React, { useState, useEffect } from 'react';
import { Card, Container, Grid, Typography, Avatar, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import BookmarkIcon from '@material-ui/icons/Bookmark';
import { addToProfileFollowing, removeFromProfileFollowing } from '../../actions/user';
import useStyles from './styles';

const FollowingCard = ({traveler}) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [userFollowing, setUserFollowing] = useState([]);
    const currentUser = JSON.parse(localStorage.getItem('profile'));
    const currentUserId = currentUser?.result?.googleId || currentUser?.result?.id;
  
      const handleFollow = () => {
        setUserFollowing([...traveler?.followers, currentUserId]);
        dispatch(addToProfileFollowing({userId : traveler.id}));
      }
  
      const handleUnfollow = () => {
        setUserFollowing(traveler?.followers.filter((id) => id !== currentUserId));
        dispatch(removeFromProfileFollowing(traveler.id));
      }
  
      useEffect(() => {
        setUserFollowing(traveler?.followers);
        console.log(traveler);
    }, [traveler])
  
    const Following = () => {
      if(traveler.id === currentUserId){
          return null;
      }
      if(userFollowing) {
      if (userFollowing?.length > 0) { 
        return userFollowing.find((follower) => follower === (currentUserId))
          ? (
            <Button variant="outlined" color='primary' size="small" style={{textTransform:'capitalize', border:'none', background: 'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', webkitBackgroundClip: "text", webkitTextFillColor: "transparent", fontWeight:'bold', letterSpacing:'normal'}} onClick={() => handleUnfollow(traveler?.id) }>Following</Button> 
          ) : (
            <Button variant="outlined" color='primary' size="small" style={{textTransform:'capitalize', background:'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', border:'1px solid white', color:'white'}} onClick={() => handleFollow(traveler?.id) }>Follow</Button>
          );
      } }
      return <Button variant="outlined" color='primary' size="small" style={{textTransform:'capitalize', background:'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', border:'1px solid white', color:'white'}} onClick={() => handleFollow(traveler?.id) }>Follow</Button>
               
    };

    return (
        <div className={classes.button} variant="outlined" 
        // disabled={loadingPosts}
        >
              <Avatar src={traveler?.profilePic ? traveler?.profilePic : null} style={{height:'75px', width:'75px', borderRadius:'10%', border:'', background:!traveler?.profilePic && '#ededed', color:'#333' }}>{traveler?.name?.charAt(0)}</Avatar>
            <div className={classes.text}>
            <Typography variant='subtitle1' color="primary" component={Link} to={`/${traveler?.id}`} style={{overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap', textDecoration:'none'}}>{traveler?.name}</Typography>
            {/* {traveler?.suggestedType == 'Friendly' ? (
            <Typography variant='subtitle2' style={{color:'darkgrey', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>Followed by {traveler.followerfName}</Typography>
            ) : (
            <Typography variant='subtitle2' style={{color:'darkgrey', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>Top User</Typography>
            )} */}
            </div>
            {/* {(!userFollowing?.includes(traveler.id)) ? 
            <Button variant="outlined" color='primary' size="small" style={{textTransform:'capitalize', background:'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', border:'1px solid white', color:'white'}} onClick={() => handleFollow(traveler?.id) }>Follow</Button>
          :
          <Button variant="outlined" color='primary' size="small" style={{textTransform:'capitalize', border:'none', background: 'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', webkitBackgroundClip: "text", webkitTextFillColor: "transparent", fontWeight:'bold', letterSpacing:'normal'}} onClick={() => handleUnfollow(traveler?.id) }>Following</Button> 
          } */}
          <Following />
          {/* {(suggested.find(id => id === traveler.id)) ?
            <Button variant="outlined" color='primary' size="small" style={{textTransform:'capitalize', background:'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', border:'1px solid white', color:'white'}} onClick={() => handleFollow(traveler.id) }>2 Follow</Button>
          :
          <Button variant="outlined" color='primary' size="small" style={{textTransform:'capitalize', border:'none', background: 'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', webkitBackgroundClip: "text", webkitTextFillColor: "transparent", fontWeight:'bold', letterSpacing:'normal'}} onClick={() => handleUnfollow(traveler.id) }>2 Following</Button> 
          } */}
            </div>
    )
}

export default FollowingCard
