import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Typography, Paper, Grid, Switch, withStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import FileBase from 'react-file-base64';
// import { IconButton, Divider } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { getSignature } from '../../../api';
import { createPost } from '../../../actions/posts';
import useStyles from './styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import SearchIcon from '@material-ui/icons/Search';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CustomizedDialogs from './VideoFormHelp';
import MaxWidthDialog from './TagDialogVideo';

const CreateVideoForm = () => {
  const date = new Date();
  const history = useHistory();
  // console.log(date);
  const [postData, setPostData] = useState({id:'', type:'', title: '', caption:'', summary:'', photoURL:'', videoURL:'', message: '', content:'', tags: '', selectedFile: '', placeName:'', location:'', coordinates: '', createdAt:'' });
  // const post = useSelector((state) => (postId ? state.posts.find((message) => message._id === postId) : null));
  const [location, setLocation] = useState({ placeName:'',  locName:'', country:'', location:'', latitude :'', longitude: '', coordinates: ''});
  const [selectedTags, setselectedTags] = useState([]);
  const [image, setImage] = useState(null);
  const [embedLink, setEmbedLink] = useState('')
  // console.log(location.placeName);
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('profile'));
  const [visitedLocation, setVisitedLocation] = useState({ placeName:'', name:'', country:'', location:'', latitude :'', longitude: '', coordinates: ''});
  const [switchState, setSwitchState] = useState(true);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snack, setSnack] = useState('');
  // console.log(location);
  // console.log(postData);

  // console.log(embedLink);
  let youtubeLink;
  // const youtubeLink = embedLink.split("src=\"").pop().split("\"")[0];
  if(embedLink.includes('youtube.com/watch?v=')) {
    const link = embedLink.split('v=')[1];
    youtubeLink = `https://www.youtube.com/embed/${link}`;
    // console.log(youtubeLink);
  } else {
    const link = embedLink.split('/')[3];
    youtubeLink = `https://www.youtube.com/embed/${link}`;
    console.log(youtubeLink);
  }

 

  ////Location States/////////////////
 
  const [place, setPlace] = 
    useState({name:'',
              // id:'',
              // address:'',
              photos:[]
  });
  const [query, setQuery] = useState("");
  const [city, setCity] = useState("")
  const autoCompleteRef = useRef(null);
  // console.log(place);

  let autoComplete;

  const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const handleScriptLoad = () => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    // { 
    //   // types: ["(cities)"],
    //  componentRestrictions: { country: "lk" } }
  );
  // autoCompleteRef.current.setFields(["address_components", "formatted_address", 'place_id', 'geometry', 'name', 'photos']);
  autoCompleteRef.current.addListener("place_changed", handlePlaceSelect);
}

const handlePlaceSelect = () => {
  const addressObject = autoCompleteRef.current.getPlace();
  const name = addressObject.name;
  const countryString = addressObject.adr_address.substring(addressObject.adr_address.lastIndexOf("<span class=\"country-name\">"), addressObject.adr_address.lastIndexOf("<"));
  const country = countryString.split(">")[1];
  let placeName;
  if(name === country) {
    placeName = name;
  } else {
    placeName = name + "," + " " + country;
  }
  // const placeName = name + "," + " " + country;
  const photos = addressObject.photos;
  const long_name = addressObject.address_components.map(addressComponent => addressComponent.long_name);
  const long_name_str = long_name.toString();
  const latitude = (addressObject.geometry.location.lat());
  const longitude = (addressObject.geometry.location.lng());
  const coordinates = addressObject.geometry.location.lat() + ', ' + addressObject.geometry.location.lng();
  const latitude_str = (addressObject.geometry.location.lat()).toString();
  const longitude_str = (addressObject.geometry.location.lng()).toString();
  // console.log(addressObject); 
  // updateQuery(query);
  // setPlace({...place, name:name, photos: photos});
  // setPostData({...postData, location:long_name_str})
  setLocation({...location, location:long_name_str, latitude:latitude, longitude:longitude, coordinates:coordinates, placeName:placeName, locName:name, country:country})
  setQuery(placeName);
  setVisitedLocation({...visitedLocation, location:long_name_str, latitude:latitude_str, longitude:longitude_str, coordinates:coordinates, placeName:placeName, name:name, country:country})

  // console.log(addressObject);
  // console.log(placeName);
  // setPlace({...place, });
}
 
useEffect(() => {
  loadScript(
    `https://maps.googleapis.com/maps/api/js?key=AIzaSyDXGCxkxHNNK_ZO18wSSJlcwCA242XZuqk&callback=initMap&libraries=places`,
    () => handleScriptLoad(setQuery, autoCompleteRef)
  );
}, []);
  /////////////////////////////////////

  // useEffect(() => {
  //   if (post) setPostData(post);
  // }, [post]);
  

  const clear = () => {
    // setCurrentId(0);
    // handleClose();
    setPostData({id:'', type:'', title: '', caption:'', summary:'', photoURL:'', message: '', content:'', tags: '', selectedFile: '', placeName:'', location:'' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // dispatch(createPost({ ...postData, id: uuidv4(), type:'Video', name: user?.result?.name, placeName:location.placeName, locName:location.locName, country:location.country, location:location.location, latitude:location.latitude, longitude:location.longitude, coordinates:location.coordinates, createdAt:date, videoURL: youtubeLink }, null, history));
    if(embedLink === '') {
      setSnack('E1');
      setSnackOpen(true);
      return;
    }

    if(location.coordinates === '') {
      setSnack('E2');
      setSnackOpen(true);
      return;
    }

    if(postData.title === '') {
      setSnack('E3');
      setSnackOpen(true);
      return;
    }

    if(switchState) {
      dispatch(createPost({ ...postData, id: uuidv4(), type:'Video', name: user?.result?.name, placeName:location.placeName, locName:location.locName, country:location.country, location:location.location, latitude:location.latitude, longitude:location.longitude, coordinates:location.coordinates, createdAt:date, tags:selectedTags, generalTags:selectedTags.filter(tag => (tag.tagType === 'General')), AVTags:selectedTags.filter(tag => (tag.type === 'AVTags')), activityTags:selectedTags.filter(tag => (tag.type === 'Activities')), vibeTags:selectedTags.filter(tag => (tag.type === 'Vibes') && tag.label), videoURL: youtubeLink }, visitedLocation, history));
    } else {
      dispatch(createPost({ ...postData, id: uuidv4(), type:'Video', name: user?.result?.name, placeName:location.placeName, locName:location.locName, country:location.country, location:location.location, latitude:location.latitude, longitude:location.longitude, coordinates:location.coordinates, createdAt:date, tags:selectedTags, generalTags:selectedTags.filter(tag => (tag.tagType === 'General')), AVTags:selectedTags.filter(tag => (tag.type === 'AVTags')), activityTags:selectedTags.filter(tag => (tag.type === 'Activities')), vibeTags:selectedTags.filter(tag => (tag.type === 'Vibes') && tag.label), videoURL: youtubeLink }, null, history));
    }
    setSnack('S1')
    setSnackOpen(true);

    clear();
  };

  if (!user?.result?.name) {
    return (
      <Paper className={classes.paper}>
        <Typography variant="h6" align="center">
          Please Sign In to create your own memories and like other's memories.
        </Typography>
      </Paper>
    );
  }

  /////////////Location Tagging////////////

  const MyWidget = () => {
    var myWidget = window.cloudinary.createUploadWidget(
      {
        cloud_name: 'travelaio',
        api_key: '222487881423418',
        // public_id:'ohHiMark',
        // uploadPreset: "<upload_preset>",
        upload_signature: generateSignature,
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          // console.log("Done! Here is the image info: ", result.info);
          setPostData({...postData, selectedFile:result.info.secure_url});
          setImage(result.info.original_filename + '.' + result.info.format)
          // console.log(postData);
        } else if(error) {
          // console.log(error);
          // console.log(result);
        }
      }
    );

    const handleWidget = (e) => {
      e.preventDefault();
      myWidget.open();
    }

    return (
      <>
      <Button id="upload_widget" className="cloudinary-button" variant="contained" color="primary" size="large" onClick={handleWidget} style={{marginLeft:'7px', marginBottom:'15px'}}>
        Add Photo
      </Button>
      {/* <p style={{marginLeft:'20px'}}>{image}</p> */}

      {(image) && 
      <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginBottom:'15px', marginLeft:'5px'}}>
        <p style={{marginLeft:"5px", marginRight:'5px'}}>Photo Added</p>
        <CheckCircleIcon style={{color:'green'}} />
      </div>
      }
      </>
    )
  }

  const generateSignature = async (callback, params_to_sign) => {
    try {
      const signature = await getSignature(params_to_sign);
      callback(signature.data);
      // console.log(signature.data);
      // console.log(params_to_sign);
      // console.log(callback);
    } catch (error) {
      console.log(error);
    }
  };  

  const VisitSwitch = withStyles({
    switchBase: {
      color: '#ffffff',
      '&$checked': {
        color: '#04cf1c',
      },
      '&$checked + $track': {
        backgroundColor: '#04cf1c',
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const handleVisitSwitch = () => {
    setSwitchState(switchState => !switchState);

  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    // <Paper className={classes.paper}>
    <>
      <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} >
        
      {/* <MyWidget /> */}
      <div style={{width:'70%'}}>
        <Typography style={{marginLeft:'10px', color:'#333'}}>YouTube Video Link</Typography>
      </div>
      <CustomizedDialogs />
        <TextField name="YouTube Video Link" variant="outlined" fullWidth multiline value={embedLink} onChange={(e)=>setEmbedLink(e.target.value)} />
        {(embedLink.includes('https://www.youtube.com') && embedLink.includes('watch?v=')) || (embedLink.includes('https://') && embedLink.includes('youtu.be/'))  ? (
            <Typography variat="subtitle2" style={{display:'flex', flexDirection:'row', justifyContent:'center', color:'green'}}><CheckCircleIcon style={{color:'green',  marginBottom:'5px', marginRight:'3px'}} />&nbsp;Youtube link added.</Typography>
          ) : (
            (embedLink !== '') && (
            <Typography variat="subtitle2" color="secondary" style={{display:'flex', flexDirection:'row'}}><ErrorIcon style={{color:'red', marginBottom:'5px', marginRight:'3px'}} />Invalid Link.</Typography>
            )
            )}
        
        <div style={{width:'100%'}}>
        <Typography style={{marginLeft:'10px', color:'#333'}}>Add a Title</Typography>
        </div>
        <TextField name="title" variant="outlined" fullWidth value={postData.title} onChange={(e) => setPostData({ ...postData, title: e.target.value })} />
        <div style={{width:'100%'}}>
        <Typography style={{marginLeft:'10px', color:'#333'}}>Add a Caption</Typography>
        </div>
        <TextField name="caption" variant="outlined" fullWidth value={postData.caption} onChange={(e) => setPostData({ ...postData, caption: e.target.value })} />
        {/* <TextField name="tags" variant="outlined" label="Tags (coma separated)" fullWidth value={postData.tags} onChange={(e) => setPostData({ ...postData, tags: e.target.value.split(',') })} /> */}
        <MaxWidthDialog selectedTags={selectedTags} setselectedTags={setselectedTags} />
        <div style={{width:'100%'}}>
        <Typography style={{marginLeft:'10px', color:'#333'}}>Add Location</Typography>
        </div>
        <div id="tinyInput2" className="search-location-input" style={{display:"flex", justifyContent:'center',  alignItems:'center', height:'49px', width:'100%', margin:'auto', marginTop:'10px'}}>
      <SearchIcon style={{backgroundColor:'white', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px', border:'1px solid silver', borderRight:'0', padding:'12px', fontSize:'3.05rem'}}/>
      <input
        ref={autoCompleteRef}
        onChange={event => setQuery(event.target.value)}
        placeholder="Enter a location"
        value={query}
      />
       {/* <Button variant="contained" style={{ marginLeft:'10px'}} color="primary">
            Search
        </Button> */}
      {/* <p>{place}</p> */}
      
    </div>
    <Grid container style={{display:'flex', alignItems:'center', marginTop:'10px'}}>
      <Grid item>
        <VisitSwitch
          checked={switchState}
          onChange={handleVisitSwitch}
          // color="primary"
          // name="checkedB"
          // inputProps={{ 'aria-label': 'primary checkbox' }}
          // style={{color:'red'}}
        />
      </Grid>
      <Grid item>
        <Typography>Mark this location as visited.</Typography>
      </Grid>
    </Grid>

      
      
        <div className={classes.fileInput}>
        
          {/* <FileBase type="file" multiple={false} onDone={({ base64 }) => setPostData({ ...postData, selectedFile: base64 })} /> */}
          </div>
        {/* <Button style={{ marginLeft:'auto'}} className={classes.buttonSubmit} variant="contained" color="primary" size="small" type="submit">Post</Button> */}
        
          <Button variant="contained" style={{ marginLeft:'auto'}} color="secondary">
            Cancel
          </Button>
          <Button style={{ marginLeft:'10px'}} variant="contained" type="submit" color="primary">
            Post
          </Button>
      </form>
      {snack === 'S1' && (
      <Snackbar open={snackOpen} autoHideDuration={5000} onClose={() => setSnackOpen(false)}>
        <Alert onClose={() => setSnackOpen(false)} severity="success">
          Creating Your Post ...
        </Alert>
      </Snackbar>
      )}
      {snack === 'E1' && (
      <Snackbar open={snackOpen} autoHideDuration={5000} onClose={() => setSnackOpen(false)}>
        <Alert onClose={() => setSnackOpen(false)} severity="error">
          Video link not added.
        </Alert>
      </Snackbar>
      )}
      {snack === 'E2' && (
      <Snackbar open={snackOpen} autoHideDuration={5000} onClose={() => setSnackOpen(false)}>
        <Alert onClose={() => setSnackOpen(false)} severity="error">
          Please select a location.
        </Alert>
      </Snackbar>
      )}
      {snack === 'E3' && (
      <Snackbar open={snackOpen} autoHideDuration={5000} onClose={() => setSnackOpen(false)}>
        <Alert onClose={() => setSnackOpen(false)} severity="error">
          Please add a title to your video.
        </Alert>
      </Snackbar>
      )}
      </>
    // </Paper>
  );
};

export default CreateVideoForm;
