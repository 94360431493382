import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Link } from 'react-router-dom';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { Chip, CircularProgress } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { getLocation } from '../../../actions/posts';
import { addToFavorites, getFavorites, removeFromFavorites, addToVisited, getVisited, removeFromVisited, addToBucket, getBucket, removeFromBucket } from "../../../actions/favorites";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function MenuListComposition({placeName, placeDetails, handleClick, viewLocation, coordinates}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('profile'));
  const userId = user?.result?.googleId || user?.result?.id;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  // const [placeDetails, setPlaceDetails] = useState({latitude: '', longitude: '', coordinates: '', location: '', name:'', placeName:'', country: ''});
  const favState = useSelector((state) => state.favorites);
  const visitState = useSelector((state) => state.visited);
  const bucketState = useSelector((state) => state.bucket);
  const [canReact, setCanReact] = useState(true);
  const [scroll, setScroll] = useState(false);
  const [favs, setFavs] = useState(null);
  const [visits, setVisits] = useState(null);
  const [buckets, setBuckets] = useState(null);
  const [loading, setLoading] = useState(false);


  const addToFavs = () => {
    setCanReact(false);
    setScroll(false);
    setFavs([...favs, userId ])
    dispatch(addToFavorites(placeDetails));
    // setSnack('F1');
    // setOpen(true);
  }
  const removeFromFavs = () => {
    setCanReact(false);
    setScroll(false);
    setFavs(favs.filter((fav) => fav !== userId));
    dispatch(removeFromFavorites(placeDetails.coordinates))
    // setSnack('F2');
    // setOpen(true);
  }

  const addToVisits = () => {
    setCanReact(false);
    setScroll(false);
    setVisits([...visits, userId])
    dispatch(addToVisited(placeDetails));
    // setSnack('V1');
    // setOpen(true);
  }
  const removeFromVisits = () => {
    setCanReact(false);
    setScroll(false);
    setVisits(visits.filter((visit) => visit !== userId));
    dispatch(removeFromVisited(placeDetails.coordinates))
    // setSnack('V2');
    // setOpen(true);
  }

  const addToBuckets = () => {
    setCanReact(false);
    setScroll(false);
    setBuckets([...buckets, userId]);
    dispatch(addToBucket(placeDetails));
    // setSnack('B1');
    // setOpen(true);
  }
  const removeFromBuckets = () => {
    setCanReact(false);
    setScroll(false);
    setBuckets(buckets.filter((bucket) => bucket !== userId));
    dispatch(removeFromBucket(placeDetails.coordinates))
    // setSnack('B2');
    // setOpen(true);
  }

  /////////////////////////////////////////////

  const Fav = () => {
    if(favs) {
    if(favs.length > 0) {
      return favs.find((fav) => fav === (userId))
      ? (
        <>
        <MenuItem onClick={removeFromFavs} disabled = {canReact === false} style={{color:'#f50057'}}><FavoriteIcon color="secondary" style={{marginRight:'16px'}} />Added To Favorites</MenuItem>
        </>
      ) : (
        <>
        <MenuItem onClick={addToFavs} disabled = {canReact === false}><FavoriteBorderIcon style={{marginRight:'16px'}}  />Add To Favorites</MenuItem>
        </>
      )
    } }
    return  <MenuItem onClick={addToFavs} disabled = {canReact === false}><FavoriteBorderIcon style={{marginRight:'16px'}}  />Add To Favorites</MenuItem>
    
  }

  const Visited = () => {
    if(visits) {
    if(visits?.length > 0) {
      return visits.find((visit) => visit === (user?.result?.googleId || user?.result?.id))
      ? (
        <>
        <MenuItem onClick={removeFromVisits} disabled = {canReact === false} style={{color:'#00a152'}}><CheckCircleIcon style={{marginRight:'16px', color:'#00a152'}} />Marked As Visited</MenuItem>
        </>
      ) : (
        <>
       <MenuItem onClick={addToVisits} disabled = {canReact === false}><CheckCircleOutlineIcon style={{marginRight:'16px'}}  />Mark As Visited</MenuItem>
        </>
      )
    } }
    return <MenuItem onClick={addToVisits} disabled = {canReact === false}><CheckCircleOutlineIcon style={{marginRight:'16px'}}  />Mark As Visited</MenuItem> 
  }
  
  const Bucket = () => {
    if(buckets) {
    if(buckets?.length > 0) {
      return buckets.find((bucket) => bucket === (user?.result?.googleId || user?.result?.id))
      ? (
        <>
          <MenuItem onClick={removeFromBuckets} disabled = {canReact === false} style={{color:'#FF9100'}} > <img src="BucketIcon.png" style={{maxWidth:'25px', marginRight:'16px'}} />Added To Bucket</MenuItem>
        </>
      ) : (

        <>
        <MenuItem onClick={addToBuckets} disabled = {canReact === false} > <img src="BucketBorderIcon.png" style={{maxWidth:'25px', marginRight:'16px'}} />Add To Bucket</MenuItem>
        </>
      )
    } }
    // return <IconButton className={classes.favorite} size="small" aria-label="add" onClick={addToBuckets}><BookmarkBorderIcon fontSize="large" /></IconButton>  
    return <MenuItem onClick={addToBuckets} disabled = {canReact === false} > <img src="BucketBorderIcon.png" style={{maxWidth:'25px', marginRight:'16px'}} />Add To Bucket</MenuItem>
  
  }

  ////////////////////////////////////////////





  useEffect(() => {
    setFavs(favState);
  }, [favState]);
  
  useEffect(() => {
    setVisits(visitState);
  }, [visitState]);
  
  useEffect(() => {
    setBuckets(bucketState);
  }, [bucketState]);
  
  useEffect(() => {
    if(canReact === false) {
      setCanReact(true);
      setLoading(false);
    }
  }, [favState, visitState, bucketState]);

  const handleToggle = () => {
    handleClick();
    setOpen((prevOpen) => !prevOpen);
    dispatch(getLocation(coordinates));
    setCanReact(false);
    setLoading(true);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root} style={{marginLeft:'auto', maxWidth:'60%'}}>
      
        {/* <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          Toggle Menu Grow
        </Button> */}
        <Chip onClick={(user && placeName) &&handleToggle}
        component={!user && Link} to="/auth"
        icon={<LocationOnIcon />}
        label={placeName}
        color="primary"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        style={{background:'linear-gradient(to left, #3ec7e0,#526bf4)', marginLeft:'auto', width:'100%',}}
      />
        <Popper id="quickReactions" open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex:1}}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  {loading ? <CircularProgress /> : 
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} style={{paddingBottom:'0px'}}>
                    {/* <MenuItem onClick={handleClose}><FavoriteBorderIcon style={{marginRight:'16px'}} />Added To Favorites</MenuItem> */}<Fav />
                    {/* <MenuItem onClick={handleClose}><CheckCircleOutlineIcon style={{marginRight:'16px'}} />Mark As Visited</MenuItem> */}<Visited />
                    {/* <MenuItem onClick={handleClose}> <img src="BucketBorderIcon.png" style={{maxWidth:'25px', marginRight:'16px'}} />Add To Bucket</MenuItem> */}<Bucket />
                    <MenuItem onClick={handleClose} style={{paddingBottom:'10px', marginTop:'5px', borderTop:'2px solid #e6e6e6'}} onClick={viewLocation}><ArrowForwardIcon style={{marginRight:'16px'}} />View Location</MenuItem>
                  </MenuList>
                  }
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </div>
  );
}
