import { SELECT_LOCATION, FETCH_EXPLORE_FAVS, FETCH_EXPLORE_VISITS, FETCH_EXPLORE_BUCKETS, SET_CONTROL } from "../constants/actionTypes";
import * as api from '../api/index.js';

export const selectLocation = (data) => async (dispatch) => {
    try {
      dispatch({ type: SELECT_LOCATION, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

  export const getExploreFavs = () => async (dispatch) => {
    try {
      const { data } = await api.getExploreFavs();
      // console.log(data);
  
      dispatch({ type: FETCH_EXPLORE_FAVS, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

  export const getExploreVisits = () => async (dispatch) => {
    try {
      const { data } = await api.getExploreVisits();
      // console.log(data);
  
      dispatch({ type: FETCH_EXPLORE_VISITS, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

  export const getExploreBuckets = () => async (dispatch) => {
    try {
      const { data } = await api.getExploreBuckets();
      // console.log(data);
  
      dispatch({ type: FETCH_EXPLORE_BUCKETS, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

  export const setControl = (data) => async (dispatch) => {
    try {
      dispatch({ type: SET_CONTROL, payload: data });
    } catch (error) {
      console.log(error);
    }
  };