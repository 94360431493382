import React from 'react';
import redressed from '@fontsource/redressed';
import { Container, Chip, Link, Grow, Grid, Typography, Button, CircularProgress } from '@material-ui/core';



const LoadingScreen = () => {
    const array = [
        'Travelling... It leaves you speechless. Then turns you into a storyteller.',
        'Wherever you go becomes a part of you somehow...',
        'The most beautiful thing in the world is, of course, the world itself.',
        'We travel, initially, to lose ourselves, and we travel, next to find ourselves.',
        'Travel is the only thing you buy that makes you richer.',
        'The journey is my home.',
        'To travel is to live.',
        'Don’t listen to what they say, go see...',
        'People don’t take trips... trips take people.',
        'Travel - the best way to be lost and found at the same time.',
        'All journeys have secret destinations of which the traveler is unaware',
        'The world is a book and those who do not travel read only one page.',
        'Go where you feel most alive...',
        'Travel is the healthiest addiction.',
        'Adventure is worthwhile...',
        'Traveling tends to magnify all human emotions.',
        'Travel far enough, you meet yourself.',
        'Little by little, one travels far...',
        'And I think to myself, what a wonderful world...',
        'Just Go. Go see all the beauty in the world...'


    ];
    const rando = array[Math.floor(Math.random() * array.length)];
  return (
    <Grow in >
    <Container style={{height:'30rem', display:'flex', alignItems:'center', justifyContent:'center'}} class="profileContainer" >
      {/* <Typography style={{fontFamily:'Redressed', fontSize:'30px', textAlign:'center'}}>Travelling...<br></br> It leaves you speechless.<br></br> Then turns you into a storyteller.</Typography> */}
      {/* <Typography style={{fontFamily:'Redressed', fontSize:'30px', textAlign:'center'}}>{rando}</Typography> */}
      <CircularProgress id="homeCircular" style={{margin:'auto'}} />
    </Container>
    </Grow>
  )
}

export default LoadingScreen