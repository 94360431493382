import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { Button, Avatar } from '@material-ui/core';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { addToFollowing, removeFromFollowing } from '../../actions/user';


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: 'white',
    marginLeft:'10px',
    border:'2px solid #ededed',
    borderRadius:'15px',
    padding:'5px'    
  },
  avatar : {
    height:'40px',
    width:'40px',
  },
  text : {
    whiteSpace:'pre-line',
    display:'flex',
    flexDirection:'column',
    textAlign:'left',
    width:'95%',
    marginLeft:'5px',
    marginRight:'5px',
    overflow:'hidden'
  },
  button: {
    // margin:'5px',
    // marginLeft:'2px',
    // marginRight:'2px',
    width:'100%',
    // height:'275px', 
    backgroundColor:'white', 
    // border:'1px solid #ededed', 
    // boxShadow:'0 0 5px #ededed',
    textTransform:'Capitalize', 
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    // marginLeft:'10px',
    // marginBottom:'10px',
    // borderRadius:'5px',
    borderTop:'1px solid #ededed',
    padding:'5px'
    // justifyContent:'flex-start', 
    // borderRadius:'25px', 
    // paddingLeft:'6px',
    // paddingTop:'1px',
    // paddingBottom:'1px'
  },
}));

export default function SuggestedTravelersPC({loadingPosts, travelers, travelerIds, setTravelerIds, loading, setLoading}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [suggested, setSuggested] = useState(travelers.map(traveler => traveler.id));
  // const travelers = useSelector((state) => state.suggestedUsers);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(travelers);

  const handleFollow = (userId) => {
            if(loading === true) return;
            setLoading(true);            
            setSuggested(suggested.filter((id) => id !== userId));
            setTravelerIds([...travelerIds, userId]);
            dispatch(addToFollowing({userId : userId})).then(() => setLoading(false));
    }

    const handleUnfollow = (userId) => {
        if(loading === true) return;
        setLoading(true);  
        setSuggested([...suggested, userId]);
        setTravelerIds(travelerIds.filter(id => id !== userId));
        dispatch(removeFromFollowing(userId)).then(() => setLoading(false));
    }

  return (
    <div className={classes.root}>
          {!travelers?.length ? null :
          <Typography style={{color:'darkgrey', textAlign:'left', marginLeft:'5px', marginBottom:'5px'}}>Suggested Travelers</Typography>}
          {
          travelers.map(traveler => 
          <div className={classes.button} variant="outlined" {...a11yProps(5)} disabled={loadingPosts}>
              <Avatar className={classes.avatar} src={traveler?.profilePic ? traveler?.profilePic : null} style={{border:'', boxShadow:'0 0 2px darkGray', background:!traveler?.profilePic && 'linear-gradient(to left, #9121c2, #FF5E5E )' }}>{traveler?.firstName.charAt(0)}</Avatar>
            <div className={classes.text}>
            <Typography variant='subtitle2' color="primary" component={Link} to={`/${traveler.id}`} style={{overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap', textDecoration:'none'}}>{traveler.name}</Typography>
            {traveler?.suggestedType == 'Friendly' ? (
            <Typography variant='subtitle2' style={{color:'darkgrey', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>Followed by {traveler.followerfName}</Typography>
            ) : (
            <Typography variant='subtitle2' style={{color:'darkgrey', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>Top User</Typography>
            )}
            </div>
            {(!travelerIds.includes(traveler.id)) ? 
            <Button variant="outlined" color='primary' size="small" style={{textTransform:'capitalize', background:'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', border:'1px solid white', color:'white'}} onClick={() => handleFollow(traveler.id) }>Follow</Button>
          :
          <Button variant="outlined" color='primary' size="small" style={{textTransform:'capitalize', border:'none', background: 'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', webkitBackgroundClip: "text", webkitTextFillColor: "transparent", fontWeight:'bold', letterSpacing:'normal'}} onClick={() => handleUnfollow(traveler.id) }>Following</Button> 
          }
          {/* {(suggested.find(id => id === traveler.id)) ?
            <Button variant="outlined" color='primary' size="small" style={{textTransform:'capitalize', background:'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', border:'1px solid white', color:'white'}} onClick={() => handleFollow(traveler.id) }>2 Follow</Button>
          :
          <Button variant="outlined" color='primary' size="small" style={{textTransform:'capitalize', border:'none', background: 'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', webkitBackgroundClip: "text", webkitTextFillColor: "transparent", fontWeight:'bold', letterSpacing:'normal'}} onClick={() => handleUnfollow(traveler.id) }>2 Following</Button> 
          } */}
            </div>
          )}
    </div>
  );
}
