export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_LANDING = 'FETCH_LANDING';
export const CLEAR_LANDING = 'CLEAR_LANDING';
export const FETCH_USER_POSTS = 'FETCH_USER_POSTS'
export const LIKE = 'LIKE';
export const DISLIKE = ' DISLIKE';
export const FETCH_LOCATION_POSTS = 'FETCH_LOCATION_POSTS';
export const CLEAR_LOCATION_POSTS = 'CLEAR_LOCATION_POSTS';
export const FETCH_TAG_POSTS = 'FETCH_TAG_POSTS';
export const FETCH_ONE = 'FETCH_ONE';
export const CHANGE_NEWSFEED = 'CHANGE_NEWSFEED';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const VERIFY = 'VERIFY';

export const GET_LIKES = 'GET_LIKES';

export const GET_REACTIONS = 'GET_REACTIONS'
export const ADDTOFAVS = 'ADDTOFAVS';
export const REMOVEFROMFAVS = 'REMOVEFROMFAVS';
export const GET_FAVS = 'GET_FAVS';

export const ADDTOVISITED = 'ADDTOVISITED';
export const REMOVEFROMVISITED = 'REMOVEFROMVISITED';
export const GET_VISITED = 'GET_VISITED';

export const ADDTOBUCKET = 'ADDTOBUCKET';
export const REMOVEFROMBUCKET = 'REMOVEFROMBUCKET';
export const GET_BUCKET = 'GET_BUCKET';

export const GET_LOCATION = 'GET_LOCATION';
export const SELECT_LOCATION = 'SELECT_LOCATION';
export const SELECT_TAG = 'SELECT_TAG';
export const FETCH_EXPLORE_FAVS = 'FETCH_EXPLORE_FAVS';
export const FETCH_EXPLORE_VISITS = 'FETCH_EXPLORE_VISITS';
export const FETCH_EXPLORE_BUCKETS = 'FETCH_EXPLORE_BUCKETS';

export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_SUGGESTED_USERS = 'FETCH_SUGGESTED_USERS';

export const ADD_TO_FOLLOWING = 'ADD_TO_FOLLOWING';
export const REMOVE_FROM_FOLLOWING = 'REMOVE_FROM_FOLLOWING';
export const GET_FOLLOWING = 'GET_FOLLOWING';
export const GET_FOLLOWERS = 'GET_FOLLOWERS';
export const GET_PROFILE_FOLLOWERS = 'GET_PROFILE_FOLLOWERS';
export const ADD_TO_PROFILE_FOLLOWING = 'ADD_TO_PROFILE_FOLLOWING';
export const REMOVE_FROM_PROFILE_FOLLOWING = 'REMOVE_FROM_PROFILE_FOLLOWING';


export const GET_COMMENTS = 'GET_COMMENTS';
export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_COMMENT_REFRESH = 'ADD_COMMENT_REFRESH';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const LIKE_COMMENT='LIKE_COMMENT';
export const DISLIKE_COMMENT='DISLIKE_COMMENT';
export const CHANGE_COMMENTS='CHANGE_COMMENTS';


export const CLEAR_USER = 'CLEAR_USER';
export const CLEAR_USER_POSTS = 'CLEAR_USER_POSTS';
export const CLEAR_PROFILE_FOLLOWERS = 'CLEAR_PROFILE_FOLLOWERS';
export const CLEAR_FOLLOWING = 'CLEAR_FOLLOWING';
export const CLEAR_FAVORITES= 'CLEAR_FAVORITES';
export const CLEAR_VISITED = 'CLEAR_VISITED';
export const CLEAR_BUCKET= 'CLEAR_BUCKET';

export const FETCH_USER_FAVS = 'FETCH_USER_FAVS';
export const ADD_TO_USER_FAVS = 'ADD_TO_USER_FAVS';
export const REMOVE_FROM_USER_FAVS = 'REMOVE_FROM_USER_FAVS';
export const CLEAR_USER_FAVS = 'CLEAR_USER_FAVS';
export const FETCH_USER_VISITS = 'FETCH_USER_VISITS';
export const ADD_TO_USER_VISITS = 'ADD_TO_USER_VISITS';
export const REMOVE_FROM_USER_VISITS = 'REMOVE_FROM_USER_VISITS';
export const CLEAR_USER_VISITS ='CLEAR_USER_VISITS';
export const FETCH_USER_BUCKET = 'FETCH_USER_BUCKET';
export const ADD_TO_USER_BUCKET = 'ADD_TO_USER_BUCKETS';
export const REMOVE_FROM_USER_BUCKET = 'REMOVE_FROM_USER_BUCKETS';
export const CLEAR_USER_BUCKET ='CLEAR_USER_BUCKETS';
export const ADD_TO_EXPLORE = 'ADD_TO_EXPLORE';
export const REMOVE_FROM_EXPLORE = 'REMOVE_FROM_EXPLORE';
export const SET_CONTROL = 'SET_CONTROL';


