import React, {useState, useEffect, useRef} from 'react';
import { Container, Grow, Grid, Typography, Button, Avatar, Card, CardMedia, CardContent, IconButton, CircularProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Posts from '../Posts/Posts';
import SignUp from '../Auth/Auth';
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

import { getLandingPosts } from '../../actions/posts';
import post from '../../reducers/post';
import LandingPost from '../Posts/Post/LandingPost';
import Whatshot from '@material-ui/icons/Whatshot';
import LandingPostMobile from '../Posts/Post/LandingPostMobile';

const useStyles = makeStyles((theme) => ({
  card: {
    width:'75%',
    marginBottom:'25px'
  },

  media: {
    paddingTop: '95%',
    width:'100%'
  },
  title: {
    fontSize:'1.35rem',
  },
  subtitle: {
    fontSize:'1rem'
  },
  textBox : {
    padding:'15px'
  }
}))

const LandingPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const contentRef = useRef();
  const posts = useSelector((state) => state.landingPosts);
  const [paused, setPaused] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [feed, setFeed] = useState(true);

  useEffect(() => {
    window.scroll(0,0);
  }, [posts])

  // useEffect(() => {
  //   dispatch(getLandingPosts());
  // }, [dispatch])

  useEffect(() => {
    let timerId;
    let retries = 0;

    if (posts.length) {
      return;
    }
  
    const fetchData = async () => {
      console.log('fired');
      if (retries < 1) {
      dispatch(getLandingPosts())
      } else if (retries < 3) {
        await dispatch(getLandingPosts())
      }
      if (retries < 3 && !posts.length) {
        timerId = setTimeout(() => {
          retries++;
          fetchData();
        }, 10000);
      }
    };
  
    fetchData();
  
    return () => {
      clearTimeout(timerId);
    };
  }, [dispatch, posts]);

  const showContent = () => {
    if (!contentRef.current) return;
    contentRef.current.scrollIntoView({behavior:'smooth'})
  }

  const handleTop = () => {
    setFeed(true);
  }

  const handleAbout = () => {
    setFeed(false);
  }



  return (
    <Grow in>
      <Container id="landingContainer">
      <Grid container justify="space-between" alignItems="stretch" style={{display:'flex', justifyContent:'center'}} >
        <Grid md={6} style={{width:'100%'}}>
          <Grid id="titleBox" item md={12} style={{display:'flex', background:'', minHeight:'660px', alignItems:'center', justifyContent:'center', flexDirection:'column', animationName: posts.length > 0 ? 'celeste' : 'celeste2', animationDuration:'2s', animationFillMode:'forwards',}}>
        <Typography variant='h5'>Welcome To</Typography>
        <Typography variant='h1' style={{fontSize:'4rem'}}>TravelAIO</Typography>
        {posts.length > 0 ? (
          <Button id="landingSignIn" component = {Link} to="/auth" variant="contained" size="large"
          style={{background:'linear-gradient(to left, #3ec7e0,#526bf4)', animationName:posts.length > 0 && 'celeste2', animationDuration:'2s', boxShadow:'none', borderRadius:'10px', color:'white', marginTop:'50px'}}>Sign In</Button>
        ) : (
        <CircularProgress style={{marginTop:'100px', color:'#526bf4'}} />
        )}   
        {posts.length > 0 && (
        <IconButton style={{animationName:posts.length > 0 && 'celeste3', animationDuration:'2s', border:'3.5px solid #333', top:'100px', ':hover':{background:'red !important', color:'white'}}} size="small" onClick={showContent}>
          <KeyboardArrowDownIcon fontSize="large" style={{color:'#333'}}/>
        </IconButton>
        )} 
        </Grid>
        {/* here */}
      {/* </Grid> */}
      {posts.length > 0 && (
      <Grid id="mainBox" ref={contentRef} container justify="space-between" alignItems="stretch" style={{display:'flex', justifyContent:'center', paddingTop:'50px', animationName:'appear', animationDuration:'2s', animationFillMode:'forwards'}} >
        <Grid id="btnBox" xs={12} sm={12} md={12} style={{display:'flex', top:'0', position:'sticky',zIndex:'1', background:'#ededed', paddingTop:'10px', paddingBottom:'10px', marginBottom:'10px', width:'100%', flexDirection:'row', alignItems:'center', justifyContent:'flex-start'}}>
        <Button size="small" variant="outlined" style={{marginLeft:'10px', background:feed && 'black', textTransform:'capitalize', color:feed ? 'white':'black', border:'2px solid black', fontWeight:'bold'}} onClick={handleTop}>Top Posts</Button>
        <Button size="small" variant="outlined" style={{marginLeft:'10px', background:!feed && 'black', textTransform:'capitalize', color:!feed ? 'white':'black', border:'2px solid black', fontWeight:'bold'}} onClick={handleAbout}>About</Button>
        <Button variant="contained" component = {Link} to="/auth"
          style={{marginLeft:'auto', marginRight:'10px', background:'#ededed', animationName:posts.length > 0 && 'celeste2', animationDuration:'2s', boxShadow:'none', textTransform:'uppercase', color:'black', textTransform:'capitalize', fontWeight:'bold'}}>Sign In</Button>
        </Grid>
          <Grid id="aboutBox" item md={12} style={{display:!feed ? 'flex' : 'none', background:'', justifyContent:'center', flexDirection:'column'}}>
        <Grid style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
          <Grid item md={12} style={{display:'flex', justifyContent:'center', padding:'10px', marginBottom:'50px'}}>
            <Typography variant='h5' style={{fontSize:'1.2rem'}}>Hello there traveler! TravelAIO is a social media platform just for travelers. Sign up today to share your travel experiences, connect with travelers around the world and explore amazing travel content.</Typography>
          </Grid>
        </Grid>
        <Grid class="contentBox" style={{display:'flex', alignItems:'center'}}>
          <Grid className={classes.textBox} item xs={12} sm={12} md={6}>
            <Typography color="primary" id="contentTitle1" className={classes.title} variant='subtitle1'>Share your content</Typography>
            <Typography id="contentSubTitle1" className={classes.subtitle} variant='subtitle2'>Share your travel experiences. You can post awesome photos, travel videos, blog articles or even your tips for other travelers.</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <img src="postpic.png" style={{width:'100%'}}></img>
          </Grid>
        </Grid>
        <Grid class="contentBox" style={{display:'flex', alignItems:'center'}}>
          <Grid className={classes.textBox} item xs={12} sm={12} md={6}>
            <Typography color="primary" id="contentTitle1" className={classes.title} variant='subtitle1'>Follow other travelers</Typography>
            <Typography id="contentSubTitle1" className={classes.subtitle} variant='subtitle2'>Follow travelers from around the world and stay updated on their journeys.</Typography>
          </Grid>
          <Grid item xs={10} sm={6} md={6}>
            <img src="travelerpics.png" style={{width:'100%'}}></img>
          </Grid>
        </Grid>
        <Grid class="contentBox" style={{display:'flex', alignItems:'center'}}>
          <Grid className={classes.textBox} item xs={12} sm={12} md={6}>
            <Typography color="primary" id="contentTitle1" className={classes.title} variant='subtitle1'>Browse Categories and Tags</Typography>
            <Typography id="contentSubTitle1" className={classes.subtitle} variant='subtitle2'>Browse your favorite categories or explore content with tags.</Typography>
          </Grid>
          <Grid item xs={10} sm={6} md={6}>
            <img src="categorypics2.png" style={{width:'100%'}}></img>
          </Grid>
        </Grid>
        <Grid class="contentBox" style={{display:'flex', alignItems:'center'}}>
          <Grid className={classes.textBox} item xs={12} sm={12} md={6}>
            <Typography color="primary" id="contentTitle1" className={classes.title} variant='subtitle1'>Explore Destinations</Typography>
            <Typography id="contentSubTitle1" className={classes.subtitle} variant='subtitle2'>Check out popular travel destinations and add them to your Favorites, Visited or Bucket.</Typography>
          </Grid>
          <Grid item xs={10} sm={6} md={6}>
            <img src="explorePics2.png" style={{width:'100%'}}></img>
          </Grid>
        </Grid>
        <Grid class="contentBox" style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
          <Grid item xs={12} sm={12} md={6} style={{display:'flex', width:'100%', justifyContent:'center'}}>
          <Button component = {Link} to="/auth" variant="contained" size="large"
          style={{background:'linear-gradient(to left, #3ec7e0,#526bf4)', animationName:posts.length > 0 && 'celeste2', animationDuration:'2s', boxShadow:'none', borderRadius:'10px', color:'white', marginTop:'30px'}}>Sign In</Button>
          </Grid>
        </Grid>        
        </Grid>
        <Grid id="topPostMobileBox" item xs={12} sm={12} md={12} style={{display:feed ? 'flex' : 'none', background:'', justifyContent:'center', flexDirection:'column'}}>
        {/* <div style={{display:'block', alignItems:'center', flexDirection:'column', maxHeight:'600px', overflowY:'auto'}}> */}
            {posts.map((post) =>
            <Grid id="mobilePostGrid" item>
            <LandingPostMobile post={post} paused={paused} setPaused={setPaused} scroll={scroll} setScroll={setScroll} />
            </Grid>
             )}
          {/* </div> */}
         </Grid>
        <Grid  item md={6} style={{display:'flex', background:'', alignItems:'center', flexDirection:'column'}}>
        </Grid>
      </Grid>
      )}
      </Grid>
      {posts.length > 0 && (
        <Grid id="topPostBox" item md={6} style={{display:'flex', animationName: posts.length > 0 && 'albi', animationDuration:'3s', animationFillMode:'forwards'}}>
          <div style={{
            position: '-webkit-sticky', position:'sticky', top:'25px'
            }}>
          <div style={{width:'75%', margin:'auto', display:'flex', alignItems:'center', flexDirection:'row', marginBottom:'5px'}}>
                    <Typography variant="subtitle1" style={{color:'black'}}>Top Posts</Typography>
                  {!scroll ? (  
                    <IconButton style={{}} onClick={()=>setScroll(true)}>
                       <PauseCircleOutlineIcon style={{color:'black'}}  />
                    </IconButton>
                  ) : (
                    <IconButton style={{}} onClick={()=>setScroll(false)}>
                      <PlayCircleOutlineIcon style={{color:'black'}} />
                    </IconButton>
                  )}
          </div>
          
          <div class={scroll ? 'nullfeed' : 'landingFeed'} style={{display:'block', alignItems:'center', flexDirection:'column', maxHeight:'600px', overflowY:'auto'}} onMouseEnter={()=>setPaused(true)} onMouseLeave={()=>setPaused(false)} onScroll={()=>setScroll(true)}>
            {posts.map((post) =>
            <LandingPost post={post} style={{}} paused={paused} setPaused={setPaused} scroll={scroll} setScroll={setScroll} />
             )}
          </div>
          </div>
        </Grid>
        )}
      </Grid>
      {posts.length > 0 && (
      <Grid 
      style={{display:'flex', flexDirection:'column', alignItems:'center', borderTop:'2px solid #ededed', paddingTop:'10px', marginTop:'35px', marginBottom:'10px'}}
      >
          <Grid item md={12} style={{}}>
          <IconButton>
            <FacebookIcon />
          </IconButton>
          <IconButton>
            <InstagramIcon />
          </IconButton>
          <Button style={{textTransform:'inherit'}} component={Link} to="/terms">Terms</Button>
          <Button style={{textTransform:'inherit'}} component={Link} to="/privacy">Privacy</Button>
          </Grid>
        </Grid>
      )}
      
      </Container>
    </Grow>
  )
}

export default LandingPage;