import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import PhoneIcon from '@material-ui/icons/Phone';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import PersonPinIcon from '@material-ui/icons/PersonPin';
// import HelpIcon from '@material-ui/icons/Help';
// import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
// import ThumbDown from '@material-ui/icons/ThumbDown';
// import ThumbUp from '@material-ui/icons/ThumbUp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Fab, Container, Grow, Grid } from '@material-ui/core';
// import Form from '../../Form/Form';
import PhotoIcon from '@material-ui/icons/Photo';
import DescriptionIcon from '@material-ui/icons/Description';
import YouTubeIcon from '@material-ui/icons/YouTube';
import CreatePhotoForm from './CreatePhotoForm';
import CreateArticleForm from './CreateArticleForm';
import CreateVideoForm from './CreateVideoForm';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding:'auto'
  },
   fab1: {
    background:'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    color:'white'
  }, 
  fab2 : {
    background: 'linear-gradient(45deg, #ee0979, #ff6a00)',
    color:'white'
  },
  fab3 : {
    background: 'linear-gradient(45deg, #11998e, #38ef7d)',
    color:'white'
  }
}));

export default function ScrollableTabsButtonForce({handleClose}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
  <Grow in>
    {/* <Container style={{backgroundColor:'blue'}}> */}
      <Grid container justify="space-between" alignItems="stretch" spacing={1}>
        <Grid item sm={1} md={2}>
          {/* <p>ohm</p> */}
        </Grid>
        <Grid item id="createGrid" sm={10} md={5} style={{background:'white', border:'2px solid #ededed', marginBottom:'30px'}} >
        <Container id="createContainer" className={classes.root}>
          <Container style={{textAlign:'center', marginBottom:"25px", paddingTop:"25px"}}>
          <Typography variant="h5">Create A Post</Typography>
          </Container>
          
      {/* <AppBar position="static" color="default"> */}
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          scrollButtons="off"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab label="Photo" style={{}} icon={<Fab className={classes.fab1} size="small" aria-label="add"><PhotoIcon /></Fab>} {...a11yProps(0)} />
          <Tab label="Video" style={{}} icon={<Fab className={classes.fab2} size="small" aria-label="add"><YouTubeIcon /></Fab>} {...a11yProps(1)} />
          <Tab label="Article" style={{}} icon={<Fab className={classes.fab3} size="small" aria-label="add"><DescriptionIcon /></Fab>} {...a11yProps(2)} />
          {/* <Tab label="Item Four" icon={<HelpIcon />} {...a11yProps(3)} />
          <Tab label="Item Five" icon={<ShoppingBasket />} {...a11yProps(4)} />
          <Tab label="Item Six" icon={<ThumbDown />} {...a11yProps(5)} />
          <Tab label="Item Seven" icon={<ThumbUp />} {...a11yProps(6)} /> */}
        </Tabs>
      {/* </AppBar> */}
      <TabPanel value={value} index={0}>
        <CreatePhotoForm handleClose = {handleClose}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CreateVideoForm handleClose = {handleClose}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CreateArticleForm handleClose = {handleClose}/>
      </TabPanel>
      {/* <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel> */}
    </Container>
        </Grid>
        <Grid item sm={1} md={2}>
          {/* <p>ohm</p> */}
        </Grid>
      </Grid>
    {/* </Container> */}
  </Grow>
  );
}
