import React, { useState } from 'react';
import { Container, Paper, Grid, Typography, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Input from '../Auth/Input';
import useStyles from '../Auth/styles';
import { confirmEmail } from '../../actions/auth';

const initialState = { email: ''}

const Email = () => {
    const [form, setForm] = useState(initialState);
    const dispatch = useDispatch();
    // const history = useHistory();
    const classes = useStyles();
  
    const handleSubmit = (e) => {
      e.preventDefault();
      
      dispatch(confirmEmail({ email:form.email }));      
        alert('Password reset link has been sent. Please check your email')
    };

    const handleChange = (e) => setForm({ ...form, email: e.target.value });

    
    return (
        <Container component="main" id="emailContainer"  maxWidth="xs">
            {/* {form.email} */}
        <Paper className={classes.paper} elevation={3}>
          <Typography component="h1" variant="h5">Enter Your Email</Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
            <Input name="email" label="Email" type='email' handleChange={handleChange} />
            </Grid>
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Confirm
            </Button>
          </form>
        </Paper>
      </Container>
    )
}

export default Email;
