import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Typography, Container, Grow, Grid } from '@material-ui/core';
// import { selectLocation } from '../../actions/locations';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import FavList from '../List/FavList';
// import VisitList from '../List/VisitList';
// import BucketList from '../List/BucketList';
import { getExploreFavs, getExploreVisits, getExploreBuckets } from '../../actions/locations';
// import { Classnames } from 'react-alice-carousel';
import useStyles from './styles';


const FavList = lazy(() => import ('../List/FavList'));
const VisitList = lazy(() => import ('../List/VisitList'));
const BucketList = lazy(() => import ('../List/BucketList'));

const Explore = () => {
    // var x = null;
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [selectedLocation, setSelectedLocation] = useState({coordinates: null, location: null, name: null, country: null});
    
    useEffect(() => {
        dispatch(getExploreFavs())
        .then(() =>
        dispatch(getExploreVisits())
        .then(() =>
        dispatch(getExploreBuckets())
        )
        )
      }, []);

    // const handleClick = () => {
    //     // x = 1;
    //     setSelectedLocation({...selectedLocation, coordinates:'25.2048493, 55.2707828', location:'Colombo,Colombo,Sri Lanka', name:'Colombo, Sri Lanka'});    
           
    // }

    // useEffect(() => {
    //     dispatch(selectLocation(selectedLocation));
    //     console.log(selectedLocation);
    //     if(selectedLocation.coordinates){
    //         history.push('/search');
    //     }
    //   }, [selectedLocation]);
    
    return (
        // <div class="exploreContainer">
        //     {/* <Button onClick={handleClick}>Click Me!</Button> */}
        //     <Suspense fallback = {<Typography>Loading...</Typography>}>
        //         <FavList />
        //     </Suspense>

        //     <Suspense fallback = {<Typography>Loading...</Typography>}>
        //         <VisitList />
        //     </Suspense>

        //     <Suspense fallback = {<Typography>Loading...</Typography>}>
        //         <BucketList />
        //     </Suspense>
        //     {/* <List />
        //     <List />
        //     <List /> */}
        // </div>
    <Grow in>
      <Container id="exploreContainer">
            <Suspense fallback = {<Typography>Loading...</Typography>}>
            <Typography variant="subtitle1" style={{fontSize:'1.75rem', color:'#333', marginLeft:'10px'}}>Favorites</Typography>
                <FavList />
            </Suspense>

            <Suspense fallback = {<Typography>Loading...</Typography>}>
            <Typography variant="subtitle1" style={{fontSize:'1.75rem', color:'#333', marginLeft:'10px'}}>Most Visited</Typography>
                <VisitList />
            </Suspense>

            <Suspense fallback = {<Typography>Loading...</Typography>}>
            <Typography variant="subtitle1" style={{fontSize:'1.75rem', color:'#333', marginLeft:'10px'}}>Top of the Bucket List</Typography>
                <BucketList />
            </Suspense>
      </Container>
    </Grow>
    )
}

export default Explore;

