import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { TextField, Grid, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import MessageIcon from '@material-ui/icons/Message';
import { v4 as uuidv4 } from 'uuid';
import { getComments, addComment } from '../../actions/comments';
import CommentCards from './CommentCards';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ postId, userId, commentCount, userStatus }) {
  const date = new Date();
  const [open, setOpen] = React.useState(false);
  const [commentData, setCommentData] = useState({id:'', postId:'', userId:'', commentBody:''});
  const commentRef = useRef();
//   setCommentData({...commentData, id: uuidv4(), postId:postId, userId: userId, commentBody:''});
  
  const dispatch = useDispatch();

//   console.log(commentData.commentBody);
  // console.log(userId);
  // console.log(postId);
  
  const Comment = () => { 
    // console.log(commentData);
    dispatch(addComment({...commentData, id: uuidv4(), postId:postId, userId: userId, createdAt: date}, postId))
    setCommentData({...commentData, commentBody:''});
  }
//   const CommentCard = ({comment}) => {
//     <Grid container xs={12} sm={12} md={12}>
//         <Grid container xs={12} sm={12} md={12}>
//             <h1>{comment?.userName}</h1>
//         </Grid>
//         <Grid container xs={12} sm={12} md={12}>
//             <h1>{comment?.commentBody}</h1>
//         </Grid>
//     </Grid> 
//   }

//   const CommentCards = () => {
//     !comments.length ? <CircularProgress /> : (
//         <Grid className={classes.container} container alignItems="stretch" spacing={3}>
//         {comments.map((comment) => (
//           <Grid key={comment.id} item xs={12} sm={12} md={12}>
//             <CommentCard comment = {comment} />
//           </Grid>
//         ))}
//       </Grid>
//     )
//   }
  

  const handleClickOpen = () => {
    setOpen(true);
    dispatch(getComments(postId)).then(() => commentRef.current.scrollIntoView({behavior:'smooth'}))
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!commentRef.current) return;
    commentRef.current.scrollIntoView({behavior:'smooth'});
  }, [commentData])

  return (
    <div>
      <IconButton size="medium" onClick={handleClickOpen} style={{color:'#526bf4'}}>
      <MessageIcon /> 
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Comments"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
              <Grid container xs ={12} sm={12} md={12} style={{marginBottom:'20px'}}>
                  <Grid item xs ={9} sm={10} md={10}>
                    {!userId ? (
                    <TextField disabled label="Sign in to comment on this post." variant="outlined" fullWidth onChange={(e) => setCommentData({ ...commentData, commentBody: e.target.value })} value={commentData.commentBody} />
                    ) : (userStatus == 'Pending') ? (
                      <TextField disabled label="Confirm your email to comment on this post." variant="outlined" fullWidth onChange={(e) => setCommentData({ ...commentData, commentBody: e.target.value })} value={commentData.commentBody} />
                      ) : (
                      <TextField label="Add a comment" variant="outlined" fullWidth onChange={(e) => setCommentData({ ...commentData, commentBody: e.target.value })} value={commentData.commentBody} />
                    )}
                  </Grid>
                  <Grid item xs ={3} sm={2} md={2} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Button variant="contained" color="primary" onClick={Comment} disabled={!userId || (userStatus == 'Pending')}>Post</Button>
                  </Grid>
              </Grid>  
              <Grid container xs ={12} sm={12} md={12} style={{maxHeight:'500px', overflowY:'scroll', overflowX:'hidden', paddingTop:'10px', paddingLeft:'10px', paddingRight:'10px', paddingBottom:'10px'}} >
            <CommentCards commentCount={commentCount} />
              </Grid>            
            
          </DialogContentText>
          <div ref={commentRef} />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
            Disagree
          </Button> */}
          <Button onClick={handleClose} size="small" variant="outlined" color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
