import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Container, Chip, Link, Grow, Grid, Typography, Button, Avatar, IconButton, CircularProgress } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import ScrollableTabsButtonForce from './ProfileTabs';
// import CustomizedTabs from './ProfileTabs2';
import { getUser } from '../../actions/user';
import { getUserPosts, getProfileFollowers } from '../../actions/user';
import UserPosts from '../Posts/UserPosts';
import { getFollowers, addToFollowing, removeFromFollowing } from '../../actions/user';
// import FavoriteCard from './FavoriteCard';
import SettingsIcon from '@material-ui/icons/Settings';
// import UserInfoDialog from './UserInfo/UserInfoDialog';
// import YouTubeIcon from '@material-ui/icons/YouTube';
import FavoriteCards from './FavoriteCards';
import VisitedCards from './VisitedCards';
import BucketCards from './BucketCards';
import FollowerCards from './FollowerCards';
import FollowingCards from './FollowingCards';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import LanguageIcon from '@material-ui/icons/Language';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LoadingScreen from '../Home/LoadingScreen';
import useStyles from './styles';
import ScrollableTabsButtonForcePC from './ProfileTabsPC';
import UserInfo from './UserInfo/UserInfo';
import { setControl } from '../../actions/locations';


// const ScrollableTabsButtonForce = lazy(() => import ('./ProfileTabs'));
const UserInfoDialog = lazy(() => import('./UserInfo/UserInfoDialog'));


const Profile = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {userId} = useParams();
  const user = useSelector(state => state.user);
  const followerState = useSelector(state => state.followers);
  const control = useSelector(state => state.control);
  const [followers, setfollowers] = useState(null);
  const currentUser = JSON.parse(localStorage.getItem('profile'));
  const currentUserId = currentUser?.result?.googleId || currentUser?.result?.id;
  const [pageLoading, setPageLoading] = useState(true);
  // const [btnLoading, setBtnLoading] = useState(true);
  const [tabState, setTabState] = useState("Posts");
  const [show, setShow] = useState(true);
  const [yPosition, setYPosition] = useState(0);
  const [widgetOpen, setWidgetOpen] = useState(false);
  const [enablePhoto, setEnablePhoto] = useState(true);
  const settingsRef = useRef();

  // const controlNav = () => {
  //   if(yPosition > window.scrollY) {
  //     setShow(true);
  //   } else {
  //     setShow(false);
  //   }
  //   setYPosition(window.scrollY);
  // }

  // useEffect(() => {
  //     window.addEventListener('scroll', controlNav);
  //   return () => {
  //     window.removeEventListener('scroll', controlNav)
  //   }
  // }, [yPosition])

//   useEffect(() => {
//     if(control === 'Settings') {
//       setTabState("Settings")
//       ramen()
//     }
// }, [userId])
  

  // console.log(userId);
  // console.log(user?.result?.id);
  // const user = '102905570188181136049'

  useEffect(() => {
    
    window.scrollTo(0, 0); 
    
    // dispatch(getUser(userId)).then
    // (()=>  
    // dispatch(getUserPosts(userId)).then
    // (()=>
    // dispatch(getFollowers(userId)), setPageLoading(false)
    //  ), setBtnLoading(false) 
    // );
    setPageLoading(true);
    // setBtnLoading(true);

    dispatch(getUser(userId))
    .then(()=>  
        dispatch(getUserPosts(userId))
    )
    .then(()=>
        dispatch(getFollowers(userId))
    )
    .then(()=>{
        setPageLoading(false)
        // setBtnLoading(false);
    })
  }, [Profile, dispatch, userId]);



  useEffect(() => {
    setPageLoading(true);
  }, [userId])

  // useEffect(() => {
  //   setBtnLoading(true);
  // }, [userId])

  useEffect(() => {
    setTabState("Posts")
  }, [userId]);
  
  useEffect(() => {
    setfollowers(followerState);
  }, [followerState]);
  
  useEffect(() => {
    if(userId !== currentUserId) return;
    if(control === 'Settings') {
      setWidgetOpen(true)
      setTabState('Settings')
      dispatch(setControl(''));
    }
}, [userId])
  

  const followUser = () => {
    if((currentUser?.result?.googleId && currentUser?.result?.googleId !== userId) || (currentUser?.result?.id && currentUser?.result?.id !== userId)) {
    setfollowers([...followers, currentUserId])
    dispatch(addToFollowing({userId : userId })).then(() => 
    dispatch(getProfileFollowers(userId)))
    // alert('foo!')
    } else {
      alert("Oops! You can't perform this action.")
    }
  }

  const unfollowUser = () => {
    setfollowers(followers.filter((follower) => follower !== currentUserId))
    dispatch(removeFromFollowing(userId)).then(() => 
    dispatch(getProfileFollowers(userId)))
  }

  const ramen = () => {
    setWidgetOpen(true)
    setTabState('Settings')
    if(settingsRef?.current) {
      settingsRef.current.scrollIntoView({behavior:'smooth'})
      }
  }

  const ProfileButton = () => {
    if(pageLoading) {
      return <CircularProgress />
    }
    if(currentUser?.result?.googleId === userId || currentUser?.result?.id === userId) {
      return <>
      <Suspense fallback = {<Typography>Loading...</Typography>}>
        {!pageLoading && (
        // <UserInfoDialog />
        <Button variant="contained" size="large"
          style={{background:'linear-gradient(to left, #3ec7e0,#526bf4)', boxShadow:'none', borderRadius:'10px', color:'white', width:'75%'}} onClick={() => setTabState("Settings")}><SettingsIcon style={{color:'white'}}/> &nbsp;Settings</Button>
        )}
      </Suspense></>
      
    } else {

    if (followers?.length > 0) {
      return followers.find((follower) => follower === (currentUser?.result?.googleId || currentUser?.result?.id))
      ? (
        <Button variant="outlined" fullWidth size="large" onClick={unfollowUser}
          style={{color:'#526bf4', border:'2px solid #526bf4', boxShadow:'none', borderRadius:'10px', width:'75%'}}>Following</Button>
      ) : (
        <Button variant="contained" fullWidth size="large" color="primary" onClick={followUser}
          style={{background:'linear-gradient(to left, #3ec7e0,#526bf4)', boxShadow:'none', borderRadius:'10px', color:'white', width:'75%'}}>Follow</Button>
      )
    }
    return <Button variant="contained" fullWidth size="large" color="primary" onClick={followUser}
          style={{background:'linear-gradient(to left, #3ec7e0,#526bf4)', boxShadow:'none', borderRadius:'10px', color:'white', width:'75%'}}>Follow</Button>
  }
}

  // useEffect(() => {
  //   dispatch(getFollowers(userId))
  // }, []);

  // const user = JSON.parse(localStorage.getItem('profile'));
  // const placesCount =  user?.result?.favoriteCount + user?.result?.visitedCount + user?.result?.bucketCount;
  if((!pageLoading) && (user?.result?.id === userId)) {
    return (
      <Grow in>
        <Container id="homeContainer">
        <Grid container style={{display:'flex'}} >
          <Grid item md={3} sm={5} xs={12} style={{display:'flex', flexDirection:'column'}}>
          <div id="profileInfo" className={classes.categoryDiv} style={{position: '-webkit-sticky', position:'sticky', top:'75px'}}>
            <Avatar src = {user?.result?.profilePic || "Avatar.png"} style={{height:'200px', width:'200px', margin:'auto', border:'4px solid lightgray', marginBottom:'10px'}}>
            </Avatar>
            {userId === currentUserId && (
            <IconButton 
            disabled={!enablePhoto}
            size='small'
            style={{top:'-60px', right:'-70px', border:'4px solid lightgrey', background:'white', marginBottom:'-50px'}} onClick={() => ramen()}>
                <CameraAltIcon />
              </IconButton>
            )}
          {/* <Grid style={{
              // backgroundColor:'blue',
               display:'flex', justifyContent:'center', alignItems:'center'}}>
            <img style={{border: '10px solid #F5F5F5', boxShadow: '0 0 5px silver', borderRadius:'50%', margin:'5px', height:'200px', width:'200px', objectFit:'cover'}} src = {user?.result?.profilePic || "Avatar.png"}></img>
          </Grid> */}
          <div style={{marginTop:(userId === currentUserId) && '15px', paddingTop:'7.5px', textAlign:'center', width:'100%', paddingLeft:'5%', paddingRight:'5%', borderTop:'1px solid #ededed', marginBottom:'5px'}}>
          <Typography variant='h6' style={{overflow: 'hidden', textOverflow: 'ellipsis'}} >{user?.result?.name}</Typography>
          </div>
          {(user?.result?.country) && (
          <Chip
                  icon={<LocationOnIcon style={{color:'white'}}/>}
                  label={user?.result?.country}
                  style={{background:'linear-gradient(to left, #3ec7e0,#526bf4)', color:'white', }}
                />
          )}
              {(user?.result?.bio) && (
                <div style={{marginTop:'15px', paddingTop:'7.5px', textAlign:'center', width:'100%', paddingLeft:'5%', paddingRight:'5%', borderTop:'1px solid #ededed'}}>
                  <Typography variant="subtitle1" style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{user?.result?.bio}</Typography>
                </div>
              )}
              <div style={{display:'flex', justifyContent:'space-evenly', width:'100%', borderTop:'1px solid #ededed', marginTop:'7.5px', paddingTop:'10px', paddingBottom:'10px', marginBottom:'5px'}}>
              <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
              <Typography variant='h6'>{user?.result?.postCount}</Typography>
              <Typography variant="subtitle2">Posts</Typography>
              </div>

              <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
              <Typography variant="h6">{followerState.length}</Typography>
              <Typography variant="subtitle2">Followers</Typography>
              </div>

              <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
              <Typography variant="h6">{user?.result?.placeCount}</Typography>
              <Typography variant="subtitle2">{user?.result?.placeCount === 1 ? 'Place' : 'Places'}</Typography>
              </div>
            </div>
            <div style={{padding:'5px', paddingTop:'10px', paddingBottom:'10px', borderTop:'1px solid #ededed', width:'100%', display:'flex', justifyContent:'center'}}>
              <ProfileButton />
            </div>
            <div style={{padding:'5px', paddingTop:'10px', paddingBottom:'10px', borderTop:'1px solid #ededed', width:'100%', display:'flex', justifyContent:'center'}}>
              {user?.result?.facebook &&
              <IconButton style={{background:'#fafafa', marginRight:'2.5px', marginLeft:'2.5px'}}>
                <FacebookIcon color="primary" style={{opacity:'1'}}  />
              </IconButton>
              // <Link href={user?.result?.facebook} style={{display:'flex', alignItems:'center', textDecoration:'none', color:'black'}}><FacebookIcon color="primary" style={{opacity:'1'}}  /></Link>
              //  : 
              //     <FacebookIcon fontSize="large" color="primary" style={{opacity:'0.5'}}  />
              }
              {user?.result?.instagram &&
              <IconButton style={{background:'#fafafa', marginRight:'2.5px', marginLeft:'2.5px'}}>
                <InstagramIcon style={{opacity:'1', color:'#fd297b'}} />
              {/* <Link href={user?.result?.instagram} style={{display:'flex', alignItems:'center', textDecoration:'none', color:'black'}}><InstagramIcon style={{opacity:'1', color:'#fd297b'}} /></Link> */}
              </IconButton>
              //  : 
              //     <InstagramIcon fontSize="large" color="secondary" style={{opacity:'0.1'}} />
               }
              {user?.result?.youtube &&
              <IconButton style={{background:'#fafafa', marginRight:'2.5px', marginLeft:'2.5px'}}>
                <PlayCircleFilledWhiteIcon color="secondary" style={{opacity:'1'}} />
              </IconButton>
              // <Link href={user?.result?.youtube} style={{display:'flex', alignItems:'center', textDecoration:'none', color:'black'}}><PlayCircleFilledWhiteIcon fontSize="large" color="secondary" style={{opacity:'1'}} /></Link>
              //  : 
              //  <PlayCircleFilledWhiteIcon fontSize="large" color="secondary" style={{opacity:'0.1'}} />
               }
              {user?.result?.website &&
              <IconButton style={{background:'#fafafa', marginRight:'2.5px', marginLeft:'2.5px'}}>
                <LanguageIcon color="primary" style={{opacity:'1'}} />
              </IconButton>
              // <Link href={user?.result?.website} style={{display:'flex', alignItems:'center', textDecoration:'none', color:'black'}}><LanguageIcon fontSize="large" color="primary" style={{opacity:'1'}} /></Link>
              // : 
              // <LanguageIcon fontSize="large" color="primary" style={{opacity:'0.1'}} />
              }
              </div>
            </div>
            {/* <div>
              <Typography>sup</Typography>
            </div> */}
            {/* <div ref={settingsRef}>

</div> */}
          </Grid>
          
          <Grid item xs={12} sm={7} md={6} style={{display:'flex', justifyContent:'flex-start', flexDirection:'column'}}>
            <div ref={settingsRef} id="profileBar"  style={{width:'100%', position:'sticky', top:show ? '60px' : 0, position:'sticky', zIndex:300}}>
              <ScrollableTabsButtonForce userId={userId} tabState={tabState} setTabState={setTabState} show={show}/>
            </div>
              {(tabState === "Posts") && (
              <UserPosts />
              )}
              {(tabState === "Favorites") && (
              <FavoriteCards userId={userId} />
              )}
              {(tabState === "Visited") && (
              <VisitedCards userId={userId} />
              )}
              {(tabState === "Bucket") && (
              <BucketCards userId={userId} />
              )}
              {(tabState === "Following") && (
              <FollowingCards />
              )}
              {(tabState === "Followers") && (
              <FollowerCards />
              )}
              {(tabState === "Settings") && (
              <UserInfo widgetOpen={widgetOpen} setWidgetOpen={setWidgetOpen} enablePhoto={enablePhoto} setEnablePhoto={setEnablePhoto} />
              )}
          </Grid>
          <Grid item md={3} style={{display:'flex', alignItems:'center', flexDirection:'column', paddingLeft:'10px'}}>
          <div id="profileBarPC" style={{width:'100%', position:'sticky', top:'75px'}}>
          <ScrollableTabsButtonForcePC id="profileBarPC" userId={userId} tabState={tabState} setTabState={setTabState} show={show}/>
          </div>
          </Grid>
        </Grid>
        </Container>
      </Grow>
    )
  } else {
    return <LoadingScreen />
  }
}

export default Profile;
