import { FETCH_TAG_POSTS, UPDATE, LIKE, DISLIKE, DELETE, ADD_COMMENT_REFRESH } from '../constants/actionTypes';

export default (tagPosts = [], action) => {
  switch (action.type) {
    case FETCH_TAG_POSTS:
      if(!action.payload.length) {
        return tagPosts  = [0];
      } else {
      return action.payload;
      }
    case LIKE:
      return tagPosts.map((post) => (post.id === action.payload.id ? action.payload : post));
    case DISLIKE:
      return tagPosts.map((post) => (post.id === action.payload.id ? action.payload : post));
    case UPDATE:
      return tagPosts.map((post) => (post.id === action.payload.id ? action.payload : post));
    case DELETE:
      return tagPosts.filter((post) => post.id !== action.payload);
    case ADD_COMMENT_REFRESH: 
      return tagPosts.map((post) => (post.id === action.payload.id ? action.payload : post));
    default:
      return tagPosts;
  }
};