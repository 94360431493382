import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import { IconButton } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { Chip, Divider, Avatar, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Fab } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs({authStatus}) {
  const [open, setOpen] = React.useState(authStatus === 'newUser' ? true : false);
   


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    localStorage.removeItem('authStatus')
    setOpen(false);
  };



  return (
    <div>
      {/* <Fab variant="contained" style={{background:'linear-gradient(to left, #3ec7e0,#526bf4)'}} onClick={handleClickOpen} fullWidth> */}
        {/* <ExploreIcon style={{color:'white'}} /> */}
        <Chip
        icon={<HelpIcon />}
        label="Find Out How TravelAIO Works"
        clickable
        color="primary"
        onClick={handleClickOpen}
        style={{background:'linear-gradient(to left, #3ec7e0,#526bf4)', height:'40px', borderRadius:'1px'}}
        // onDelete={}
        // deleteIcon={<DoneIcon />}
      />
        {/* Welcome to TravelAIO! Find out how it works */}
      {/* </Fab> */}
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Welcome to TravelAIO!
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom style={{marginBottom:'2px'}}>
            Hello there traveler! We're glad you joined TravelAIO. TravelAIO is a
            social media platform just for travel. You can share your photos, videos and articles, 
            and tag them to destinations. 
            </Typography>
            <img src="createPost.png" style={{width:'100%'}} />
            <Typography gutterBottom style={{marginBottom:'10px', marginTop:'10px'}}>
            You can follow other travelers and like
            and comment on their posts. This is a place to express yourself and share your
            journeys with others.
            </Typography>
            <img src="profile.png" style={{width:'100%'}} />
            <Typography gutterBottom style={{marginBottom:'10px', marginTop:'10px'}}>
            But TravelAIO is not just about travelers. It's about travel destinations too. You can search for 
            destinations all around the globe, add them to your <span style={{color:'#f50057', fontWeight:'bold'}}>Favorites</span>, mark them as <span style={{color:'#00a152', fontWeight:'bold'}}>Visited</span>, or add them 
            to your <span style={{color:'#FF9100', fontWeight:'bold'}}>Bucket List</span> (known simply as the Bucket).
            </Typography>
            <img src="reactions.png" style={{width:'100%'}} />
            <Typography gutterBottom style={{marginBottom:'30px', marginTop:'10px'}}>
            When you search for destinations you will be able to see all the posts travelers have tagged to that
            specific destination as well as destinations within it. (For example, if you search for
            a country, you will see all the posts tagged to that country as well as all the 
            posts tagged to any destination within that country.)
            </Typography>
            <Typography gutterBottom style={{marginBottom:'10px', marginTop:'10px', fontWeight:'bold'}}>
            How to navigate through the site:
            
            </Typography>
            <Typography gutterBottom style={{marginBottom:'10px', marginTop:'10px'}}>
            <HomeOutlinedIcon style={{marginBottom:'-5px'}} /> The home page shows posts created by travelers around the world.
            
            </Typography>
            <Typography gutterBottom style={{marginBottom:'10px', marginTop:'10px'}}>
            <SearchIcon style={{marginBottom:'-5px'}} /> The search page lets you search for destinations and users.
            </Typography>

            <Typography gutterBottom style={{marginBottom:'10px', marginTop:'10px'}}>
            <AddBoxOutlinedIcon style={{marginBottom:'-5px'}} /> Create a post and share your travel content with the world.
            </Typography>

            <Typography gutterBottom style={{marginBottom:'10px', marginTop:'10px'}}>
              <ExploreOutlinedIcon style={{marginBottom:'-5px'}} /> Visit the Explore page to discover the most popular destinations. 
            </Typography>

            <Typography gutterBottom style={{marginBottom:'10px', marginTop:'10px'}}>
              <NotificationsOutlinedIcon style={{marginBottom:'-5px'}} /> You'll get notified here when other users interact with you. 
            </Typography>

            <Typography gutterBottom style={{marginBottom:'10px', marginTop:'0px'}}>
              <AccountCircleOutlinedIcon style={{marginBottom:'-5px'}} /> Visit your Profile. To update your info, click on the <SettingsIcon fontSize="small" style={{marginBottom:'-4px', opacity:'0.8'}}/>Settings button. 
            </Typography>

            <Typography gutterBottom style={{marginBottom:'10px', marginTop:'10px'}}>
              <ExitToAppIcon style={{marginBottom:'-5px'}} /> Logout. 
            </Typography>

            <Grid style={{display:'flex', flexDirection:'row', alignItems:'center',marginTop:'0px', marginBottom:'20px'}}>
              <Avatar style={{width:'25px', height:'25px', marginTop:'-10px'}} /> 
              <Typography gutterBottom style={{marginBottom:'10px', }}>&nbsp;Click on the Avatar icon to visit your Profile or Logout. 
            </Typography>
            </Grid>

            <Typography gutterBottom>
            We hope you have a great time sharing your adventures with other travel enthusiasts! We'll be adding more and more features soon, so don't forget to stay tuned. Enjoy!
          
          </Typography>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
