import React, { useState, useEffect, lazy, Suspense, useRef, useCallback } from 'react';
import { Grid, CircularProgress, Typography, Chip } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import SuggestedTravelers from '../Home/SuggestedTravelers';
import { getPosts, getCategoryPosts } from '../../actions/posts';

// import Post from './Post/Post';
import useStyles from './styles';
const Post = lazy(() => import('./Post/Post'));

const Posts = ({ setCurrentId, postState, loadingPosts, setLoadingPosts, posts, page, setPage, user, category, setCategory, lastPostPage, travelers, travelerIds, setTravelerIds }) => {
  const dispatch = useDispatch();
  // const posts = useSelector((state) => state.posts);
  // let lastPost;
  // lastPost = posts[posts.length - 1];
  // const [page, setPage] = useState((lastPost?.pageNumber + 1) || 1);
  const [loading, setLoading] = useState(false);
  const [viewingPost, setViewingPost] = useState(0);
  const [postCount, setPostCount] = useState(0);
  const ids = posts.map(post => post.id);
  const [selectedPost, setSelectedPost] = useState();
  // console.log(ids);
  const classes = useStyles();

   useEffect(() => {
    let postStateValue;
    if(category !== '') return;
    if(!postState) {
      // alert('recen')
      setLoadingPosts(true);
      setLoading(true);
      postStateValue = 'Recent';
      dispatch(getPosts(page, postStateValue))
      .then(() => setLoading(false)).then(() => setLoadingPosts(false));

    } else {
      // alert('top')
      setLoadingPosts(true);
      setLoading(true);
      postStateValue = 'Top';
      dispatch(getPosts(page, postStateValue))
      .then(() => setLoading(false)).then(() =>  setLoadingPosts(false));

    }
  }, [page, postState]);

  useEffect(() => {
    if(category !== '') {
    setLoadingPosts(true);
    setLoading(true);
    dispatch(getCategoryPosts(page, category))
    .then(() => setLoading(false)).then(() => setLoadingPosts(false));
    console.log(category);
    // setCategory('');
    }
  }, [page, category])
  

  useEffect(() => {
    setPage(1);
  }, [user]);

  // console.log(loading);
 
  // const [node, setNode] = useState('');

const observer = useRef();
const lastPostRef = useCallback(node => {
  if(loading) return;
  if(page !== lastPostPage) return;
  if (posts?.length < 2) return;
  if(observer.current) observer.current.disconnect()
  observer.current = new IntersectionObserver(entries => {
    if (entries[0].isIntersecting) {
      setPage(page => page + 1);
    }
  })

  if(node) observer.current.observe(node)
}, [loading])

const observerx = useRef();
const postRef = useCallback(node => {
  observerx.current = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      entry.target.classList.toggle("postShow", entry.isIntersecting)
    })
  })

  if(node) 
  observerx.current.observe(node)
}, [])


// const observerx = new IntersectionObserver(entries => {
//   console.log(entries);
// })

// observerx.observe(postRef);

  return (
    !posts.length ? <CircularProgress id="homeCircular" style={{margin:'auto'}} /> : (
      <Grid className={classes.container} container alignItems="stretch" spacing={3}>
        {/* <Typography>{lastPost.pageNumber}</Typography> */}
        {posts.map((post, index) => {
          if(posts.length === index + 1) {
            return (
            <Grid ref={lastPostRef} key={post.id} item xs={12} sm={12} md={12}>
            <Suspense fallback = {<Typography>Loading...</Typography>}>
              <Post post={post} setCurrentId={setCurrentId} selectedPost={selectedPost} setSelectedPost={setSelectedPost}  />
            </Suspense>
          </Grid> )
          } else {
            if(index === 1) {
              return (
                <React.Fragment>
                  <Grid ref={postRef} key={post.id} item xs={12} sm={12} md={12} className={classes.postShow}>
                    <Suspense fallback = {<Typography>Loading...</Typography>}>
                      <Post ref={postRef} post={post} setCurrentId={setCurrentId} selectedPost={selectedPost} setSelectedPost={setSelectedPost} />
                    </Suspense>
                  </Grid>
                  
                  {travelers?.length > 0 && (
                    <div id="suggestedMobile" style={{overflowX:'scroll', background:'white', borderTop:'1px solid #ededed', borderBlock:'1px solid #ededed', marginTop:'15px', marginBottom:'15px', margin:'auto', width:'93%'}}>
                      {!travelers?.length ? null :
                      <Typography style={{color:'darkgrey', textAlign:'left', marginBottom:'-5px', marginTop:'10px', marginLeft:'11px'}}>Suggested Travelers</Typography>}
                      <div>
                        <SuggestedTravelers loadingPosts={loadingPosts} travelers={travelers} travelerIds={travelerIds} setTravelerIds={setTravelerIds} loading={loading} setLoading={setLoading} />
                      </div>
                    </div>                    
                  )}
                </React.Fragment>
              )}
            return (
          <Grid ref={postRef} key={post.id} item xs={12} sm={12} md={12} className={classes.postShow}>
            <Suspense fallback = {<Typography>Loading...</Typography>}>
              <Post ref={postRef} post={post} setCurrentId={setCurrentId} selectedPost={selectedPost} setSelectedPost={setSelectedPost} />
            </Suspense>
          </Grid>
            )
          }
      })}
      </Grid>
    )
  );
};

export default Posts;
