import React, { useState, useEffect, lazy, Suspense, useRef, useCallback } from 'react';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

// import { getPosts } from '../../actions/posts';
import { getUserPosts } from '../../actions/user';

// import Post from './Post/Post';
import useStyles from './styles';
const Post = lazy(() => import('./Post/Post'));


const UserPosts = ({ setCurrentId }) => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.userPosts);
//   const [page, setPage] = useState(1);
//   const [loading, setLoading] = useState(false);
  const ids = posts.map(post => post.id)
  console.log(ids);
  const classes = useStyles();

  if(posts.includes(0)) {
    return <Typography variant="subtitle1" style={{margin:'auto', color:'darkgray'}}>No posts to show yet.</Typography>
  }

//    useEffect(() => {
//     setLoading(true);
//     dispatch(getUserPosts())
//     .then(() => setLoading(false));
//   }, [dispatch, page]);

//   console.log(loading);

  // const [node, setNode] = useState('');

// const observer = useRef();
// const lastPostRef = useCallback(node => {
//   if(loading) return;
//   if(observer.current) observer.current.disconnect()
//   observer.current = new IntersectionObserver(entries => {
//     if (entries[0].isIntersecting) {
//       console.log('Visible');
//       setPage(page => page + 1);
//     }
//   })

//   if(node) observer.current.observe(node)

//   console.log(node);
//   console.log('cool');
  // setNode(node);
// }, [loading])

  return (
    !posts.length ? <CircularProgress style={{margin:'auto'}} /> : (
      <Grid className={classes.container} container alignItems="stretch" spacing={3} style={{background:'white', marginLeft:'auto', marginRight:'auto', marginTop:'0px', width:'100%', border:'2px solid #ededed', borderRadius:'15px', marginBottom:'10px'}}>
        {posts.map((post, index) => {
          if(posts.length === index + 1) {
            return (
            <Grid
            //  ref={lastPostRef} 
             key={post.id} item xs={12} sm={12} md={12}>
            <Suspense fallback = {<Typography>Loading...</Typography>}>
              <Post post={post} setCurrentId={setCurrentId} />
            </Suspense>
          </Grid> )
          } else {
            return (
          <Grid key={post.id} item xs={12} sm={12} md={12}>
            <Suspense fallback = {<Typography>Loading...</Typography>}>
              <Post post={post} setCurrentId={setCurrentId} />
            </Suspense>
          </Grid>
            )
          }
      })}
      </Grid>
    )
  );
};

export default UserPosts;
