import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { Button, Avatar } from '@material-ui/core';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { addToFollowing, removeFromFollowing } from '../../actions/user';


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: '#fafafa',
    
  },
  avatar : {
    height:'125px',
    width:'125px',
  },
  text : {
    whiteSpace:'pre-line',
    textAlign:'center',
    width:'95%',
  },
  button: {
    // margin:'5px',
    // marginLeft:'2px',
    // marginRight:'2px',
    width:'200px',
    height:'275px', 
    backgroundColor:'white', 
    border:'1px solid #ededed', 
    boxShadow:'0 0 5px #ededed',
    textTransform:'Capitalize', 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin:'10px',
    borderRadius:'15px'
    // justifyContent:'flex-start', 
    // borderRadius:'25px', 
    // paddingLeft:'6px',
    // paddingTop:'1px',
    // paddingBottom:'1px'
  },
}));

export default function SuggestedTravelers({loadingPosts, travelers, travelerIds, setTravelerIds, loading, setLoading}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [suggested, setSuggested] = useState(travelers.map(traveler => traveler.id));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(travelers);

  const handleFollow = (userId) => {
    if(loading === true) return;  
    setLoading(true);   
    setSuggested(suggested.filter((id) => id !== userId));
    setTravelerIds([...travelerIds, userId]);
    dispatch(addToFollowing({userId : userId})).then(() => setLoading(false));
}

const handleUnfollow = (userId) => {
  if(loading === true) return;
  setLoading(true); 
setSuggested([...suggested, userId]);
setTravelerIds(travelerIds.filter(id => id !== userId));
dispatch(removeFromFollowing(userId)).then(() => setLoading(false));
}

  return (
    <div className={classes.root}>
      <AppBar class="suggestedTravelerBar" position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          textColor="primary"
          aria-label="scrollable force tabs example"
          TabIndicatorProps={{
            style: {display: 'none'}
          }}
          sx={{
            display:'flex !important'
          }}
          style={{paddingTop:'5px', paddingBottom:'5px', backgroundColor:'white', height:'300px'}}
        >
          {!travelers?.length ? null :
          travelers.map(traveler => 
          <div className={classes.button} variant="outlined" {...a11yProps(5)} disabled={loadingPosts}>
              <Avatar className={classes.avatar} src={traveler?.profilePic ? traveler?.profilePic : null } style={{border:'', boxShadow:'0 0 2px darkGray', background:!traveler?.profilePic && 'linear-gradient(to left, #9121c2, #FF5E5E )'}}>{traveler?.firstName.charAt(0)}</Avatar>
              <div className={classes.text}>
            <Typography color="primary" component={Link} to={`/${traveler.id}`} style={{textDecoration:'none'}}>{traveler.name}</Typography>
            {traveler?.suggestedType == 'Friendly' ? (
            <Typography variant='subtitle2' style={{color:'darkgrey'}}>Followed by {traveler.followerfName}</Typography>
            ) : (
            <Typography variant='subtitle2' style={{color:'darkgrey'}}>Top User</Typography>
            )}
             </div>
  
            {(!travelerIds.includes(traveler.id)) ?
            <Button variant="outlined" color='primary' style={{textTransform:'capitalize', background:'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', border:'1px solid white', color:'white', width:'75%'}} onClick={() => handleFollow(traveler.id) }>Follow</Button>
          :
          <Button variant="outlined" color='primary' style={{textTransform:'capitalize', border:'none', background: 'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', width:'75%', webkitBackgroundClip: "text", webkitTextFillColor: "transparent", fontWeight:'bold', letterSpacing:'normal'}} onClick={() => handleUnfollow(traveler.id) }>Following</Button> 
          }
            </div>
          )}
        </Tabs>
      </AppBar>
    </div>
  );
}
