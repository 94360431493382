import React from 'react';
import { Container } from '@material-ui/core';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Navbar from './components/Navbar/Navbar';
import FixedBottomNavigation from './components/Navbar/MobileNavbar';
import FixedTopNavigation from './components/Navbar/MobileTopNav';
import Auth from './components/Auth/Auth';
import Home from './components/Home/Home';
import LandingPage from './components/Home/LandingPage';
import SearchLocationInput from './components/Search/Search';
import CreatePost from './components/Posts/CreatePost/Tabs';
import NotificationInbox from './components/Home/Notifications';
import Explore from './components/Explore/Explore';
import Profile from './components/Profile/Profile';
import NewProfile from './components/Profile/NewProfile';
import MobileTopNav from './components/Navbar/MobileTopNav';
import SinglePost from './components/Home/SinglePost';
import Verification from './components/VerifyAndReset/Verification';
import Reset from './components/VerifyAndReset/Reset';
import Terms from './components/Home/Terms';
import Email from './components/VerifyAndReset/Email';
import SearchUserInput from './components/Search/SearchUsers';
import SearchTagInput from './components/Search/SearchTags';
import Privacy from './components/Home/Privacy';
import '@fontsource/source-sans-pro';

const user = JSON.parse(localStorage.getItem('profile'));
const userStatus1 = user?.result?.status;
// const userId = user?.result?.googleId || user?.result?.id;
// const postId = '5710de00-90bc-490e-9651-fb6f1affdc26';

const App = () => {
  const userStatus2 = useSelector(state => state?.auth?.authData?.result?.status);
  
  // const Root = () => {
  //   if (userStatus1 == 'Active' || userStatus2 == 'Active') {
  //     return <Home />;
  //   } else {
  //     return <LandingPage />;
  //   }
  // };
  
return (
  <BrowserRouter>
      <Navbar userStatus1={userStatus1} userStatus2={userStatus2} />
            
      <Switch>
        {/* <Route path="/" exact component={Root} /> */}
        <Route path="/" exact render={() => (userStatus1 === 'Active' || userStatus2 === 'Active') ? <Home /> : <LandingPage />} />
        {/* <Route path="/notifications" exact component={NotificationInbox} /> */}
        
        {
        (userStatus1 == 'Pending' && userStatus2 == 'Pending') ? 
          <Route path="/auth">
            <Redirect to = "/account/verify/unverified" />
          </Route> :
          (userStatus1 == 'Active' || userStatus2== 'Active') ?
          <Route path="/auth">
            <Redirect to = "/" />
          </Route> : 
          <Route path="/auth" exact component={Auth} />
          }

        {(userStatus1 == 'Pending' && userStatus2 == 'Pending') ? 
          <Route path="/create">
            <Redirect to = "/account/verify/unverified" />
          </Route> : 
          (userStatus1 == 'Active' || userStatus2== 'Active') ?
          <Route path="/create" exact component = {CreatePost}>
          </Route> : 
          <Route path="/create">
          <Redirect to = "/auth" />
          </Route>}

         {(userStatus1 !== 'Active' && userStatus2 == 'Pending') || (userStatus1 == 'Pending' && userStatus2 !== 'Active') || (userStatus1 == 'Pending' && userStatus2 == 'Pending') ? 
          <Route path="/search">
            <Redirect to = "/account/verify/unverified" />
          </Route> : 
          (userStatus1 == 'Active' || userStatus2== 'Active') ?
          <Route path="/search" exact component = {SearchLocationInput}>
          </Route> : 
          <Route path="/search">
           <Redirect to = "/auth" />
          </Route>}

          {(userStatus1 !== 'Active' && userStatus2 == 'Pending') || (userStatus1 == 'Pending' && userStatus2 !== 'Active') || (userStatus1 == 'Pending' && userStatus2 == 'Pending') ? 
          <Route path="/search/users">
            <Redirect to = "/account/verify/unverified" />
          </Route> : 
          (userStatus1 == 'Active' || userStatus2== 'Active') ?
          <Route path="/search/users" exact component = {SearchUserInput}>
          </Route> : 
          <Route path="/search/users">
           <Redirect to = "/auth" />
          </Route>}

          {(userStatus1 !== 'Active' && userStatus2 == 'Pending') || (userStatus1 == 'Pending' && userStatus2 !== 'Active') || (userStatus1 == 'Pending' && userStatus2 == 'Pending') ? 
          <Route path="/search/tags">
            <Redirect to = "/account/verify/unverified" />
          </Route> : 
          (userStatus1 == 'Active' || userStatus2== 'Active') ?
          <Route path="/search/tags" exact component = {SearchTagInput}>
          </Route> : 
          <Route path="/search/tags">
           <Redirect to = "/auth" />
          </Route>}

          {(userStatus1 !== 'Active' && userStatus2 == 'Pending') || (userStatus1 == 'Pending' && userStatus2 !== 'Active') || (userStatus1 == 'Pending' && userStatus2 == 'Pending') ? 
          <Route path="/explore">
            <Redirect to = "/account/verify/unverified" />
          </Route> : 
          (userStatus1 == 'Active' || userStatus2== 'Active') ?
          <Route path="/explore" exact component = {Explore}>
          </Route> : 
          <Route path="/explore">
           <Redirect to = "/auth" />
          </Route>}

        {/* <Route path="/search" exact component={SearchLocationInput} /> */}
        {/* <Route path="/create" exact component={CreatePost} /> */}
        {/* <Route path = "/explore" exact component={Explore} /> */}

        <Route path = "/account/verify/:token" exact component = {Verification} />
        <Route path = "/account/reset/password/:tempToken" exact component = {Reset} />
        <Route path = "/account/reset/email" exact component = {Email} />

        <Route path="/terms" exact component={Terms} />
        <Route path="/privacy" exact component={Privacy} />
        
        <Route path = "/posts/post/:postId" exact component={SinglePost} />
        {/* <Route path = "/:userId" exact component={Profile} /> */}
        {(userStatus1 !== 'Active' && userStatus2 == 'Pending') || (userStatus1 == 'Pending' && userStatus2 !== 'Active') || (userStatus1 == 'Pending' && userStatus2 == 'Pending') ? 
          <Route path="/:userId">
            <Redirect to = "/account/verify/unverified" />
          </Route> : 
          (userStatus1 == 'Active' || userStatus2== 'Active') ?
          <Route path="/:userId" exact component = {Profile}>
          </Route> : 
          <Route path="/:userId">
           <Redirect to = "/auth" />
          </Route>}

        
      </Switch>
      {/* <FixedTopNavigation /> */}
      <MobileTopNav userStatus1={userStatus1} userStatus2={userStatus2} />
      <FixedBottomNavigation style={{}} userStatus1={userStatus1} userStatus2={userStatus2} />
    {/* </Container> */}
  </BrowserRouter>
  )
};

export default App;
