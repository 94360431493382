import React , { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Container, Grid, Typography, Avatar, IconButton } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import useStyles from './styles';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { getUserFavorites } from '../../actions/user';
import { addToUserFavorites, removeFromUserFavorites } from '../../actions/favorites';
import { addToUserVisited, removeFromUserVisited } from '../../actions/favorites';
import { addToUserBucket, removeFromUserBucket } from '../../actions/favorites';
import { selectLocation } from '../../actions/locations';
import { useHistory } from 'react-router-dom';

const FavoriteCard = ({favorite, userId, canReact, setCanReact}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [favs, setFavs] = useState(favorite.favs);
    const [visits, setVisits] = useState(favorite.visits);
    const [buckets, setBuckets] = useState(favorite.buckets);
    const [placeDetails, setPlaceDetails] = useState({latitude: favorite.latitude, longitude: favorite.longitude, coordinates: favorite.coordinates, location: favorite.location, name:favorite.name, placeName:favorite.placeName, country: favorite.country});
    const user = JSON.parse(localStorage.getItem('profile'));
    const currentUserId = user?.result?.googleId || user?.result?.id;
    const [selectedLocation, setSelectedLocation] = useState({latitude: '', longitude: '', coordinates: '', location: '', name:'', placeName:'', country: ''});
    
    const handleClick = () => {
      setSelectedLocation({...selectedLocation, latitude: `${favorite.latitude}`, longitude: `${favorite.longitude}`, coordinates:`${favorite.coordinates}`, location:`${favorite.location}`, name:`${favorite.name}`, placeName:`${favorite.placeName}`, country:`${favorite.country}` });  
  } 
    useEffect(() => {
      if(selectedLocation.coordinates !== '') {
        dispatch(selectLocation(selectedLocation))
      }
        // console.log(selectedLocation);
        if(selectedLocation.coordinates){
            history.push('/search');
        }
      }, [handleClick]);

      useEffect(() => {
        setFavs(favorite.favs);
        setVisits(favorite.visits);
        setBuckets(favorite.buckets);
        setPlaceDetails({latitude: favorite.latitude, longitude: favorite.longitude, coordinates: favorite.coordinates, location: favorite.location, name:favorite.name, placeName:favorite.placeName, country: favorite.country})
      }, [favorite]);


    const addToFavs = () => {
      setCanReact(false);
      setFavs([...favs, currentUserId ])
      dispatch(addToUserFavorites(placeDetails)).then(() =>
      dispatch(getUserFavorites(userId)).then(() => (
        setCanReact(true)
      ))
      )
    }
    const removeFromFavs = () => {
      setCanReact(false);
      setFavs(favs.filter((fav) => fav !== currentUserId));
      dispatch(removeFromUserFavorites(placeDetails.coordinates)).then(() =>
      dispatch(getUserFavorites(userId)).then(() => (
        setCanReact(true)
      ))
      )
    }

    const addToVisits = () => {
      setCanReact(false);
      setVisits([...visits, currentUserId ])
      dispatch(addToUserVisited(placeDetails)).then(() =>
      dispatch(getUserFavorites(userId)).then(() => (
        setCanReact(true)
      ))
      )
    }
    const removeFromVisits = () => {
      setCanReact(false);
      setVisits(visits.filter((visit) => visit !== currentUserId));
      dispatch(removeFromUserVisited(placeDetails.coordinates)).then(() =>
      dispatch(getUserFavorites(userId)).then(() => (
        setCanReact(true)
      ))
      )
    }
  
    const addToBuckets = () => {
      setCanReact(false);
      setBuckets([...buckets, currentUserId]);
      dispatch(addToUserBucket(placeDetails)).then(() =>
      dispatch(getUserFavorites(userId)).then(() => (
        setCanReact(true)
      ))
      )
    }
    const removeFromBuckets = () => {
      setCanReact(false);
      setBuckets(buckets.filter((bucket) => bucket !== currentUserId));
      dispatch(removeFromUserBucket(placeDetails.coordinates)).then(() =>
      dispatch(getUserFavorites(userId)).then(() => (
        setCanReact(true)
      ))
      )
    }
    
    const Fav = () => {
      if(favs) {
      if(favs.length > 0) {
        return favs.find((fav) => fav === (currentUserId))
        ? (
          <>
          <IconButton size="small" style={{background:'#f7f7f7', marginRight:'3px', border:'1px solid #ededed'}} onClick={removeFromFavs} disabled = {canReact === false}>
            <FavoriteIcon style={{color:'#fd297b'}} />
            {/* #22c960 */}
          </IconButton>
          {/* <Typography variant="subtitle1">{favs.length}</Typography> */}
          </>
        ) : (
          <>
          <IconButton size="small" style={{background:'#f7f7f7', marginRight:'3px', border:'1px solid #ededed'}} onClick={addToFavs} disabled = {canReact === false}>
            <FavoriteBorder style={{color:canReact && '#333'}} />
            </IconButton>
          {/* <Typography variant="subtitle1">{favs.length}</Typography> */}
          </>
        )
      } }
      return  <IconButton size="small" style={{background:'#f7f7f7', marginRight:'3px', border:'1px solid #ededed'}} onClick={addToFavs} disabled = {canReact === false}>
      <FavoriteBorder style={{color:canReact && '#333'}} />
      </IconButton>
    }

    const Visited = () => {
      if(visits) {
      if(visits?.length > 0) {
        return visits.find((visit) => visit === (currentUserId))
        ? (
          <>
          <IconButton size="small" style={{marginLeft:'10px', marginRight:'3px', background:'#f7f7f7', border:'1px solid #ededed'}} onClick={removeFromVisits} disabled = {canReact === false}>
            <CheckCircleIcon style={{color:'#22c960'}} />
          </IconButton>
          {/* <Typography variant="subtitle1">{visits.length}</Typography> */}
          </>
        ) : (
          <>
          <IconButton size="small" style={{marginLeft:'10px', marginRight:'3px', background:'#f7f7f7', border:'1px solid #ededed'}} onClick={addToVisits} disabled = {canReact === false}>
            <CheckCircleOutline style={{color:canReact && '#333'}} />
          </IconButton>
          {/* <Typography variant="subtitle2">{visits.length}</Typography> */}
          </>
        )
      } }
      return <IconButton size="small" style={{marginLeft:'10px', marginRight:'3px', background:'#f7f7f7', border:'1px solid #ededed'}} onClick={addToVisits} disabled = {canReact === false}>
      <CheckCircleOutline style={{color:canReact && '#333'}} />
      </IconButton> 
    }

    const Bucket = () => {
      if(buckets) {
      if(buckets?.length > 0) {
        return buckets.find((bucket) => bucket === (user?.result?.googleId || user?.result?.id))
        ? (
          <>
          <IconButton size="small" style={{marginLeft:'10px', marginRight:'3px', background:'#f7f7f7', border:'1px solid #ededed'}} onClick={removeFromBuckets} disabled = {canReact === false}>
            <img src="BucketIcon.png" style={{maxWidth:'25px'}} />
            </IconButton>
            {/* <Typography variant="subtitle1">{buckets.length}</Typography> */}
            </>
        ) : (
          // dispatch(addToBucket(topLocation.coordinates ? topLocation : placeDetails))}><BookmarkBorderIcon fontSize="large" /></IconButton>
          // <><IconButton className={classes.favorite} size="small" aria-label="add" onClick={addToBuckets}><BookmarkBorderIcon fontSize="large" /></IconButton>
          <><IconButton size="small" style={{marginLeft:'10px', marginRight:'3px', background:'#f7f7f7', border:'1px solid #ededed'}} onClick={addToBuckets} disabled = {canReact === false}>
          {canReact ? <img src="BucketBorderIcon.png" style={{maxWidth:'25px'}} /> : <img src="BucketBorderIconDisabled.png" style={{maxWidth:'25px'}} /> }
          </IconButton>
          {/* {/* <Typography variant="subtitle1">{buckets.length}</Typography> */}
          </>
        )
      } }
      // return <IconButton className={classes.favorite} size="small" aria-label="add" onClick={addToBuckets}><BookmarkBorderIcon fontSize="large" /></IconButton>  
      return <IconButton size="small" style={{marginLeft:'10px', marginRight:'3px', background:'#f7f7f7', border:'1px solid #ededed'}} onClick={addToBuckets} disabled = {canReact === false}>
      {canReact ? <img src="BucketBorderIcon.png" style={{maxWidth:'25px'}} /> : <img src="BucketBorderIconDisabled.png" style={{maxWidth:'25px'}} /> }
      </IconButton>
    
    }

    return (
        // <Container>
            
        //     <Grid container style={{display:'flex', justifyContent:'center'}}>
        //         <Grid item xs={12} sm={12} md={12}>
        //             <Card class="profileCard" style={{display:'flex', flexDirection:'column', 
        //             // boxShadow:'0 0 8px silver',
        //              border:'2px solid #ededed',
        //             // backgroundColor:'purple',
        //              paddingTop:'2px', paddingBottom:'2px'}}>
        //                 <Grid item xs={12} sm={12} md={12} style={{
        //                     // backgroundColor:'#EDEDED',
        //                     borderBottom:'2px solid #FAFAFA',
        //                     padding:'15px', paddingBottom:'10px', display:'flex', flexDirection:'row', justifyContent:'', alignItems:'center'
        //                     //  textAlign:'center'
        //                      }}>
        //                         <LocationOnIcon fontSize = "medium" color="primary"/>
        //                         <Typography variant="subtitle1">{favorite.name}</Typography>
        //                         {favorite.name !== favorite.country && (
        //                         <Typography variant="subtitle1" style={{color:'#818181'}}>&nbsp;&#183;&nbsp;{favorite.country}</Typography>       
        //                         )}
        //                 </Grid>
        //                 <Grid item xs={12} sm={12} md={12} style={{backgroundColor:'', display:'flex', flexDirection:'row', padding:'10px'}}>
        //                 <Grid item style={{
        //                     // backgroundColor:'pink', 
        //                     display:'flex', flexDirection:'row', alignItems:'center',justifyContent:'center', marginLeft:'5px'}}>
        //                     <FavoriteIcon fontSize = "medium" color="secondary" />
        //                 <Typography variant="subtitle1" style={{marginLeft:'5px', fontWeight:''}}>{favorite.favCount ? favorite.favCount : 0}</Typography>
        //                 </Grid>
        //                 <Grid item style={{
        //                     // backgroundColor:'pink', 
        //                     display:'flex', flexDirection:'row', alignItems:'center',justifyContent:'center', marginLeft:'10px'}}>
        //                     <CheckCircleIcon fontSize = "medium" style={{color:'#5cb85c'}} />
        //                 <Typography variant="subtitle1" style={{marginLeft:'5px', fontWeight:''}}>{favorite.visitCount ? favorite.visitCount : 0}</Typography>
        //                 </Grid>
        //                 <Grid item style={{
        //                     // backgroundColor:'pink', 
        //                     display:'flex', flexDirection:'row', alignItems:'center',justifyContent:'center', marginLeft:'10px'}}>
        //                     {/* <BookmarkIcon fontSize = "medium" style={{color:'#f0ad4e'}} /> */}
        //                     <img src="BucketIcon.png" style={{maxWidth:'25px'}} />
        //                     <Typography variant="subtitle1" style={{marginLeft:'5px', fontWeight:''}}>{favorite.bucketCount ? favorite.bucketCount : 0}</Typography>
        //                 </Grid>
        //                 </Grid>                       
        //             </Card>
        //         </Grid>
        //     </Grid>
            
        // </Container>
        <div className={classes.button} variant="outlined" style={{justifyContent:'flex-start'}}>
            <Avatar className={classes.locPic} src={favorite?.locationImage || favorite?.locPic || 'Urban.jpeg'}></Avatar>
            <div className={classes.content}>
            <div className={classes.locationText}>
            <Typography variant='subtitle1' color="primary" style={{overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap', textDecoration:'none', cursor:'pointer'}} onClick={handleClick}>{favorite?.name}</Typography>
            {/* {favorite.name !== favorite.country && (
            <Typography variant="subtitle2" style={{color:'#818181'}}>&nbsp;&#183;&nbsp;{favorite.country}</Typography>       
            )} */}
            {/* {traveler?.suggestedType == 'Friendly' ? (
            <Typography variant='subtitle2' style={{color:'darkgrey', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>Followed by {traveler.followerfName}</Typography>
            ) : (
            <Typography variant='subtitle2' style={{color:'darkgrey', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>Top User</Typography>
            )} */}
            </div>
            {/* <Typography variant="subtitle2" style={{marginLeft:'10px', color:'silver'}}>{favorite?.locPostCount} {favorite?.locPostCount === 1 ? 'Post' : 'Posts'}</Typography> */}
            {favorite.name !== favorite.country && (
            <Typography variant="subtitle1" style={{color:'silver', marginLeft:'10px', marginTop:'-5px'}}>{favorite.country}</Typography>       
            )}
            <Typography  variant="subtitle2" style={{color:'silver', marginLeft:'10px', marginTop:'-5px'}}>{favorite.locPostCount} {favorite?.locPostCount === 1 ? 'Post' : 'Posts'}</Typography>
            <div className={classes.reacts}>
            {/* <IconButton size="small" style={{background:'#f7f7f7', marginRight:'3px', border:'1px solid #ededed'}}>
            <FavoriteBorder style={{color:'#333'}} />
            </IconButton> */}
            <Fav />
            <Typography>{favs?.length ? favs.length : 0}</Typography>
            <Visited />
            <Typography>{visits?.length ? visits.length : 0}</Typography>
            <Bucket />
            <Typography>{buckets?.length ? buckets.length : 0}</Typography>
            
            </div>
            </div>
            {/* {(!userFollowing?.includes(traveler.id)) ? 
            <Button variant="outlined" color='primary' size="small" style={{textTransform:'capitalize', background:'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', border:'1px solid white', color:'white'}} onClick={() => handleFollow(traveler?.id) }>Follow</Button>
          :
          <Button variant="outlined" color='primary' size="small" style={{textTransform:'capitalize', border:'none', background: 'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', webkitBackgroundClip: "text", webkitTextFillColor: "transparent", fontWeight:'bold', letterSpacing:'normal'}} onClick={() => handleUnfollow(traveler?.id) }>Following</Button> 
          } */}
          {/* <Following /> */}
          {/* {(suggested.find(id => id === traveler.id)) ?
            <Button variant="outlined" color='primary' size="small" style={{textTransform:'capitalize', background:'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', border:'1px solid white', color:'white'}} onClick={() => handleFollow(traveler.id) }>2 Follow</Button>
          :
          <Button variant="outlined" color='primary' size="small" style={{textTransform:'capitalize', border:'none', background: 'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', webkitBackgroundClip: "text", webkitTextFillColor: "transparent", fontWeight:'bold', letterSpacing:'normal'}} onClick={() => handleUnfollow(traveler.id) }>2 Following</Button> 
          } */}
            </div>
    )
}

export default FavoriteCard
