import React, { useState, useEffect, useRef} from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Container, Button, Typography, Grid } from '@material-ui/core/';
import SearchIcon from '@material-ui/icons/Search';
import UserResults from "./UserResults";
import { useDispatch } from 'react-redux';
import useStyles from './styles';
import { getUsers } from "../../actions/user";

const SearchUserInput = () => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const searchRef = useRef();

  const dispatch = useDispatch();
  const classes = useStyles();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [SearchUserInput, dispatch]);

  const [query, setQuery] = useState("");
  const [showResults, setShowResults] = useState(false);

const handleSearchBar = () => {
  var input = document.getElementById("searchbar");
  input.addListener("keyup", function(event) {
      if (event.KeyboardEvent.code === 13) {
          event.preventDefault();
          document.getElementById("searchbar").click();
      }
  });
}

const handleSubmit = () => {
    if(query !== "") {
      dispatch(getUsers(query))
      setShowResults(true)
      searchRef.current.scrollIntoView({behavior:'smooth'})
    }
}

  return (
    <Container id="searchContainer">
    <Grid id="gridImage" className="search-location-input" style={{ backgroundSize:'cover', backgroundPosition:'30%', display:"flex", flexDirection:'column', justifyContent:'center',  alignItems:'center', height:'450px', margin:'0px'}}>
    <div class="searchDiv">
      <SearchIcon style={{backgroundColor:'white', borderTopLeftRadius: '25px', borderBottomLeftRadius: '25px', padding:'12px', fontSize:'2.8rem'}}/>
        <input
        class="pac-target-input"
        onChange={event => setQuery(event.target.value)}
        placeholder="Search For Users"
        value={query}
        onLoad = {handleSearchBar}
      />
    
      <Button variant="contained" color="primary" style={{borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', borderTopRightRadius: '25px', borderBottomRightRadius: '25px', padding:'10.5px', fontSize:'0.85rem', marginLeft:'-30px', textTransform:'inherit'}} onClick={handleSubmit}>Search</Button>
      </div>
    <div class="searchBtns">
    {/* <Button size="small" variant="outlined" component={Link} to="/search" color="primary" style={{margin:'10px', marginLeft:0, textTransform:'inherit'}}>
        Search For Destinations
    </Button>    
    <Button size="small" variant="contained" component={Link} to="/search/users" color="primary" style={{margin:'10px', textTransform:'inherit'}}>
        Searching For Users
    </Button> */}
    <Button size="small" component={Link} to="/search" style={{margin:'10px', marginLeft:0, textTransform:'inherit', color:'#333', color:'white', backgroundColor:'rgba(0,0,0,0.4)'}}>
        Destinations
    </Button>    
    <Typography style={{color:'white'}}> | </Typography>
    <Button size="small" component={Link} to="/search/users" style={{margin:'10px', textTransform:'inherit', color:'white', backgroundColor:'rgba(0,0,0,0.7)',  border:'2px solid #ededed', padding:'7px'}}>
        Users
    </Button>
    <Typography style={{color:'white'}}> | </Typography>
    <Button size="small" component={Link} to="/search/tags" style={{margin:'10px', textTransform:'inherit', color:'white', backgroundColor:'rgba(0,0,0,0.4)'}}>
        Tags
    </Button>
    </div>
    </Grid>
    <Typography>{showResults}</Typography>
        <Grid container>
          <Grid container className={classes.titleBar}>
          <Grid item id="searchTitle" className={classes.title}  style={{}} >
            
          </Grid>
        </Grid>
        </Grid>
        <Container style={{display:'flex', justifyContent:'center'}}>        
        {/* <div ref = {searchRef}></div> */}
          {showResults && (
            // <>
          <UserResults />
          // </>
          )}
        </Container>
        <div ref = {searchRef}></div>
    </Container>
  );
}

export default SearchUserInput;