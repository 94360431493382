import { FETCH_ALL, FETCH_LANDING, CREATE, UPDATE, DELETE, LIKE, DISLIKE, FETCH_LOCATION_POSTS, CLEAR_LOCATION_POSTS, FETCH_TAG_POSTS, GET_LIKES, FETCH_ONE, GET_LOCATION, GET_FAVS, GET_VISITED, GET_BUCKET, SELECT_TAG, CLEAR_LANDING, CLEAR_FAVORITES, CLEAR_VISITED, CLEAR_BUCKET } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const getPosts = (page, postStateValue) => async (dispatch) => {
  try {
    const { data } = await api.fetchPosts(page, postStateValue);
    // console.log(data);
    // alert(postStateValue)
    dispatch({ type: CLEAR_LANDING});
    dispatch({ type: FETCH_ALL, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getLandingPosts = () => async (dispatch) => {
  try {
    const { data } = await api.fetchLandingPosts();
    console.log(data);
    dispatch({ type: FETCH_LANDING, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getCategoryPosts = (page, category) => async (dispatch) => {
  // console.log(category);
  try {
    const { data } = await api.fetchCategoryPosts(page, category);
    console.log(data);
    // alert(postStateValue)

    dispatch({ type: FETCH_ALL, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getPost = (id) => async (dispatch) => {
  try {
    const { data } = await api.fetchPost(id);
    // console.log(data);
    // console.log(id);

    dispatch({ type: FETCH_ONE, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getTagPosts = (tag) => async (dispatch) => {
  try {
    const { data } = await api.getTagPosts(tag);
    console.log(data);
    // alert(postStateValue)

    dispatch({ type: FETCH_TAG_POSTS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

// export const getUserPosts = (user) => async (dispatch) => {
//   console.log(user);
//   try {
//     const { data } = await api.getUserPosts(user);
//     console.log(data);

//     dispatch({ type: FETCH_USER_POSTS, payload: data });
//   } catch (error) {
//     console.log(error);
//   }
// };

export const getLocation = (location) => async(dispatch) => {
  try {
    dispatch({ type: CLEAR_LOCATION_POSTS });
    dispatch({ type: CLEAR_FAVORITES });
    dispatch({ type: CLEAR_VISITED });
    dispatch({ type: CLEAR_BUCKET });
    
    const { data } = await api.getLocation(location);
  
    dispatch({ type: GET_FAVS, payload: data.favs});
    dispatch({ type: GET_VISITED, payload: data.visits});
    dispatch({ type: GET_BUCKET, payload: data.buckets});
  } catch (error) {
    console.log(error);
  }
}

export const getLocationPosts = (location) => async(dispatch) => {
  // console.log(location);
  try {
    
    const { data } = await api.getLocationPosts(location);
    // console.log(data);
    dispatch({ type: FETCH_LOCATION_POSTS, payload: data});
    
  } catch (error) {
    console.log(error);
  }
}

export const createPost = (post, visited, router) => async (dispatch) => {
  try {
    const { data } = await api.createPost(post);

    if(visited) {
    await api.addToVisited(visited);
    } 

    dispatch({ type: CREATE, payload: data });

    router.push('/');
    window.location.reload();
  } catch (error) {
    console.log(error);
  }
};

export const updatePost = (id, post) => async (dispatch) => {
  try {
    const { data } = await api.updatePost(id, post);
    

    dispatch({ type: UPDATE, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const likePost = (id) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem('profile'));

  try {
    const { data } = await api.likePost(id, user?.token);

    dispatch({ type: LIKE, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const dislikePost = (id) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem('profile'));

  try {
    const { data } = await api.dislikePost(id, user?.token);
    // console.log(data);

    dispatch({ type: DISLIKE, payload: data});
  } catch (error) {
    console.log(error);
  }
};

export const deletePost = (id) => async (dispatch) => {
  try {
   const {data} = await api.deletePost(id);
  //  console.log(data);

    dispatch({ type: DELETE, payload: id });
  } catch (error) {
    console.log(error);
  }
};

export const getLikes = (id) => async (dispatch) => {
  try {
    const { data } = await api.getLikes(id);
    // console.log(data);

    dispatch({ type: GET_LIKES, payload: data });
  } catch (error) {
    console.log(error);
  }
}

export const selectTag = (data) => async (dispatch) => {
  try {
    console.log(data);
    dispatch({ type: SELECT_TAG, payload: data });
  } catch (error) {
    console.log(error);
  }
};
