import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme)=>({
  media: {
    // height: 0,
    paddingTop: '95%',
    width:'100%'
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    // backgroundBlendMode: 'darken',
  },
  border: {
    border: 'solid',
  },
  fullHeightCard: {
    height: '100%',
  },
  card: {
    borderBottomLeftRadius:'25px',
    borderBottomRightRadius:'25px'
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    // borderRadius: '25px',
    // height: '100%',
    // position: 'relative',
    // backgroundColor:'#FAFAFA'
  },
  overlay: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    color: 'white',
    
  },
  overlay2: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    color: 'white',
  },
  grid: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px',
  },
  title: {
    padding: '0 16px',
  },
  section : {
    marginTop:'5px',
    marginBottom:'10px'
  },
  tagTitles : {
    color:'darkgrey',
    borderTop : '1px solid #ededed',
    paddingTop : '5px',
    display:'flex',
    alignItems:'center'
  },
  cardActions: {
    padding: '0 16px 8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  showingMore: {
    // transform: 'rotate(180deg)',
    // transitionDelay:'5s',
    opacity:'0',
    marginRight:'-150px'
    // transition:'ease-in-out 1.5s'
  }, 
  notShowingMore: {
    transform: 'rotate(0deg)',
    transitionDelay:'0.5s',
    opacity:'1',
    transition:'ease-in-out 0.5s'
  }, 
  landingCard: {
    width:'75%',
    marginBottom:'25px',
    borderBottomLeftRadius:'25px',
    borderBottomRightRadius:'25px',
    borderTopLeftRadius:'25px',
    borderTopRightRadius:'25px',
    position:'relative',
    margin:'auto',
    animationName:'example', 
    animationDuration:'240s', 
    animationTimingFunction:'linear', animationDelay:'1s', 
    animationFillMode:'forwards',
    animationIterationCount:'infinite'
  },

  landingCardMobile: {
    width:'100%',
    marginBottom:'25px',
    borderBottomLeftRadius:'25px',
    borderBottomRightRadius:'25px',
    // borderTopLeftRadius:'25px',
    // borderTopRightRadius:'25px',
    position:'relative',
    margin:'auto',
  },

  landingMedia: {
    paddingTop: '95%',
    width:'100%'
  }
}));
