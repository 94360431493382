import {CLEAR_FOLLOWING, GET_FOLLOWING} from '../constants/actionTypes';

export default (following = [], action) => {
  switch (action.type) {
    case GET_FOLLOWING:
      if(!action.payload.length) {
        return following = [0];
      } else {
      return action.payload;
      }
    case CLEAR_FOLLOWING: {
      return following = [];
    }
    default:
      return following;
  }
};