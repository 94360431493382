import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import ReactDOM from 'react-dom';
import MagicBell, { FloatingNotificationInbox } from '@magicbell/magicbell-react';


const NotificationInbox = () => {
  // const [user, setuser] = useState(JSON.parse(localStorage.getItem('profile')));
const user = JSON.parse(localStorage.getItem('profile'));
const userId = user?.result?.googleId || user?.result?.id;
const hmac = user?.data?.hmac;

    return (
        <MagicBell apiKey={"9911446f6cc705b1f4f623cb213c4364e404a130"} userExternalId= {userId} userKey= {hmac}
        theme={{ 
          icon: { borderColor: '#333'}
        }}
        >
          {(props) => <FloatingNotificationInbox 
             height={600}
             placement="bottom-end"
              {...props} 
              />}
             
        </MagicBell>
    )
}

export default NotificationInbox;
