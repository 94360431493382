import { FETCH_LANDING, CLEAR_LANDING } from '../constants/actionTypes';

export default (landingPosts = [], action) => {
  switch (action.type) {
    case FETCH_LANDING:
      return action.payload;
    case CLEAR_LANDING:
      return landingPosts = [];
    default:
      return landingPosts;
  }
};

