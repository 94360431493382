import {CLEAR_USER, FETCH_USER, UPDATE_USER } from '../constants/actionTypes';

export default (user = [], action) => {
  switch (action.type) {
    case FETCH_USER:
      return action.payload;
    case CLEAR_USER:
      return user = [];
    case UPDATE_USER:
      const item = JSON.parse(localStorage.getItem('profile'));
      item.result.firstName = action.payload.firstName;
      item.result.familyName = action.payload.lastName;
      item.result.name = action.payload.name;
      item.result.profilePic = action.payload.profilePic;
      localStorage.setItem('profile', JSON.stringify(item));
      return user;
    default:
      return user;
  }
}; 