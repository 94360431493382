import React, { useState, useEffect, useRef} from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Container, Button, Typography, Grid, Chip } from '@material-ui/core/';
import SearchIcon from '@material-ui/icons/Search';
import TagResults from "./TagResults";
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getTagPosts } from "../../actions/posts";

// import useStyles from './styles';

const useStyles = makeStyles((theme) => ({
    titleBar : {
        backgroundColor:'#FAFAFA',
        marginTop:'20px',
        marginBottom:'20px',
        padding:'5px',
        display:'flex'
    },

    title: {
        // backgroundColor:'blue',
        // marginLeft:'5px',
        width:'auto',
        display:'flex',
        alignItems:'center',
        // justifyContent:'center',
        textAlign:'center'
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
    root: {
      display: 'flex',
      alignItems:'center',
      justifyContent: 'flex-start',
      padding:'5px',
      cursor:'text',
      width:'97%',
      minHeight:'50px',
      border:'1px solid silver',
      borderRadius:'5px',
      margin: 'auto',
      marginTop:'7px',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      }
    },
    
    tagTitles : {
      color:'#000',
      borderTop : '1px solid #ededed',
      paddingTop : '15px',
      display:'flex',
      alignItems:'center'
    }, 
    tagSubTitles : {
      color:'grey',
      display:'flex',
      alignItems:'flex-start',
      marginTop:'-5px',
      marginBottom:'5px'
    }, 
    chip : {
      margin:'3px',
      marginLeft:'0px'
    },
    section : {
      marginTop:'10px',
      marginBottom:'10px'
    }, 
    tabRoot: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    tabs : {
      minWidth:'50px'
    }, 
    vibesSelected: {
      margin:'3px',
      marginLeft:'0px',
      backgroundColor:'#6e0dd0 !important',
      color:'white'
    }, 
    vibesOutlined: {
      margin:'3px',
      marginLeft:'0px',
      backgroundColor:'white !important',
      color:'#6e0dd0',
      border: '1px solid #6e0dd0'
    }, 
    miscSelected: {
      margin:'3px',
      marginLeft:'0px',
      backgroundColor:'green !important',
      color:'white'
    }, 
    miscOutlined: {
      margin:'3px',
      marginLeft:'0px',
      backgroundColor:'white !important',
      color:'green',
      border: '1px solid green'
    }
  }));
  

const SearchTagInput = () => {
    const categoryArray = ["Video Tags", "Article Tags", "Categories", "Nature", "Animals", "Urban", "Architecture", "Culture", "History", "Religion", "Scenery & Landscapes", "Experiences", "Events", "Vibes", "Activities"];
    const [selectedTags, setselectedTags] = useState([]);
    const [selectedTag, setselectedTag] = useState('');
    const [dropSearch, setDropSearch] = useState(false);
    const [scroll, setScroll] = useState(false);
    const searchRef = useRef();
    const titleRef = useRef();
    const topTag = useSelector((state) => (state.tag))
    
    const [chipData, setChipData] = useState([

        {label:'Short Clip', type:'Video Tags', tagType:'AVTags'},
        {label:'Cinematic Video', type:'Video Tags', tagType:'AVTags'},
        {label:'Vlog', type:'Video Tags', tagType:'AVTags'},
        {label:'Travel Guide', type:'Video Tags', tagType:'AVTags'},
        {label:'Best Of', type:'Video Tags', tagType:'AVTags'},
        {label:'Tour', type:'Video Tags', tagType:'AVTags'},
        {label:'Review', type:'Video Tags', tagType:'AVTags'},

        {label:'Personal Blog Post', type:'Article Tags', tagType:'AVTags'},
        {label:'Travel Guide', type:'Article Tags', tagType:'AVTags'},
        {label:'Destination Spotlight', type:'Article Tags', tagType:'AVTags'},
        {label:'Review Article', type:'Article Tags', tagType:'AVTags'},
        {label:'Best Of', type:'Article Tags', tagType:'AVTags'},
        {label:'How-To Guide', type:'Article Tags', tagType:'AVTags'},
        {label:'Advice & Tips', type:'Article Tags', tagType:'AVTags'},
        {label:'FAQs', type:'AVTags', tagType:'Article Tags'},




        {label:'Nature', type:'Categories',tagType:'General'},
        {label:'Animals', type:'Categories',tagType:'General'},
        {label:'Urban', type:'Categories',tagType:'General'},
        {label:'Architecture', type:'Categories',tagType:'General'},
        {label:'Culture', type:'Categories',tagType:'General'},
        {label:'History', type:'Categories',tagType:'General'},
        {label:'Religion', type:'Categories',tagType:'General'},
        {label:'Scenery & Landscapes', type:'Categories',tagType:'General'},
        {label:'Experiences', type:'Categories',tagType:'General'},
        {label:'Events', type:'Categories',tagType:'General'},
    
        {label:'Nature Photography', type:'Nature', tagType:'General'},
        {label:'Enjoying Nature', type:'Nature', tagType:'General'},
        {label:'Amidst the Wilderness', type:'Nature', tagType:'General'},
        {label:'Majestic Mountains', type:'Nature', tagType:'General'},
        {label:'Sea & Beaches', type:'Nature', tagType:'General'},
        {label:'Enchanting Forests', type:'Nature', tagType:'General'},
        {label:'Tropical Paradise', type:'Nature', tagType:'General'},
        {label:'Winter Destination', type:'Nature', tagType:'General'},
        {label:'Polar Regions', type:'Nature', tagType:'General'},
        {label:'Desert Dunes', type:'Nature', tagType:'General'},
        {label:'Countryside', type:'Nature', tagType:'General'},
        {label:'Remote', type:'Nature', tagType:'General'},
        {label:'Natural Attractions', type:'Nature', tagType:'General'},
        {label:'Natural Wonders', type:'Nature', tagType:'General'},
    
        {label:'Wildlife', type:'Animals', tagType:'General'},
        {label:'Birds', type:'Animals', tagType:'General'},
        {label:'Aquatic Life', type:'Animals', tagType:'General'},
        {label:'Marine Wildlife', type:'Animals', tagType:'General'},
        {label:'Exotic Animals', type:'Animals', tagType:'General'},
        {label:'Domestic Animals', type:'Animals', tagType:'General'},
        {label:'Endangered Species', type:'Animals', tagType:'General'},
        {label:'Animals & Humans', type:'Animals', tagType:'General'},
        {label:'Awesome Animal Moments', type:'Animals', tagType:'General'},
    
    
        {label:'Cityscape', type:'Urban', tagType:'General'},//////////////////////////x1-
        {label:'Townscape', type:'Urban', tagType:'General'},//////////////////////////x1-
        {label:'Small Town Charm', type:'Urban', tagType:'General'},
        {label:'Life in the City', type:'Urban', tagType:'General'},
        {label:'City Lights', type:'Urban', tagType:'General'},
        {label:'City at Night', type:'Urban', tagType:'General'},
        {label:'Exploring the City', type:'Urban', tagType:'General'},
        {label:'Skyline', type:'Urban', tagType:'General'},
        {label:'Street Photography', type:'Urban', tagType:'General'},//////////////////x1-
    
        {label:'Cultural Landmarks', type:'Culture', tagType:'General'},
        {label:'Life of Locals', type:'Culture', tagType:'General'},
        {label:'Everyday Life', type:'Culture', tagType:'General'},
        {label:'Heritage', type:'Culture', tagType:'General'},
        {label:'Traditions & Customs', type:'Culture', tagType:'General'},
        {label:'Cultural Events', type:'Culture', tagType:'General'},////////////////////x1-
        {label:'Cultural Hotspots', type:'Culture', tagType:'General'},
        {label:'Multicultural', type:'Culture', tagType:'General'},
        {label:'Street Photography', type:'Culture', tagType:'General'},//////////////////x1-
        {label:'Indigenous Peoples', type:'Culture', tagType:'General'},
    
        {label:'Ancient Ruins', type:'History', tagType:'General'},
    {label:'Museums', type:'History', tagType:'General'},
    {label:'Historical Landmarks', type:'History', tagType:'General'},
    {label:'Historical Site', type:'History', tagType:'General'},
    {label:'Historic Architecture', type:'History', tagType:'General'},
    {label:'Historical Art', type:'History', tagType:'General'},
    {label:'Historical Monuments', type:'History', tagType:'General'},
    {label:'Natural History', type:'History', tagType:'General'},
    {label:'Prehistoric', type:'History', tagType:'General'},
    
    {label:'Religious Landmarks', type:'Religion', tagType:'General'},
    {label:'Religious Sites', type:'Religion', tagType:'General'},
    {label:'Religious Architecture', type:'Religion', tagType:'General'},////////////////////x1-
    {label:'Religious Art', type:'Religion', tagType:'General'},
    {label:'Religious Monuments', type:'Religion', tagType:'General'},
    {label:'Religious Events', type:'Religion', tagType:'General'},//////////////////////////x1-
    {label:'Holy', type:'Religion', tagType:'General'},
    {label:'Sacred', type:'Religion', tagType:'General'},
    
    {label:'Iconic Landmarks', type:'Architecture', tagType:'General'},
    {label:'Modern Architecture', type:'Architecture', tagType:'General'},
    {label:'Historic Architecture', type:'Architecture', tagType:'General'},
    {label:'Religious Architecture', type:'Architecture', tagType:'General'},/////////////////////x1-
    {label:'Landscape Architecture', type:'Architecture', tagType:'General'},
    {label:'Indoor Scenery', type:'Architecture', tagType:'General'},//////////////////////////////x1-
    {label:'Amazing Structures', type:'Architecture', tagType:'General'},
    {label:'Engineering Marvels', type:'Architecture', tagType:'General'},
    
    {label:'Beautiful Scenery', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Amazing View', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Indoor Scenery', type:'Scenery & Landscapes', tagType:'General'},/////////////////////////x1-
    {label:'Random Travel Clicks', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Forest Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Cityscape', type:'Scenery & Landscapes', tagType:'General'},//////////////////////////////x1-
    {label:'Townscape', type:'Scenery & Landscapes', tagType:'General'},/////////////////////////////x1-
    {label:'Urban Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Rural Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Desert Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Seascape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Cloudscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Mountain Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Fall Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Winter Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Summer Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Spring Landscape', type:'Scenery & Landscapes', tagType:'General'},
    {label:'Sunrise', type:'Scenery & Landscapes', tagType:'General' },
    {label:'Sunset', type:'Scenery & Landscapes', tagType:'General' },
    {label:'Northern Lights', type:'Scenery & Landscapes', tagType:'General' },
    {label:'Starry Skies', type:'Scenery & Landscapes', tagType:'General' },
    
    {label:'Just My Travels', type:'Experiences', tagType:'General'},
    {label:'Adventure', type:'Experiences', tagType:'General'},
    {label:'Entertainment', type:'Experiences', tagType:'General'},
    {label:'Leisure & Relaxation', type:'Experiences', tagType:'General'},
    {label:'Luxury Experience', type:'Experiences', tagType:'General'},
    {label:'Cultural Experience', type:'Experiences', tagType:'General'},
    {label:'Spiritual Experience', type:'Experiences', tagType:'General'},
    {label:'Romantic Experience', type:'Experiences', tagType:'General'},
    {label:'Food & Dining', type:'Experiences', tagType:'General'},
    {label:'Nightlife', type:'Experiences', tagType:'General'},
    
    {label:'Festivals', type:'Events', tagType:'General'},
    {label:'Carnivals', type:'Events', tagType:'General'},
    {label:'Fairs', type:'Events', tagType:'General'},
    {label:'Concerts', type:'Events', tagType:'General'},
    {label:'Cultural Events', type:'Events', tagType:'General'},/////////////////////////////////////x1-
    {label:'Religious Events', type:'Events', tagType:'General'},/////////////////////////////////////x1-
    {label:'Sports Events', type:'Events', tagType:'General'},
    
    {label:'Peaceful', type:'Vibes', tagType:'Vibes'},
    {label:'Relaxing', type:'Vibes', tagType:'Vibes'},
    {label:'Luxurious', type:'Vibes', tagType:'Vibes'},
    {label:'Exciting', type:'Vibes', tagType:'Vibes'},
    {label:'Adventurous', type:'Vibes', tagType:'Vibes'},
    {label:'Romantic', type:'Vibes', tagType:'Vibes'},
    {label:'Exotic', type:'Vibes', tagType:'Vibes'},
    {label:'Spiritual', type:'Vibes', tagType:'Vibes'},
    {label:'Scenic', type:'Vibes', tagType:'Vibes'},
    {label:'Awe-Inspiring', type:'Vibes', tagType:'Vibes'},
    {label:'Bustling', type:'Vibes', tagType:'Vibes'},
    {label:'Charming', type:'Vibes', tagType:'Vibes'},
    {label:'Vibrant', type:'Vibes', tagType:'Vibes'},
    {label:'Cozy', type:'Vibes', tagType:'Vibes'},
            
        {label:'Abseiling', type:'Activities', tagType:'Activities'},
        {label:'Air Rifle Shooting', type:'Activities', tagType:'Activities'},
        {label:'Archery', type:'Activities', tagType:'Activities'},
        {label:'ATV Ride', type:'Activities', tagType:'Activities'},
    
        {label:'Backpacking', type:'Activities', tagType:'Activities'},
        {label:'Badminton', type:'Activities', tagType:'Activities'},
        {label:'Banana Boat Ride', type:'Activities', tagType:'Activities'},
        {label:'BASE Jumping', type:'Activities', tagType:'Activities'},
        {label:'Beach Volleyball', type:'Activities', tagType:'Activities'},
        {label:'Bird Watching', type:'Activities', tagType:'Activities'},
        {label:'Boat Ride', type:'Activities', tagType:'Activities'},
        {label:'Bodyboarding', type:'Activities', tagType:'Activities'},
        {label:'Bowling', type:'Activities', tagType:'Activities'},
        {label:'Bungee Jumping', type:'Activities', tagType:'Activities'},
    
        {label:'Cage Diving', type:'Activities', tagType:'Activities'},
        {label:'Camping', type:'Activities', tagType:'Activities'},
        {label:'Canoeing', type:'Activities', tagType:'Activities'},
        {label:'Canyoning', type:'Activities', tagType:'Activities'},
        {label:'Cave Diving', type:'Activities', tagType:'Activities'},
        {label:'Chess', type:'Activities', tagType:'Activities'},
        {label:'Cliff Diving', type:'Activities', tagType:'Activities'},
        {label:'Clubbing', type:'Activities', tagType:'Activities'},
        {label:'Cycling', type:'Activities', tagType:'Activities'},
    
        {label:'Dancing', type:'Activities', tagType:'Activities'},
        {label:'Deep Diving', type:'Activities', tagType:'Activities'},
        {label:'Diving', type:'Activities', tagType:'Activities'},
        {label:'Dolphin Watching', type:'Activities', tagType:'Activities'},
    
        {label:'Equestrian', type:'Activities', tagType:'Activities'},
        {label:'Fly Fish Ride', type:'Activities', tagType:'Activities'},
        {label:'Flyboard Flying', type:'Activities', tagType:'Activities'},
        {label:'Freediving', type:'Activities', tagType:'Activities'},
        {label:'Go-Karting', type:'Activities', tagType:'Activities'},
        {label:'Golf', type:'Activities', tagType:'Activities'},
        {label:'Hang Gliding', type:'Activities', tagType:'Activities'},
        {label:'Helicopter Ride', type:'Activities', tagType:'Activities'},
        {label:'Hiking', type:'Activities', tagType:'Activities'},
        {label:'Hockey', type:'Activities', tagType:'Activities'},
        {label:'Horseback Ride', type:'Activities', tagType:'Activities'},
    
        {label:'Ice Climbing', type:'Activities', tagType:'Activities'},
        {label:'Ice Diving', type:'Activities', tagType:'Activities'},
        {label:'Ice Hockey', type:'Activities', tagType:'Activities'},
        {label:'Ice Skating', type:'Activities', tagType:'Activities'},
        {label:'Jet Skiing', type:'Activities', tagType:'Activities'},
        {label:'Jogging', type:'Activities', tagType:'Activities'},
    
        {label:'Kayaking', type:'Activities', tagType:'Activities'},
        {label:'Kite Flying', type:'Activities', tagType:'Activities'},
        {label:'Kite Surfing', type:'Activities', tagType:'Activities'},
        {label:'Kneeboarding', type:'Activities', tagType:'Activities'},
        {label:'Laser Tag', type:'Activities', tagType:'Activities'},
        {label:'Longboarding', type:'Activities', tagType:'Activities'},
    
        {label:'Mini Golf', type:'Activities', tagType:'Activities'},
        {label:'Motorcycle Ride', type:'Activities', tagType:'Activities'},
        {label:'Mountain Biking', type:'Activities', tagType:'Activities'},
        {label:'Mountaineering', type:'Activities', tagType:'Activities'},
    
        {label:'Paddleboarding', type:'Activities', tagType:'Activities'},
        {label:'Paddlesurfing', type:'Activities', tagType:'Activities'},
        {label:'Paintball', type:'Activities', tagType:'Activities'},
        {label:'Parachuting', type:'Activities', tagType:'Activities'},
        {label:'Paragliding', type:'Activities', tagType:'Activities'},
        {label:'Parasailing', type:'Activities', tagType:'Activities'},
        {label:'Pool & Billiards', type:'Activities', tagType:'Activities'},
    
        {label:'Quad Bike Ride', type:'Activities', tagType:'Activities'},
        {label:'Racquetball', type:'Activities', tagType:'Activities'},
        {label:'Rafting', type:'Activities', tagType:'Activities'},
        {label:'Rock Climbing', type:'Activities', tagType:'Activities'},
        {label:'Roller Coaster Ride', type:'Activities', tagType:'Activities'},
        {label:'Rowing', type:'Activities', tagType:'Activities'},
        {label:'Ocean Walker', type:'Activities', tagType:'Activities'},
    
        {label:'Safari', type:'Activities', tagType:'Activities'},
        {label:'Sailing', type:'Activities', tagType:'Activities'},
        {label:'Scuba Diving', type:'Activities', tagType:'Activities'},
        {label:'Seaplane Ride', type:'Activities', tagType:'Activities'},
        {label:'Shark Cage Diving', type:'Activities', tagType:'Activities'},
        {label:'Skateboarding', type:'Activities', tagType:'Activities'},
        {label:'Skiing', type:'Activities', tagType:'Activities'},
        {label:'Skimboarding', type:'Activities', tagType:'Activities'},
        {label:'Sky Diving', type:'Activities', tagType:'Activities'},
        {label:'Sledding', type:'Activities', tagType:'Activities'},
        {label:'Snorkelling', type:'Activities', tagType:'Activities'},
        {label:'Snowboarding', type:'Activities', tagType:'Activities'},
        {label:'Snowmobiling', type:'Activities', tagType:'Activities'},
        {label:'Snowshoeing', type:'Activities', tagType:'Activities'},
        {label:'Speed Boat Ride', type:'Activities', tagType:'Activities'},
        {label:'Spelunking', type:'Activities', tagType:'Activities'},
        {label:'Spinnaker Flying', type:'Activities', tagType:'Activities'},
        {label:'Stargazing', type:'Activities', tagType:'Activities'},
        {label:'Surfing', type:'Activities', tagType:'Activities'},
        {label:'Swimming', type:'Activities', tagType:'Activities'},
    
        {label:'Table Tennis', type:'Activities', tagType:'Activities'},
        {label:'Tennis', type:'Activities', tagType:'Activities'},
        {label:'Train Ride', type:'Activities', tagType:'Activities'},
        {label:'Trekking', type:'Activities', tagType:'Activities'},
    
        {label:'Underwater Scooter Riding', type:'Activities', tagType:'Activities'},
        {label:'Video Games', type:'Activities', tagType:'Activities'},
        {label:'VR/AR Games', type:'Activities', tagType:'Activities'},
    
        {label:'Wakeboarding', type:'Activities', tagType:'Activities'},
        {label:'Water Skiing', type:'Activities', tagType:'Activities'},
        {label:'Water Sports', type:'Activities', tagType:'Activities'},
        {label:'Whale Watching', type:'Activities', tagType:'Activities'},
        {label:'Whitewater Rafting', type:'Activities', tagType:'Activities'},
        {label:'Windsurfing', type:'Activities', tagType:'Activities'},
        {label:'Yoga', type:'Activities', tagType:'Activities'},
        {label:'Ziplining', type:'Activities', tagType:'Activities'},
        {label:'Zorbing', type:'Activities', tagType:'Activities'},
    
        
        
    
      ]);
  const user = JSON.parse(localStorage.getItem('profile'));

  const dispatch = useDispatch();
  const classes = useStyles();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [SearchTagInput, dispatch]);

  const [query, setQuery] = useState("");
  const [showResults, setShowResults] = useState(false);

const handleSearchBar = () => {
  var input = document.getElementById("searchbar");
  input.addListener("keyup", function(event) {
      if (event.KeyboardEvent.code === 13) {
          event.preventDefault();
          document.getElementById("searchbar").click();
      }
  });
}

useEffect(() => {
    // console.log(topTag);
    if(topTag !== ""){
        setShowResults(true);
        dispatch(getTagPosts(encodeURIComponent(topTag))).then(() =>
        titleRef.current.scrollIntoView())
        setQuery('');
        setselectedTag(topTag);   
    }
  }, [topTag]);

  const handleDrop = () => {
    setDropSearch(false);
  }


const handleClick = (data) => {
    setShowResults(true);
    dispatch(getTagPosts(encodeURIComponent(data.label)))
    .then(() =>
    titleRef.current.scrollIntoView()
    )
    setQuery('');
    setselectedTag(data.label);
  }

  // useEffect(() => {
  //   if (!searchRef.current) return;
  //   searchRef.current.scrollIntoView({behavior:'smooth'})
  // }, [getTagPosts()])

//   useEffect(() => {
//     if(searchRef.current !== null) {
//         (scroll === true && (
//         searchRef.current.scrollIntoView({behavior:'smooth'})
//         ))
//       }
//     // searchRef.current.scrollIntoView({behavior:'smooth'})
//     // window.scrollTo(0, 500);
//   }, [showResults])
  

  return (
    <Container id="searchContainer">
    <Grid id="gridImage" className="search-location-input" style={{ backgroundSize:'cover', backgroundPosition:'30%', display:"flex", flexDirection:'column', justifyContent:'center', alignItems:'center', height:'450px', margin:'0px', position:'relative'}}>
    <div class="searchDiv" style={{position:'absolute'}}>
      <SearchIcon style={{backgroundColor:'white', borderTopLeftRadius: '25px', borderBottomLeftRadius: `${query !== "" ? '0px' : '25px'}`, padding:'12px', fontSize:'2.8rem'}}/>
        <input
        autoComplete="off"
        id="tagSearch"
        style={{borderBottomRightRadius: `${((query !== "") || dropSearch ) ? '0px' : '25px'}`}}
        class="pac-target-input"
        onChange={event => setQuery(event.target.value)}
        placeholder="Search For Tags"
        value={query}
        onLoad = {handleSearchBar}
        onFocus={()=>setDropSearch(true)}
        onBlur={handleDrop}
      />
    
      {/* <Button variant="contained" color="primary" style={{borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', borderTopRightRadius: '25px', borderBottomRightRadius: '25px', padding:'10.5px', fontSize:'0.85rem', marginLeft:'-30px', textTransform:'inherit'}} onClick={handleSubmit}>Search</Button> */}
      
      </div>
      {((query !== "") || dropSearch) && (
      <div class="searchDiv" style={{backgroundColor:'white', padding:'20px', position:'absolute', zIndex:'1', display:'flex', flexDirection:'column', height:'250px', overflowY:'scroll', overflowX:'hidden',  marginTop:'250px'}}>
          {
        ((query !== "") || dropSearch) && (
        categoryArray.map(cat => 
          <div className={chipData.find((dat) => 
            ((dat.label.toLowerCase().includes(query.toLowerCase()) && dat.type === cat))) && classes.section}>
          {chipData.find((dat) => 
          ((dat.label.toLowerCase().includes(query.toLowerCase()) && dat.type === cat))) && 
          <Typography className={classes.tagTitles} variant="subtitle1">{cat}</Typography>
          }
          <div>
          {chipData.map((data) => {
          if(data.label.toLowerCase().includes(query.toLowerCase()) && data.type === cat) {
            switch (data.type) {
              case "Video Tags":
                return <Chip
                    label={data.label}
                    className={classes.chip}
                    onMouseDown={()=> handleClick(data)}
                    style={{cursor:'pointer', color:'#FD297B', border:'1px solid #FF655B'}}
                    variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                    />
                break;

                case "Article Tags":
                  return <Chip
                      label={data.label}
                      className={classes.chip}
                      onMouseDown={()=> handleClick(data)}
                      style={{cursor:'pointer', color:'#11998e', border:'1px solid #11998e'}}
                      variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                      />
                  break;

              case "Activities":
                return <Chip
                    label={data.label}
                    className={classes.chip}
                    onMouseDown={()=> handleClick(data)}
                    style={{cursor:'pointer'}}
                    variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                    color="secondary"
                    />
                break;
              
              case "Vibes":
                return <Chip
                    label={data.label}
                    className = {selectedTags.find(tag => tag.label === data.label) ? classes.vibesSelected : classes.vibesOutlined}
                    onClick={()=> handleClick(data)}
                    variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                    onMouseDown={()=> handleClick(data)}
                    style={{cursor:'pointer'}}
                    
                    />
                break;
            
              default:
                return <Chip
                    label={data.label}
                    className={classes.chip}
                    onMouseDown={()=> handleClick(data)}
                    style={{cursor:'pointer'}}
                    variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                    color="primary"
                    />
                break;
            }
          } else {
            return null; 
          }
        })}
        </div>
          </div>
            )
          )
      }
      </div>
)}
    <div class="searchBtns">
    {/* <Button size="small" variant="outlined" component={Link} to="/search" color="primary" style={{margin:'10px', marginLeft:0, textTransform:'inherit'}}>
        Search For Destinations
    </Button>    
    <Button size="small" variant="contained" component={Link} to="/search/users" color="primary" style={{margin:'10px', textTransform:'inherit'}}>
        Searching For Users
    </Button> */}
    <Button size="small" component={Link} to="/search" style={{margin:'10px', marginLeft:0, textTransform:'inherit', color:'#333', color:'white', backgroundColor:'rgba(0,0,0,0.4)'}}>
        Destinations
    </Button>    
    <Typography style={{color:'white'}}> | </Typography>
    <Button size="small" component={Link} to="/search/users" style={{margin:'10px', textTransform:'inherit', color:'white', backgroundColor:'rgba(0,0,0,0.4)',}}>
        Users
    </Button>
    <Typography style={{color:'white'}}> | </Typography>
    <Button size="small" component={Link} to="/search/tags" style={{margin:'10px', textTransform:'inherit', color:'white', backgroundColor:'rgba(0,0,0,0.7)', border:'2px solid #ededed', padding:'7px'}}>
        Tags
    </Button>
    </div>
    </Grid>

    {showResults && (
        <Grid container style={{borderBottom:'2px solid #EDEDED', marginTop:'1px'}}>
        <div class="searchRef" style={{height:'1px'}} ref={titleRef}></div>
          <Grid container className={classes.titleBar}>
          <Grid item id="searchTitle" className={classes.title}  style={{}} >
            <Typography id="place" variant="h4">{selectedTag}</Typography>
            {/* {placeDetails.name !== placeDetails.country && (
            <>
            <Typography id="comma" variant="h5" style={{color:'#818181'}}>&nbsp;&#183;&nbsp;</Typography>
            <Typography id="country" variant="h5" style={{color:'#818181'}}>{placeDetails.country}</Typography>
            </>
            )} */}
            {/* <><FavoriteBorderIcon fontSize="small" />{favs.length} people like this</> */}
          </Grid>
            </Grid>
            <div class="searchRef2" style={{height:'1px', width:'100%', marginTop:'-160px'}} ref={searchRef}></div>
        </Grid>
      )}

    {/* <Typography>{showResults? 't' : 'f'}</Typography> */}
        <Container style={{display:'flex', justifyContent:'center'}}>        
        {/* <div ref = {searchRef}></div> */}
          {showResults && (
            // <>
          <TagResults />
          // </>
          )}
        </Container>
        {/* <div ref = {searchRef} style={{backgroundColor:'red', width:'50px', height:'3px'}}></div> */}
    </Container>
  );
}

export default SearchTagInput;