import React from 'react';
import { Container, Grow, Grid } from '@material-ui/core';
// import { useDispatch } from 'react-redux';

// import { getLocationPosts } from '../../actions/posts';
import Posts from '../Posts/TagPosts';

const TagResults = () => {

    // const dispatch = useDispatch();
    // const location = 'Colombo';

    // useEffect(() => {
    //     dispatch(getLocationPosts(location));
    // }, [dispatch])
 
    return (
    <Grow in>
      <Container>
        <Grid container justify="space-between" alignItems="stretch" spacing={3} style = {{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'25px', paddingTop:'25px'}}>
          <Grid item xs={12} sm={6} style={{display:'flex', justifyContent:'center'}}>
            <Posts />
          </Grid>
        </Grid>
      </Container>
    </Grow>
    )
}

export default TagResults;
