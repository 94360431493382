import { ADDTOFAVS, GET_FAVS, REMOVEFROMFAVS, ADDTOVISITED, ADD_TO_USER_FAVS, ADD_TO_USER_VISITS, REMOVE_FROM_USER_FAVS, REMOVE_FROM_USER_VISITS, REMOVE_FROM_USER_BUCKET, ADD_TO_USER_BUCKETS, GET_VISITED, REMOVEFROMVISITED, ADDTOBUCKET, GET_BUCKET, REMOVEFROMBUCKET, ADD_TO_EXPLORE, REMOVE_FROM_EXPLORE } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const addToFavorites = (favorite) => async (dispatch) => {
    try {
      const { data } = await api.addToFavorites(favorite);
  
      dispatch({ type: ADDTOFAVS, payload: data.userId });
      // console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  export const removeFromFavorites = (favorite) => async (dispatch) => {
    try {
      const { data } = await api.removeFromFavorites(favorite);
      // console.log(favorite);
  
      dispatch({ type: REMOVEFROMFAVS, payload: data.userId });
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  export const getFavorites = (coordinates) => async(dispatch) => {
    try {
      const { data } = await api.getFavorites(coordinates);
      
      dispatch({ type: GET_FAVS, payload: data});
    } catch (error) {
      console.log(error);
    }
  }

  export const addToUserFavorites = (favorite) => async (dispatch) => {
    try {
      // const { data } = 
      await api.addToFavorites(favorite);

      // dispatch({ type: ADD_TO_USER_FAVS, payload: data.loc });
    } catch (error) {
      console.log(error);
    }
  };

  export const removeFromUserFavorites = (favorite) => async (dispatch) => {
    console.log(favorite);
    try {
      const { data } = await api.removeFromFavorites(favorite);
      // console.log(data);
      console.log(data.loc);

      dispatch({ type: REMOVE_FROM_USER_FAVS, payload: data.loc });
    } catch (error) {
      console.log(error);
    }
  };

  export const addToExploreFavorites = (favorite) => async (dispatch) => {
    try {
      const { data } = await api.addToFavorites(favorite);
      console.log(data);

      dispatch({ type: ADD_TO_EXPLORE, payload: data.loc });
    } catch (error) {
      console.log(error);
    }
  };

  export const removeFromExploreFavorites = (favorite) => async (dispatch) => {
    console.log(favorite);
    try {
      const { data } = await api.removeFromFavorites(favorite);
      // console.log(data);
      console.log(data.loc);

      dispatch({ type: REMOVE_FROM_EXPLORE, payload: data.loc });
    } catch (error) {
      console.log(error);
    }
  };


  export const addToVisited = (visit) => async (dispatch) => {
    try {
      const { data } = await api.addToVisited(visit);
  
      dispatch({ type: ADDTOVISITED, payload: data.userId });
      // console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  export const addToUserVisited = (visit) => async (dispatch) => {
    try {
      await api.addToVisited(visit);
    } catch (error) {
      console.log(error);
    }
  };

  export const removeFromVisited = (visit) => async (dispatch) => {
    // console.log(visit);
    try {
      const { data } = await api.removeFromVisited(visit);
      // console.log(visit);
  
      dispatch({ type: REMOVEFROMVISITED, payload: data.userId });
      // console.log(data);
    } catch (error) {
      console.log(error);
      // console.log(visit);
    }
  };

  export const removeFromUserVisited = (visit) => async (dispatch) => {
    try {
      const { data } = await api.removeFromVisited(visit);

      dispatch({ type: REMOVE_FROM_USER_VISITS, payload: data.loc });
    } catch (error) {
      console.log(error);
    }
  };

  export const getVisited = (coordinates) => async(dispatch) => {
    try {
      const { data } = await api.getVisited(coordinates);
     
      // console.log(data);
      // console.log({coordinates});

      dispatch({ type: GET_VISITED, payload: data});
    } catch (error) {
      console.log(error);
      // console.log(coordinates);
    }
  }

  export const addToExploreVisited = (visit) => async (dispatch) => {
    try {
      const { data } = await api.addToVisited(visit);
      console.log(data);

      dispatch({ type: ADD_TO_EXPLORE, payload: data.loc });
    } catch (error) {
      console.log(error);
    }
  };

  export const removeFromExploreVisited = (visit) => async (dispatch) => {
    console.log(visit);
    try {
      const { data } = await api.removeFromVisited(visit);
      // console.log(data);
      console.log(data.loc);

      dispatch({ type: REMOVE_FROM_EXPLORE, payload: data.loc });
    } catch (error) {
      console.log(error);
    }
  };
//////////////////////////////////////////////////////////////

  export const addToBucket = (bucket) => async (dispatch) => {
    try {
      const { data } = await api.addToBucket(bucket);
  
      dispatch({ type: ADDTOBUCKET, payload: data.userId });
      // console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  export const addToUserBucket = (bucket) => async (dispatch) => {
    try {
      await api.addToBucket(bucket);
    } catch (error) {
      console.log(error);
    }
  };

  export const removeFromBucket = (bucket) => async (dispatch) => {
    // console.log(bucket);
    try {
      const { data } = await api.removeFromBucket(bucket);
      // console.log(bucket);
  
      dispatch({ type: REMOVEFROMBUCKET, payload: data.userId });
      // console.log(data);
    } catch (error) {
      console.log(error);
      // console.log(bucket);
    }
  };

  export const removeFromUserBucket = (bucket) => async (dispatch) => {
    // console.log(bucket);
    try {
      const { data } = await api.removeFromBucket(bucket);
      // console.log(bucket);
  
      dispatch({ type: REMOVE_FROM_USER_BUCKET, payload: data.loc });
      // console.log(data);
    } catch (error) {
      console.log(error);
      // console.log(bucket);
    }
  };
  
  export const getBucket = (coordinates) => async(dispatch) => {
    
    try {
      const { data } = await api.getBucket(coordinates);
      
      console.log(data);
      // console.log({coordinates});

      dispatch({ type: GET_BUCKET, payload: data});
    } catch (error) {
      console.log(error);
      // console.log(coordinates);
    }
  }

  export const addToExploreBucket = (bucket) => async (dispatch) => {
    try {
      const { data } = await api.addToBucket(bucket);
      console.log(data);

      dispatch({ type: ADD_TO_EXPLORE, payload: data.loc });
    } catch (error) {
      console.log(error);
    }
  };

  export const removeFromExploreBucket = (bucket) => async (dispatch) => {
    console.log(bucket);
    try {
      const { data } = await api.removeFromBucket(bucket);
      // console.log(data);
      console.log(data.loc);

      dispatch({ type: REMOVE_FROM_EXPLORE, payload: data.loc });
    } catch (error) {
      console.log(error);
    }
  };

  export const locationImage = (locationImage) => async(dispatch) => {
    // console.log(locationImage);
    try {
      const { data } = await api.locationImage(locationImage);
    } catch (error) {
      console.log(error);
    }
  }