import React, { useState, useEffect} from 'react';
import { Box, Menu, Icon, CssBaseline, BottomNavigation, BottomNavigationAction, Paper, Avatar, MenuItem, IconButton, Typography } from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import decode from 'jwt-decode';
import useStyles from './styles';
// import Box from '@mui/material/Box';
// import CssBaseline from '@mui/material/CssBaseline';
// import BottomNavigation from '@mui/material/BottomNavigation';
// import BottomNavigationAction from '@mui/material/BottomNavigationAction';
// import RestoreIcon from '@material-ui/icons/Restore';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import ArchiveIcon from '@material-ui/icons/Archive';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
// import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Badge from '@material-ui/core/Badge';
import LandingPage from '../Home/LandingPage';
// import NotificationInbox from '../Home/Notifications';
// import Paper from '@mui/material/Paper';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemAvatar from '@mui/material/ListItemAvatar';
// import ListItemText from '@mui/material/ListItemText';
// import Avatar from '@mui/material/Avatar';

function refreshMessages() {
  const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

  return Array.from(new Array(50)).map(
    () => messageExamples[getRandomInt(messageExamples.length)],
  );
}

export default function FixedBottomNavigation({userStatus1, userStatus2}) {
  const ref = React.useRef(null);
  const [messages, setMessages] = React.useState(() => refreshMessages());
  
  ///////////////////////////////////////
  const classes = useStyles();
  const [user, setuser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(0);

  // const userO = JSON.parse(localStorage.getItem('profile'));
  // const userId = userObj?.result?.googleId || userObj?.result?.id;
  // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const logout = () => {
    dispatch({ type: 'LOGOUT'});
    history.push('/');
    setuser(null);
    handleMenuClose();
    };

    useEffect(() => {
      switch (location.pathname) {
        case '/':
          setValue(0);
          break;
        case '/search':
          setValue(1);
          break;
        case '/create':
          setValue(2);
          break;
        case '/explore':
          setValue(3);
          break;
        default:
          setValue(null);
      }
    }, [location]);


    // console.log(user);
useEffect(() => {
    const token = user?.token;

    if(token) {
        const decodedToken = decode(token);
        if(decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setuser(JSON.parse(localStorage.getItem('profile'))); 
}, [location]);


  const isMenuOpen = Boolean(anchorEl);
  // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleMobileMenuClose = () => {
  //   setMobileMoreAnchorEl(null);
  // };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  // const handleMobileMenuOpen = (event) => {
  //   setMobileMoreAnchorEl(event.currentTarget);
  // };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem component={Link} to={`/${user?.result?.googleId || user?.result?.id}`} style={{paddingLeft : 0}} onClick={handleMenuClose}>
        <IconButton aria-label="show 4 new mails" color="inherit">
            <AccountCircleOutlinedIcon />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem onClick={logout} style={{paddingLeft : 0}}>
        <IconButton aria-label="show 4 new mails" >
            <ExitToAppIcon style={{color:'black'}}/>
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );
  //////////////////////////////////////
  React.useEffect(() => {
    if (!ref.current) return;
    ref.current.ownerDocument.body.scrollTop = 0;
    setMessages(refreshMessages());
  }, [value, setMessages]);

  if ((location.pathname == '/') && (userStatus1 !== 'Active' && userStatus2 !== 'Active')) {
    return null;
  }
  if (location.pathname == '/auth') {
    return null;
  }
   else {

  return (
    <Box id= "box" sx={{ pb: 7 }} ref={ref}> 
      <CssBaseline />
      {/* <List>
        {messages.map(({ primary, secondary, person }, index) => (
          <ListItem button key={index + person}>
            <ListItemAvatar>
              <Avatar alt="Profile Picture" src={person} />
            </ListItemAvatar>
            <ListItemText primary={primary} secondary={secondary} />
          </ListItem>
        ))}
      </List> */}
      <Paper id= "mobileNav" style={{ position: 'fixed', bottom: 0, left: 0, right: 0, marginBottom:0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          {/* <BottomNavigationAction /> */}
            {/* <MenuItem  component={Link} to="/" >
              <IconButton color="inherit">
                <HomeOutlinedIcon />
              </IconButton>
            </MenuItem> */}
            {/* <Typography>{value}</Typography> */}

            <BottomNavigationAction  icon={
              <MenuItem  component={Link} to="/" onClick={()=>window.scrollTo(0,0)}>
                <IconButton color="inherit"> 
                  <HomeOutlinedIcon />
                </IconButton>
              </MenuItem>} />
            
            <BottomNavigationAction  icon={
            <MenuItem component={Link} to="/search">
              <IconButton color="inherit">
                <SearchIcon />
              </IconButton>
            </MenuItem>} />


            <BottomNavigationAction  icon={
            <MenuItem component={Link} to="/create">
              <IconButton color="inherit">
                <AddBoxOutlinedIcon />
              </IconButton>
            </MenuItem> } />


            <BottomNavigationAction  icon={
            <MenuItem component={Link} to="/explore">
            <IconButton color="inherit">
              <ExploreOutlinedIcon />
            </IconButton>
            </MenuItem>} />
            {/* <MenuItem>
            <IconButton aria-label="show 17 new notifications" color="inherit" style={{zIndex:'2000'}}>
                <NotificationInbox />
            </IconButton>
            </MenuItem> */}
            {user ? (
              <BottomNavigationAction  icon={
              <MenuItem onClick={handleProfileMenuOpen}>
              
              <IconButton
                // edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                // onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar className = {classes.small} alt={user.result?.name} src = {user?.result?.profilePic || user?.data?.user?.profilePic}>
                  {/* {user.result?.name.charAt(0) } */}
                  {/* {user?.data?.profilePic} */}
                  </Avatar>
                {/* {console.log(user.result)} */}
              </IconButton>
              {/* <p className = {classes.userName} variant = "h6">{user.result?.givenName || user.result?.firstName} </p> */}
              </MenuItem> } />
            ) : (
              <BottomNavigationAction  icon={
              <MenuItem component = {Link} to="/auth" >
                <IconButton>
                  <Avatar className = {classes.small} />
                </IconButton>
              </MenuItem> } />
              
            )}
        </BottomNavigation>
        {renderMenu}
      </Paper>
    </Box>
  )};
}

const messageExamples = [
  {
    primary: 'Brunch this week?',
    secondary: "I'll be in the neighbourhood this week. Let's grab a bite to eat",
    person: '/static/images/avatar/5.jpg',
  },
  {
    primary: 'Birthday Gift',
    secondary: `Do you have a suggestion for a good present for John on his work
      anniversary. I am really confused & would love your thoughts on it.`,
    person: '/static/images/avatar/1.jpg',
  },
  {
    primary: 'Recipe to try',
    secondary: 'I am try out this new BBQ recipe, I think this might be amazing',
    person: '/static/images/avatar/2.jpg',
  },
  {
    primary: 'Yes!',
    secondary: 'I have the tickets to the ReactConf for this year.',
    person: '/static/images/avatar/3.jpg',
  },
  {
    primary: "Doctor's Appointment",
    secondary: 'My appointment for the doctor was rescheduled for next Saturday.',
    person: '/static/images/avatar/4.jpg',
  },
  {
    primary: 'Discussion',
    secondary: `Menus that are generated by the bottom app bar (such as a bottom
      navigation drawer or overflow menu) open as bottom sheets at a higher elevation
      than the bar.`,
    person: '/static/images/avatar/5.jpg',
  },
  {
    primary: 'Summer BBQ',
    secondary: `Who wants to have a cookout this weekend? I just got some furniture
      for my backyard and would love to fire up the grill.`,
    person: '/static/images/avatar/1.jpg',
  },
];
