import axios from 'axios';

// const API = axios.create({ baseURL: 'https://travelaio.herokuapp.com/' }); 
// const API = axios.create({ baseURL: 'https://travelaio-server-peo94.ondigitalocean.app' }); 

const API = axios.create({ baseURL: 'https://travelaio-server.herokuapp.com/' }); 
// const API = axios.create({ baseURL: 'http://localhost:5000' });


API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  }

  return req;
});

export const fetchPosts = (page, postStateValue) => API.get(`/posts?page=${page}&postStateValue=${postStateValue}`);
export const fetchLandingPosts = () => API.get('posts/landing');
export const fetchCategoryPosts = (page, category) => API.get(`/posts/categoryPosts?page=${page}&category=${category}`);
export const createPost = (newPost) => API.post('/posts', newPost);
export const likePost = (id) => API.post(`/posts/${id}/likePost`);
export const dislikePost = (id) => API.delete(`/posts/${id}/likePost`);
export const updatePost = (id, updatedPost) => API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);
export const fetchPost = (id) => API.get(`/posts/${id}`);

export const getLocation = (location) => (API.get(`/posts/location?location=${location}`));
export const getLocationPosts = (location) => (API.get(`/posts/locationPosts?location=${location}`));

export const getTagPosts = (tag) => (API.get(`/posts/tagPosts?tag=${tag}`));
// export const getFavorites = (location) => (API.get(`/posts/getFavorites?location=${location}`));

export const signIn = (formData) => API.post('/auth/signin', formData);
export const signUp = (formData) => API.post('/auth/signup', formData);
export const googleLogin = (formData) => API.post('/auth/googleLogin', formData);
export const verifyAccount = (token) => API.post('/auth/account/verify', token);
export const resendVerification = (user) => API.post('/auth/account/resendVerification', user);
export const confirmEmail = (email) => API.post('/auth/account/confirmEmail', email);
export const resetPassword = (formData) => API.post('/auth/account/resetPassword', formData);

export const addToFavorites = (newFavorite) => API.post('/favorites', newFavorite);
export const removeFromFavorites = (favorite) => API.delete(`/favorites/${favorite}`);
export const getFavorites = (coordinates) => (API.get(`/favorites?coordinates=${coordinates}`));

export const addToVisited = (newVisit) => API.post('/visited', newVisit);
export const removeFromVisited = (visit) => API.delete(`/visited/${visit}`);
export const getVisited = (coordinates) => (API.get(`/visited?coordinates=${coordinates}`));

export const addToBucket = (newBucket) => API.post('/bucket', newBucket);
export const removeFromBucket = (bucket) => API.delete(`/bucket/${bucket}`);
export const getBucket = (coordinates) => (API.get(`/bucket?coordinates=${coordinates}`));

export const getLikes = (id) => (API.get(`/posts/likePost?id=${id}`));

export const getSignature = (params_to_sign) => (API.post('/signature', params_to_sign));

export const getExploreFavs = () => API.get('/explore/favorites');
export const getExploreVisits = () => API.get('/explore/visited');
export const getExploreBuckets = () => API.get('/explore/buckets');

export const getUser = (user) => API.get(`/users/user?id=${user}`);
export const updateUser = (id, userData) => API.patch(`/users/user/${id}`, userData);
export const getUserPosts = (user) => (API.get(`/users/userPosts?user=${user}`));
export const getUserFavorites = (user) => (API.get(`/users/userFavorites?user=${user}`));
export const getUserVisited = (user) => (API.get(`/users/userVisited?user=${user}`));
export const getUserBucket = (user) => (API.get(`/users/userBucket?user=${user}`));
export const getUsers = (user) => API.get(`/users/getUsers?name=${user}`);
export const getSuggestedUsers = (userId) => API.get(`/users/getSuggestedUsers?userId=${userId}`);


export const getFollowers = (userId) => API.get(`/users/getFollowers?userId=${userId}`);
export const getProfileFollowers = (userId) => API.get(`/users/getProfileFollowers?userId=${userId}`);
export const getFollowing = (userId) => API.get(`/users/getFollowing?userId=${userId}`);
export const addToFollowing = (userId) => API.post('/users/addToFollowing', userId);
export const removeFromFollowing = (userId) => API.delete(`/users/removeFromFollowing/${userId}`);
// export const getBucket = (coordinates) => (API.get(`/bucket?coordinates=${coordinates}`));

export const getComments = (postId) => (API.get(`/posts/${postId}/comments?postId=${postId}`));
export const addComment = (commentData, postId) => API.post(`/posts/${postId}/comments`, commentData);
export const deleteComment = (commentId, postId) => API.delete(`/posts/${postId}/comments?commentId=${commentId}`);
export const likeComment = (postId, commentId) => API.post(`/posts/${postId}/comments/likeComment?commentId=${commentId}`);
export const dislikeComment = (postId, commentId) => API.delete(`/posts/${postId}/comments/likeComment?commentId=${commentId}`);

export const locationImage = (locationImage) => API.post('/favorites/locationImage', locationImage);