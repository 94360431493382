import { AUTH, CHANGE_NEWSFEED, VERIFY } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const signin = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.signIn(formData);
    // console.log(data);

    const result = {id:data.user.id, email:data.user.email, firstName:data.user.firstName, lastName:data.user.lastName, name:data.user.name, status:data.user.status, profilePic:data.user.profilePic};
    const token = data.token;
    dispatch({ type: CHANGE_NEWSFEED })
    dispatch({ type: AUTH, data : {result, token, data} });
    router.push('/');
  } catch (error) {
    // console.log(error.response); 
    alert(error?.response?.data?.message);
  }
}; 

export const signup = (formData, router) => async (dispatch) => {
  // console.log(formData);
  try {
    const { data } = await api.signUp(formData);

    const result = {id:data.user.id, email:data.user.email, firstName:data.user.firstName, lastName:data.user.lastName, name:data.user.name, status:data.user.status, profilePic:data.user.profilePic};
    const token = data.token;
    dispatch({ type: AUTH, data : {result, token, data} });
    // console.log(data);

    router.push('/account/verify/unverified');
  } catch (error) { 
    // console.log(error);
    alert(error?.response?.data?.message);
  }
};

export const googleLogin = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.googleLogin(formData);
    const {googleId, email, givenName, familyName, name, token } = formData;
    const result = {googleId:googleId, email:email, givenName:givenName, familyName:familyName, name:name, status:data.user.status, profilePic:data.user.profilePic};
    // const currentUser = data.user;
    // delete currentUser.email;
    // delete currentUser.loginType;
    // delete currentUser.tempToken;
    dispatch({ type: CHANGE_NEWSFEED })
    dispatch({ type: AUTH, data : {result, token, data} });
    // console.log(currentUser);
    console.log(data);
    // console.log(result);
    // console.log(data);
    router.push('/');
  } catch (error) {
    // console.log(error);
    alert(error?.response?.data?.message);
  }
}

export const verifyAccount = (token, router) => async (dispatch) => {
  // console.log(token);
  try {
    const { data } = await api.verifyAccount(token);

    if( data.message == 'Account Verified') {
      const result = {id:data.user.id, email:data.user.email, firstName:data.user.firstName, lastName:data.user.lastName, name:data.user.name, status:data.user.status, profilePic:data.user.profilePic};
      const token = data.token;
      delete data.message;

      dispatch({ type: VERIFY, data : {result, token, data} });
      router.push('/')
      // window.location.reload();\
    } else {
      console.log('error');
    }
  } catch (error) {
    // console.log('error');
    alert(error?.response?.data?.message);
  }
}

export const resendVerification = (user) => async (dispatch) => {
  // console.log(user);
  try {
    if(user.status == 'Pending') {
      const { data } = await api.resendVerification(user);
      // console.log(data);
    } else {
      console.log('User already verified');
    }
  } catch (error) {
    console.log('error');
    alert(error?.response?.data?.message);
  }
}


export const confirmEmail = (email) => async (dispatch) => {
  // console.log(email);
  try {
    const { data } = await api.confirmEmail(email);
    // console.log(data);
  } catch (error) {
    // console.log(error);
    alert(error?.response?.data?.message);
  }
}

export const resetPassword = (formData, router) => async (dispatch) => {
  // console.log(formData);
  try {
    const { data } = await api.resetPassword(formData);
    router.push('/auth');
    // console.log(data);
  } catch (error) {
    // console.log(error);
    alert(error?.response?.data?.message);
  }
}