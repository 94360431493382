import { yellow } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
// import background from '../../../public/Calle.jpg';

export default makeStyles({
    fab1 : {
        // background:'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        backgroundColor:'white',
        margin:'5px',
        // color:'green'
    },

    favorite : {
        // background:'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        // backgroundColor:'white',
        margin:'5px',
        color:'#000'
    },

    titleBar : {
        backgroundColor:'#FAFAFA',
        marginTop:'20px',
        marginBottom:'20px',
        padding:'5px',
        display:'flex'
    },

    title: {
        // backgroundColor:'blue',
        // marginLeft:'5px',
        width:'auto',
        display:'flex',
        alignItems:'center',
        // justifyContent:'center',
        textAlign:'center'
    },

    icons: {
        marginLeft:'25px',
        // backgroundColor:'yellow',
        display:'flex',
        alignItems:'center'
    }, 
    
    typo: {
        marginLeft:'-5px'
    },

    
})