import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Container, Grow, Grid, Typography, Button } from '@material-ui/core';

const Privacy = () => {

  return (
    <Grow in>
      <Container id="homeContainer">
        <Grid container xs={12} sm={7} md={6} justify="space-between" alignItems="stretch" style={{display:'flex', justifyContent:'center', margin:'auto', padding:'20px', boxShadow: '0 0 4px grey', flexDirection:'column'}} >
          {/* <Grid item xs={12} sm={7} md={6} style={{display:'flex', justifyContent:'center'}}> */}
            <h3 style={{textAlign:'center'}}>Privacy Policy</h3>
            <p>This policy describes the information that we process to provide and support features offered by TravelAIO.</p>
            <h4 style={{fontWeight:'bold'}}>Types of information we collect</h4>
            <p style={{fontWeight:'bold'}}>Things that you and others do and provide.</p>
            <ul>
                <li style={{textDecoration:'underline'}}>User generated content and user provided information. </li>
                <p>We collect the content and information your provide when you use TravelAIO, including when you sign up for an account, create, interact with or share content. This can include information in or about the content that you provide (e.g. metadata), such as the location of a photo or the date a file was created. </p>
                <li style={{textDecoration:'underline'}}>Networks and connections.</li>
                <p>We collect information such as the people you follow and the people who follow you in TravelAIO and how you interact with them through our website/app.</p>
                <li style={{textDecoration:'underline'}}>Usage.</li>
                <p>We collect information about how you use TravelAIO, such as the posts and followers you engage with, features you use, locations and tags you interact with through the website, how often you visit the website, how long you spend on the website etc.</p>
            </ul>

            <p style={{fontWeight:'bold'}}>Device Information</p>

            <p>As described below, we collect information about the computers, phones and tablets your use to browse the website.</p>
            <p>Information we obtain from these devices includes:</p>
            <ul>
                <li>Device attributes such as the device type, operating system and browser etc.</li>
                <li>Data from device settings: information you allow us to receive through device settings that you turn on, such as access to your GPS location, camera or photos.</li>
                <li>Cookie data: data from cookies stored on your device, including cookie IDs and settings.</li>
            </ul>

            <h4 style={{fontWeight:'bold'}}>How do we use this information?</h4>

            <p>We use the information that you provide us as described below:</p>
            
            <ul>
                <li style={{textDecoration:'underline'}}>Provide, personalize and improve our website.</li>
                <p>We use this information to personalize features and content for you within the website, such as suggesting locations, users, activities, tags etc. that you may be interested in. </p>
                <li style={{textDecoration:'underline'}}>Location-related information: </li>
                <p>We use location-related information - such as your current location, your country, the places you add to your favorites, visited places and bucket list to improve our website so that we will be able to better understand attributes of locations (such as how popular it is as a travel destination).</p>
                <li style={{textDecoration:'underline'}}>Research and development: </li>
                <p>We use the information we have to develop, test and improve our website, including by conducting surveys and research, and testing and troubleshooting new features.</p>
                <li style={{textDecoration:'underline'}}>Promoting safety, integrity and security</li>
                <p>We use the information that we have to verify accounts and activity, combat harmful conduct, detect and prevent spam and other bad experiences, maintain the integrity of the website. For example, we use data that we have to investigate suspicious activity or violations of our Terms or Policies.</p>
                <li style={{textDecoration:'underline'}}>Communicate with you.</li>
                <p>We use the information that we have to send you marketing communications, communicate with you about updates to our website. </p>
            </ul>

            <h4 style={{fontWeight:'bold'}}>How is this information shared?</h4>
            <p>When you share posts, interact with users and locations, other users can see your posts, comments, locations you interact with, posts your interact with, users you follow and users who follow you. They can also see the information you add to your profile such as your name, display picture, bio, country, links to your social media, your YouTube channel, blog or personal website. People who are visiting the website but are not registered users may be able to see your posts in the homepage, but will not be able to interact with them.</p>

            <h4 style={{fontWeight:'bold'}}>How to contact us with questions</h4>
            <p>You can contact us at support@travelaio.com</p>

            <p>We may need to make changes to these Terms so that they accurately reflect our policies. We may change these terms at any time without prior notification. </p>

            <p>© 2022 TravelAIO</p>



        </Grid>
      </Container>
    </Grow>
  );
};

export default Privacy;
