import { FETCH_LOCATION_POSTS, CLEAR_LOCATION_POSTS, UPDATE, LIKE, DISLIKE, DELETE, ADD_COMMENT_REFRESH } from '../constants/actionTypes';

export default (places = [], action) => {
  switch (action.type) {
    case FETCH_LOCATION_POSTS:
      if(!action.payload.length) {
        return places = [0];
      } else {
      return action.payload;
      }
    case LIKE:
      return places.map((place) => (place.id === action.payload.id ? action.payload : place));
    case DISLIKE:
      return places.map((place) => (place.id === action.payload.id ? action.payload : place));
    case UPDATE:
      return places.map((post) => (post.id === action.payload.id ? action.payload : post));
    case DELETE:
      return places.filter((post) => post.id !== action.payload);
    case ADD_COMMENT_REFRESH: 
      return places.map((post) => (post.id === action.payload.id ? action.payload : post));
    case CLEAR_LOCATION_POSTS:
      return places = []
    default:
      return places;
  }
};