import React, { useState, useEffect, useRef} from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Container, Button, Typography, Grid } from '@material-ui/core/';
import SearchIcon from '@material-ui/icons/Search';
// import useStyles from '../Posts/Post/styles';
import SearchResults from "./SearchResults";
// import AliceCarousel from 'react-alice-carousel';
// import "react-alice-carousel/lib/alice-carousel.css";
import { getSignature } from '../../api';
import { useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import useStyles from './styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { getLocationPosts, getLocation } from '../../actions/posts';
import { addToFavorites, getFavorites, removeFromFavorites, addToVisited, getVisited, removeFromVisited, addToBucket, getBucket, removeFromBucket, locationImage } from "../../actions/favorites";
// import { addToVisited, getVisited, removeFromVisited } from "../../actions/favorites";
// import Posts from '../Posts/LocationPosts';

const SearchLocationInput = () => {
  const user = JSON.parse(localStorage.getItem('profile'));
  const userId = user?.result?.googleId || user?.result?.id;
  const favState = useSelector((state) => state.favorites);
  const visitState = useSelector((state) => state.visited);
  const bucketState = useSelector((state) => state.bucket);
  // const favState = useSelector((state) => state.placeSelect?.favs);
  // const visitState = useSelector((state) => state.placeSelect?.visits);
  // const bucketState = useSelector((state) => state.placeSelect?.buckets);

  const [open, setOpen] = React.useState(false);
  const [snack, setSnack] = useState();
  const [image, setImage] = useState(null);
  const [locImage, setLocImage] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [favs, setFavs] = useState(null);
  const [visits, setVisits] = useState(null);
  const [buckets, setBuckets] = useState(null);
  const [canReact, setCanReact] = useState(true);
  const searchRef = useRef();
  const [scroll, setScroll] = useState(false);
  // console.log(userId);
  
///////////////////////////////////////
// console.log(favState);
// console.log(favs);

const MyWidget = () => {
  var myWidget = window.cloudinary.createUploadWidget(
    {
      cloud_name: 'travelaio',
      api_key: '222487881423418',
      // public_id:'ohHiMark',
      // uploadPreset: "<upload_preset>",
      upload_signature: generateSignature,
    },
    (error, result) => {
      if (!error && result && result.event === "success") {
        // console.log("Done! Here is the image info: ", result.info);
        setLocImage(result.info.secure_url);
        setImage(result.info.original_filename + '.' + result.info.format)
        // console.log(postData);
      } else if(error) {
        console.log(error);
        // console.log(result); 
      }
    }
  );

  const handleWidget = (e) => {
    e.preventDefault();
    myWidget.open();
  }
  
  return (
    <>
    <Button id="upload_widget" className="cloudinary-button" variant="contained" color="primary" size="large" onClick={handleWidget} style={{marginLeft:'7px', marginBottom:'15px'}}>
      Add Photo
    </Button>
    {/* <p style={{marginLeft:'20px'}}>{image}</p> */}

    {(image) && 
    <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginBottom:'15px', marginLeft:'5px'}}>
      <p style={{marginLeft:"5px", marginRight:'5px'}}>Photo Added</p>
      <CheckCircleIcon style={{color:'green'}} />
    </div>
    }
    </>
  )
}

const generateSignature = async (callback, params_to_sign) => {
  try {
    const signature = await getSignature(params_to_sign);
    callback(signature.data);
    // console.log(signature.data);
    // console.log(params_to_sign);
    // console.log(callback);
  } catch (error) {
    console.log(error);
  }
};  

const handleLocationImage = async (e) => {
  e.preventDefault();
  dispatch(locationImage({ locationImage:locImage, coordinates: placeDetails.coordinates }));

};


  const addToFavs = () => {
    setCanReact(false);
    setScroll(false);
    setFavs([...favs, userId ])
    dispatch(addToFavorites(placeDetails));
    setSnack('F1');
    setOpen(true);
  }
  const removeFromFavs = () => {
    setCanReact(false);
    setScroll(false);
    setFavs(favs.filter((fav) => fav !== userId));
    dispatch(removeFromFavorites(placeDetails.coordinates))
    setSnack('F2');
    setOpen(true);
  }

  const addToVisits = () => {
    setCanReact(false);
    setScroll(false);
    setVisits([...visits, userId])
    dispatch(addToVisited(placeDetails));
    setSnack('V1');
    setOpen(true);
  }
  const removeFromVisits = () => {
    setCanReact(false);
    setScroll(false);
    setVisits(visits.filter((visit) => visit !== userId));
    dispatch(removeFromVisited(placeDetails.coordinates))
    setSnack('V2');
    setOpen(true);
  }

  const addToBuckets = () => {
    setCanReact(false);
    setScroll(false);
    setBuckets([...buckets, userId]);
    dispatch(addToBucket(placeDetails));
    setSnack('B1');
    setOpen(true);
  }
  const removeFromBuckets = () => {
    setCanReact(false);
    setScroll(false);
    setBuckets(buckets.filter((bucket) => bucket !== userId));
    dispatch(removeFromBucket(placeDetails.coordinates))
    setSnack('B2');
    setOpen(true);
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  // const handleSnack = () => {
  //   setOpen(true);
  // };

  const snackClose = () => {
    setOpen(false);
    setSnack(null)
  };
  ///////////////////////////////////

  // console.log(favs);
  // console.log(visits);
  const dispatch = useDispatch();
   
  const classes = useStyles();
  const [place, setPlace] = 
    useState({name:'',
              // id:'',
              // address:'',
              photos:[],
              url:''
  });
  const location = place.name;
  // console.log(location);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [SearchLocationInput, dispatch]);


//   useEffect(() => {
//     dispatch(getLocationPosts(location));
// }, [handlePlaceSelect, dispatch])

// const [userFav, setUserFav] = useState(false);

  const [query, setQuery] = useState(""); 
  // const [city, setCity] = useState("")
  const [placeDetails, setPlaceDetails] = useState({latitude: '', longitude: '', coordinates: '', location: '', name:'', placeName:'', country: ''});
  const autoCompleteRef = useRef(null);
  const topLocation = useSelector((state) => (state.locations))
  // console.log(topLocation);
  // console.log(place);
  
  

  // let autoComplete;

  const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const handleScriptLoad = () => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    // { 
    //   // types: ["(cities)"],
    //  componentRestrictions: { country: "lk" } }
  );
  // autoCompleteRef.current.setFields(["address_components", "formatted_address", 'place_id', 'geometry', 'name', 'photos']);
  autoCompleteRef.current.addListener("place_changed", handlePlaceSelect);
}



const handleSearchBar = () => {
  var input = document.getElementById("searchbar");
  input.addListener("keyup", function(event) {
      if (event.KeyboardEvent.code === 13) {
          event.preventDefault();
          document.getElementById("searchbar").click();
      }
  });
}

const handlePlaceSelect = () => {
  const addressObject = autoCompleteRef.current.getPlace();
  const name = addressObject.name;
  const photos = addressObject.photos;
  const long_name = addressObject.address_components.map(addressComponent => `(?=.*?${addressComponent.long_name})`);
  const long_name_norm = addressObject.address_components.map(addressComponent => addressComponent.long_name);
  // console.log(long_name_norm);
  // const lon = long_name_str.replace(/./g, '');
  const countryString = addressObject.adr_address.substring(addressObject.adr_address.lastIndexOf("<span class=\"country-name\">"), addressObject.adr_address.lastIndexOf("<"));
  const country = countryString.split(">")[1];
  // const url = addressObject.url;
  const placeName = name + "," + " " + country;
  const long_name_str = long_name.toString().replace(/,/g, '');
  const long_name_norm_str = long_name_norm.toString();
  // console.log(addressObject);
  // console.log(long_name_norm_str);
  // console.log(long_name_str);
  const latitude = (addressObject.geometry.location.lat());
  const longitude = (addressObject.geometry.location.lng());
  const coordinates = addressObject.geometry.location.lat() + ', ' + addressObject.geometry.location.lng();
  // updateQuery(query);
  // console.log(coordinates);
  setPlace({...place, name:placeName, photos: photos});
  setPlaceDetails({...placeDetails, latitude: latitude, longitude: longitude, coordinates: coordinates, location: long_name_norm_str, name: name, placeName: placeName, country: country}); 
  
  // setPlace({...place, });
  // dispatch(getLocationPosts(long_name_str));
  // dispatch(getLocationPosts(long_name_str));

  // dispatch(getFavorites(coordinates))
  //   .then(() =>
  //   dispatch(getLocationPosts(long_name_str))
  //   );

    // dispatch(getFavorites(coordinates))
    // .then(() =>
    // dispatch(getVisited(coordinates))
    // );

    dispatch(getLocation(coordinates))
    .then(() => 
    dispatch(getLocationPosts(long_name_str))
    )
    

    // dispatch(getFavorites(coordinates))
    // .then(() =>
    // dispatch(getVisited(coordinates))
    // .then(() =>
    // dispatch(getBucket(coordinates))
    // .then(() =>
    // dispatch(getLocationPosts(long_name_str))
    // ),searchRef.current.scrollIntoView({behavior:'smooth'})
    
    // )
    // )
    // if (!searchRef.current) return;
    // searchRef.current.scrollIntoView({behavior:'smooth'});
    setShowResults(true);
    setScroll(true);
    if(searchRef.current !== null) {
      (scroll === true && (
      searchRef.current.scrollIntoView({behavior:'smooth'})
      ))
    }
  
}

// useEffect(() => {
//   if (!searchRef.current) return;
//   searchRef.current.scrollIntoView({behavior:'smooth'});
// }, [dispatch, handlePlaceSelect])

useEffect(() => {
  setFavs(favState);
}, [favState]);

useEffect(() => {
  setVisits(visitState);
}, [visitState]);

useEffect(() => {
  setBuckets(bucketState);
}, [bucketState]);

useEffect(() => {
  if(canReact === false) {
    setCanReact(true)
  }
}, [favState, visitState, bucketState]);



  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyDXGCxkxHNNK_ZO18wSSJlcwCA242XZuqk&callback=initMap&libraries=places`,
      
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  // const handleDragStart = (e) => e.preventDefault();

  // const items = [
  //   <img src="path-to-img" onDragStart={handleDragStart} />,
  //   <img src="path-to-img" onDragStart={handleDragStart} />,
  //   <img src="path-to-img" onDragStart={handleDragStart} />,
  // ];

  // const Gallery = () => {
  //   return (
  //     <AliceCarousel mouseTracking items={items} />
  //   );
  // }
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 5 },
};

// const 


// const handleClick = () => {
// //   if(userFav == false) {
// //     dispatch(addToFavorites(placeDetails))
// //   } else {
// //     dispatch(removeFromFavorites(placeDetails.coordinates))
// //   } 
// }

// if(topLocation.coordinates){
//   dispatch(getFavorites(topLocation.coordinates));
//   console.log('Mark Says Hi');
// }
if(topLocation.coordinates){
const loc_str = topLocation.location.split(',');
const loc_arr = loc_str.map(t => `(?=.*?${t})` );
const loc_arr_str = loc_arr.toString().replace(/,/g, '');
// console.log(loc_arr);
// console.log(loc_arr_str);
}

useEffect(() => {
  // if(topLocation.coordinates){
//   dispatch(getFavorites(topLocation.coordinates));
//   console.log('Mark Says Hi');
// }
  // dispatch(getFavorites(topLocation.coordinates));
  if(topLocation.coordinates){
    const loc_str = topLocation.location.split(',');
    const loc_arr = loc_str.map(t => `(?=.*?${t})` );
    const loc_arr_str = loc_arr.toString().replace(/,/g, '');
    // console.log(loc_arr);
    // console.log(loc_arr_str);
    setPlaceDetails(topLocation);
  
    // dispatch(getLocationPosts(loc_arr_str))
    // .then(() =>
    // dispatch(getFavorites(topLocation.coordinates))
    // );

    // dispatch(getFavorites(topLocation.coordinates))
    // .then(() =>
    // dispatch(getVisited(topLocation.coordinates))
    // .then(() =>
    // dispatch(getBucket(topLocation.coordinates))
    // .then(() =>
    // dispatch(getLocationPosts(loc_arr_str)), searchRef.current.scrollIntoView({behavior:'smooth'})
    // )
    // )
    // )

    dispatch(getLocation(topLocation.coordinates))
    .then(() => 
    dispatch(getLocationPosts(loc_arr_str))
    )


    setShowResults(true);
    setScroll(true);
    

  }
}, [topLocation.coordinates]);
// if(topLocation.location){
//   dispatch(getLocationPosts(topLocation.location));
//   console.log('Mark Says Hi Again');
// }

useEffect(() => {
  if (!searchRef.current) return;
  (scroll === true && (
  searchRef.current.scrollIntoView({behavior:'smooth'})
  ))
}, [getLocationPosts()])

const Fav = () => {
  if(favs) {
  if(favs.length > 0) {
    return favs.find((fav) => fav === (userId))
    ? (
      <>
      <IconButton className={classes.favorite} size="small" onClick={removeFromFavs} disabled = {canReact === false}>
        <FavoriteIcon fontSize="large" color="secondary" />
      </IconButton>
      {/* <Typography variant="subtitle1">{favs.length}</Typography> */}
      </>
    ) : (
      <>
      <IconButton className={classes.favorite} size="small" onClick={addToFavs} disabled = {canReact === false}>
        <FavoriteBorderIcon fontSize="large" />
      </IconButton>
      {/* <Typography variant="subtitle1">{favs.length}</Typography> */}
      </>
    )
  } }
  return  <IconButton className={classes.favorite} size="small" onClick={addToFavs} disabled = {canReact === false}>
            <FavoriteBorderIcon fontSize="large"/>
          </IconButton>
}

const Visited = () => {
  if(visits) {
  if(visits?.length > 0) {
    return visits.find((visit) => visit === (user?.result?.googleId || user?.result?.id))
    ? (
      <>
      <IconButton className={classes.favorite} size="small" onClick={removeFromVisits} disabled = {canReact === false} >
        <CheckCircleIcon fontSize="large" style={{color:'#00a152'}} />
      </IconButton>
      {/* <Typography variant="subtitle1">{visits.length}</Typography> */}
      </>
    ) : (
      <>
      <IconButton className={classes.favorite} size="small" onClick={addToVisits} disabled = {canReact === false}>
      <CheckCircleOutlineIcon fontSize="large" />
      </IconButton>
      {/* <Typography variant="subtitle2">{visits.length}</Typography> */}
      </>
    )
  } }
  return <IconButton className={classes.favorite} size="small" aria-label="add" onClick={addToVisits} disabled = {canReact === false}>
    <CheckCircleOutlineIcon fontSize="large" />
    </IconButton>  
}

const Bucket = () => {
  if(buckets) {
  if(buckets?.length > 0) {
    return buckets.find((bucket) => bucket === (user?.result?.googleId || user?.result?.id))
    ? (
      <>
      <IconButton className={classes.favorite} size="small" onClick={removeFromBuckets} disabled = {canReact === false}>
        {/* <BookmarkIcon fontSize="large" style={{color:'#ff9100'}} /> */}
        <img src="BucketIcon.png" style={{maxWidth:'35px'}} />
        </IconButton>
        {/* <Typography variant="subtitle1">{buckets.length}</Typography> */}
        </>
    ) : (
      // dispatch(addToBucket(topLocation.coordinates ? topLocation : placeDetails))}><BookmarkBorderIcon fontSize="large" /></IconButton>
      // <><IconButton className={classes.favorite} size="small" aria-label="add" onClick={addToBuckets}><BookmarkBorderIcon fontSize="large" /></IconButton>
      <><IconButton className={classes.favorite} size="small" aria-label="add" onClick={addToBuckets} disabled = {canReact === false}>
        {canReact ? <img src="BucketBorderIcon.png" style={{maxWidth:'35px'}} /> : <img src="BucketBorderIconDisabled.png" style={{maxWidth:'35px'}} /> }
        </IconButton>
      {/* {/* <Typography variant="subtitle1">{buckets.length}</Typography> */}
      </>
    )
  } }
  // return <IconButton className={classes.favorite} size="small" aria-label="add" onClick={addToBuckets}><BookmarkBorderIcon fontSize="large" /></IconButton>  
  return <IconButton className={classes.favorite} size="small" aria-label="add" onClick={addToBuckets} disabled = {canReact === false}>
        {canReact ? <img src="BucketBorderIcon.png" style={{maxWidth:'35px'}} /> : <img src="BucketBorderIconDisabled.png" style={{maxWidth:'35px'}} /> }
    </IconButton>  

}

// console.log(userFav);
  return (
    <Container id="searchContainer">
    <Grid id="gridImage" className="search-location-input" style={{ backgroundSize:'cover', backgroundPosition:'30%', display:"flex", flexDirection:'column', justifyContent:'center',  alignItems:'center', height:'450px', margin:'0px'}}>
      <div class="searchDiv">
      <SearchIcon style={{backgroundColor:'white', borderTopLeftRadius: '25px', borderBottomLeftRadius: '25px', padding:'12px', fontSize:'2.8rem'}}/>
        <input
        ref={autoCompleteRef}
        onChange={event => setQuery(event.target.value)}
        placeholder="Search For Destinations"
        value={query}
        onLoad = {handleSearchBar}
      />
      </div>
      
    
    <div class="searchBtns" >
    {/* <Typography style={{color:'white'}} variant="subtitle2">Searching For : </Typography> */}
    {/* <Typography style={{color:'white'}}> | </Typography> */}
    <Button size="small" component={Link} to="/search" style={{margin:'10px', marginLeft:0, textTransform:'inherit', color:'#333', color:'white', backgroundColor:'rgba(0,0,0,0.7)', border:'2px solid #ededed', padding:'7px'}}>
        Destinations
    </Button>    
    <Typography style={{color:'white'}}> | </Typography>
    <Button size="small" component={Link} to="/search/users" style={{margin:'10px', textTransform:'inherit', color:'white', backgroundColor:'rgba(0,0,0,0.4)'}}>
        Users
    </Button>
    <Typography style={{color:'white'}}> | </Typography>
    <Button size="small" component={Link} to="/search/tags" style={{margin:'10px', textTransform:'inherit', color:'white', backgroundColor:'rgba(0,0,0,0.4)'}}>
        Tags
    </Button>
    {/* <p>{topLocation.coordinates}</p> */}
    </div>
    </Grid>
    {/* {place.photos.map((photo)=> (
       <img src={photo.getUrl()}></img>  
       
      ))} */}
      {/* <div style={{height:'300px'}}> */}
      {showResults && (
        <Grid container style={{borderBottom:'2px solid #EDEDED'}}>
          <Grid container className={classes.titleBar}>
          <Grid item id="searchTitle" className={classes.title}  style={{}} >
            <Typography id="place" variant="h4">{placeDetails.name}</Typography>
            {placeDetails.name !== placeDetails.country && (
            <>
            <Typography id="comma" variant="h5" style={{color:'#818181'}}>&nbsp;&#183;&nbsp;</Typography>
            <Typography id="country" variant="h5" style={{color:'#818181'}}>{placeDetails.country}</Typography>
            </>
            )}
            {/* <><FavoriteBorderIcon fontSize="small" />{favs.length} people like this</> */}
          </Grid>
          <Grid item id="icons" className={classes.icons} style={{}}> 
            {/* <><Fab className={classes.favorite} size="small" aria-label="add" onClick={handleClick}><FavoriteBorderIcon fontSize="large" /></Fab>{favs.length}</> */}
            <Fav />
            <Typography variant="subtitle2" style={{marginLeft:'-5px', fontWeight:'bold'}}>{(favs?.length > 0 )&& favs?.length}</Typography>
            {/* <Fab className={classes.fab1} size="small" aria-label="add"><CheckCircleOutlineIcon fontSize="large" /></Fab> */}
            <Visited />
            <Typography variant="subtitle2" style={{marginLeft:'-5px', fontWeight:'bold'}}>{(visits?.length > 0 )&& visits.length}</Typography>
            {/* <Fab className={classes.fab1} size="small" aria-label="add"><BookmarkBorderIcon fontSize="large" /></Fab> */}
            <Bucket />
            <Typography variant="subtitle2" style={{marginLeft:'-5px', fontWeight:'bold'}}>{(buckets?.length > 0 )&& buckets?.length}</Typography>
          </Grid>
          {(user?.data?.user?.clearance === 'Admin') && (
            <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleLocationImage} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} >
          <MyWidget /> 
          <Button style={{ marginLeft:'10px'}} variant="contained" size="large" type="submit" color="primary">
            Post
          </Button>
          </form>
          )}
        </Grid>
        {/* {!favs.length ? <CircularProgress /> : ( 
            <Grid className={classes.container} container alignItems="stretch" spacing={3}>
              {favs.map((fav) => (
                <p> [{fav.name}] </p>
              ))}
            </Grid> )} */}
            <div style={{height:'5px', width:'5px', marginTop:'-160px'}} ref = {searchRef}></div>
        </Grid>
      )}
        
        {/* <div style={{display:'flex'}}> */}
        {(place?.photos) && 
        <Container>
        
        {/* <AliceCarousel responsive = {responsive} mouseTracking items = {place.photos.map((photo)=> 
        <div style={{height:'200px', width:'320px', backgroundColor:'red', margin:'5px', backgroundImage: `url(${photo.getUrl()})`, backgroundSize:'cover'}}>
        </div>
        )} /> */}
        {/* </div> */}
      {/* </div> */}
      
      {/* <Typography>{visits}</Typography>
      <Typography>{visitState}</Typography> */}
        </Container>
        }

        {(snack === 'F1') ? (
        <Snackbar open={open} autoHideDuration={3000} onClose={snackClose}>
        <Alert onClose={snackClose} severity="success">
          Added To Favorites.
        </Alert>
      </Snackbar>
        ) : (snack === 'F2') ? (
      <Snackbar open={open} autoHideDuration={3000} onClose={snackClose}>
        <Alert onClose={snackClose} severity="warning">
          Removed From Favorites.
        </Alert>
      </Snackbar>
        ) : (snack === 'V1') ? (
        <Snackbar open={open} autoHideDuration={3000} onClose={snackClose}>
        <Alert onClose={snackClose} severity="success">
          Added To Visited.
        </Alert>
      </Snackbar>
       ) : (snack === 'V2') ?  (
        <Snackbar open={open} autoHideDuration={3000} onClose={snackClose}>
        <Alert onClose={snackClose} severity="warning">
          Removed From Visited.
        </Alert>
      </Snackbar>
       ) : (snack === 'B1') ? (
        <Snackbar open={open} autoHideDuration={3000} onClose={snackClose}>
        <Alert onClose={snackClose} severity="success">
          Added To Bucket.
        </Alert>
      </Snackbar>
       ) : (snack === 'B2') ?  (
        <Snackbar open={open} autoHideDuration={3000} onClose={snackClose}>
        <Alert onClose={snackClose} severity="warning">
          Removed From Bucket.
        </Alert>
      </Snackbar>
       ) : (
        null
       ) 
      }
        {(showResults && <>
          {/* <div ref = {searchRef}></div> */}
          <SearchResults />
          </>
        )} 

    </Container>
  );
}

export default SearchLocationInput;