import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Container, Chip, Link, Grow, Grid, Typography, Button } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
// import ScrollableTabsButtonForce from './ProfileTabs';
// import CustomizedTabs from './ProfileTabs2';
import { getUser } from '../../actions/user';
import { getUserPosts } from '../../actions/user';
import { getFollowers, addToFollowing, removeFromFollowing } from '../../actions/user';
// import FavoriteCard from './FavoriteCard';
// import SettingsIcon from '@material-ui/icons/Settings';
// import UserInfoDialog from './UserInfo/UserInfoDialog';
// import YouTubeIcon from '@material-ui/icons/YouTube';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import LanguageIcon from '@material-ui/icons/Language';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LoadingScreen from '../Home/LoadingScreen';


const ScrollableTabsButtonForce = lazy(() => import ('./ProfileTabs'));
const UserInfoDialog = lazy(() => import('./UserInfo/UserInfoDialog'));


const Profile = () => {
  const dispatch = useDispatch();
  const {userId} = useParams();
  const user = useSelector(state => state.user);
  const followerState = useSelector(state => state.followers);
  const [followers, setfollowers] = useState(null);
  const currentUser = JSON.parse(localStorage.getItem('profile'));
  const currentUserId = currentUser?.result?.googleId || currentUser?.result?.id;
  const [pageLoading, setPageLoading] = useState(true);
  
  console.log(userId);
  console.log('hasta la vista');
  // console.log(user?.result?.id);
  // const user = '102905570188181136049'

  useEffect(() => {
    
    window.scrollTo(0, 0); 
    
    dispatch(getUser(userId)).then
    (()=>  
    dispatch(getUserPosts(userId)).then
    (()=>
    dispatch(getFollowers(userId)), setPageLoading(false)
     ) 
    );
  }, [Profile, dispatch, userId]);

  useEffect(() => {
    setPageLoading(true);
  }, [userId])
  

  
  useEffect(() => {
    setfollowers(followerState);
  }, [followerState]);
  

  const followUser = () => {
    if((currentUser?.result?.googleId && currentUser?.result?.googleId !== userId) || (currentUser?.result?.id && currentUser?.result?.id !== userId)) {
    setfollowers([...followers, currentUserId])
    dispatch(addToFollowing({userId : userId })) 
    // alert('foo!')
    } else {
      alert("Oops! You can't perform this action.")
    }
  }

  const unfollowUser = () => {
    setfollowers(followers.filter((follower) => follower !== currentUserId))
    dispatch(removeFromFollowing(userId))
  }


  const ProfileButton = () => {
    if(currentUser?.result?.googleId === userId || currentUser?.result?.id === userId) {
      return <>
      <Suspense fallback = {<Typography>Loading...</Typography>}>
        {!pageLoading && (
        <UserInfoDialog />
        )}
      </Suspense></>
      
    } else {

    if (followers?.length > 0) {
      return followers.find((follower) => follower === (currentUser?.result?.googleId || currentUser?.result?.id))
      ? (
        <Button variant="outlined" fullWidth size="large" onClick={unfollowUser}
          style={{marginLeft:'10px', marginRight:'10px', color:'#526bf4', border:'2px solid #526bf4'}}>Following</Button>
      ) : (
        <Button variant="contained" fullWidth size="large" color="primary" onClick={followUser}
          style={{marginLeft:'10px', marginRight:'10px', background:'linear-gradient(to left, #3ec7e0,#526bf4)'}}>Follow</Button>
      )
    }
    return <Button variant="contained" fullWidth size="large" color="primary" onClick={followUser}
          style={{marginLeft:'10px', marginRight:'10px', background:'linear-gradient(to left, #3ec7e0,#526bf4)'}}>Follow</Button>
  }
}

  // useEffect(() => {
  //   dispatch(getFollowers(userId))
  // }, []);

  // const user = JSON.parse(localStorage.getItem('profile'));
  // const placesCount =  user?.result?.favoriteCount + user?.result?.visitedCount + user?.result?.bucketCount;
  if((!pageLoading) && (user?.result?.id === userId)) {
    return (
        <Grow in>
      <Container style={{backgroundColor:'white'}} class="profileContainer" >
        <Grid container justify="space-between" alignItems="stretch" spacing={1} style={{
              // backgroundColor:'white',
              display:'flex',
              justifyContent:'center',
              //  boxShadow:'0 0 4px grey',
                padding:'15px'
               }}>
          <Grid container justify="space-between" alignItems="stretch" item xs={12} sm={8} md={8} style={{
              // backgroundColor:'red',
            //    boxShadow:'0 0 4px black', padding:'15px'
               }}>

          <Grid item xs={12} sm={6} md={4} style={{
              // backgroundColor:'blue',
               display:'flex', justifyContent:'center', alignItems:'center'}}>
            <img style={{border: '10px solid #F5F5F5', boxShadow: '0 0 5px silver', borderRadius:'50%', margin:'5px', height:'200px', width:'200px', objectFit:'cover'}} src = {user?.result?.profilePic || "Avatar.png"}></img>
          </Grid>
          <Grid id="infoHolder" item xs={12} sm={6} md={8} style={{
              display:'flex', flexDirection:'column', justifyContent:'center',
              // backgroundColor:'yellow'
              }}>
              <Grid container id="nameCountry" style={{backgroundColor:'', borderBottom:'2px solid #EDEDED', paddingBottom:'10px', paddingTop:'10px'}} spacing={1}>
                <Grid item id="profileName">
                  <Typography variant="h5">{user?.result?.name}</Typography>
                </Grid>
                <Grid item>
                <Chip
                  icon={<LocationOnIcon style={{color:'white'}}/>}
                  label={user?.result?.country}
                  // onDelete={handleDelete}
                  // color="primary"
                  style={{background:'linear-gradient(to left, #3ec7e0,#526bf4)', color:'white', }}
                  // variant="contained"
                />
                </Grid>
              </Grid>
              <Grid item id="profileBio" style={{backgroundColor:'', marginTop:'5px', marginBottom:'5px'}}>
                <Typography variant="subtitle1" style={{marginLeft:'3px'}}>{user?.result?.bio}</Typography>
                {/* <Typography>{followers}</Typography>
                <Typography>x</Typography>
                <Typography>{followerState}</Typography> */}
              </Grid>
              {/* <Grid container >
                <Grid item md={6}>
                <Chip
                  icon={<LocationOnIcon />}
                  label={user?.result?.country}
                  // onDelete={handleDelete}
                  color="primary"
                  style={{}}
                  variant="contained"
                />
                </Grid>
              </Grid> */}

              
              <Grid item id="socialLinks" style={{display:'flex', alignItems:'center', textDecoration:'none', color:'black', backgroundColor:''}}>
              {user?.result?.facebook ?
              <Link href={user?.result?.facebook} style={{display:'flex', alignItems:'center', textDecoration:'none', color:'black'}}><FacebookIcon fontSize="large" color="primary" style={{opacity:'1'}}  /></Link>
               : 
                  <FacebookIcon fontSize="large" color="primary" style={{opacity:'0.5'}}  />
              }
              {user?.result?.instagram ?
              <Link href={user?.result?.instagram} style={{display:'flex', alignItems:'center', textDecoration:'none', color:'black'}}><InstagramIcon fontSize="large" color="secondary" style={{opacity:'1'}} /></Link>
               : 
                  <InstagramIcon fontSize="large" color="secondary" style={{opacity:'0.1'}} />
               }
              {user?.result?.youtube ? 
              <Link href={user?.result?.youtube} style={{display:'flex', alignItems:'center', textDecoration:'none', color:'black'}}><PlayCircleFilledWhiteIcon fontSize="large" color="secondary" style={{opacity:'1'}} /></Link>
               : 
               <PlayCircleFilledWhiteIcon fontSize="large" color="secondary" style={{opacity:'0.1'}} />
               }
              {user?.result?.website ?
              <Link href={user?.result?.website} style={{display:'flex', alignItems:'center', textDecoration:'none', color:'black'}}><LanguageIcon fontSize="large" color="primary" style={{opacity:'1'}} /></Link>
              : 
              <LanguageIcon fontSize="large" color="primary" style={{opacity:'0.1'}} />
              }
              </Grid>
              {/* <Grid container >
                <Grid item md={6}>
                <Chip
                  icon={<LocationOnIcon />}
                  label={user?.result?.country}
                  // onDelete={handleDelete}
                  color="primary"
                  style={{}}
                  variant="contained"
                />
                </Grid>
              </Grid> */}
          </Grid>
          </Grid>
          <Grid container item id="profileButton" alignItems="stretch" xs={12} sm={4} md={3} style={{
              // backgroundColor:'grey', 
              // width:'30%',
            display:'flex', flexDirection:'column', justifyContent:'center', marginTop:'40px', marginBottom:'60px', boxShadow:'0 0 4px silver',
            
              }}>

          <Grid item alignItems="stretch" style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', marginBottom:'20px',
              // backgroundColor:'red'
              }}>
              {!pageLoading && (
              <ProfileButton />
              )}
              {/* <Button variant="contained" fullWidth size="large" color="primary" onClick={() => dispatch(addToFollowing({userId : userId }))}
               style={{marginLeft:'10px', marginRight:'10px'}}>Follow</Button> */}
               {/* <Button variant="contained" fullWidth size="large" color="primary" onClick={() => dispatch(removeFromFollowing(userId))}
               style={{marginLeft:'10px', marginRight:'10px'}}>UnFollow</Button> */}
              </Grid>
                  <Grid item spacing={1} alignItems="stretch" style={{
              // backgroundColor:'yellow',
            display:'flex', flexDirection:'row', justifyContent:'center', width:'100%', marginBottom:'-5px'
              }}>
                <Grid xs={3} sm ={3} md={3} lg={3} item alignItems="stretch" spacing={1} style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', margin:'auto', marginLeft:'0', paddingLeft:'0',
              // backgroundColor:'red'
              }}>
                <Typography variant="h6">{user?.result?.postCount}</Typography>
                <Typography variant="subtitle2">Posts</Typography>
              </Grid>
              <Grid xs={3} sm ={3} md={3} lg={3} item alignItems="stretch" spacing={1} style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', margin:'auto',}}>
                <Typography variant="h6">{followerState.length}</Typography>
                <Typography variant="subtitle2">Followers</Typography>
              </Grid>
              <Grid xs={3} sm ={3} md={3} lg={3} item alignItems="stretch" spacing={1} style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', margin:'auto', marginRight:'0'}}>
                <Typography variant="h6">{user?.result?.placeCount}</Typography>
                <Typography variant="subtitle2">{user?.result?.placeCount === 1 ? 'Place' : 'Places'}</Typography>
              </Grid>
              
                
              </Grid>
              {/* <Grid xs={12} sm ={12} md={12} lg={12} item alignItems="stretch" style={{
              backgroundColor:'yellow',
            display:'flex', flexDirection:'row', justifyContent:'center', width:'100%'
              }}>
                <Button variant="contained" size="large" color="primary" >Follow</Button>
              </Grid> */}

              {/* <Grid item alignItems="stretch" style={{display:'flex', flexDirection:'row', width:'100%', 
              // backgroundColor:'blue'
              }}>
              <Grid xs={12} sm ={12} md={12} lg={12} item alignItems="stretch" spacing={1} style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                <Typography variant="h6">120</Typography>
                <Typography variant="h6" style={{textAlign:'center'}}>Places Visited</Typography>
              </Grid>
            </Grid> */}

              
          </Grid>
        </Grid>

        <Grid container justify="space-between" alignItems="stretch" spacing={1} style={{
              // backgroundColor:'red',
              //  boxShadow:'0 0 4px grey',
              display:'flex',
              justifyContent:'center',
                paddingTop:'15px'
               }}>
                 <Grid container justify="space-between" alignItems="stretch" item xs={12} sm={12} md={8} style={{
              // backgroundColor:'red',
               boxShadow:'0 0 4px silver', 
              //  padding:'15px'
               }}>
        {/* <Grid item xs={12} sm={6} md={4} style={{
              // backgroundColor:'blue',
               display:'flex', justifyContent:'center', alignItems:'center'}}>
            <img style={{border: '10px solid #F5F5F5', boxShadow: '0 0 10px silver', borderRadius:'50%', margin:'5px', height:'200px', width:'200px'}} src = "https://res.cloudinary.com/travelaio/image/upload/v1630150067/Norway_llldp2.jpg"></img>
          </Grid> */}
          <Suspense fallback = {<Typography>Loading...</Typography>}>
            <ScrollableTabsButtonForce userId={userId} />
          </Suspense>
          {/* <FavoriteCard /> */}
          {/* <CustomizedTabs /> */}
          </Grid>
        </Grid>
      </Container>
    </Grow>
    )
  } else {
    return <LoadingScreen />
  }
}

export default Profile;
