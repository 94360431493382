import {ADD_TO_USER_BUCKET, CLEAR_USER_BUCKET, FETCH_USER_BUCKET, REMOVE_FROM_USER_BUCKET } from '../constants/actionTypes';

export default (userBuckets = [], action) => {
  switch (action.type) {
    case FETCH_USER_BUCKET:
      if(!action.payload.length) {
        return userBuckets = [0];
      } else {
      return action.payload;
      }
    case ADD_TO_USER_BUCKET:
      return userBuckets.map((bucket) => (bucket.coordinates === action.payload.coordinates ? action.payload : bucket));
    case REMOVE_FROM_USER_BUCKET:
      return userBuckets.map((bucket) => (bucket.coordinates === action.payload.coordinates ? action.payload : bucket));
      // return userFavorites.filter((favorite) => ((favorite.name !== action.payload.name) && (favorite.coordinates !== action.payload.coordinates)));
    case CLEAR_USER_BUCKET: {
      return userBuckets = [];
    }
    default:
      return userBuckets;
  }
};