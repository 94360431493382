import {ADD_TO_USER_VISITS, CLEAR_USER_VISITS, FETCH_USER_VISITS, REMOVE_FROM_USER_VISITS } from '../constants/actionTypes';

export default (userVisits = [], action) => {
  switch (action.type) {
    case FETCH_USER_VISITS:
      if(!action.payload.length) {
        return userVisits = [0];
      } else {
      return action.payload;
      }
    case ADD_TO_USER_VISITS:
      return userVisits.map((visit) => (visit.coordinates === action.payload.coordinates ? action.payload : visit));
    case REMOVE_FROM_USER_VISITS:
      return userVisits.map((visit) => (visit.coordinates === action.payload.coordinates ? action.payload : visit));
      // return userFavorites.filter((favorite) => ((favorite.name !== action.payload.name) && (favorite.coordinates !== action.payload.coordinates)));
    case CLEAR_USER_VISITS: {
      return userVisits = [];
    }
    default:
      return userVisits;
  }
};