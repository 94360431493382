import { ADD_TO_EXPLORE, FETCH_EXPLORE_VISITS, REMOVE_FROM_EXPLORE } from '../constants/actionTypes';

export default (exploreVisits = [], action) => {
  switch (action.type) {
    case FETCH_EXPLORE_VISITS:
      return action.payload;
    case ADD_TO_EXPLORE:
      return exploreVisits.map((fav) => (fav.coordinates === action.payload.coordinates ? {...fav, favs:action.payload.favs, visits:action.payload.visits, buckets:action.payload.buckets} : fav));
    case REMOVE_FROM_EXPLORE:
      return exploreVisits.map((fav) => (fav.coordinates === action.payload.coordinates ? {...fav, favs:action.payload.favs, visits:action.payload.visits, buckets:action.payload.buckets} : fav));
    default:
      return exploreVisits;
  }
};