import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, Button, Paper, Grid, Typography, Container, Divider } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { v4 as uuidv4 } from 'uuid';

import Icon from './icon';
import { signin, signup, googleLogin } from '../../actions/auth';
// import { AUTH } from '../../constants/actionTypes';
import useStyles from './styles';
import Input from './Input';

const initialState = { id: '', firstName: '', lastName: '', email: '', password: '', confirmPassword: '' };
const initialGoogleInfo = { googleId: '', familyName: '', givenName: '', name:'', email: '', token: ''}

const SignUp = () => {
  const [form, setForm] = useState(initialState);
  const [googleInfo, setGoogleInfo] = useState(initialGoogleInfo);
  const [isSignup, setIsSignup] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  let x;

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const switchMode = () => {
    setForm(initialState);
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setShowPassword(false);
  };

  const handleSubmit = (e, res) => {
    e.preventDefault();

    if (isSignup) {
      (form.password !== '' && (form.password === form.confirmPassword)) ? (
      dispatch(signup({...form, id: uuidv4()}, history))
      ) : (
        alert('Passwords Do Not Match')
      )
      // console.log(form);
    } else {
      dispatch(signin(form, history, res));
      // alert(res);
      x = res;
    }
    x = res;
  };

  const googleSuccess = async (res) => {
    const result = res?.profileObj;
    const { googleId, givenName, familyName, name, email } = result; 
    const token = res?.tokenId;
    const googleInfo = ({googleId: googleId, givenName:givenName, familyName:familyName, name:name, email:email, token:token});

    // console.log(googleInfo);

    // try {
      // dispatch({ type: AUTH, data: { result, token } });
      dispatch(googleLogin(googleInfo, history));

      // history.push('/');
    // } catch (error) {
      // console.log(error);
    // }
  };

  const googleError = () => alert('Google Sign In was unsuccessful. Try again later');

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

  return (
    <Container component="main" maxWidth="xs" id="authContainer">
      <Paper className={classes.paper} elevation={3}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">{ isSignup ? 'Sign up' : 'Sign in' }</Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2} style={{justifyContent:'center'}}>
          <GoogleLogin
            clientId="84581572937-o25524i37nvcmb5jvtv4o9vsic38948h.apps.googleusercontent.com"
            render={(renderProps) => (
              <Button className={classes.googleButton} color="secondary" fullWidth onClick={renderProps.onClick} disabled={renderProps.disabled} startIcon={<Icon />} variant="contained">
                { isSignup ? 'Google Sign Up' : 'Google Sign In' }
              </Button>
            )}
            onSuccess={googleSuccess}
            onFailure={googleError}
            cookiePolicy="single_host_origin"
          />
          <Grid container alignItems="center" justifyContent='center'>
              <Grid item xs={5}>
                <Divider />
              </Grid>
              <Grid item xs={1}>
                <Typography variant="subtitle2" style={{textAlign:'center', fontSize:'1.05rem', color:'#ababab'}}>or</Typography>
              </Grid>
              <Grid item xs={5}>
                <Divider />
              </Grid>
          </Grid>
            { isSignup && (
            <>
              <Input name="firstName" label="First Name" handleChange={handleChange} autoFocus half />
              <Input name="lastName" label="Last Name" handleChange={handleChange} half />
            </>
            )}
            <Input name="email" label="Email Address" handleChange={handleChange} type="email" />
            <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
            
            { isSignup && <Input name="confirmPassword" label="Repeat Password" handleChange={handleChange} type="password" /> }
          </Grid>
          {(form.password !== '' && (form.password === form.confirmPassword)) ? 
            <Typography style={{paddingTop:'10px', color:'green'}} variant="subtitle2">Passwords Match</Typography> : null
            }
            {(form.password !== '' && form.confirmPassword !== '' && (form.password !== form.confirmPassword)) ? 
            <Typography style={{paddingTop:'10px'}} color="secondary" variant="subtitle2">Passwords Do Not Match</Typography> : null
            }
            <Grid container spacing={2} style={{justifyContent:'center'}}>
          <Button type="submit" fullWidth variant="contained" className={classes.submit}>
            { isSignup ? 'Sign Up' : 'Sign In' }
          </Button>
          </Grid>
          {!isSignup && 
              <Grid container justify="center">
              <Grid item>
                <Button component={Link} to="/account/reset/email" style={{textTransform:'capitalize', color:'lightslategray', marginTop:'5px'}}>
                  Forgot Password?
                </Button>
              </Grid>
            </Grid>
            }
            <Grid container alignItems="center" justifyContent='center' style={{marginBottom:'15px', marginTop:isSignup ? '15px' : '5px'}}>
              <Grid item xs={11}>
                <Divider />
              </Grid>
          </Grid>
          {/* <GoogleLogin
            clientId="84581572937-o25524i37nvcmb5jvtv4o9vsic38948h.apps.googleusercontent.com"
            render={(renderProps) => (
              <Button className={classes.googleButton} color="secondary" fullWidth onClick={renderProps.onClick} disabled={renderProps.disabled} startIcon={<Icon />} variant="contained">
                { isSignup ? 'Google Sign Up' : 'Google Sign In' }
              </Button>
            )}
            onSuccess={googleSuccess}
            onFailure={googleError}
            cookiePolicy="single_host_origin"
          /> */}
          <Button style={{textTransform:'capitalize', color:'black', border:'2px solid #333'}} onClick={switchMode} variant="outlined" fullWidth>
                { isSignup ? 'Already have an account? Sign in' : "Don't have an account? Sign Up" }
          </Button>
          {/* <Grid container justify="flex-end">
            <Grid item>
              <Button style={{textTransform:'capitalize'}} onClick={switchMode} variant="outlined" fullidth>
                { isSignup ? 'Already have an account? Sign in' : "Don't have an account? Sign Up" }
              </Button>
            </Grid>
          </Grid> */}
          {/* {!isSignup && 
              <Grid container justify="flex-end">
              <Grid item>
                <Button component={Link} to="/account/reset/email" style={{textTransform:'capitalize'}}>
                  Forgot Password?
                </Button>
              </Grid>
            </Grid>
            } */}
        </form>
      </Paper>
      <Grid container style={{display:'flex', justifyContent:'center'}}>
      <Button style={{textTransform:'inherit', color:'silver'}} component={Link} to="/terms">Terms</Button>
      <Button style={{textTransform:'inherit', color:'silver'}} component={Link} to="/privacy">Privacy</Button>
      </Grid>
    </Container>
  );
};

export default SignUp;
