import React from 'react';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

import FollowingCard from './FollowingCard';
// import useStyles from './styles';

const FollowingCards = () => {
  const users = useSelector((state) => state.following);
//   const ids = posts.map(post => post.id)
//   console.log(ids);
//   const classes = useStyles();

if(users.includes(0)) {
  return <Typography variant="subtitle1" style={{margin:'auto', color:'darkgray'}}>No users to show yet.</Typography>
}

  return (
    !users.length ? <CircularProgress style={{margin:'auto'}}/> : (
      <Grid container alignItems="stretch" spacing={3} style={{background:'white', marginLeft:'auto', marginRight:'auto', marginTop:'0px', width:'100%', border:'2px solid #ededed', borderRadius:'15px', paddingBottom:'10px', marginBottom:'10px'}}>
        {users.map((user) => (
          <Grid item xs={12} sm={12} md={12} style={{padding: '10px', paddingBottom:'0'}}>
            <FollowingCard traveler={user} />
          </Grid>
        ))}
      </Grid>
    )
  );
};

export default FollowingCards;
