import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Icon, Typography, IconButton, MenuItem, Menu, Button, Grid } from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import decode from 'jwt-decode';
import useStyles from './styles';
import logo from '../../images/TravelAIO7.png';
// import InputBase from '@material-ui/core/InputBase';
// import Badge from '@material-ui/core/Badge';
// import MenuItem from '@material-ui/core/MenuItem';
// import Menu from '@material-ui/core/Menu';
// import MenuIcon from '@material-ui/icons/Menu';
// import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
// import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
// import SearchIcon from '@material-ui/icons/Search';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
// import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
// import MoreIcon from '@material-ui/icons/MoreVert';
// import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
// import { red } from '@material-ui/core/colors';
// import CreateFormDialog from '../Posts/CreatePost/CreateFormDialog';
import NotificationInbox from '../Home/Notifications';
import LandingPage from '../Home/LandingPage';
// import FixedBottomNavigation from './MobileNavbar';

const MobileTopNav = ({userStatus1, userStatus2}) => { //export dis
  const classes = useStyles();
  const [user, setuser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // const userO = JSON.parse(localStorage.getItem('profile'));
  // const userId = userObj?.result?.googleId || userObj?.result?.id;
  // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const logout = () => {
    dispatch({ type: 'LOGOUT'});
    history.push('/');
    setuser(null);
    handleMenuClose();
    
    };
    // console.log(user);
useEffect(() => {
    const token = user?.token;

    if(token) {
        const decodedToken = decode(token);
        if(decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setuser(JSON.parse(localStorage.getItem('profile'))); 
}, [location]);


  const isMenuOpen = Boolean(anchorEl);
  // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleMobileMenuClose = () => {
  //   setMobileMoreAnchorEl(null);
  // };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  // const handleMobileMenuOpen = (event) => {
  //   setMobileMoreAnchorEl(event.currentTarget);
  // };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem component={Link} to={`/${user?.result?.googleId || user?.result?.id}`} style={{paddingLeft : 0}}>
        <IconButton aria-label="show 4 new mails" color="inherit">
            <AccountCircleOutlinedIcon />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem onClick={logout} style={{paddingLeft : 0}}>
        <IconButton aria-label="show 4 new mails" >
            <Icon style={{color:"black"}}>logout</Icon>
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  // const mobileMenuId = 'primary-search-account-menu-mobile';
  // const renderMobileMenu = (
  //   <Menu
  //     anchorEl={mobileMoreAnchorEl}
  //     anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     id={mobileMenuId}
  //     keepMounted
  //     transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     open={isMobileMenuOpen}
  //     onClose={handleMobileMenuClose}
  //   >
  //     <MenuItem>
  //       <IconButton aria-label="show 4 new mails" color="inherit">
  //         <Badge badgeContent={4} color="secondary">
  //           <MailIcon />
  //         </Badge>
  //       </IconButton>
  //       <p>Messages</p>
  //     </MenuItem>
  //     <MenuItem>
  //       <IconButton aria-label="show 11 new notifications" color="inherit">
  //         <Badge badgeContent={11} color="secondary">
  //           <NotificationsOutlinedIcon />
  //         </Badge>
  //       </IconButton>
  //       <p>Notifications</p>
  //     </MenuItem>
  //     <MenuItem onClick={handleProfileMenuOpen}>
  //       <IconButton
  //         aria-label="account of current user"
  //         aria-controls="primary-search-account-menu"
  //         aria-haspopup="true"
  //         color="inherit"
  //       >
  //         <AccountCircleOutlinedIcon />
  //       </IconButton>
  //       <p>Profile</p>
  //     </MenuItem>
  //   </Menu>
  // );

  if ((location.pathname == '/') && (userStatus1 !== 'Active' && userStatus2 !== 'Active')) {
    return null;
  }
  if (location.pathname == '/auth') {
    return null;
  }  
  else {

  return (
    // <>
    <div id = "mobileTopNav" className={classes.grow}>
      <AppBar className={classes.appbar} position="static">
        <Toolbar style={{paddingLeft:0}}>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}
          {/* <Typography component={Link} to="/" className={classes.mobileTitle} variant="h6" noWrap style={{fontFamily:'Source Sans Pro', fontWeight:'bold'}}>
            TravelAIO
          </Typography> */}
          <Grid component={Link} to="/" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <img src={logo} style={{maxWidth:'145px'}} />
          </Grid>
          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div> */}
          {/* <MenuItem>
              <IconButton color="inherit">
                <HomeOutlinedIcon />
              </IconButton>
              <p>Home</p>
            </MenuItem>

            <MenuItem>
              <IconButton color="inherit">
                <AddBoxOutlinedIcon />
              </IconButton>
              <p>Create</p>
            </MenuItem> */}
          <div className={classes.grow} />
          <div className={classes.sectionMobile}>

            {/* <MenuItem  component={Link} to="/" >
              <IconButton color="inherit">
                <HomeOutlinedIcon />
              </IconButton>
              <p>Home</p>
            </MenuItem> */}

            {/* <MenuItem>
              <IconButton color="inherit">
                <AddBoxOutlinedIcon />
              </IconButton>
              <p>Create</p>
            </MenuItem> */}

            {/* <CreateFormDialog /> */}
            
            {/* <MenuItem component={Link} to="/create">
              <IconButton color="inherit">
                <AddBoxOutlinedIcon />
              </IconButton>
              <p>Create</p>
            </MenuItem>

            
            <MenuItem component={Link} to="/search">
              <IconButton color="inherit">
                <SearchIcon />
              </IconButton>
              <p>Search</p>
            </MenuItem>

            <MenuItem component={Link} to="/explore">
            <IconButton color="inherit">
              <ExploreOutlinedIcon />
            </IconButton>
            <p>Explore</p>
            </MenuItem> */}

            {/* <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
                <p>Notifications</p>
              </Badge>
            </IconButton> */}
            {user ? (
            <MenuItem id="mobileNotifications">
            <IconButton aria-label="show 17 new notifications" color="inherit" style={{zIndex:'2000'}}>
              {/* <Badge badgeContent={17} color="secondary"> */}
                {/* <NotificationsOutlinedIcon /> */}
                <NotificationInbox />
              {/* </Badge> */}
            </IconButton>
            {/* <p>Notifications</p> */}
            </MenuItem>    
            ) : (
              <MenuItem  style={{paddingRight:'6px', paddingLeft:'0'}}>
                <Button component = {Link} to="/auth" variant="contained" color="primary">Sign In</Button>
              </MenuItem>
            )}        
            
          </div>
          {/* </div> */}
          {/* <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div> */}
        </Toolbar>
      </AppBar>
      {/* {renderMobileMenu} */}
      {renderMenu}
    </div>
    // <FixedBottomNavigation />
    // </>
  )};
}

export default MobileTopNav;