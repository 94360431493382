import {CLEAR_PROFILE_FOLLOWERS, GET_PROFILE_FOLLOWERS, ADD_TO_PROFILE_FOLLOWING, REMOVE_FROM_PROFILE_FOLLOWING } from '../constants/actionTypes';

export default (profileFollowers = [], action) => {
  switch (action.type) {
    case GET_PROFILE_FOLLOWERS:
      if(!action.payload.length) {
        return profileFollowers = [0];
      } else {
      return action.payload;
      }
  
    case ADD_TO_PROFILE_FOLLOWING:
      return [...profileFollowers, action.payload];
    case REMOVE_FROM_PROFILE_FOLLOWING:
      return profileFollowers.filter((followers) => followers !== action.payload);

    case CLEAR_PROFILE_FOLLOWERS:
      return profileFollowers = [];
    default:
      return profileFollowers;
  }
};