import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme)=>({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: 'white',
    // marginLeft:'10px',
    marginTop:'5px',
    marginBottom:'5px',
    border:'2px solid #fafafa',
    borderRadius:'15px',
    display:'flex',
    // paddingTop:'5px'    
  },
  avatar : {
    height:'40px',
    width:'40px',
    marginBottom:'auto'
  },
  avatarContainer : {
    display:'flex',
    flexDirection:'column',
    // justifyContent:'flex-start'
  },
  actions : {
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    margin:'5px',
    marginBottom:'auto'
  },
  text : {
    display:'flex',
    flexDirection:'column',
    whiteSpace:'pre-line',
    textAlign:'left',
    width:'95%',
    marginLeft:'5px',
    marginRight:'5px',
    overflow:'hidden',
    backgroundColor:'#fafafa',
    borderRadius:'10px',
    padding:'5px'
  },
  button: {
    width:'100%',
    // backgroundColor:'red', 
    textTransform:'Capitalize', 
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // borderTop:'1px solid #ededed',
    padding:'5px',
    borderRadius:'15px',
    padding:'5px'
  },
  creatorDets : {
    display:'flex',
    alignItems:'center',
    flexDirection:'row'
  },
  media: {
    // height: 0,
    paddingTop: '75%',
    width:'100%'
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    // backgroundBlendMode: 'darken',
  },
  border: {
    border: 'solid',
  },
  fullHeightCard: {
    height: '100%',
  },
  card: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    // borderRadius: '25px',
    // height: '100%',
    // position: 'relative',

  },
  overlay: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    color: 'white',
    
  },
  overlay2: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    color: 'white',
  },
  grid: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px',
  },
  title: {
    padding: '0 16px',
  },
  cardActions: {
    padding: '0 16px 8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));
