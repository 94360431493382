import { FETCH_USER, CLEAR_USER, FETCH_USERS, FETCH_SUGGESTED_USERS, UPDATE_USER, FETCH_USER_POSTS, FETCH_USER_FAVS, FETCH_USER_VISITS, FETCH_USER_BUCKET, ADD_TO_FOLLOWING, REMOVE_FROM_FOLLOWING, GET_FOLLOWERS, GET_FOLLOWING, GET_PROFILE_FOLLOWERS, CLEAR_PROFILE_FOLLOWERS, CLEAR_FOLLOWING, CLEAR_FAVORITES, CLEAR_VISITED, CLEAR_BUCKET, CLEAR_USER_POSTS, REMOVE_FROM_PROFILE_FOLLOWING, ADD_TO_PROFILE_FOLLOWING, CLEAR_USER_FAVS, CLEAR_USER_VISITS, CLEAR_USER_BUCKET } from '../constants/actionTypes';
import * as api from '../api/index.js';

// export const getPosts = () => async (dispatch) => {
//   try {
//     const { data } = await api.fetchPosts();
//     console.log(data);

//     dispatch({ type: FETCH_ALL, payload: data });
//   } catch (error) {
//     console.log(error);
//   }
// };

export const getUser = (user) => async (dispatch) => {
  // console.log(user);
  try {
    const { data } = await api.getUser(user);
    // console.log(data);
    dispatch({ type: CLEAR_USER });
    dispatch({ type: CLEAR_USER_POSTS });
    dispatch({ type: CLEAR_PROFILE_FOLLOWERS});
    dispatch({ type: CLEAR_FOLLOWING });
    dispatch({ type: CLEAR_USER_FAVS });
    dispatch({ type: CLEAR_USER_VISITS });
    dispatch({ type: CLEAR_USER_BUCKET });
    dispatch({ type: FETCH_USER, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const updateUser = (id, user, router) => async (dispatch) => {
  try {
    const { data } = await api.updateUser(id, user);
    dispatch({ type: UPDATE_USER, payload: data });
    router.push(`/${id}`);
    window.location.reload();
    // console.log(data);

  } catch (error) {
    alert('Error')
    console.log(error);
  }
};

export const getUserPosts = (user) => async (dispatch) => {
  // console.log(user);
  try {
    const { data } = await api.getUserPosts(user);
    // console.log(data);

    dispatch({ type: FETCH_USER_POSTS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getUserFavorites = (user) => async (dispatch) => {
  // console.log(user);
  try {
    const { data } = await api.getUserFavorites(user);
    // console.log(data);

    dispatch({ type: FETCH_USER_FAVS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getUserVisited = (user) => async (dispatch) => {
  // console.log(user);
  try {
    const { data } = await api.getUserVisited(user);
    // console.log(data);

    dispatch({ type: FETCH_USER_VISITS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getUserBucket = (user) => async (dispatch) => {
  console.log(user);
  try {
    const { data } = await api.getUserBucket(user);
    // console.log(data);

    dispatch({ type: FETCH_USER_BUCKET, payload: data });
  } catch (error) {
    console.log(error);
  }
};


export const getFollowers = (userId) => async (dispatch) => {
  console.log(userId);
  try {
    const { data } = await api.getFollowers(userId);
    // console.log(data);

    dispatch({ type: GET_FOLLOWERS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getFollowing = (userId) => async (dispatch) => {
  console.log(userId);
  try {
    const { data } = await api.getFollowing(userId);
    // console.log(data);

    dispatch({ type: GET_FOLLOWING, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getProfileFollowers = (userId) => async (dispatch) => {
  console.log(userId);
  try {
    const { data } = await api.getProfileFollowers(userId);
    // console.log(data);

    dispatch({ type: GET_PROFILE_FOLLOWERS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const addToFollowing = (userId) => async (dispatch) => {
  try {
    const { data } = await api.addToFollowing(userId);
    
    // console.log(data);
    dispatch({ type: ADD_TO_FOLLOWING, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const removeFromFollowing = (userId) => async (dispatch) => {
  try {
    const { data } = await api.removeFromFollowing(userId);
    // console.log(userId);

    dispatch({ type: REMOVE_FROM_FOLLOWING, payload: data });
    // console.log(data);
  } catch (error) {
    console.log(error);
    // console.log(userId);
  }
};

export const addToProfileFollowing = (userId) => async (dispatch) => {
  try {
    const { data } = await api.addToFollowing(userId);
    
    // console.log(data);
    // dispatch({ type: ADD_TO_PROFILE_FOLLOWING, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const removeFromProfileFollowing = (userId) => async (dispatch) => {
  try {
    const { data } = await api.removeFromFollowing(userId);
    // console.log(userId);

    // dispatch({ type: REMOVE_FROM_PROFILE_FOLLOWING, payload: data });
    // console.log(data);
  } catch (error) {
    console.log(error);
    // console.log(userId);
  }
};


export const getUsers = (user) => async (dispatch) => {
  // console.log(user);
  try {
    const { data } = await api.getUsers(user);
    console.log(data);

    dispatch({ type: FETCH_USERS, payload: data });
  } catch (error) {
    console.log(error);
  }
};


export const getSuggestedUsers = (userId) => async (dispatch) => {
  console.log(userId);
  try {
    const { data } = await api.getSuggestedUsers(userId);
    console.log(data);

    dispatch({ type: FETCH_SUGGESTED_USERS, payload: data });
  } catch (error) {
    console.log(error);
  }
};