import React, { useState } from 'react';
import { Container, Paper, Grid, Typography, Button } from '@material-ui/core'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { resetPassword } from '../../actions/auth';
import Input from '../Auth/Input';
import useStyles from '../Auth/styles';

const initialState = { password: '', confirmPassword:'', tempToken:''}

const Reset = () => {
    const [form, setForm] = useState(initialState);
    // const [googleInfo, setGoogleInfo] = useState(initialGoogleInfo);
    // const [isSignup, setIsSignup] = useState(false);
    const dispatch = useDispatch();
    const {tempToken} = useParams();
    const history = useHistory();
    const classes = useStyles();
  
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword(!showPassword);
  
   
  
    const handleSubmit = (e) => {
      e.preventDefault();

      if(form.password !== '' && form.confirmPassword !== '' && form.password === form.confirmPassword ) {
        dispatch(resetPassword({ password:form.password, tempToken:tempToken }, history))
      }

    };

    const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

    
    return (
        <Container component="main" maxWidth="xs" id="resetContainer">
        <Paper className={classes.paper} elevation={3}>
          <Typography component="h1" variant="h5">Reset Password</Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
            <Input name="password" label="Password" type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} handleChange={handleChange} />
            <Input name="confirmPassword" label="Repeat Password" type="password" handleChange={handleChange} />
            </Grid>
            {/* <Typography variant="subtitle2">{form.password}</Typography>
            <Typography variant="subtitle2">{form.confirmPassword}</Typography> */}
            {(form.password !== '' && (form.password === form.confirmPassword)) ? 
            <Typography style={{paddingTop:'10px', color:'green'}} variant="subtitle2">Passwords Match</Typography> : null
            }
            {(form.password !== '' && form.confirmPassword !== '' && (form.password !== form.confirmPassword)) ? 
            <Typography style={{paddingTop:'10px'}} color="secondary" variant="subtitle2">Passwords Do Not Match</Typography> : null
            }
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Confirm
            </Button>
          </form>
        </Paper>
      </Container>
    )
}

export default Reset;
