import {ADDTOVISITED, CLEAR_VISITED, FETCH_USER_VISITS, GET_VISITED, REMOVEFROMVISITED } from '../constants/actionTypes';

export default (visited = [], action) => {
  switch (action.type) {
    case GET_VISITED:
      return action.payload;
    case ADDTOVISITED:
      return [...visited, action.payload];
    case REMOVEFROMVISITED:
      return visited.filter((visit) => visit !== action.payload);
    case FETCH_USER_VISITS:
        if(!action.payload.length) {
          return visited  = [0];
        } else {
        return action.payload;
        }
    case CLEAR_VISITED:
      return visited = [];
    default:
      return visited;
  }
};