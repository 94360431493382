import React, { useState } from 'react';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

import FavoriteCard from './FavoriteCard';
// import useStyles from './styles';

const FavoriteCards = ({userId}) => {
  const favorites = useSelector((state) => state.userFavs);
  const [canReact, setCanReact] = useState(true);
//   const ids = posts.map(post => post.id)
//   console.log(ids);
//   const classes = useStyles();

if(favorites.includes(0)) {
  return <Typography variant="subtitle1" style={{margin:'auto', color:'darkgray'}}>No places to show yet.</Typography>
}
 
  return (
    !favorites.length ? <CircularProgress style={{margin:'auto'}} /> : (
      <Grid container alignItems="stretch" spacing={3} style={{background:'white', marginLeft:'auto', marginRight:'auto', marginTop:'0px', width:'100%', border:'2px solid #ededed', borderRadius:'15px', paddingBottom:'10px', marginBottom:'10px' }}>
        {favorites.map((favorite) => (
          <Grid item xs={12} sm={12} md={12} style={{padding: '10px', paddingBottom:'0'}}>
            <FavoriteCard favorite={favorite} userId={userId} canReact={canReact} setCanReact={setCanReact} />
          </Grid>
        ))}
      </Grid>
    )
  );
};

export default FavoriteCards;
