import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Container, Grow, Grid, TextField, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from 'react-router';
import Post from '../Posts/Post/Post';
import CommentCards from '../Comments/CommentCards';


import { getPost } from '../../actions/posts';
import { getComments, addComment } from '../../actions/comments';
import { v4 as uuidv4 } from 'uuid';

const SinglePost = () => {
    const date = new Date();
    const post = useSelector((state) => state.post);
    const [commentData, setCommentData] = useState({id:'', postId:'', userId:'', commentBody:''});
    const dispatch = useDispatch();
    const { postId } = useParams();
    const currentUser = JSON.parse(localStorage.getItem('profile'));
    const currentUserId = currentUser?.result?.googleId || currentUser?.result?.id;
    const currentUserStatus = currentUser?.result?.status;
    const commentCount = post?.commentCount;
    const comments = useSelector(state => state.comments);
    
    // const postId = '15012eab-02e0-4e9a-bd93-fe10d40363df';
    // alert(postId)

    useEffect(() => {
      // console.log(postId);
      dispatch(getPost(postId))
                .then(() => 
                dispatch(getComments(postId))
                )
                ;
    }, [postId]);
    
    // const Comment = () => { 
    //   // console.log(commentData);
    //   dispatch(addComment({...commentData, id: uuidv4(), postId:postId, userId: post.creator, createdAt: date}, postId))
    // }
    
    // console.log(postId);
    // console.log(post);
    
    return (
    <Grow in>
      <Container id="singlePostContainer">
        <Grid container justify="space-between" alignItems="stretch" style={{display:'flex', justifyContent:'center'}} spacing={3} >
          <Grid item xs={12} sm={7} md={6} style={{marginBottom:'50px'}}>
            <Post post={post} />
          </Grid>
        </Grid>
      </Container>
    </Grow>
    )
}

export default SinglePost;
