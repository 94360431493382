import { makeStyles } from '@material-ui/core/styles';
import { deepPurple } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
//   mainContainer: {
//     borderRadius: 15,
//     margin: '30px 0',
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     padding: '10px 50px',
//   },
//   heading: {
//     color: 'rgba(0,183,255, 1)',
//     textDecoration: 'none',
//   },
//   image: {
//     marginLeft: '15px',
//   },
//   toolbar: {
//     display: 'flex',
//     justifyContent: 'flex-end',
//     width: '400px',
//   },
//   profile: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     width: '400px',
//   },
//   userName: {
//     display: 'flex',
//     alignItems: 'center',
//   },
//   brandContainer: {
//     display: 'flex',
//     alignItems: 'center',
//   },
//   smMargin: {
//     margin: theme.spacing(1),
//   },
//   purple: {
//     color: theme.palette.getContrastText(deepPurple[500]),
//     backgroundColor: deepPurple[500],
//   },
//   [theme.breakpoints.down('sm')]: {
//     appBar: {
//       padding: '10px 20px',
//     },
//     heading: {
//       display: 'none',
//     },
//     userName: {
//       display: 'none',
//     },
//     image: {
//       marginLeft: '5px',
//     },
//     toolbar: {
//       display: 'flex',
//       justifyContent: 'flex-end',
//       width: '160px',
//     },
//   },

//   actionDiv: {
//     textAlign: 'center',
//   },
categoryDiv : {
    backgroundColor:'white',
    display:'flex',
    alignItems:'center',
    flexDirection:'column',
    margin:'10px',
    marginTop:0,
    padding:'5px',
    borderRadius:'15px',
    border:'2px solid #ededed',
    paddingTop:'10px',
    paddingBottom:'5px'
  }, 
  button1: {
    display:'flex',
    flexDirection:'column'
  },
  button: {
    width:'100%', 
    backgroundColor:'white',
    textTransform:'Capitalize', 
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    border:'1px solid #ededed',
    borderRadius:'10px',
    padding:'5px'
  },
  text : {
    whiteSpace:'pre-line',
    display:'flex',
    flexDirection:'column',
    textAlign:'left',
    width:'95%',
    marginLeft:'10px',
    marginRight:'5px',
    overflow:'hidden'
  },
  locationText : {
    whiteSpace:'pre-line',
    display:'flex',
    flexDirection:'row',
    textAlign:'left',
    width:'95%',
    marginLeft:'10px',
    marginRight:'5px',
    overflow:'hidden',
    alignItems:'center'
  },
  locPic : {
    height:'95px',
    width:'95px',
    borderRadius:'10%'
  },
  content : {
    display:'flex',
    flexDirection:'column',
    maxWidth:'70%',
    height:'95px'
  }, 
  reacts : {
    display:'flex',
    flexdirection:'row',
    marginLeft:'8px',
    alignItems:'center',
    marginTop:'auto'
  },
  follow : {
    width:'200px',
    height:'275px', 
    backgroundColor:'white', 
    border:'1px solid #ededed', 
    boxShadow:'0 0 5px #ededed',
    textTransform:'Capitalize', 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin:'10px',
    borderRadius:'15px'
  },
  followAvatar : {
    height:'125px',
    width:'125px',
  },
  followText : {
    whiteSpace:'pre-line',
    textAlign:'center',
    width:'95%',
  },

}));
