import {ADD_TO_USER_FAVS, CLEAR_USER_FAVS, FETCH_USER_FAVS, REMOVE_FROM_USER_FAVS } from '../constants/actionTypes';

export default (userFavorites = [], action) => {
  switch (action.type) {
    case FETCH_USER_FAVS:
      if(!action.payload.length) {
        return userFavorites = [0];
      } else {
      return action.payload;
      }
    case ADD_TO_USER_FAVS:
      return userFavorites.map((fav) => (fav.coordinates === action.payload.coordinates ? action.payload : fav));
    case REMOVE_FROM_USER_FAVS:
      return userFavorites.map((fav) => (fav.coordinates === action.payload.coordinates ? action.payload : fav));
      // return userFavorites.filter((favorite) => ((favorite.name !== action.payload.name) && (favorite.coordinates !== action.payload.coordinates)));
    case CLEAR_USER_FAVS: {
      return userFavorites = [];
    }
    default:
      return userFavorites;
  }
};