import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Grid, Button, Box, Tabs, Tab, CardContent, Collapse, CardActions, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';
import { Typography } from '@material-ui/core';
import VerticalTabs from './TagLibrary';
import SearchIcon from '@material-ui/icons/Search';
import { red } from '@material-ui/core/colors';
import HelpIcon from '@material-ui/icons/Help';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'flex-start',
    padding:'5px',
    cursor:'text',
    width:'97%',
    minHeight:'50px',
    border:'1px solid silver',
    borderRadius:'5px',
    margin: 'auto',
    marginTop:'7px',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    }
  },
  
  tagTitles : {
    color:'#000',
    borderTop : '1px solid #ededed',
    paddingTop : '15px',
    display:'flex',
    alignItems:'center'
  }, 
  tagSubTitles : {
    color:'grey',
    display:'flex',
    alignItems:'flex-start',
    marginTop:'-5px',
    marginBottom:'5px'
  }, 
  chip : {
    margin:'3px',
    marginLeft:'0px'
  },
  section : {
    marginTop:'20px',
    marginBottom:'10px'
  }, 
  tabRoot: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tabs : {
    minWidth:'50px'
  }, 
  vibesSelected: {
    margin:'3px',
    marginLeft:'0px',
    backgroundColor:'#9b59b6 !important',
    color:'white'
  }, 
  vibesOutlined: {
    margin:'3px',
    marginLeft:'0px',
    backgroundColor:'white !important',
    color:'#9b59b6',
    border: '1px solid #9b59b6'
  },
  typeSelected: {
    margin:'3px',
    marginLeft:'0px',
    background:'linear-gradient(to right, #11998e, #38ef7d)',
    color:'white'
  }, 
  typeOutlined: {
    margin:'3px',
    marginLeft:'0px',
    backgroundColor:'white !important',
    color:'#11998e',
    border: '1px solid #11998e'
  }, 
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  subtitle: {
    paddingBottom: '15px',
    color: '#000',
    borderBottom: '1px solid #ededed',
    marginBottom: '15px',
    fontWeight: 'normal',
    lineHeight: '1.5em',
  },
  bold: {
    color: '#11998e'
  },
  bold2: {
    color: '#9b59b6'
  },
  listItem: {
    border:'1px solid #ededed',
    borderRadius:'10px',
    padding:'10px',
    color:'#333',
    margin:'10px',
    width:'50%',
    fontSize:'0.9rem'
  },
  list: {
    borderBottom:'1px solid #ededed',
    borderTop:'1px solid #ededed',
    marginBottom:'10px',
    '& li': {
      marginBottom: theme.spacing(2),
    },
  }
}));

const MaxWidthDialog = ({selectedTags, setselectedTags}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [expanded, setExpanded] = React.useState(false);
  // const [selected, setSelected] = useState({broadCategories:[], generalTags:[] });
  // const [selectedTags, setselectedTags] = useState([]);
  const [pseudoCount, setPseudoCount] = useState(0);
  const [query, setQuery] = useState("");
  const parentRef = useRef();
  const childRef = useRef();
  const [value, setValue] = React.useState(0);
  const [vidtagSelected, setVidtagSelected] = useState(false);
  const [chipCount, setChipCount] = useState(
  //   [
  //   "Nature" = 0,
  //   "Animals" = 0,
  //   "Urban" = 0,
  //   "Architecture" = 0
  // ]
  0
  )
  const [chipData, setChipData] = useState([
    {label:'Nature', type:'Categories',tagType:'General'},
    {label:'Animals', type:'Categories',tagType:'General'},
    {label:'Urban', type:'Categories',tagType:'General'},
    {label:'Architecture', type:'Categories',tagType:'General'},
    {label:'Culture', type:'Categories',tagType:'General'},
    {label:'History', type:'Categories',tagType:'General'},
    {label:'Religion', type:'Categories',tagType:'General'},
    // {label:'Scenery & Landscapes', type:'Categories',tagType:'General'},
    {label:'Experiences', type:'Categories',tagType:'General'},
    {label:'Events', type:'Categories',tagType:'General'},

    // {label:'Nature Photography', type:'Nature', tagType:'General'},
    {label:'Enjoying Nature', type:'Nature', tagType:'General'},
    {label:'Amidst the Wilderness', type:'Nature', tagType:'General'},
    {label:'Majestic Mountains', type:'Nature', tagType:'General'},
    {label:'Sea & Beaches', type:'Nature', tagType:'General'},
    {label:'Enchanting Forests', type:'Nature', tagType:'General'},
    {label:'Tropical Paradise', type:'Nature', tagType:'General'},
    {label:'Winter Destination', type:'Nature', tagType:'General'},
    {label:'Polar Regions', type:'Nature', tagType:'General'},
    {label:'Desert Dunes', type:'Nature', tagType:'General'},
    {label:'Countryside', type:'Nature', tagType:'General'},
    {label:'Remote', type:'Nature', tagType:'General'},
    {label:'Natural Attractions', type:'Nature', tagType:'General'},
    {label:'Natural Wonders', type:'Nature', tagType:'General'},

    {label:'Wildlife', type:'Animals', tagType:'General'},
    {label:'Birds', type:'Animals', tagType:'General'},
    {label:'Aquatic Life', type:'Animals', tagType:'General'},
    {label:'Marine Wildlife', type:'Animals', tagType:'General'},
    {label:'Exotic Animals', type:'Animals', tagType:'General'},
    {label:'Domestic Animals', type:'Animals', tagType:'General'},
    {label:'Endangered Species', type:'Animals', tagType:'General'},
    {label:'Animals & Humans', type:'Animals', tagType:'General'},
    // {label:'Awesome Animal Moments', type:'Animals', tagType:'General'},


    // {label:'Cityscape', type:'Urban', tagType:'General'},
    // {label:'Townscape', type:'Urban', tagType:'General'},
    {label:'Small Town Charm', type:'Urban', tagType:'General'},
    {label:'Life in the City', type:'Urban', tagType:'General'},
    // {label:'City Lights', type:'Urban', tagType:'General'},
    // {label:'City at Night', type:'Urban', tagType:'General'},
    {label:'Exploring the City', type:'Urban', tagType:'General'},
    // {label:'Skyline', type:'Urban', tagType:'General'},

    {label:'Cultural Landmarks', type:'Culture', tagType:'General'},
    {label:'Life of Locals', type:'Culture', tagType:'General'},
    {label:'Everyday Life', type:'Culture', tagType:'General'},
    {label:'Heritage', type:'Culture', tagType:'General'},
    {label:'Traditions & Customs', type:'Culture', tagType:'General'},
    {label:'Cultural Events', type:'Culture', tagType:'General'},////////////////////x1-
    {label:'Cultural Hotspots', type:'Culture', tagType:'General'},
    {label:'Multicultural', type:'Culture', tagType:'General'},
    // {label:'Street Photography', type:'Culture', tagType:'General'},//////////////////x1-
    {label:'Indigenous Peoples', type:'Culture', tagType:'General'},

    {label:'Ancient Ruins', type:'History', tagType:'General'},
    {label:'Museums', type:'History', tagType:'General'},
    {label:'Historical Landmarks', type:'History', tagType:'General'},
    {label:'Historical Site', type:'History', tagType:'General'},
    {label:'Historic Architecture', type:'History', tagType:'General'},
    {label:'Historical Art', type:'History', tagType:'General'},
    {label:'Historical Monuments', type:'History', tagType:'General'},
    {label:'Natural History', type:'History', tagType:'General'},
    {label:'Prehistoric', type:'History', tagType:'General'},

    {label:'Religious Landmarks', type:'Religion', tagType:'General'},
    {label:'Religious Sites', type:'Religion', tagType:'General'},
    {label:'Religious Architecture', type:'Religion', tagType:'General'},////////////////////x1-
    {label:'Religious Art', type:'Religion', tagType:'General'},
    {label:'Religious Monuments', type:'Religion', tagType:'General'},
    {label:'Religious Events', type:'Religion', tagType:'General'},//////////////////////////x1-
    {label:'Holy', type:'Religion', tagType:'General'},
    {label:'Sacred', type:'Religion', tagType:'General'},

    {label:'Iconic Landmarks', type:'Architecture', tagType:'General'},
    {label:'Modern Architecture', type:'Architecture', tagType:'General'},
    {label:'Historic Architecture', type:'Architecture', tagType:'General'},
    {label:'Religious Architecture', type:'Architecture', tagType:'General'},/////////////////////x1-
    {label:'Landscape Architecture', type:'Architecture', tagType:'General'},
    // {label:'Indoor Scenery', type:'Architecture', tagType:'General'},
    {label:'Amazing Structures', type:'Architecture', tagType:'General'},
    {label:'Engineering Marvels', type:'Architecture', tagType:'General'},

    // {label:'Beautiful Scenery', type:'Scenery & Landscapes', tagType:'General'},
    // {label:'Amazing View', type:'Scenery & Landscapes', tagType:'General'},
    // {label:'Indoor Scenery', type:'Scenery & Landscapes', tagType:'General'},
    // {label:'Random Travel Clicks', type:'Scenery & Landscapes', tagType:'General'},
    // {label:'Forest Landscape', type:'Scenery & Landscapes', tagType:'General'},
    // {label:'Cityscape', type:'Scenery & Landscapes', tagType:'General'},
    // {label:'Townscape', type:'Scenery & Landscapes', tagType:'General'},
    // {label:'Urban Landscape', type:'Scenery & Landscapes', tagType:'General'},
    // {label:'Rural Landscape', type:'Scenery & Landscapes', tagType:'General'},
    // {label:'Desert Landscape', type:'Scenery & Landscapes', tagType:'General'},
    // {label:'Seascape', type:'Scenery & Landscapes', tagType:'General'},
    // {label:'Cloudscape', type:'Scenery & Landscapes', tagType:'General'},
    // {label:'Mountain Landscape', type:'Scenery & Landscapes', tagType:'General'},
    // {label:'Fall Landscape', type:'Scenery & Landscapes', tagType:'General'},
    // {label:'Winter Landscape', type:'Scenery & Landscapes', tagType:'General'},
    // {label:'Summer Landscape', type:'Scenery & Landscapes', tagType:'General'},
    // {label:'Spring Landscape', type:'Scenery & Landscapes', tagType:'General'},

    {label:'Just My Travels', type:'Experiences', tagType:'General'},
    {label:'Adventure', type:'Experiences', tagType:'General'},
    {label:'Entertainment', type:'Experiences', tagType:'General'},
    {label:'Leisure & Relaxation', type:'Experiences', tagType:'General'},
    {label:'Luxury Experience', type:'Experiences', tagType:'General'},
    {label:'Cultural Experience', type:'Experiences', tagType:'General'},
    {label:'Spiritual Experience', type:'Experiences', tagType:'General'},
    {label:'Romantic Experience', type:'Experiences', tagType:'General'},
    {label:'Food & Dining', type:'Experiences', tagType:'General'},
    {label:'Nightlife', type:'Experiences', tagType:'General'},

    {label:'Festivals', type:'Events', tagType:'General'},
    {label:'Carnivals', type:'Events', tagType:'General'},
    {label:'Fairs', type:'Events', tagType:'General'},
    {label:'Concerts', type:'Events', tagType:'General'},
    {label:'Cultural Events', type:'Events', tagType:'General'},/////////////////////////////////////x1-
    {label:'Religious Events', type:'Events', tagType:'General'},/////////////////////////////////////x1-
    {label:'Sports Events', type:'Events', tagType:'General'},

    {label:'Personal Blog Post', type:'AVTags', tagType:'AVTags'},
    {label:'Travel Guide', type:'AVTags', tagType:'AVTags'},
    {label:'Destination Spotlight', type:'AVTags', tagType:'AVTags'},
    {label:'Review Article', type:'AVTags', tagType:'AVTags'},
    {label:'Best Of', type:'AVTags', tagType:'AVTags'},
    {label:'How-To Guide', type:'AVTags', tagType:'AVTags'},
    {label:'Advice & Tips', type:'AVTags', tagType:'AVTags'},
    {label:'FAQs', type:'AVTags', tagType:'AVTags'},

    {label:'Peaceful', type:'Vibes', tagType:'Vibes'},
    {label:'Relaxing', type:'Vibes', tagType:'Vibes'},
    {label:'Luxurious', type:'Vibes', tagType:'Vibes'},
    {label:'Exciting', type:'Vibes', tagType:'Vibes'},
    {label:'Adventurous', type:'Vibes', tagType:'Vibes'},
    {label:'Romantic', type:'Vibes', tagType:'Vibes'},
    {label:'Exotic', type:'Vibes', tagType:'Vibes'},
    {label:'Spiritual', type:'Vibes', tagType:'Vibes'},
    {label:'Scenic', type:'Vibes', tagType:'Vibes'},
    {label:'Awe-Inspiring', type:'Vibes', tagType:'Vibes'},
    {label:'Bustling', type:'Vibes', tagType:'Vibes'},
    {label:'Charming', type:'Vibes', tagType:'Vibes'},
    {label:'Vibrant', type:'Vibes', tagType:'Vibes'},
    {label:'Cozy', type:'Vibes', tagType:'Vibes'},

    {label:'Abseiling', type:'Activities', tagType:'Activities'},
    {label:'Air Rifle Shooting', type:'Activities', tagType:'Activities'},
    {label:'Archery', type:'Activities', tagType:'Activities'},
    {label:'ATV Ride', type:'Activities', tagType:'Activities'},

    {label:'Backpacking', type:'Activities', tagType:'Activities'},
    {label:'Badminton', type:'Activities', tagType:'Activities'},
    {label:'Banana Boat Ride', type:'Activities', tagType:'Activities'},
    {label:'BASE Jumping', type:'Activities', tagType:'Activities'},
    {label:'Beach Volleyball', type:'Activities', tagType:'Activities'},
    {label:'Bird Watching', type:'Activities', tagType:'Activities'},
    {label:'Boat Ride', type:'Activities', tagType:'Activities'},
    {label:'Bodyboarding', type:'Activities', tagType:'Activities'},
    {label:'Bowling', type:'Activities', tagType:'Activities'},
    {label:'Bungee Jumping', type:'Activities', tagType:'Activities'},

    {label:'Cage Diving', type:'Activities', tagType:'Activities'},
    {label:'Camping', type:'Activities', tagType:'Activities'},
    {label:'Canoeing', type:'Activities', tagType:'Activities'},
    {label:'Canyoning', type:'Activities', tagType:'Activities'},
    {label:'Cave Diving', type:'Activities', tagType:'Activities'},
    {label:'Chess', type:'Activities', tagType:'Activities'},
    {label:'Cliff Diving', type:'Activities', tagType:'Activities'},
    {label:'Clubbing', type:'Activities', tagType:'Activities'},
    {label:'Cycling', type:'Activities', tagType:'Activities'},

    {label:'Dancing', type:'Activities', tagType:'Activities'},
    {label:'Deep Diving', type:'Activities', tagType:'Activities'},
    {label:'Diving', type:'Activities', tagType:'Activities'},
    {label:'Dolphin Watching', type:'Activities', tagType:'Activities'},

    {label:'Equestrian', type:'Activities', tagType:'Activities'},
    {label:'Fly Fish Ride', type:'Activities', tagType:'Activities'},
    {label:'Flyboard Flying', type:'Activities', tagType:'Activities'},
    {label:'Freediving', type:'Activities', tagType:'Activities'},
    {label:'Go-Karting', type:'Activities', tagType:'Activities'},
    {label:'Golf', type:'Activities', tagType:'Activities'},
    {label:'Hang Gliding', type:'Activities', tagType:'Activities'},
    {label:'Helicopter Ride', type:'Activities', tagType:'Activities'},
    {label:'Hiking', type:'Activities', tagType:'Activities'},
    {label:'Hockey', type:'Activities', tagType:'Activities'},
    {label:'Horseback Ride', type:'Activities', tagType:'Activities'},

    {label:'Ice Climbing', type:'Activities', tagType:'Activities'},
    {label:'Ice Diving', type:'Activities', tagType:'Activities'},
    {label:'Ice Hockey', type:'Activities', tagType:'Activities'},
    {label:'Ice Skating', type:'Activities', tagType:'Activities'},
    {label:'Jet Skiing', type:'Activities', tagType:'Activities'},
    {label:'Jogging', type:'Activities', tagType:'Activities'},

    {label:'Kayaking', type:'Activities', tagType:'Activities'},
    {label:'Kite Flying', type:'Activities', tagType:'Activities'},
    {label:'Kite Surfing', type:'Activities', tagType:'Activities'},
    {label:'Kneeboarding', type:'Activities', tagType:'Activities'},
    {label:'Laser Tag', type:'Activities', tagType:'Activities'},
    {label:'Longboarding', type:'Activities', tagType:'Activities'},

    {label:'Mini Golf', type:'Activities', tagType:'Activities'},
    {label:'Motorcycle Ride', type:'Activities', tagType:'Activities'},
    {label:'Mountain Biking', type:'Activities', tagType:'Activities'},
    {label:'Mountaineering', type:'Activities', tagType:'Activities'},

    {label:'Paddleboarding', type:'Activities', tagType:'Activities'},
    {label:'Paddlesurfing', type:'Activities', tagType:'Activities'},
    {label:'Paintball', type:'Activities', tagType:'Activities'},
    {label:'Parachuting', type:'Activities', tagType:'Activities'},
    {label:'Paragliding', type:'Activities', tagType:'Activities'},
    {label:'Parasailing', type:'Activities', tagType:'Activities'},
    {label:'Pool & Billiards', type:'Activities', tagType:'Activities'},

    {label:'Quad Bike Ride', type:'Activities', tagType:'Activities'},
    {label:'Racquetball', type:'Activities', tagType:'Activities'},
    {label:'Rafting', type:'Activities', tagType:'Activities'},
    {label:'Rock Climbing', type:'Activities', tagType:'Activities'},
    {label:'Roller Coaster Ride', type:'Activities', tagType:'Activities'},
    {label:'Rowing', type:'Activities', tagType:'Activities'},
    {label:'Ocean Walker', type:'Activities', tagType:'Activities'},

    {label:'Safari', type:'Activities', tagType:'Activities'},
    {label:'Sailing', type:'Activities', tagType:'Activities'},
    {label:'Scuba Diving', type:'Activities', tagType:'Activities'},
    {label:'Seaplane Ride', type:'Activities', tagType:'Activities'},
    {label:'Shark Cage Diving', type:'Activities', tagType:'Activities'},
    {label:'Skateboarding', type:'Activities', tagType:'Activities'},
    {label:'Skiing', type:'Activities', tagType:'Activities'},
    {label:'Skimboarding', type:'Activities', tagType:'Activities'},
    {label:'Sky Diving', type:'Activities', tagType:'Activities'},
    {label:'Sledding', type:'Activities', tagType:'Activities'},
    {label:'Snorkelling', type:'Activities', tagType:'Activities'},
    {label:'Snowboarding', type:'Activities', tagType:'Activities'},
    {label:'Snowmobiling', type:'Activities', tagType:'Activities'},
    {label:'Snowshoeing', type:'Activities', tagType:'Activities'},
    {label:'Speed Boat Ride', type:'Activities', tagType:'Activities'},
    {label:'Spelunking', type:'Activities', tagType:'Activities'},
    {label:'Spinnaker Flying', type:'Activities', tagType:'Activities'},
    {label:'Stargazing', type:'Activities', tagType:'Activities'},
    {label:'Surfing', type:'Activities', tagType:'Activities'},
    {label:'Swimming', type:'Activities', tagType:'Activities'},

    {label:'Table Tennis', type:'Activities', tagType:'Activities'},
    {label:'Tennis', type:'Activities', tagType:'Activities'},
    {label:'Train Ride', type:'Activities', tagType:'Activities'},
    {label:'Trekking', type:'Activities', tagType:'Activities'},

    {label:'Underwater Scooter Riding', type:'Activities', tagType:'Activities'},
    {label:'Video Games', type:'Activities', tagType:'Activities'},
    {label:'VR/AR Games', type:'Activities', tagType:'Activities'},

    {label:'Wakeboarding', type:'Activities', tagType:'Activities'},
    {label:'Water Skiing', type:'Activities', tagType:'Activities'},
    {label:'Water Sports', type:'Activities', tagType:'Activities'},
    {label:'Whale Watching', type:'Activities', tagType:'Activities'},
    {label:'Whitewater Rafting', type:'Activities', tagType:'Activities'},
    {label:'Windsurfing', type:'Activities', tagType:'Activities'},
    {label:'Yoga', type:'Activities', tagType:'Activities'},
    {label:'Ziplining', type:'Activities', tagType:'Activities'},
    {label:'Zorbing', type:'Activities', tagType:'Activities'},

    
    

  ]);

  const categoryArray = ["AVTags", "Categories", "Nature", "Animals", "Urban", "Architecture", "Culture", "History", "Religion", "Experiences", "Events", "Vibes", "Activities"];
  const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","Y","Z"];

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setselectedTags([]);
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const handleClick = (selected) => () => {
    if(selectedTags.find((tag) => tag.label === selected.label)) {
      setselectedTags(selectedTags.filter(tag => tag.label !== selected.label));
      if(selected.type === 'AVTags') {
      setVidtagSelected(false);
      }
    } else {
      if(selectedTags.length < 10) 
    setselectedTags([...selectedTags, selected]);
    if(selected.type === "Categories") {
    setPseudoCount(pseudoCount + 1)
    };
    if(selected.type === 'AVTags') {
    setVidtagSelected(true);
    }
    }
  }

  const handleClearTags = () => {
    setselectedTags([]);
    setPseudoCount(0);
  }

  const handleDelete = (chipToDelete) => {
    setselectedTags((chips) => chips.filter((chip) => chip.label !== chipToDelete));
    setVidtagSelected(false);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if(selectedTags.length > 1) {
    parentRef.current.scrollIntoView();
    }
  }, [pseudoCount]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // useEffect(() => {
  //   chipData.map(data => (
  //     data.type !== "Categories" && (
  //     (data.label.toLowerCase().includes(query.toLowerCase())) ? (
  //       !queryTags.find(qt => qt === data.type) && setQueryTags([...queryTags, data.type])
  //     ) :
  //      (
  //       queryTags.includes(data.type) && (
  //        setQueryTags(queryTags.filter(t => (t !== data.type))))
  //     )
  //     )))
  //     console.log(queryTags);
  // }, [query])
  

  return (
    <React.Fragment>
    <div ref={parentRef} style={{width:'100%'}}>
     <Typography style={{marginLeft:'10px', color:'#333'}}>Add Tags</Typography>
     </div>
      <div style={{marginBottom:'15px'}} className={classes.root} label="Add Tags" onClick={handleClickOpen}>
      {selectedTags.map((data) => {
          return (
              
              <Chip
              label={data.label}
              className={classes.chip}
              variant="default"
              />
          )
        })}
      </div>
      <Dialog
        id="formTagDialog"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title" style={{boxShadow:'0 0 10px -6.5px black', marginBottom:'13px'}}>Add Tags</DialogTitle>
        <DialogContent>
      <div id="tagSearch">
          <SearchIcon style={{backgroundColor:'#ededed', borderTopLeftRadius: '25px', borderBottomLeftRadius: '25px', padding:'12px', fontSize:'2.8rem'}}/>
        <input
        autoComplete='off'
        id="tagSearchInput"
        class="pac-target-input"
        onChange={event => setQuery(event.target.value)}
        placeholder="Search For Tags"
        value={query}
      />
      </div>

          {/* <form className={classes.form} noValidate>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="max-width">maxWidth</InputLabel>
              <Select
                autoFocus
                value={maxWidth}
                onChange={handleMaxWidthChange}
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value={false}>false</MenuItem>
                <MenuItem value="xs">xs</MenuItem>
                <MenuItem value="sm">sm</MenuItem>
                <MenuItem value="md">md</MenuItem>
                <MenuItem value="lg">lg</MenuItem>
                <MenuItem value="xl">xl</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              className={classes.formControlLabel}
              control={<Switch checked={fullWidth} onChange={handleFullWidthChange} />}
              label="Full width"
            />
          </form> */}

        <div className={classes.section}>
        {/* {selectedTags.length ? ( */}
          <div style={{display:'flex', alignItems:'baseline', borderTop:'1px solid #ededed', paddingTop:'10px'}}>
        <Typography variant="subtitle1" >Selected Tags <span variant="subtitle2" style={{color:'grey', fontSize:'0.8rem'}}>({selectedTags.length}/10)</span></Typography>
        {(selectedTags.length > 0) && (
        <Button onClick={handleClearTags} color="secondary" style={{marginLeft:'auto', textTransform:'capitalize'}}>
            Clear All
          </Button>
        )}
          </div>
        <div className={classes.selected}>
        {selectedTags.map((data) => {
          return (
              
              <Chip
              label={data.label}
              className={classes.chip}
              variant="default"
              onDelete = {() => handleDelete(data.label)}
              // color="primary"
              />
          )
        })}
        </div>
        </div>
        {(query === "") && (
        <div className={classes.tabRoot} style={{borderTop : '1px solid #ededed',}}>
      <div className={classes.section}>
      <AppBar id="tagBar" position="static" color="default" style={{width:'100%'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab label="Article Tags" {...a11yProps(0)} className={classes.tabs} />
          <Tab label="Descriptive" {...a11yProps(1)} className={classes.tabs} />
          <Tab label="Vibes" {...a11yProps(2)} className={classes.tabs} />
          <Tab label="Activities" {...a11yProps(3)} className={classes.tabs} />
        </Tabs>
      </AppBar>
      </div>
      <TabPanel class="tabPanel" value={value} index={0}>
      <div class="tabPanel" className={classes.section}>
      <div className={classes.listItem} style={{background:'linear-gradient(45deg, #11998e, #38ef7d)', color:'white', width:'100%', margin:'10px', marginLeft:'auto', marginRight:'auto', borderRadius:'0'}}>
      Article tags allow users to easily identify the content and style of your article at a glance. You can only one Article tag, but you can also add additional types of tags to better describe your content.
          </div>
        <CardActions style={{borderBottom : !expanded && '1px solid #ededed'}}>
          {/* {!expanded ? ( */}
          <Typography variant="subtitle1" onClick={handleExpandClick} style={{color:'#11998e', cursor:'pointer'}}>Not sure which tag to use?</Typography>
         
          <IconButton size="small"
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          style={{padding:'initial'}}
        > 
          {/* <Fab size="small" style={{backgroundColor:'#EDEDED'}}> */}
          <ExpandMoreIcon style={{background:'linear-gradient(45deg, #11998e, #38ef7d)', color:'white', borderRadius:'50%' }} />
          
          {/* </Fab> */}
        </IconButton>
        </CardActions>
        {/* <Collapse in={expanded} timeout="auto" unmountOnExit> */}
        <CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.list}>
          <Grid>
            <Grid item style={{display:'flex'}}>
          <div className={classes.listItem}>
            <span className={classes.bold}>Personal Blog Post</span><br></br>
            Use this tag for sharing your personal travel experiences and reflections.
          </div>
          <div className={classes.listItem}>
            <span className={classes.bold}>Travel Guide</span><br></br>
            Use this tag when providing helpful information to others about a destination.
          </div>
          </Grid>
          <Grid item style={{display:'flex'}}>
          <div className={classes.listItem}>
            <span className={classes.bold}>Destination Spotlight</span><br></br>
            Use this tag when writing about a specific destination and its highlights.
          </div>
          <div className={classes.listItem}>
            <span className={classes.bold}>Review Article</span><br></br>
            Use this tag when writing reviews of hotels, experiences, food etc. 
          </div>
          </Grid>
          <Grid item style={{display:'flex'}}>
          <div className={classes.listItem}>
            <span className={classes.bold}>Best Of</span><br></br>
            Use this tag when showcasing the best places to visit, things to do, etc. in a destination.
          </div>
          <div className={classes.listItem}>
            <span className={classes.bold}>How-to Guide</span><br></br>
            Use this tag when providing step by step instructions or advice on how to plan a trip.
          </div>
          </Grid>
          <Grid item style={{display:'flex'}}>
          <div className={classes.listItem} style={{width:'47%'}}>
            <span className={classes.bold}>Advice & Tips</span><br></br>
            Use this tag when providing general tips and recommendations for travelers.
          </div>
          <div className={classes.listItem} style={{width:'47%'}}>
            <span className={classes.bold}>FAQs</span><br></br>
            Use this tag when providing answers to common questions related to a destination.
          </div>
          </Grid>
          </Grid>
        </div>
        </Collapse>
        </CardContent>

        
        {/* <Typography className={classes.tagTitles} variant="subtitle2" style={{paddingBottom:'15px', color:'#000', borderBottom:'1px solid #ededed', marginBottom:'15px'}}>Video tags let travelers know the type of video. You can only select one video tag. <br></br>Add the 'Short Clip' tag for videos less than 30 seconds. <br></br>Add the 'Best Of ' tag for videos that show the top places to go or top things to do <br></br> (e.g. - Top 10 places to visit in Rio).</Typography> */}
        {        
        chipData.map((data) => {
          if(data.type === "AVTags") {
          return (
              
              <Chip
              label={data.label}
              className = {selectedTags.find(tag => tag.label === data.label) ? classes.typeSelected : classes.typeOutlined}
              onClick={handleClick(data)}
              variant={selectedTags.find((tag) => tag.label === data.label) ? "default" : "outlined"}
              color="primary"
              disabled={(selectedTags.find((tag) => (tag.type === 'AVTags' && tag.label !== data.label))) ? true : false }
              />
          )
        } else { 
          return null;
        }})}
        {/* {(selectedTags.length > 0 && selectedTags.find((tag) => (tag.type === 'AVTags'))) && 
        <CardActions style={{justifyContent:'flex-end', borderTop:'1px solid #ededed', marginTop:'10px'}}>
        <CardContent style={{display:'flex', alignItems:'center', paddingRight:'0 !important', padding:'10px'}}>
          <Typography color="primary" onClick={() => handleChange(null, 1)} style={{cursor:'pointer'}}>Add more tags</Typography>
          <IconButton  size="small" onClick={() => handleChange(null, 1)} style={{background:'linear-gradient(to right, #2196F3 30%, #21CBF3 90%)', marginLeft:'8px'}}>
            <NavigateNextIcon style={{color:'white', padding:'initial'}} />
          </IconButton>
        </CardContent>
        </CardActions>
} */}
        
        {/* {
        selectedTags.map(tag => 
          (tag.type === "Categories") &&
          <div className={classes.section}>
          <Typography className={classes.tagTitles} variant="subtitle1">{tag.label}</Typography>
          {chipData.map((data) => {
            if(tag.label === data.type) {
            return (
                
                <Chip
                label={data.label}
                className={classes.chip}
                onClick={handleClick(data)}
                variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                color="primary"
                />
            )
            } else {
              return null;
            }
          })}
          </div>
        )} */}
        </div>
      </TabPanel>
      <TabPanel class="tabPanel" value={value} index={1}>
        <div class="tabPanel" className={classes.section}>
        <div className={classes.listItem} style={{background:'linear-gradient(to left, rgb(62, 199, 224), rgb(82, 107, 244))', color:'white', width:'100%', margin:'10px', marginLeft:'auto', marginRight:'auto', borderRadius:'0'}}>
        Add Descriptive tags to categorize your post and make it easily discoverable. Choose from main categories and add relevant tags for a more accurate description.
          </div>
        <Typography className={classes.tagTitles} variant="subtitle1">Select a category to see it's tags</Typography>
        {/* {selectedTags.length === 0 && 
        <Typography className={classes.tagSubTitles} variant="subtitle1">Select a category to see it's tags</Typography>
        } */}

        {
        // (query !== "") ? (
        // chipData.map((data) => {
        //   if(data.type === "Categories") {
        //   if(data.label.toLowerCase().includes(query.toLowerCase())) {
        //   return (
              
        //     <Chip
        //     label={data.label}
        //     className={classes.chip}
        //     onClick={handleClick(data)}
        //     variant={selectedTags.find((tag) => tag.label === data.label) ? "default" : "outlined"}
        //     color="primary"
        //     />
        // )
        // }}
        // })) : 
        
        chipData.map((data) => {
          if(data.type === "Categories") {
          return (
              
              <Chip
              label={data.label}
              className={classes.chip}
              onClick={handleClick(data)}
              variant={selectedTags.find((tag) => tag.label === data.label) ? "default" : "outlined"}
              color="primary"
              />
          )
        } else {
          return null;
        }})}
        {
        selectedTags.map(tag => 
          (tag.type === "Categories") &&
          <div className={classes.section}>
          <Typography className={classes.tagTitles} variant="subtitle1">{tag.label}</Typography>
          {chipData.map((data) => {
            if(tag.label === data.type) {
            return (
                
                <Chip
                label={data.label}
                className={classes.chip}
                onClick={handleClick(data)}
                variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                color="primary"
                />
            )
            } else {
              return null;
            }
          })}
          </div>
        )}
        </div>

      </TabPanel>
      <TabPanel class="tabPanel" value={value} index={2}>
      <div class="tabPanel" className={classes.section}>
      <div className={classes.listItem} style={{background:'radial-gradient(circle at top left, #9b59b6, #8e44ad)', color:'white', width:'100%', margin:'10px', marginLeft:'auto', marginRight:'auto', borderRadius:'0'}}>
        Vibe tags help describe the feeling or atmosphere associated with a destination and help to give other travelers an idea of what to expect when visiting the location you're sharing about.
          </div>
          <CardActions style={{borderBottom : !expanded && '1px solid #ededed'}}>
          <Typography variant="subtitle1" onClick={handleExpandClick} style={{color:'purple', cursor:'pointer'}}>Not sure which tag to use?</Typography>
         
          <IconButton size="small"
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          style={{padding:'initial'}}
        > 

          <ExpandMoreIcon style={{background:'#9b59b6', color:'white', borderRadius:'50%' }} />

        </IconButton>
        </CardActions>
        <CardContent>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          
          <div className={classes.list}>
          <Grid>
            <Grid item style={{display:'flex'}}>
          <div className={classes.listItem}>
            <span className={classes.bold2}>Peaceful</span><br></br>
            Use this tag to describe destinations that offer a sense of peace.
          </div>
          <div className={classes.listItem}>
            <span className={classes.bold2}>Relaxing</span><br></br>
            Use this tag to describe destinations that offer a relaxing environment.
          </div>
          </Grid>
          <Grid item style={{display:'flex'}}>
          <div className={classes.listItem}>
            <span className={classes.bold2}>Luxurious</span><br></br>
            Use this tag to describe destinations such as luxury hotels, that offer a high-end luxury environment.
          </div>
          <div className={classes.listItem}>
            <span className={classes.bold2}>Exciting</span><br></br>
            Add this tag for destinations that offer a sense of excitement. 
          </div>
          </Grid>
          <Grid item style={{display:'flex'}}>
          <div className={classes.listItem}>
            <span className={classes.bold2}>Adventurous</span><br></br>
            Use this tag to describe destinations that offer an adventurous experience. 
          </div>
          <div className={classes.listItem}>
            <span className={classes.bold2}>Romantic</span><br></br>
            Use this tag to describe destinations that offer a romantic and intimate atmosphere.
          </div>
          </Grid>
          <Grid item style={{display:'flex'}}>
          <div className={classes.listItem} style={{width:'47%'}}>
            <span className={classes.bold2}>Exotic</span><br></br>
            Use this tag to describe destinations that are unique and unusual.
          </div>
          <div className={classes.listItem} style={{width:'47%'}}>
            <span className={classes.bold2}>Spiritual</span><br></br>
            Use this tag to describe destinations that have a meditative or religious atmosphere.
          </div>
          </Grid>
          <Grid item style={{display:'flex'}}>
          <div className={classes.listItem} style={{width:'47%'}}>
            <span className={classes.bold2}>Scenic</span><br></br>
            Use this tag to describe destinations that are beautiful and picturesque.
          </div>
          <div className={classes.listItem} style={{width:'47%'}}>
            <span className={classes.bold2}>Awe-Inspiring</span><br></br>
            Use this tag to describe destinations that offer a truly breathtaking and inspiring atmosphere.
          </div>
          </Grid>
          <Grid item style={{display:'flex'}}>
          <div className={classes.listItem} style={{width:'47%'}}>
            <span className={classes.bold2}>Bustling</span><br></br>
            Use this tag to describe destinations that are lively and energetic.
          </div>
          <div className={classes.listItem} style={{width:'47%'}}>
            <span className={classes.bold2}>Charming</span><br></br>
            Use this tag to describe destinations that offer a quaint and pleasant atmosphere.
          </div>
          </Grid>
          <Grid item style={{display:'flex'}}>
          <div className={classes.listItem} style={{width:'47%'}}>
            <span className={classes.bold2}>Vibrant</span><br></br>
            Use this tag to describe destinations colorful and full of life.
          </div>
          <div className={classes.listItem} style={{width:'47%'}}>
            <span className={classes.bold2}>Cozy</span><br></br>
            Use this tag to describe destinations that offer a warm and inviting atmosphere.
          </div>
          </Grid>
          </Grid>
        </div>
        </Collapse>
        </CardContent>
        {        
        chipData.map((data) => {
          if(data.type === "Vibes") {
          return (
              
              <Chip
              label={data.label}
              className = {selectedTags.find(tag => tag.label === data.label) ? classes.vibesSelected : classes.vibesOutlined}
              onClick={handleClick(data)}
              variant={selectedTags.find((tag) => tag.label === data.label) ? "default" : "outlined"}
              color="primary"
              />
          )
        } else {
          return null;
        }})}
        {/* {
        selectedTags.map(tag => 
          (tag.type === "Categories") &&
          <div className={classes.section}>
          <Typography className={classes.tagTitles} variant="subtitle1">{tag.label}</Typography>
          {chipData.map((data) => {
            if(tag.label === data.type) {
            return (
                
                <Chip
                label={data.label}
                className={classes.chip}
                onClick={handleClick(data)}
                variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                color="primary"
                />
            )
            } else {
              return null;
            }
          })}
          </div>
        )} */}
        </div>
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        Item Three
      </TabPanel> */}
      <TabPanel class="tabPanel" value={value} index={3}>
      <div className={classes.listItem} style={{background:'#eb1661', color:'white', width:'100%', margin:'10px', marginLeft:'auto', marginRight:'auto', borderRadius:'0'}}>
      Activity tags let travelers see your content when searching for activities. They also let travelers know what activities can be found in the location you are sharing about.
          </div>
        {alphabet.map(cat => 
        <div className={classes.section}>
          <Typography className={classes.tagTitles} variant="subtitle2" style={{marginBottom:'10px'}}>{cat}</Typography>
         {chipData.map(data => 
          {
            if(data.type === "Activities" && data.label.charAt(0) === cat) {
              return (
                <Chip
                label={data.label}
                className={classes.chip}
                onClick={handleClick(data)}
                variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                color="secondary"
                />
            )
            } else {
              return null; 
            }
          })
        }</div>)}
        
      </TabPanel>
      {/* <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel> */}
    </div>
   )}
        {
        // (query === "") ? 
        // selectedTags.map(tag => 
        // (tag.type === "Categories") &&
        // <div className={classes.section}>
        // <Typography className={classes.tagTitles} variant="subtitle1">{tag.label}</Typography>
        // {chipData.map((data) => {
        //   if(tag.label === data.type) {
        //   return (
              
        //       <Chip
        //       label={data.label}
        //       className={classes.chip}
        //       onClick={handleClick(data)}
        //       variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
        //       color="primary"
        //       />
        //   )
        //   } else {
        //     return null;
        //   }
        // })}
        // </div>
        //  )
          // : 
          (
        // chipData.map(data => (
        //   (data.type !== "Categories" && data.label.toLowerCase().includes(query.toLowerCase())) && (
        //   <div className={classes.section}>
        //     <Typography className={classes.tagTitles} variant="subtitle1">{data.type}</Typography>
        //     <Chip
        //     label={data.label}
        //     className={classes.chip}
        //     onClick={handleClick(data)}
        //     variant={selectedTags.find((tag) => tag.label === data.label) ? "default" : "outlined"}
        //     color="primary"
        //     />
        //   </div>
        //   )
        // ))
        (query !== "") && (
        categoryArray.map(cat => 
          <div className={classes.section}>
          {chipData.find((dat) => 
          ((dat.label.toLowerCase().includes(query.toLowerCase()) && dat.type === cat))) && 
          <Typography className={classes.tagTitles} variant="subtitle1">{cat}</Typography>
          }
          <div>
          {chipData.map((data) => {
          if(data.label.toLowerCase().includes(query.toLowerCase()) && data.type === cat) {
            switch (data.type) {
              case "Activities":
                return <Chip
                    label={data.label}
                    className={classes.chip}
                    onClick={handleClick(data)}
                    variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                    color="secondary"
                    />
                break;
              
              case "Vibes":
                return <Chip
                    label={data.label}
                    className = {selectedTags.find(tag => tag.label === data.label) ? classes.vibesSelected : classes.vibesOutlined}
                    onClick={handleClick(data)}
                    variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                    
                    />
                break;

              // case value:
              //   return <Chip
              //       label={data.label}
              //       className={classes.chip}
              //       onClick={handleClick(data)}
              //       variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
              //       color="primary"
              //       />
              //   break;
            
              default:
                return <Chip
                    label={data.label}
                    className={classes.chip}
                    onClick={handleClick(data)}
                    variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
                    color="primary"
                    />
                break;
            }
          // return (
          //     <Chip
          //     label={data.label}
          //     className={classes.chip}
          //     onClick={handleClick(data)}
          //     variant={selectedTags.find(tag => tag.label === data.label) ? "default" : "outlined"}
          //     color={data.type === 'Activities' ? "secondary" : "primary"}
          //     />
          // )
          } else {
            return null; 
          }
        })}
        </div>
          </div>
            ))
          )
      }
        {/* <div className={classes.section}>
        <Typography className={classes.tagTitles} variant="subtitle1">Weather</Typography>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Nature"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Animals"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Urban"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Architecture"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Culture"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="History"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Religion"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Scenery & Landscapes"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Experiences"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Events"/>
        </div> */}
        {/* <div className={classes.section}>
        <Typography className={classes.tagTitles} variant="subtitle1">Seasons and Times of Day</Typography>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Summer"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Winter"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Autumn"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Spring"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Dawn"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Morning"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Religion"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Scenery & Landscapes"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Experiences"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Events"/>
        </div> */}
        {/* <div className={classes.section}>
        <Typography className={classes.tagTitles} variant="subtitle1">Sky Tags</Typography>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Sunrise"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Sunset"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Starry Skies"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Moonlit"/>
        <Chip className={classes.chip} variant="outlined" color="primary" label="Northern Lights"/>
        </div> */}
        <div ref={parentRef} style={{backgroundColor:'red', width:'100%'}}>

        </div>
        </DialogContent>
        <DialogActions style={{boxShadow:'0 0 10px -6.5px black'}}>
        <Button onClick={handleClose} color="secondary">
              Cancel
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
              Done
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default MaxWidthDialog;
