import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { DialogActions, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { blue } from '@material-ui/core/colors';
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
  } from "react-share";

  import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
  } from "react-share";
import { useState } from 'react';


  const emails = ['username@gmail.com', 'user02@gmail.com'];
  const useStyles = makeStyles({
    avatar: {
      backgroundColor: blue[100],
      color: blue[600],
    },
    icons: {
        borderRadius:'50%'
    },
    box: {
        // border:'1px solid black !important',
        marginBottom:'20px',
        marginTop:'20px',
        width:'70px'
    }
  });
  
  const SimpleDialog = (props) => {
    const classes = useStyles();
    const { onClose, selectedValue, open, postId } = props;
    const [copied, setCopied] = useState(false);
    const postURL = `https://travelaio.com/posts/post/${postId}`
  
    const handleClose = () => {
      onClose(selectedValue);
      setCopied(false);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(postURL)
        setCopied(true);
    }
  
    const handleListItemClick = (value) => {
      onClose(value);
    };
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title" style={{borderBottom:'1px solid #ededed', textAlign:'left'}}>Share This Post</DialogTitle>
        <Grid style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly', padding:'15px', flexWrap:'wrap'}}>
        <FacebookShareButton className={classes.box} url={postURL} onClick={handleClose}>
            <FacebookIcon className={classes.icons} size={40}></FacebookIcon>
            <Typography variant="subtitle2">Facebook</Typography>
        </FacebookShareButton>
        <TwitterShareButton className={classes.box} url={postURL}>
            <TwitterIcon className={classes.icons} size={40}></TwitterIcon>
            <Typography variant="subtitle2">Twitter</Typography>
        </TwitterShareButton>
        <WhatsappShareButton className={classes.box} url={postURL}>
            <WhatsappIcon className={classes.icons} size={40}></WhatsappIcon>
            <Typography variant="subtitle2">Whatsapp</Typography>
        </WhatsappShareButton>
        <TelegramShareButton className={classes.box} url={postURL}>
            <TelegramIcon className={classes.icons} size={40}></TelegramIcon>
            <Typography variant="subtitle2">Telegram</Typography>
        </TelegramShareButton>
        <RedditShareButton className={classes.box} url={postURL}>
            <RedditIcon className={classes.icons} size={40}></RedditIcon>
            <Typography variant="subtitle2">Reddit</Typography>
        </RedditShareButton>
        <EmailShareButton className={classes.box} url={postURL}>
            <EmailIcon className={classes.icons} size={40} bgStyle={{fill:'mediumseagreen'}}></EmailIcon>
            <Typography variant="subtitle2">Email</Typography>
        </EmailShareButton>
        <EmailShareButton className={classes.box} onClick={handleCopy}>
            <FileCopyIcon className={classes.icons} style={{height:'40px', width:'40px', padding:'8px', backgroundColor:'#ed4255', color:'white'}} />
            {copied ? 
            <Typography color="primary" variant="subtitle2">Copied!</Typography> :
            <Typography variant="subtitle2">Copy Link</Typography>}
        </EmailShareButton>

        {/* <Grid className={classes.box}>
        <IconButton>
            <FileCopyIcon className={classes.icons} size={40}/>
        </IconButton>
        <Typography variant="subtitle2">Copy Link</Typography>
        </Grid> */}
        </Grid>
        <DialogActions style={{borderTop:'1px solid #ededed'}}>
            <Button onClick={handleClose} color="secondary" variant="contained" size="small" style={{textTransform:'capitalize'}}>
                Cancel
            </Button>
        </DialogActions>
      </Dialog>
    );
  }
  
  SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
  };
  
  const SharePhoto = ({postId}) => {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);
    // const postURL = 'http://localhost:3000/posts/post/postId'
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = (value) => {
      setOpen(false);
      setSelectedValue(value);
    };
  
    return (
        <>
        <Button size="small" fullWidth onClick={handleClickOpen} style={{height:'2.5rem', paddingLeft:'15px', color:'#333', justifyContent:'left', textTransform:'capitalize', fontSize:'0.95rem'}}>
            <ShareIcon style={{color:'#333'}}/>&nbsp;Share
        </Button>
        <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} postId={postId} size="small"/>
        </>
    );
  }

export default SharePhoto;