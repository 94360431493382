import React, { useEffect, useState } from 'react';
import { Grid, Container, CircularProgress, Button } from '@material-ui/core';
import { useParams } from 'react-router';
import { verifyAccount } from '../../actions/auth';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resendVerification } from '../../actions/auth';


const Verification = () => {
    const [user, setuser] = useState(JSON.parse(localStorage.getItem('profile')));
    const dispatch = useDispatch();
    const history = useHistory();
    const {token} = useParams(); 
    // console.log(token);

    useEffect(() => {
        !(token == 'unverified') &&
        dispatch(verifyAccount({token:token}, history));
      }, [dispatch]);

      const handleClick = (e) => {
         dispatch(resendVerification(user?.result));
         alert("Verification code has been sent. Please check your email.")
      }

    return (
        <Container id="verifyContainer">
        {token == 'unverified' ? (
        <Grid container style={{display:'flex', justifyContent:'center'}}>
            <Grid item style={{width:'100%', textAlign:'center'}}>
                {/* <CircularProgress /> */}
            </Grid>
            <Grid item style={{width:'100%', textAlign:'center'}}>
                {/* <h2>{token}</h2> */}
                <h2>Please check your email and click the confirmation link to verify your account.</h2>
            </Grid>
            <Grid item style={{width:'100%', textAlign:'center'}}>
                {/* <h2>{token}</h2> */}
                <h3>Didn't recieve a verification code? Check your spam folder. If you still haven't received the code, click the button below to request a new one.</h3>
            </Grid>
            <Grid item>
                <Button fullWidth variant="contained" color="primary" onClick={handleClick}>
                    Resend Code
                </Button>
            </Grid>
        </Grid>
         ) : (
        <Grid container style={{display:'flex', justifyContent:'center'}}>
            <Grid item style={{width:'100%', textAlign:'center'}}>
                <CircularProgress />
            </Grid>
            <Grid>
            {/* <h2>{token}</h2> */}
                <h2>Verifying your account...</h2>
            </Grid>
        </Grid>
        )}
        </Container>
    )
}

export default Verification

