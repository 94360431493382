import { FETCH_ONE, UPDATE, LIKE, DISLIKE, DELETE, ADD_COMMENT_REFRESH } from '../constants/actionTypes';

export default (post = [], action) => {
  switch (action.type) {
    case FETCH_ONE:
      return action.payload;
    case LIKE:
      // return post.map((pst) => (pst.id === action.payload.id ? action.payload : pst));
      return action.payload;
    case DISLIKE:
      // return post.map((pst) => (pst.id === action.payload.id ? action.payload : pst));
      return action.payload;
    // case CREATE:
    //   return [...post, action.payload];
    case UPDATE:
      // return post.map((pst) => (pst.id === action.payload.id ? action.payload : pst));
      return action.payload;
    case DELETE:
      // return post.filter((pst) => pst.id !== action.payload);
      return action.payload;
    case ADD_COMMENT_REFRESH: 
      // return post.map((pst) => (pst.id === action.payload.id ? action.payload : pst));
      return action.payload;
    
    default:
      return post;
  }
};

