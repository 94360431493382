import { red } from '@material-ui/core/colors';
import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({

  appbar: {
    backgroundColor: theme.palette.background.paper,
    // backgroundColor:'red',
    color: '#333',
    marginBottom: '30px',
    transitionTimingFunction:'ease-in-out',
    position:'fixed',
    // position:'relative',
    // opacity:'0',
    transition:'0.5s',
    
  },
  navStick: {
    backgroundColor: theme.palette.background.paper,
    // color: '#333',
    // marginBottom: '30px',
    // // opacity:'0.9',
    opacity:'0',
    position:'fixed',
    // // transitionTimingFunction:'ease-in-out',
    // opacity:'1',
    // animation:'fade-in',
    transition:'0.5s',
    // transitionTimingFunction:'ease-in-out',
    
  },
  // #nav : {
  //   transitionTimingFunction:'ease-in-out',
  //   transition:'0.5s'
  // },
  icons : {

  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    textDecoration: 'none',
    color: '#333',
  },
  mobileTitle: {
      display: 'block',
    textDecoration: 'none',
    color: '#333',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
  //   padding: theme.spacing(0, 2),
  //   height: '100%',
  //   position: 'absolute',
  //   pointerEvents: 'none',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  border: '1px solid red'
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    // display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));