import React, { useState, useEffect, useRef } from 'react';
import { Card, Chip, Fab, Container, Grid, Avatar, CardHeader, CardActions, CardContent, CardMedia, Button, Typography, Menu, MenuItem } from '@material-ui/core/';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MessageIcon from '@material-ui/icons/Message';
import IconButton from '@material-ui/core/IconButton';
import MenuListComposition from './QuickReactions';
import Share from './Share';
import useStyles from './styles';


const LandingPostMobile = ({ post, paused, setPaused, scroll, setScroll }) => {
  const postRef = useRef();
  const classes = useStyles();
  

  return (
    <Card className={classes.landingCardMobile}>
      
      {post?.photoURL && (
      <CardMedia className={classes.landingMedia} image={post.photoURL} title={post.title}>
      </CardMedia>
      )}

      {post.videoURL && (
        <CardMedia>
        <iframe src={post.videoURL} width="100%" height="400px" style={{}}></iframe>
        
        </CardMedia>
        
      )}
          {post?.title && (
              <Grid container style={{backgroundColor:'white', borderBottom:'2px solid #FAFAFA', paddingTop:'10px', paddingBottom:'10px', display:'flex', alignItems:'center', justifyContent:'flex-start', textAlign:'left'}}>
              <Grid item xs={10} md={11} style={{paddingLeft:'10px'}}>
              <Typography variant="h6" style={{marginRight:'10px',color:'#000', display:'inline', alignItems:'center'}}>{post.title}&nbsp;
              {/* </Typography> */}
              {post.type === 'Article' && post?.AVTags?.length && (
      <Chip
              label={post?.AVTags.map(vt => vt)}
              // className={classes.chip}
              style={{margin:'3px',
              marginLeft:'auto',
              marginRight:'10px',
              marginBottom:'5px',
              background:'linear-gradient(to right, #11998e, #38ef7d)',
              color:'white'}}
              variant="default"
              size="small"
              />
        )}
        </Typography>
        </Grid>
        <Grid xs={2} md={1} style={{display:'flex', justifyContent:'center'}}>
          <Share postId={post.id} />          
        </Grid>
            </Grid>
          )}
              <div id="postRef" ref={postRef} />
      <Grid container xs={12} sm={12} md={12} style={{
        paddingLeft:'5px'
        }}>
        <Grid id="postProfilePic" item xs={2} sm={2} md={1} style={{
           display:'flex', justifyContent:'center', padding:'10px', paddingRight:''}}>
        <Avatar className={classes.large} src = {post?.profilePic ? post?.profilePic : null} style={{border:'', boxShadow:'0 0 2px darkGray', background:!post?.profilePic && 'linear-gradient(to left, #9121c2, #FF5E5E )' }}>{post?.creatorName.charAt(0)}</Avatar>
        </Grid>
        <Grid id="postUserName" item xs={9} sm={9} md={10} style={{
           display:'flex', flexDirection:'row', alignItems:'center', padding:'10px', paddingLeft:'5px'}}>
          <Typography variant="subtitle1" color="primary" style={{textDecoration:'none', marginLeft:'5px', textTransform:'capitalize'}}>&nbsp;{post.creatorName}&nbsp;</Typography>
        </Grid>
      </Grid>
      <CardContent id="homeCard">
        
        <Typography id="postCaption" variant="subtitle1" component="p" style={{color:'#000', paddingLeft:'2px', lineHeight:'1.5'}}>{post.caption || post.summary}</Typography>
        
      </CardContent>
      <CardActions disableSpacing style={{borderTop:'2px solid #FAFAFA', borderBottom:'2px solid #FAFAFA', paddingTop:'0px', paddingBottom:'0px'}}>
      <IconButton size="medium" color="secondary" disabled>
          <FavoriteBorderIcon color="secondary" fontSize="medium" />
      </IconButton>
      <IconButton size="medium" style={{color:'#526bf4', marginLeft:'-15px'}} disabled>
        <MessageIcon /> 
      </IconButton>
      <MenuListComposition placeName={post.placeName} />
      </CardActions>
    </Card>
  );
};

export default LandingPostMobile;
