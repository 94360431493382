import React, { useState, useEffect, useRef } from 'react';
import { Card, Chip, Fab, Container, Grid, Avatar, CardHeader, CardActions, CardContent, CardMedia, Button, Typography, Menu, MenuItem } from '@material-ui/core/';
import { Link, useHistory, useLocation } from 'react-router-dom';
// import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import DeleteIcon from '@material-ui/icons/Delete';
// import ThumbUpAltOutlined from '@material-ui/icons/ThumbUpAltOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MessageIcon from '@material-ui/icons/Message';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
// import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CommentDialog from '../../Comments/CommentDialog';
import CommentCards from '../../Comments/CommentCards';
import { getComments, addComment } from '../../../actions/comments';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { selectLocation } from '../../../actions/locations';
import { selectTag } from '../../../actions/posts';
import clsx from 'clsx';
import SendIcon from '@material-ui/icons/Send';
import SearchIcon from '@material-ui/icons/Search';
import ShareIcon from '@material-ui/icons/Share';
// import { formatDistance, formatDistanceStrict, subMinutes } from "date-fns";
import { formatDistanceStrict } from "date-fns";
import MenuListComposition from './QuickReactions';
import CancelIcon from '@material-ui/icons/Cancel';
import Share from './Share';
import SharePhoto from './SharePhoto';


import { likePost, dislikePost, deletePost } from '../../../actions/posts';
import EditFormDialog from '../EditPost/EditFormDialog';
import MaxWidthDialog from '../EditPost/EditForm';
import useStyles from './styles';
import { CHANGE_COMMENTS } from '../../../constants/actionTypes';


const Post = ({ post, viewingPost, setViewingPost }) => {
  const currentTime = new Date();
  const date = new Date();
  // const createdAt = formatDistance(subMinutes(currentTime, (post?.createdAt || 0)), { addSuffix: true });
  if(post?.createdAt){
    var createdAtTime = formatDistanceStrict(new Date(post?.createdAt), new Date(currentTime), {addSuffix:false})
        var createdAtDigit = createdAtTime.split(' ')[0];
        var createdAtWord = createdAtTime.split(' ')[1];
        var createdAtLetter = createdAtWord.charAt(0);
        var createdAt = createdAtDigit + createdAtLetter;
  }
  // console.log(createdAt);
  const postRef = useRef();
  const likes = useSelector((state) => state.likes);
  const [selectedLocation, setSelectedLocation] = useState({latitude: '', longitude: '', coordinates: '', location: '', placeName: '', name:'', country: ''});
  const [selectedTag, setSelectedTag] = useState('');
  // const postLikes = likes.map(like => like.postId === post.id ? like : null)
  // console.log(likes);
  // console.log(postLikes);
  // const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('profile'));
  const [expanded, setExpanded] = React.useState(false);
  const [commentsExpanded, setCommentsExpanded] = useState(false);
  const [commenting, setCommenting] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [postLikes, setPostLikes] = useState(post?.likes);
  const [postType, setPostType] = useState(post.type);
  const [commentData, setCommentData] = useState({id:'', postId:'', userId:'', commentBody:''});
  const comments = useSelector(state => state.comments);

  // const useLoadPostComments = (postId) => {
  //   const [prevPostId, setPrevPostId] = useState(null);
  //   useEffect(() => {
  //       if (postId !== prevPostId) {
  //           dispatch(getComments(postId));
  //           setPrevPostId(postId);
  //       }
  //   }, [postId, prevPostId, dispatch]);
  // }

  // useLoadPostComments(post.id);


  useEffect(() => {
    setPostLikes(post.likes);
    setPostType(post.type);
  }, [post]);
  

  const userId = user?.result?.googleId || user?.result?.id; 
  // const hasLiked = (post?.likes.filter((like) => like === userId));
  // const hasLiked = postLikes.find((like) => like === (user?.result?.googleId || user?.result?.id))

  const handleLike = () => {
    if(post?.likes.find((like) => like === (user?.result?.googleId || user?.result?.id))) {
      setPostLikes(post?.likes.filter((id) => id !== userId));
      dispatch(dislikePost(post.id));
    } else {
      setPostLikes([...post?.likes, userId]);
      dispatch(likePost(post.id));
      console.log('like');
    }
  }

  // console.log(user);
  // console.log(post.creator);
  // console.log(post.creatorName);
  // console.log(post.profilePic);
  // console.log(post);

  // useEffect(() => {
  //   if(commentsExpanded) {
  //     postRef.current.scrollIntoView({behavior:'smooth', block:'nearest'});
  //   }
  // }, [commentsExpanded]);

  const handleCommenting = () => {
    setCommenting(true);
    setExpanded(false);
    if(post?.commentCount > 0) {
      // if(comments?.length === 0) {
    if(!commentsExpanded){
      dispatch({ type: CHANGE_COMMENTS})
    }
    
    dispatch(getComments(post.id))
    // }
    setCommentsExpanded(true);
    }
  } 
// COMMENTING 
  const handleUncommenting = () => {
    if(commentData.commentBody === '') {
    setCommenting(false);
    // setCommentsExpanded(false);
    }
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if(commentsExpanded) {
      postRef.current.scrollIntoView({behavior:'smooth', block:'start'});
    }
  }, [commentsExpanded])
  

  const handleViewComments = () => {
    dispatch({ type: CHANGE_COMMENTS});
    dispatch(getComments(post.id))
    setCommentsExpanded(!commentsExpanded);
    // .then(() => (postRef?.current.scrollIntoView({behavior:'smooth'})))
  };

  const handleClick = () => {
    setSelectedLocation({...selectedLocation, latitude: `${post.latitude}`, longitude: `${post.longitude}`, coordinates:`${post.coordinates}`, location:`${post.location}`, placeName:`${post.placeName}`, name:`${post.locName}`, country:`${post.country}` });  
} 

  const handleTagClick = (tag) => {
    setSelectedTag(tag)
    // dispatch(selectTag(selectedTag));
    //   history.push('/search/tags');
  }

  const Comment = () => { 
    console.log(commentData);
    if (commentData.commentBody.trim() === '') {
      return;
    }
    dispatch(addComment({...commentData, id: uuidv4(), postId:post.id, userId: userId, createdAt: date}, post.id)).then(()=>
    setCommentsExpanded(true));
    setCommentData({...commentData, commentBody:''});
  }

useEffect(() => {
  if(selectedTag !== ''){
    dispatch(selectTag(selectedTag));
      history.push('/search/tags');
  }
}, [selectedTag]);

const handleViewLocation = () => {
  if(selectedLocation.coordinates){
    dispatch(selectLocation(selectedLocation));
      history.push('/search');
  }
}

useEffect(() => {
  setShowMore(!showMore);
}, [commenting])


useEffect(() => {
  if(comments.length && comments[0]?.postId !== (post.id)) {
    setCommentsExpanded(false)
  }
}, [comments])


// const handleViewTag = () => {
//   if(selectedTag){
//     dispatch(selectTag(selectedTag));
//       history.push('/search/tags');
//   }
// }

  // const Dispaschte = () => {
  //   // const postLikes = likes.map(like => like.postId === post.id ? like : null)
  //   // if(likes.(like => like.postId === post.id)) {
  //     if(likes.postId === post.id){
  //     return likes.users.length;
  //   } else {
  //     return null;
  //   }
  // }
  
  
  const LongMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [buttonState, setButtonState] = useState(true);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
      setButtonState(true);
    };
  
    return (
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls="long-menu"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon  id ="longMenu" style={{color:'#333'}} />
        </IconButton>
        <Menu
          disableScrollLock={true}
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          {options.map((option) => (
            <MenuItem id="moreVertPopup" key={option} onClick={(e) => setButtonState(false)} disabled={buttonState === false} style={{width:'100%'}} >
              {option} 
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }

  const options = [
    <SharePhoto postId={post.id} />,
    <MaxWidthDialog post={post} postType = {post.type} postId={post.id} />,
    <Button size="small" color="secondary" onClick={() => dispatch(deletePost(post.id))} fullWidth style={{height:'2.5rem', paddingLeft:'15px', justifyContent:'left', textTransform:'capitalize', fontSize:'0.95rem'}}>
      <DeleteIcon fontSize="small" />&nbsp;&nbsp;Delete
    </Button>,
  ];
  
  const ITEM_HEIGHT = 48;

  const Likes = () => {
    const reactorName = user?.result?.name;
    // console.log(reactorName);
    if(postLikes) {
    if (postLikes?.length > 0) { 
      return postLikes.find((like) => like === (user?.result?.googleId || user?.result?.id))
        ? (
          <><IconButton size="medium" color="secondary" onClick={handleLike} disabled={!user?.result || (user.result?.status == 'Pending')}>
          <FavoriteIcon fontSize="medium" />
          </IconButton>
          {/* &nbsp;{post.likes.length > 2 ? `You and ${post.likes.length - 1} others` : `${post.likes.length} like${post.likes.length > 1 ? 's' : ''}` } */}
          </>
        ) : (
          <><IconButton size="medium" color="secondary" onClick={handleLike} disabled={!user?.result || (user.result?.status == 'Pending')}>
            <FavoriteBorderIcon fontSize="medium" />
            </IconButton>
            {/* &nbsp;{post.likes.length} {post.likes.length === 1 ? 'Like' : 'Likes'} */}
            </>
        );
    } }
    return <><IconButton size="medium" color="secondary" onClick={handleLike} disabled={!user?.result || (user?.result?.status == 'Pending')}>
             <FavoriteBorderIcon fontSize="medium"/> 
             {/* &nbsp;Like; */}
             </IconButton>
             </>
             
  };

  return (
    // <Card className={classes.card}>
    //   {/* <CardHeader
    //     // avatar = {<Avatar className = {classes.small} src = {post.creator}>{post.name.charAt(0)}</Avatar>}
    //     title = {<Typography variant="h6">{post.title || post.caption}</Typography>}
    //     action = {(user?.result?.googleId === post?.creator || user?.result?.id === post?.creator) && (
    //     //     <EditFormDialog postId={post._id} style={{backgroundColor:'red', marginTop:8}}/>
            
             
              // <EditFormDialog postId={post.id} />
            
          
    //       )}
    //     // subheader = {<Typography variant="body2">{moment(post.createdAt).fromNow()}</Typography>}
    //   /> */}
    //   {post.photoURL && (
    //   <CardMedia className={classes.media} image={post.photoURL} title={post.title} />
    //   )}

    //   {post.videoURL && (
    //   // <CardMedia className={classes.media} video={post.videoURL} title={post.title} />
    //   <iframe src={post.videoURL} width="100%" height="400px" title="Iframe Example"></iframe>
    //   )}
    //   {/* <div className={classes.overlay}>
    //     <Typography variant="h6">{post.name}</Typography>
    //     <Typography variant="body2">{moment(post.createdAt).fromNow()}</Typography>
    //   </div> */}
      
    //   <div className={classes.details}>
    //     <Typography variant="body2" color="textSecondary" component="h2">{(post.tags) ? post.tags.map((tag) => `#${tag} `) : 'no tagzz'}</Typography>
    //   </div>
    //   <Typography className={classes.title} gutterBottom variant="h5" component="h2">{post.title}</Typography>
    //   <CardContent>
    //   <div style={{display: 'flex'}}>
    //   <LocationOnIcon color="primary" fontSize="small" />
    //   <Typography variant="body2" color="textSecondary" component="p">{post.placeName}</Typography>
    //   </div>
    //     <Typography variant="body2" color="textSecondary" component="p">{post.message}</Typography>
    //   </CardContent>
    //   <CardActions className={classes.cardActions}>
    //   {/* <p color="secondary"> */}
    //     {/* {likes.length}</p> */}
    //     {/* <Button size="small" color="secondary" disabled={!user?.result} onClick={() => dispatch(getLikes(post.id))}> */}
    //       {/* <Dispaschte /> */}
    //        {/* Dispaschte      </Button> */}
    //     {/* <Button size="small" color="primary" disabled={!user?.result} onClick={() => dispatch(likePost(post.id))}> */}
    //       <Likes />

    //       <CommentDialog postId={post.id} userId = {user?.result?.googleId || user?.result?.id} />
    //     {/* </Button> */}
    //     {/* <Button size="small" color="primary" disabled={!user?.result} onClick={() => dispatch(dislikePost(post.id))}>
    //       Dislike Me
    //     </Button> */}
    //     {(user?.result?.googleId === post?.creator || user?.result?.id === post?.creator) && (
        // <Button size="small" color="secondary" onClick={() => dispatch(deletePost(post.id))}>
        //   <DeleteIcon fontSize="small" /> Delete
        // </Button>
        
    //     )}
    //   </CardActions>
    // </Card>




    <Card className={classes.card}>
      
      {post?.photoURL && (
      <CardMedia className={classes.media} image={post.photoURL} title={post.title}>
        {/* {post?.title && (
        <Grid container style={{backgroundColor:'rgba(0,0,0,0.8)', paddingTop:'10px', paddingBottom:'10px', display:'flex', alignItems:'center', justifyContent:'center', textAlign:'left'}}>
          <Typography variant="h5" style={{marginLeft:'10px', marginRight:'10px', width:'100%',color:'#EDEDED'}}>{post.title}</Typography>
        </Grid>
        )} */}
      </CardMedia>
      )}

      {post.videoURL && (
        <CardMedia>
        <iframe src={post.videoURL} width="100%" height="400px" style={{}}></iframe>
        
        </CardMedia>
        
      )}
          {post?.title && (
              <Grid container style={{backgroundColor:'white', borderBottom:'2px solid #FAFAFA', paddingTop:'10px', paddingBottom:'10px', display:'flex', alignItems:'center', justifyContent:'flex-start', textAlign:'left'}}>
              <Grid item xs={10} md={11} style={{paddingLeft:'10px'}}>
              <Typography variant="h6" style={{marginRight:'10px',color:'#000', display:'inline', alignItems:'center'}}>{post.title}&nbsp;
              {/* </Typography> */}
              {(post.type === 'Article' && post?.AVTags.length > 0) && (
      <Chip
              label={post?.AVTags.map(vt => vt)}
              style={{margin:'3px',
              marginLeft:'auto',
              marginRight:'10px',
              marginBottom:'5px',
              background:'linear-gradient(to right, #11998e, #38ef7d)',
              color:'white'}}
              variant="default"
              size="small"
              onClick={() => handleTagClick(post?.AVTags.map(vt => vt))}
              />
        )}
        {(post.type === 'Video' && post?.AVTags?.length > 0) && (
      <Chip
              label={post?.AVTags.map(vt => vt)}
              // className={classes.chip}
              style={{margin:'3px',
              marginLeft:'auto',
              marginRight:'10px',
              marginBottom:'5px',
              background:'linear-gradient(to right, #FD297B, #FF655B)',
              color:'white'}}
              variant="default"
              size="small"
              onClick={() => handleTagClick(post?.AVTags.map(vt => vt))}
              />
        )}
        </Typography>
        </Grid>
        <Grid xs={2} md={1} style={{display:'flex', justifyContent:'center'}}>
          <Share postId={post.id} />          
        </Grid>
            </Grid>
          )}
              {/* <Typography variant="h5" style={{marginLeft:'10px', marginTop:'10px', marginRight:'10px'}}>Top 10 things to do in Sri Lanka</Typography> */}
              <div id="postRef" ref={postRef} />
      <Grid container xs={12} sm={12} md={12} style={{
        // backgroundColor:'red',
        // borderBottom:'1px solid #EDEDED',
        // borderBottom:'2px solid #FAFAFA',
        // marginBottom:'5px'
        }}>
        <Grid id="postProfilePic" item xs={2} sm={2} md={1} style={{
          // backgroundColor:'red',
           display:'flex', justifyContent:'center', padding:'10px', paddingRight:''}}>
          {/* <img style={{border: '5px solid #F5F5F5', boxShadow: '0 0 2px silver', borderRadius:'50%', height:'40px', width:'40px'}} src = "https://res.cloudinary.com/travelaio/image/upload/v1630150067/Norway_llldp2.jpg"></img> */}
        <Avatar className={classes.large} src = {post?.profilePic ? post?.profilePic : null}  component={Link} to={`/${post.creator}`} style={{border:'', boxShadow:'0 0 2px darkGray', background:!post?.profilePic && 'linear-gradient(to left, #9121c2, #FF5E5E )', textDecoration:'none' }}>{post?.creatorName?.charAt(0)}</Avatar>
        </Grid>
        <Grid id="postUserName" item xs={9} sm={9} md={10} style={{
          // backgroundColor:'green',
           display:'flex', flexDirection:'row', alignItems:'center', padding:'10px', paddingLeft:'5px'}}>
          <Typography variant="subtitle1" color="primary" component={Link} to={`/${post.creator}`} style={{textDecoration:'none'}}>&nbsp;{post.creatorName}&nbsp;</Typography>
          <Typography variant="subtitle2" style={{color:'grey', marginTop:'3px'}}>	&#183;&nbsp;{createdAt}</Typography>
        </Grid>
          
        {((user?.result?.googleId === post?.creator || user?.result?.id === post?.creator) || user?.result?.googleId === '110432610290531512807') ? (
        <>
         <Grid item xs={1} sm={1} md={1} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
          <LongMenu />
        </Grid>
        </>
         ) : (post?.type === 'Photo') && (
          <Grid xs={1} md={1} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
          <Share postId={post.id} />          
        </Grid>
         )}
        
      </Grid>
      <CardContent id="homeCard">
      {/* <Chip
        icon={<LocationOnIcon />}
        label={post.placeName}
        // onDelete={handleDelete}
        color="primary"
      /> */}

      {/* <Grid container style={{display: 'flex', backgroundColor:'red', alignItems:'center'}}>
        <LocationOnIcon color="primary" fontSize="medium" />
        <Typography variant="subtitle2">{post.placeName}</Typography>
      </Grid> */}

        {/* <Typography variant="h6">Top 10 things to do in Sri Lanka</Typography> */}
        
        <Typography id="postCaption" variant="subtitle1" component="p" style={{color:'#000', paddingLeft:'2px', lineHeight:'1.5'}}>{post.caption || post.summary}</Typography>
        {/* <Chip
        icon={<LocationOnIcon />}
        label={post.placeName}
        // onDelete={handleDelete}
        color="primary"
        style={{marginTop:'10px'}}
        // variant="contained"
      /> */}
      </CardContent>
      {/* <Chip onClick={handleClick}
        icon={<LocationOnIcon />}
        label={post.placeName}
        // onDelete={handleDelete}
        color="primary"
        style={{marginLeft:'10px'}}
        variant="contained"
      /> */}
      <CardActions disableSpacing style={{borderTop:'2px solid #FAFAFA', borderBottom:'2px solid #FAFAFA', paddingTop:'0px', paddingBottom:'0px'}}>
      <Likes />
      {userId ? (
        <Typography variant="subtitle2" color="secondary" style={{marginLeft:'-8px'}}>{postLikes?.length}</Typography>
      ) : (
        <Typography variant="subtitle2"  style={{marginLeft:'-8px', color:'silver'}}>{postLikes?.length}</Typography>
      )}
      <IconButton size="medium" style={{color:'#526bf4'}} onClick={handleViewComments}>
        <MessageIcon /> 
      </IconButton>
      {/* <CommentDialog postId={post.id} userId={user?.result?.googleId || user?.result?.id} userStatus = {user?.result?.status} commentCount={post.commentCount} /> */}
      <Typography variant="subtitle2" color="primary" style={{marginLeft:'-8px'}}>{post.commentCount}</Typography>
      {/* <Typography>{postLikes}</Typography> */}
      {/* <Chip onClick={handleClick}
        icon={<LocationOnIcon />}
        label={post.placeName}
        // onDelete={handleDelete}
        color="primary"
        style={{marginLeft:'auto', maxWidth:'60%', background:'linear-gradient(to left, #3ec7e0,#526bf4)'}}
        // variant="contained"
      /> */}
      {/* <Typography>{commentData.commentBody}</Typography> */}
      <MenuListComposition placeName={post.placeName} coordinates={post.coordinates} placeDetails={selectedLocation} handleClick={handleClick} viewLocation={handleViewLocation} />

      </CardActions>
      <Collapse in={commentsExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          <CommentCards commentCount={post.commentCount} comments={comments} postId={post.id} />
        </CardContent>
      </Collapse>
      {post.type !== 'Article' && (
      <CardActions style = {{backgroundColor:'#FAFAFA', padding:'8px'}}>
        
      <div id="tagSearch" style={{justifyContent:'flex-start', marginLeft:'5px', width:commenting ? '88%' : '50%', transition:'ease-in-out 0.8s'}}>
          {/* <SearchIcon style={{backgroundColor:'#ededed', borderTopLeftRadius: '25px', borderBottomLeftRadius: '25px', padding:'12px', fontSize:'2.8rem'}}/> */}
        <input
        autoComplete='off'
        id="tagSearchInput"
        class="pac-target-input"
        onChange={(e) => setCommentData({ ...commentData, commentBody: e.target.value })}
        onFocus={handleCommenting}
        onBlur={handleUncommenting}
        placeholder="Add a Comment"
        multiline = "true"
        autoFocus={commenting ? true : false}
        style={{width:'100%', borderTopLeftRadius: '25px', borderBottomLeftRadius: '25px', paddingLeft:'15px', paddingRight:'45px'}}
        value={commentData?.commentBody}
      />
      {(commenting && commentData?.commentBody !== '') && (
      <i style={{marginLeft:'-40px'}}>
        <IconButton size="small" onClick={() => setCommentData({...commentData, commentBody: ''})}>
          <CancelIcon />
        </IconButton>
      </i>
      )}
      </div>
      {!commenting ? (
      <div className={showMore ? classes.notShowingMore : classes.showingMore} style={{display:'flex', justifyContent:'center', alignItems:'center', marginLeft:'auto'}}>
      <Typography variant="subtitle2" color="primary" onClick={handleExpandClick} style={{cursor:'pointer', marginLeft:'auto'}}>View Tags</Typography>
      <IconButton size="small"
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          style={{marginLeft:'5px'}}
        > 
          {/* <Fab size="small" style={{backgroundColor:'#EDEDED'}}> */}
          <ExpandMoreIcon style={{backgroundColor:'#526bf4', color:'white', borderRadius:'50%'}} />

        </IconButton>
        </div>
      ) : (
        <IconButton size="small" style={{padding:'5px', margin:'auto'}} onClick={Comment}>
          <SendIcon className={showMore ? classes.showingMore : classes.notShowingMore} style={{color:'#526bf4'}} />
        </IconButton>
        // <Button variant="contained" color="primary" onClick={Comment} className={showMore ? classes.showingMore : classes.notShowingMore} style={{marginRight:'8px', borderRadius:'25px', textTransform:'capitalize'}}>Post</Button>
      )}
      
        </CardActions>
      )}

        <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>

        {(post?.generalTags?.length) > 0 &&
        <div className={classes.section} style={{display:'flex', flexDirection:'column', marginBottom:'7px', marginTop:'0px'}}>
        <Typography className={classes.tagTitles} variant="subtitle1" style={{color:'darkgrey' }}>Tags</Typography>

        <div style={{display:'flex', alignItems:'center', flexDirection:'row', flexWrap:'wrap'}}>
        {post?.generalTags && post?.generalTags.map(tag => 
          (post?.generalTags?.length) > 1 ? (
            <Typography variant='subtitle2' color="primary" onClick={() => handleTagClick(tag)} style={{cursor:'pointer'}}>{tag}&nbsp;&#183;&nbsp;</Typography>
          ) : (
            <Typography variant='subtitle2' color="primary" onClick={() => handleTagClick(tag)} style={{cursor:'pointer'}}>{tag}</Typography>
          )
        )}
        </div>
        </div>
        }

        {(post?.vibeTags?.length > 0) &&
        <div className={classes.section} style={{display:'flex', flexDirection:'column', marginBottom:'7px'}}>
        
        <Typography className={classes.tagTitles} variant="subtitle1" style={{color:'darkgrey'}}>Vibes</Typography>
        
        <div style={{display:'flex', alignItems:'center', flexDirection:'row', flexWrap:'wrap'}}>
        {post?.vibeTags && post?.vibeTags.map(tag => 
          (post?.vibeTags?.length) > 1 ? (
            <Typography variant='subtitle2' style={{color:'purple', cursor:'pointer'}} onClick={() => handleTagClick(tag)}>{tag}&nbsp;&#183;&nbsp;</Typography>
          ) : (
            <Typography variant='subtitle2' style={{color:'purple', cursor:'pointer'}} onClick={() => handleTagClick(tag)}>{tag}</Typography>
          )
        )}
        </div>
        </div>
        }

        {(post?.activityTags?.length > 0) &&
        <div className={classes.section} style={{display:'flex', flexDirection:'column', marginBottom:'7px'}}>

        <Typography className={classes.tagTitles} variant="subtitle1" style={{color:'darkgrey'}}>Activities</Typography>
        <div style={{display:'flex', alignItems:'center', flexDirection:'row', flexWrap:'wrap'}}>
        {post?.activityTags && post?.activityTags.map(tag => 
          (post?.activityTags?.length) > 1 ? (
            <Typography variant='subtitle2' color="secondary" style={{cursor:'pointer'}} onClick={() => handleTagClick(tag)}>{tag}&nbsp;&#183;&nbsp;</Typography>
          ) : (
            <Typography variant='subtitle2' color="secondary" style={{cursor:'pointer'}} onClick={() => handleTagClick(tag)}>{tag}</Typography>
          )
        )}
        </div>
        </div>
        }

        {/* <div className={classes.section}>
        <Typography className={classes.tagTitles} variant="subtitle1">Vibes</Typography>
        <Typography variant='subtitle2' style={{color:'purple'}}>Luxurious</Typography>
        </div>
        <div className={classes.section}>
        <Typography className={classes.tagTitles} variant="subtitle1">Activities</Typography>
        <Typography variant='subtitle2' color="Secondary">Sky Diving</Typography>
        </div> */}
        </CardContent>
      </Collapse>
      
      {post?.content && (
        <>
        <CardActions style = {{backgroundColor:'#FAFAFA', padding:'5px'}}>
        <div id="tagSearch" style={{justifyContent:'flex-start', marginLeft:'5px', width:commenting ? '88%' : '50%', transition:'ease-in-out 0.8s'}}>
          {/* <SearchIcon style={{backgroundColor:'#ededed', borderTopLeftRadius: '25px', borderBottomLeftRadius: '25px', padding:'12px', fontSize:'2.8rem'}}/> */}
        <input
        autoComplete='off'
        id="tagSearchInput"
        class="pac-target-input"
        onChange={(e) => setCommentData({ ...commentData, commentBody: e.target.value })}
        onFocus={handleCommenting}
        onBlur={handleUncommenting}
        placeholder="Add a Comment"
        multiline = "true"
        autoFocus={commenting ? true : false}
        style={{width:'100%', borderTopLeftRadius: '25px', borderBottomLeftRadius: '25px', paddingLeft:'15px', paddingRight:'45px'}}
        value={commentData?.commentBody}
        // value={query}
      />
      </div>
      {!commenting ? (
      <div className={showMore ? classes.notShowingMore : classes.showingMore} style={{display:'flex', justifyContent:'center', alignItems:'center', marginLeft:'auto'}}>
      <Typography variant="subtitle2" color="primary" onClick={handleExpandClick} style={{cursor:'pointer', marginLeft:'auto'}}>Read Full Article</Typography>
      <IconButton size="small"
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          style={{marginLeft:'5px'}}
        > 
          {/* <Fab size="small" style={{backgroundColor:'#EDEDED'}}> */}
          <ExpandMoreIcon style={{backgroundColor:'#526bf4', color:'white', borderRadius:'50%' }} />

        </IconButton>
        </div>
        ) : (
          <IconButton size="small" style={{padding:'5px', margin:'auto'}} onClick={Comment}>
            <SendIcon className={showMore ? classes.showingMore : classes.notShowingMore} style={{color:'#526bf4'}} />
          </IconButton>
        )}

        </CardActions>
      
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>{post.content}</Typography>
        </CardContent>
      </Collapse>
      </>
      )}
    </Card>
  );
};

export default Post;
