import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Container, Grow, Grid, Typography, Button } from '@material-ui/core';

const Terms = () => {

  return (
    <Grow in>
      <Container id="homeContainer">
        <Grid container xs={12} sm={7} md={6} justify="space-between" alignItems="stretch" style={{display:'flex', justifyContent:'center', margin:'auto', padding:'20px', boxShadow: '0 0 4px grey', flexDirection:'column'}} >
          {/* <Grid item xs={12} sm={7} md={6} style={{display:'flex', justifyContent:'center'}}> */}
            <h3 style={{textAlign:'center'}}>Terms Of Use</h3>
            <p>These Terms of Use (or "Terms") govern your use of TravelAIO, except where we expressly state that separate terms (and not these) apply, and provide information about the TravelAIO Service (the "Service"), outlined below. When you create an TravelAIO account or use TravelAIO, you agree to these Terms.</p>
            <p style={{fontWeight:'bold'}}>The TravelAIO Service</p>
            <p>We agree to provide you with the TravelAIO Service. It is made up of the following aspects:</p>
            <ul>
                <li>Offering personalized opportunities to search for and interact with travel destinations.</li>
                <li>Acting as a platform for users to share their travel related content with other travel enthusiasts, as well as interact with others' content.</li>
                <li>Connecting you with travel destinations, venues, events, activities and travel enthusiasts.</li>
                <li>Ensuring access to our Service.</li>
                <li>Adding more features and capabilities to our Service to make travel related activities easier and more efficient for users. </li>
            </ul>
            <p style={{fontWeight:'bold'}}>Your commitments:</p>
            <ul>
                <li>You must be at least 13 years old or the minimum legal age in your country to use TravelAIO.</li>
                <li>We must not have previously disabled your account for violation of law or any of our policies.</li>
                <li>You can't do anything unlawful, misleading or fraudulent or for an illegal or unauthorized purpose.</li>
                <li>You can't attempt to create accounts or access or collect information in unauthorized ways.</li>
                <li>You can't post someone else's private or confidential information or do anything to violate their intellectual property rights.</li>
                <li>You can't modify, translate, create derivative works of or reverse engineer our products or their components.</li>
                <li>You cannot post content that is not related to travel. </li>
                <li>You cannot post anything that is rude, vulgar, sexually explicit or demeaning.</li>
                <li>You cannot provide false information about a travel destination to artificially damage it's reputation.</li>
            </ul>

            <p style={{fontWeight:'bold'}}>Permissions you give us:</p>
            <ul>
                <li style={{textDecoration:'underline'}}>We do not claim ownership of your content, but you grant us a licence to use it.</li>
                <p>You have the rights in your content. We do not claim ownership of your content that you post on or through the Service and you are free to share your content with anyone else, wherever you choose. However, we need certain legal permissions from you (known as a "licence") to provide the Service. When you share, post or upload content that is covered by intellectual property rights (such as photos or videos) on or in connection with our Service, you hereby grant to us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide licence to host, use, distribute, modify, run, copy, publicly perform or display, translate and create derivative works of your content.</p>

                <li style={{textDecoration:'underline'}}>Permission to use your username, display picture, and information about your relationships with other users, locations and content.</li>
                <p>You give us permission to show your username, profile picture and information about your actions (such as likes) or relationships (such as follows) next to or in connection with accounts.</p>
                
            </ul>

            <p style={{fontWeight:'bold'}}>Content removal and disabling or terminating your account:</p>
            <p>We can remove any content or information you share on the Service, or terminate your account if we believe that it violates our Terms Of Use or our policies. </p>

            <p style={{fontWeight:'bold'}}>Agreement:</p>
            <ul>
                <li>Any amendment or waiver to this agreement must be written by us. If we fail to enforce any aspect of this agreement, it will not be a waiver.</li>
                <li>We reserve all rights not expressly granted to you.</li>
            </ul>
            <p style={{fontWeight:'bold'}}>Who has rights under this agreement:</p>
            <ul>
                <li>This agreement does not give rights to any third parties.</li>
                <li>You cannot transfer your rights or obligations under this agreement without our consent.</li>
                <li>Our rights and obligations can be assigned to others. For example, this could occur if our ownership changes (as in a merger, acquisition or sale of assets) or by law.</li>
            </ul>
            <p style={{fontWeight:'bold'}}>Who is responsible if something happens:</p>
            <ul>
                <li>Our Service is provided "as is", and we can't guarantee that it will be safe and secure or will work perfectly all the time. TO THE EXTENT PERMITTED BY LAW, WE ALSO DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</li>
                <li>We do not control what other users do or say, and we aren't responsible for their (or your) actions or conduct (whether online or offline) or content (including unlawful or objectionable content). We also aren't responsible for services and features offered by other people or companies, even if you access them through our Service.</li>
                <li>Our responsibility for anything that happens on the Service (also called "liability") is limited as much as the law will allow. If there is an issue with our Service, we can't know what all the possible impacts might be. You agree that we won't be responsible ("liable") for any lost profits, revenues, information or data, or consequential, special, indirect, exemplary, punitive or incidental damages arising out of or related to these Terms, even if we know that they are possible. This includes when we delete your content, information or account.</li>
                </ul>
                <p>We may change our Service and Policies, and we may need to make changes to these Terms so that they accurately reflect our Service and Policies. We may change these terms at any time without prior notification.</p>
                <p>You can contact us at support@travelaio.com</p>


        </Grid>
      </Container>
    </Grow>
  );
};

export default Terms;
